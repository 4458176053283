import { t } from "i18next";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { statisticsFormatDate } from "./../utils/helper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export const getBatchHolidayHeading = (roleType, deleteHolidayAlert) => {
  return [
    {
      field: "holidayDate",
      headerName: t("erps.batchholiday.page.date"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return statisticsFormatDate(params.value);
      },
    },
    {
      field: "holidayDescription",
      headerName: t("erps.batchholiday.page.description"),
      width: 348,
      sortable: false,
    },
    ...(roleType == "PA"
      ? [
          {
            field: "delete",
            headerName: "",
            align: "right",
            width: 50,
            sortable: false,
            renderCell: (params) => (
              <Tooltip title="Delete" placement="right">
                <IconButton
                  aria-label="delete"
                  alt="delete"
                  onClick={() => deleteHolidayAlert(params)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            ),
          },
        ]
      : []),
  ];
};

export const EMPTY_HOLIDAY_CRITERIA = {
  startOffset: 0,
  endOffset: 25,
};

import React, { useEffect, useState, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { getBillerPendingGridHeadings } from "../../constants/BillerManagementConstant";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../custom/containers/ErrorMessage";
import actions from "../../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { sanitizeQueryParams } from "../../utils/helper";

const BillerPendingApprovals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const userRoleTypes = useSelector((state) => state.userRoleTypeReducer);

  const userDetails = useSelector((state) => state.loginUserReducer?.user);
  const userRole = userDetails?.role?.role_type;
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const billerPendingData = useSelector(
    (state) => state.billerApprovalReducer?.billerPendingApprovalData
  );
  const billerPendingError = useSelector(
    (state) => state.billerApprovalReducer?.billerPendingApprovalError
  );
  const errorRef = useRef();
  const [data, setBillerPendingApprovalData] = useState(null);
  const [roleData, setRoleData] = useState(null);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [billerPendingError]);
  const {
    location: {
      state: { componentAccess },
    },
  } = props;

  useEffect(() => {
    dispatch(
      actions.userActions.getUserRoleTypeList(
        userDetails.fiNumber,
        userDetails.role.role_type,
        "manageusercomponent",
        t,
        setRoleData,
        false
      )
    );
    dispatch(actions.fiActions.getMasterFiDescriptionList(t));
  }, []);

  useEffect(() => {
    if (roleData) {
      setInitialLoading(true);
      dispatch(
        actions.billerPendingApprovalsActions.getPendingBillerApprovals(
          {
            userId: userDetails.id,
            roleIdList: roleData,
            languageCode: userDetails.languageCode || "E",
            fiNumber: userDetails.fiNumber,
            selectedFiNumber: selectedFiNumber,
            offset: 0,
            limit: 99,
          },
          setInitialLoading,
          t,
          setBillerPendingApprovalData
        )
      );
    }
  }, [roleData]);

  const paginateBillers = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    if (actualCriteria.startOffset < billerPendingData.length) {
      const rowPerPage = actualCriteria.endOffset - actualCriteria.startOffset;
      const pageNumber =
        actualCriteria.endOffset /
          (actualCriteria.endOffset - actualCriteria.startOffset) -
        1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setBillerPendingApprovalData(
        billerPendingData.slice(
          pageNumber * rowPerPage,
          (pageNumber + 1) * rowPerPage
        )
      );
    }
  };

  return (
    <div role="main">
      {billerPendingError ? (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={billerPendingError ? 2 : 0}
        >
          {billerPendingError && (
            <ErrorMessage error={billerPendingError} errorRef={errorRef} />
          )}
        </Box>
      ) : (
        ""
      )}

      <CommonContainerBox
        marginTop="30px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
        title={t("erps.pendingapprovals.page.title.", "Pending approvals")}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h1">
              {t("erps.pendingapprovals.page.title.", "Pending approvals")}
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "erps.pendingapprovals.page.description",
                  "Click on a transaction ID, in the table, to view details for the pending approval and either accept or decline the change."
                )}
              </Typography>
            </Grid>
            <Box mt={3} marginBottom={1}>
              <Typography variant="body1">
                {(billerPendingData && !initialLoading ? billerPendingData.length : 0) +
                  t(
                    "erps.pendingapprovals.page.description.pendingapprovals",
                    " pending approvals"
                  )}
              </Typography>
            </Box>
            {billerPendingData && data && !initialLoading ? (
              <Box>
                <>
                  {billerPendingData.length <= 100 ? (
                    <ERPSGrid
                      data={{
                        rows: data ? data : [],
                        count: data.length,
                        columns: getBillerPendingGridHeadings(
                          userDetails.userId,
                          componentAccess
                        ),
                      }}
                      dataGridWidth="dataGrid__box__container__pendingApproval__billers"
                      hideFooterPagination={true}
                      initialLoading={!(data && data?.length) && initialLoading}
                      userRole={userRole}
                      tableLabelText={t(
                        "erps.pendingapprovals.user.page.title"
                      )}
                    />
                  ) : (
                    <ERPSGrid
                      data={{
                        rows: data ? data : [],
                        count: billerPendingData ? billerPendingData.length : "0",
                        columns: getBillerPendingGridHeadings(
                          userDetails.userId,
                          componentAccess
                        ),
                      }}
                      dataGridWidth="dataGrid__box__container__pendingApproval__billers"
                      initialLoading={!(data && data?.length) && initialLoading}
                      pageNumber={pageNumber}
                      rowPerPage={rowPerPage}
                      userRole={userRole}
                      showPaginationBottom={true}
                      onAnyPaginationAction={paginateUsers}
                      tableLabelText={t(
                        "erps.pendingapprovals.user.page.title"
                      )}
                    />
                  )}
                </>
              </Box>
            ) : (
              <Box ml={40} mt={10} sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </CommonContainerBox>
    </div>
  );
};
export default withSidebar(BillerPendingApprovals);

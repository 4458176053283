import styled from "@emotion/styled";
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)((props) => ({
  borderWidth: "1px  ",
  borderRadius: "60px ",
  height: "35px ",
  marginTop: !props?.hasMargin ? "2rem " : "",
  textTransform: "none ",
  fontSize: "14px ",
  fontFamily: "Roboto Regular",

  // "& .MuiFormLabel-root.Mui-focused": {
  //   color: "#2A2C2E ",
  // },

  // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input ":
  //   {
  //     borderColor: "#2A2C2E !important",
  //   },
  //   "& .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{}
  "&:MuiOutlinedInput-root:hover": {
    borderColor: "#2A2C2E !important",
  },
  "&:MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    { borderColor: "#2A2C2E !important" },
}));

export default TextField;

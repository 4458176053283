import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withERPSButtonValidator = (Component) => (props) => {
  const { actionType, componentAccess } = props;
  const user = useSelector((state) => state.loginUserReducer.user);

  if (hasShowComponent({ componentAccess, actionType, user })) {
    return <Component {...props} />;
  } else {
    return null;
  }
};

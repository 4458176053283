import React, { useRef, useState, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { sanitizeQueryParams } from "../../utils/helper";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../custom/containers/ErrorMessage";
import ReportGrid from "./ReportGrid";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import {
  BSR,
  CBAS,
  CBANS,
  DSR,
  FIS,
  ALL_BILLERS,
  SPECIFIC_BILLER,
} from "./data";

import {
  findReportLabel,
  findSettlementLabel,
  findSortByLabel,
  formatAmount,
  getBillerIdWithName,
  skipFirstRecord,
  cbasAddRows,
  resultsTitle,
} from "./helper";

const ViewStatistics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let componentRef = useRef();
  const {
    location: { state, search },
  } = props;

  // State
  const [initialLoading, setInitialLoading] = useState(false);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);

  // Redux data
  const financialInstitutionResponse = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const viewStatisticsData = useSelector(
    (state) => state.viewStatisticsReducer?.viewStatisticsData
  );
  const viewStatisticsError = useSelector(
    (state) => state.viewStatisticsReducer?.viewStatisticsError
  );
  const viewStatisticsFilters = useSelector(
    (state) => state.viewStatisticsReducer?.viewStatisticsFilters
  );
  const viewStatisticsBiller = useSelector(
    (state) => state.viewStatisticsReducer?.viewStatisticsBiller
  );

  const {
    reportType,
    fromDate,
    toDate,
    showStatisticsFor,
    billerId,
    settlementType,
    sortResultsBy,
    periodType,
    periodValue,
  } = viewStatisticsFilters;

  const initialData = viewStatisticsData
    ? reportType == CBANS
      ? viewStatisticsData
      : skipFirstRecord(viewStatisticsData)
    : [];

  const initialDataWithTotal =
    viewStatisticsData && reportType == CBAS
      ? skipFirstRecord(cbasAddRows(viewStatisticsData))
      : [];

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    if (viewStatisticsData) {
      if (reportType == CBAS) {
        setPageData(initialData);
      } else {
        setPageData(
          initialData.slice(
            pageNumber * rowPerPage,
            (pageNumber + 1) * rowPerPage
          )
        );
      }
    }
  }, [viewStatisticsData]);

  const paginateData = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const hasReportTypeCBSA = reportType === CBAS;

    const limitCheck = hasReportTypeCBSA
      ? viewStatisticsData[0]?.totalTransactionCount
      : initialData.length;

    if (actualCriteria.startOffset < limitCheck) {
      const rowPerPage = actualCriteria.endOffset - actualCriteria.startOffset;
      const pageNumber =
        actualCriteria.endOffset /
          (actualCriteria.endOffset - actualCriteria.startOffset) -
        1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      if (hasReportTypeCBSA) {
        dispatch(
          actions.viewStatisticsActions.getViewStatistics(
            {
              reportType,
              fromDate,
              toDate,
              showStatisticsFor,
              billerId,
              settlementType,
              sortResultsBy,
              periodType,
              periodValue,
              fiNumber: fiDetails.fi_number,
              offset: pageNumber * rowPerPage,
              limit: (pageNumber + 1) * rowPerPage,
            },
            setInitialLoading,
            t
          )
        );
      } else {
        setPageData(
          initialData.slice(
            pageNumber * rowPerPage,
            (pageNumber + 1) * rowPerPage
          )
        );
      }
    }
  };

  const viewStatsCountBsr = () => {
    return viewStatisticsData ? skipFirstRecord(viewStatisticsData).length : 0;
  };

  const fiNumber = selectedFi?.toString() || loginUser.fiNumber.toString();
  let fiDetails = "";
  if (
    financialInstitutionResponse &&
    Array.isArray(financialInstitutionResponse)
  ) {
    fiDetails = financialInstitutionResponse.find(
      ({ fi_number }) => fi_number.toString() === fiNumber
    );
  } else {
    fiDetails = loginUser.fi;
  }

  useEffect(() => {
    setInitialLoading(true);
    if (billerId) {
      dispatch(
        actions.viewStatisticsActions.setStatisticsBiller(
          billerId,
          fiDetails.fi_number,
          {
            t,
          }
        )
      );
    }
    dispatch(
      actions.viewStatisticsActions.getViewStatistics(
        {
          reportType,
          fromDate,
          toDate,
          showStatisticsFor,
          billerId,
          settlementType,
          sortResultsBy,
          periodType,
          periodValue,
          fiNumber: fiDetails.fi_number,
        },
        setInitialLoading,
        t
      )
    );
  }, []);

  const getPageData = () => {
    if (reportType == CBAS) {
      return pageData;
    } else {
      return initialData?.length <= 100 ? initialData : pageData;
    }
  };

  return (
    <div role="main">
      {viewStatisticsError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={2}
          pl={3}
        >
          {viewStatisticsError && <ErrorMessage error={viewStatisticsError} />}
        </Box>
      )}
      <Box ml={3} sx={{ paddingRight: "20px" }} style={{overflow: 'auto'}}>
        <Breadcrumb
          {...props}
          componentAccess={state?.componentAccess}
          fromDetailPage={true}
        />
        {/* For Title update */}
        <CommonContainerBox title={findReportLabel(reportType)?.label} />
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {findReportLabel(reportType)?.label}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.statisticsEnquiry.page.label.statisticsParameters")}
          </Typography>
        </Box>

        {[FIS, CBAS, CBANS, DSR].includes(reportType) && (
          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="view__statistics__value">
              {reportType === DSR
                ? t("erps.statisticsEnquiry.page.label.day")
                : t("erps.statisticsEnquiry.page.label.fromDate")}
              <Typography variant="body2">{fromDate}</Typography>
            </Typography>
            {[FIS, CBAS, CBANS].includes(reportType) && (
              <Typography className="view__statistics__value left_space">
                {t("erps.statisticsEnquiry.page.label.toDate")}
                <Typography variant="body2">{toDate}</Typography>
              </Typography>
            )}
            <Typography className="view__statistics__value left_space">
              {t("erps.statisticsEnquiry.page.label.fi")}
              <Typography variant="body2">
                {`${fiDetails.fi_number} - ${fiDetails.fi_name}`}
              </Typography>
            </Typography>
          </Box>
        )}
        {[CBAS, CBANS, DSR, BSR].includes(reportType) && (
          <Box mt={3} sx={{ display: "flex" }}>
            {[CBAS, CBANS, BSR].includes(reportType) && (
              <Typography className="view__statistics__value">
                {t("erps.statisticsEnquiry.page.label.biller")}
                <Typography variant="body2">
                  {showStatisticsFor === ALL_BILLERS
                    ? t("erps.statisticsEnquiry.page.label.allBiller")
                    : viewStatisticsBiller
                    ? getBillerIdWithName(
                        billerId,
                        viewStatisticsBiller?.data?.billerName
                      )
                    : billerId}
                </Typography>
              </Typography>
            )}
            {reportType === DSR && (
              <Typography className="view__statistics__value">
                {t("erps.statisticsEnquiry.page.label.settlementType")}
                <Typography variant="body2">
                  {findSettlementLabel(settlementType, fiNumber)?.name}
                </Typography>
              </Typography>
            )}
            {[CBAS, DSR].includes(reportType) &&
              !(
                reportType === CBAS && showStatisticsFor === SPECIFIC_BILLER
              ) && (
                <Typography className="view__statistics__value left_space">
                  {t("erps.statisticsEnquiry.page.label.sortBy")}
                  <Typography variant="body2">
                    {findSortByLabel(sortResultsBy)?.name}
                  </Typography>
                </Typography>
              )}
          </Box>
        )}

        <Box mt={4}>
          <Typography variant="h3">
            {resultsTitle(reportType, viewStatsCountBsr())}
          </Typography>
        </Box>
        {!initialLoading &&
        viewStatisticsData &&
        viewStatisticsData.length > 0 ? (
          <>
            {/* FIS Results */}
            {reportType === FIS && (
              <Box mt={3} sx={{ display: "flex" }}>
                <Typography className="view__statistics__value">
                  {t("erps.statisticsEnquiry.page.label.totalVolumeForPeriod")}
                  <Typography variant="body2">
                    {viewStatisticsData[0].totalVolumnForPeriod}
                  </Typography>
                </Typography>
                <Typography className="view__statistics__value left_space">
                  {t("erps.statisticsEnquiry.page.label.totalAmountForPeriod")}
                  <Typography variant="body2">
                    {formatAmount(viewStatisticsData[0].totalAmountForPeriod)}
                  </Typography>
                </Typography>
                <Typography
                  variant="label"
                  className="view__statistics__value left_space"
                >
                  {t(
                    "erps.statisticsEnquiry.page.label.averageTransactionAmount"
                  )}
                  <Typography variant="body2">
                    {formatAmount(viewStatisticsData[0].averageTransaction)}
                  </Typography>
                </Typography>
              </Box>
            )}
            {/* CBAS Results */}
            {reportType === CBAS && pageData && pageData.length > 0 && (
              <Box mt={3}>
                <Grid item xl={8} lg={11} md={12} sm={12} xs={12}>
                  <ReportGrid
                    // viewStatisticsData={viewStatisticsData}
                    reportType={reportType}
                    initialData={initialData}
                    initialDataWithTotal={initialDataWithTotal}
                    viewStatisticsFirstData={
                      viewStatisticsData ? viewStatisticsData[0] : {}
                    }
                    data={pageData}
                    pageNumber={pageNumber}
                    rowPerPage={rowPerPage}
                    paginateData={paginateData}
                  />
                </Grid>
              </Box>
            )}
            {/* CBANS Results */}
            {reportType === CBANS && pageData && pageData.length > 0 && (
              <Box mt={3}>
                <Grid item xl={9} lg={12} md={12} sm={12} xs={12}>
                  <ReportGrid
                    // viewStatisticsData={viewStatisticsData}
                    reportType={reportType}
                    initialData={initialData}
                    data={pageData}
                    pageNumber={pageNumber}
                    rowPerPage={rowPerPage}
                    paginateData={paginateData}
                  />
                </Grid>
              </Box>
            )}
            {/* DSR Results */}
            {reportType === DSR && pageData && pageData.length > 0 && (
              <>
                <Box mt={3} sx={{ display: "flex" }}>
                  <Typography className="view__statistics__value">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalSettlementCount"
                    )}
                    <Typography variant="body2">
                      {viewStatisticsData[0]?.totalSettlementCount}
                    </Typography>
                  </Typography>
                  <Typography className="view__statistics__value left_space">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalSettlementAmount"
                    )}
                    <Typography variant="body2">
                      {formatAmount(
                        viewStatisticsData[0]?.totalSettlementAmount
                      )}
                    </Typography>
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Grid item xl={9} lg={12} md={12} sm={12} xs={12}>
                    <ReportGrid
                      // viewStatisticsData={viewStatisticsData}
                      reportType={reportType}
                      initialData={initialData}
                      data={pageData}
                      pageNumber={pageNumber}
                      rowPerPage={rowPerPage}
                      paginateData={paginateData}
                    />
                  </Grid>
                </Box>
              </>
            )}
            {/* BSR Results */}
            {reportType === BSR && pageData && pageData.length > 0 && (
              <>
                <Box mt={3} sx={{ display: "flex" }}>
                  <Typography className="view__statistics__value">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalNumberOfTransactions"
                    )}
                    <Typography variant="body2">
                      {viewStatisticsData[0]?.totalTransactionCount}
                    </Typography>
                  </Typography>
                  <Typography className="view__statistics__value left_space">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalTransactionAmount"
                    )}
                    <Typography variant="body2">
                      {formatAmount(
                        viewStatisticsData[0]?.totalTransactionAmount
                      )}
                    </Typography>
                  </Typography>
                  <Typography className="view__statistics__value left_space">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalSettlementAmount"
                    )}
                    <Typography variant="body2">
                      {formatAmount(
                        viewStatisticsData[0]?.totalSettlementAmount
                      )}
                    </Typography>
                  </Typography>
                  <Typography className="view__statistics__value left_space">
                    {t("erps.statisticsEnquiry.page.label.discrepancyAmount")}
                    <Typography variant="body2">
                      {formatAmount(viewStatisticsData[0]?.discrepancyAmount)}
                    </Typography>
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Grid item xl={8} lg={11} md={12} sm={12} xs={12}>
                    <ReportGrid
                      // viewStatisticsData={viewStatisticsData}
                      reportType={reportType}
                      initialData={initialData}
                      data={pageData}
                      pageNumber={pageNumber}
                      rowPerPage={rowPerPage}
                      paginateData={paginateData}
                    />
                  </Grid>
                </Box>
              </>
            )}
          </>
        ) : (
          <Box mt={1}>
            {`0 ${t("erps.statisticsEnquiry.page.label.resultsFound")}`}
          </Box>
        )}
        {initialLoading ? (
          <Box ml={40} mt={10} sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack direction="row" spacing={2} style={{marginBottom: "20px"}}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.back")}
              onClick={() =>
                props.history.push("/statistics-enquiry", {
                  componentAccess: state?.componentAccess,
                  fromDetailPage: true,
                })
              }
            />
            <div>
              {/* button to trigger printing of target component */}
              <ReactToPrint
                trigger={() => (
                  <ERPSBaseButton
                    functionType="NU"
                    variant="primary"
                    onClick={() => {}}
                    className="button_with_margin48"
                    label={t("erps.statisticsEnquiry.page.label.print")}
                  />
                )}
                content={() => componentRef}
                documentTitle={`${t(
                  "erps.statisticsEnquiry.page.label.billPaymentRemittanceReporting"
                )} - ${findReportLabel(reportType)?.pdfName}`}
              />

              {/* component to be printed */}
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  ref={(el) => (componentRef = el)}
                  data={{
                    reportType,
                    fromDate,
                    toDate,
                    showStatisticsFor,
                    billerId,
                    settlementType,
                    sortResultsBy,
                    fiDetails,
                    viewStatisticsFirstData: viewStatisticsData
                      ? viewStatisticsData[0]
                      : {},
                    viewStatsCountBsr: viewStatsCountBsr(),
                    pageData: getPageData(),
                    pageNumber,
                    rowPerPage,
                    initialDataLength: initialData ? initialData?.length : 0,
                    viewStatisticsBiller,
                    t,
                  }}
                  fiNumber={fiNumber}
                />
              </div>
            </div>
          </Stack>
        )}
      </Box>
    </div>
  );
};
export default withSidebar(ViewStatistics);

export const getWeighingValue = (to, from) => {
  const weighingLength = to - from + 1;
  let weighingString = "";
  for (var i = 1; i <= weighingLength; i++) {
    if (i % 2 == 0) {
      weighingString += "2";
    } else {
      weighingString += "1";
    }
  }
  return weighingString;
};

export const getNumericValue = (value, to, from) => {
  const input = parseInt(value);
  if (value !== NaN) {
    return value.toString().slice(to, from);
  }
};

export const checkNonZeroValue = (value) => {
  if (value === 0) {
    return "";
  }
  return value;
};

import userActionTypes from "../actions/types/user.action.type";

export const userRoleTypeInitialState = {
  userRoleTypeList: null,
  error: null,
};

export default function userRoleTypeReducer(
  state = userRoleTypeInitialState,
  action
) {
  switch (action.type) {
    case userActionTypes.GET_USER_ROLE_TYPE_LIST:
      return { userRoleTypeList: action.data, error: null };
    case userActionTypes.GET_USER_ROLE_TYPE_LIST_ERROR:
      return { userRoleTypeList: null, error: action.error };
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { validateUser } from "./helper";
import pricePlanActionType from "../../actions/types/pricePlan.action.type";

const ViewPricePlanDetails = (props) => {
  const { t } = useTranslation();
  const {
    location: { state },
    history,
  } = props;

  const pricePlanList = useSelector(
    (state) => state.pricePlanReducer?.pricePlanList
  );

  const data = pricePlanList.data.find(
    ({ billingModelNumber }) => billingModelNumber === state.id
  );

  const {
    billingModelDescription,
    baseFee,
    csvFee,
    notificationFee,
    perDocumentRate,
    maximumDocumentRate,
    minimumDocumentRate,
  } = data;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    billingModelDescription: billingModelDescription,
    baseFee: baseFee.toFixed(2),
    csvFee: csvFee.toFixed(2),
    notificationFee: notificationFee.toFixed(2),
    perDocumentRate: perDocumentRate.toFixed(2),
    maximumDocumentRate: maximumDocumentRate.toFixed(2),
    minimumDocumentRate: minimumDocumentRate.toFixed(2),
    billingModelNumber: state.id,
  });

  const navigateToManagePricePlanComponent = () => {
    history.push("/manage-price-plan", {
      componentAccess: state?.componentAccess,
      fromDetailPage: true,
    });
  };

  return (
    <div role="main">
      <Box ml={3}>
        <Breadcrumb
          {...props}
          componentAccess={state?.componentAccess}
          fromDetailPage={true}
          pathValue={"/manage-price-plan/price-plan-details"}
        />
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.managePricePlan.label.pricePlan", "Price plan")}
            {`: ${state.id}`}
          </Typography>
        </Box>
        <Box component="form" autoComplete="off">
          <Stack direction="row" spacing={3}>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.managePricePlan.label.pricePlanId")}
                <br />
              </Typography>
              <label className="test-label">{state.id}</label>
            </div>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.priceplanname")}
                <br />
              </Typography>
              <label className="test-label">
                {values.billingModelDescription}
              </label>
            </div>
          </Stack>
          <Stack direction="row" spacing={3}>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.basefee")}
                <br />
              </Typography>

              <label className="test-label">{values.baseFee}</label>
            </div>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.csvfee")}
                <br />
              </Typography>

              <label className="test-label">{values.csvFee}</label>
            </div>
          </Stack>

          <Stack direction="row" spacing={3}>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.notificationfee")}
                <br />
              </Typography>

              <label className="test-label">{values.notificationFee}</label>
            </div>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.perdocumentrate")}
                <br />
              </Typography>

              <label className="test-label">{values.perDocumentRate}</label>
            </div>
          </Stack>

          <Stack direction="row" spacing={3}>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.mindocumentrate")}
                <br />
              </Typography>

              <label className="test-label">{values.minimumDocumentRate}</label>
            </div>
            <div className="view_user_profile">
              <Typography variant="body1">
                {t("erps.newpriceplan.label.maxdocumentrate")}
                <br />
              </Typography>

              <label className="test-label">{values.maximumDocumentRate}</label>
            </div>
          </Stack>

          <Stack direction="row" spacing={3}>
            <ERPSBaseButton
              variant="primary"
              onClick={navigateToManagePricePlanComponent}
              className="button_with_margin48"
              label={t("erps.newuser.button.back", "Back")}
            />
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(ViewPricePlanDetails);

import services from "../services";

export const budgetCategoryActions = {
  submitCategory,
  updateCategory,
  getBudgetCategory,
  deleteCategory,
};

function submitCategory(values, setValues, props) {
  return services.budgetCategoryService.submitCategory(
    values,
    setValues,
    props
  );
}

function getBudgetCategory(
  count,
  budgetCode,
  description,
  pageSize,
  offset,
  translation,
  endOffset,
  setLoading,
) {
  return services.budgetCategoryService.getBudgetCategory(
    count,
    budgetCode,
    description,
    pageSize,
    offset,
    translation,
    endOffset,
    setLoading,
  );
}

function updateCategory(values, setValues, props, budgetList) {
  return services.budgetCategoryService.updateCategory(
    values,
    setValues,
    props,
    budgetList
  );
}

function deleteCategory(values, setValues, props, budgetList) {
  return services.budgetCategoryService.deleteCategory(
    values,
    setValues,
    props,
    budgetList
  );
}

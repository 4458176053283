import passwordActionTypes from "../actions/types/password.action.type";

export const changePasswordInitialState = {
  user: null,
  error: null,
};

export default function changePasswordReducer(
  state = changePasswordInitialState,
  action
) {
  switch (action.type) {
    case passwordActionTypes.CHILD_USER_PWD_UPDATE_SUCCESS:
      return { user: action.user.data, error: null };
    case passwordActionTypes.CHILD_USER_PWD_UPDATE_ERROR:
      return { error: action.error, user: null };
    default:
      return state;
  }
}

import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSWeekPicker from "../custom/inputs/ERPSWeekPicker";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import viewStatisticsActionTypes from "./../../actions/types/viewStatistics.action.type";

import {
  BSR,
  CBAS,
  CBANS,
  DSR,
  FIS,
  ALL_BILLERS,
  EDI,
  EFT,
  SORT_BY_ID,
  defaultFormValues,
  reportsList,
  periodsList,
  showStatisticsForItems,
  settlementTypeItems,
  sortResultsByItems,
  SPECIFIC_BILLER,
} from "./data";
import { parseFilters, validatePeriod } from "./helper";

const StatisticsEnquiry = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: {
      state: { componentAccess, ...restProps },
    },
  } = props;

  const [errors, setErrors] = useState([]);
  const errorRef = useRef();
  const [formFilters, setFormFilters] = useState(defaultFormValues);
  const loggedInfiNumber = useSelector(
    (state) => state.loginUserReducer?.user?.fiNumber
  );

  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);

  const fiNumber = selectedFi || loggedInfiNumber;

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errors.length]);

  useEffect(() => {
    dispatch({ type: viewStatisticsActionTypes.GET_VIEW_STATISTICS_INITIAL });
  }, []);

  const handleFormChange = (e) => {
    const value = e.target.value;
    let settlementType = "";
    let sortResultsBy = "";
    let periodType = "year";
    if (e.target.id === "reportType" && value === DSR) {
      periodType = "day";
      settlementType = fiNumber == 2 ? EFT : EDI;
      sortResultsBy = SORT_BY_ID;
    }
    setFormFilters({
      ...formFilters,
      [e.target.id]: value,
      ...(e.target.id === "periodType" && {
        periodValue: new Date(),
      }),
      ...(e.target.id === "reportType" && {
        periodType: periodType,
        periodValue: new Date(),
        showStatisticsFor: "",
        billerId: "",
        settlementType: settlementType,
        sortResultsBy: sortResultsBy,
      }),
    });
    if (e.target.id === "reportType") {
      setErrors([]);
      dispatch({ type: viewStatisticsActionTypes.GET_VIEW_STATISTICS_INITIAL });
    }
  };

  const handlePeriodChange = (value) => {
    setFormFilters({
      ...formFilters,
      periodValue: value,
    });
  };

  const handleRadioChange = (key, e) => {
    const value = e.target.value;
    setFormFilters({
      ...formFilters,
      [key]: value,
      ...(key === "showStatisticsFor" &&
        value === ALL_BILLERS && { billerId: "" }),
    });
  };

  const findPeriodByType = () => {
    return periodsList.find((period) => period.value == formFilters.periodType);
  };

  const disableBillerId = () => {
    if (
      formFilters.reportType === CBAS &&
      formFilters.showStatisticsFor !== SPECIFIC_BILLER
    ) {
      return true;
    }
    return false;
  };

  const errorObject = (field, message) => {
    return {
      field: field,
      message: message,
    };
  };

  const getErrorMessages = () => {
    return errors.map((error) => error.message);
  };

  const getErrorFields = () => {
    return errors.map((error) => error.field);
  };

  const validateForm = () => {
    let errorMsg = [];
    const alphaNumericRegEx = /^[0-9A-Za-z]+$/;

    const periodCheck = validatePeriod(formFilters);

    if (periodCheck.error) {
      errorMsg.push(errorObject("periodValue", periodCheck.errorMessage));
    }
    if (!formFilters.reportType) {
      errorMsg.push(
        errorObject(
          "reportType",
          t("erps.statisticsEnquiry.page.errorMessage.reportType.required")
        )
      );
    }
    if (formFilters.reportType === BSR) {
      if (!formFilters.billerId.trim()) {
        errorMsg.push(
          errorObject(
            "billerId",
            t("erps.statisticsEnquiry.page.errorMessage.billerId.required")
          )
        );
      } else {
        const hasValidBillerId = alphaNumericRegEx.test(
          formFilters.billerId.trim()
        );
        if (!hasValidBillerId) {
          errorMsg.push(
            errorObject(
              "billerId",
              t(
                "erps.statisticsEnquiry.page.errorMessage.invalid.billerId.required"
              )
            )
          );
        }
      }
    }

    if (formFilters.reportType === CBAS) {
      if (!formFilters.showStatisticsFor) {
        errorMsg.push(
          errorObject(
            "showStatisticsFor",
            t(
              "erps.statisticsEnquiry.page.errorMessage.showStatisticsFor.required"
            )
          )
        );
      }
      if (formFilters.showStatisticsFor === SPECIFIC_BILLER) {
        if (!formFilters.billerId.trim()) {
          errorMsg.push(
            errorObject(
              "billerId",
              t("erps.statisticsEnquiry.page.errorMessage.billerId.required")
            )
          );
        } else {
          const hasValidBillerId = alphaNumericRegEx.test(
            formFilters.billerId.trim()
          );
          if (!hasValidBillerId) {
            errorMsg.push(
              errorObject(
                "billerId",
                t(
                  "erps.statisticsEnquiry.page.errorMessage.invalid.billerId.required"
                )
              )
            );
          }
        }
      }

      if (
        !formFilters.sortResultsBy &&
        (!formFilters.showStatisticsFor ||
          formFilters.showStatisticsFor === ALL_BILLERS)
      ) {
        errorMsg.push(
          errorObject(
            "sortResultsBy",
            t("erps.statisticsEnquiry.page.errorMessage.sortResultsBy.required")
          )
        );
      }
    }
    if (formFilters.reportType === CBANS) {
      if (!formFilters.billerId.trim()) {
        errorMsg.push(
          errorObject(
            "billerId",
            t("erps.statisticsEnquiry.page.errorMessage.billerId.required")
          )
        );
      } else {
        const hasValidBillerId = alphaNumericRegEx.test(
          formFilters.billerId.trim()
        );
        if (!hasValidBillerId) {
          errorMsg.push(
            errorObject(
              "billerId",
              t(
                "erps.statisticsEnquiry.page.errorMessage.invalid.billerId.required"
              )
            )
          );
        }
      }
    }
    if (formFilters.reportType === DSR) {
      if (!formFilters.settlementType) {
        errorMsg.push(
          errorObject(
            "settlementType",
            t(
              "erps.statisticsEnquiry.page.errorMessage.settlementType.required"
            )
          )
        );
      }
      if (!formFilters.sortResultsBy) {
        errorMsg.push(
          errorObject(
            "sortResultsBy",
            t("erps.statisticsEnquiry.page.errorMessage.sortResultsBy.required")
          )
        );
      }
    }
    return errorMsg;
  };

  const handleSubmit = () => {
    const errorMsg = validateForm();
    setErrors(errorMsg);
    if (!errorMsg.length) {
      const { fromDate, toDate } = parseFilters(formFilters);
      dispatch({
        type: viewStatisticsActionTypes.SET_VIEW_STATISTICS_FILTERS,
        data: {
          reportType: formFilters.reportType,
          periodType: formFilters.periodType,
          periodValue: formFilters.periodValue,
          fromDate: fromDate,
          toDate: toDate,
          showStatisticsFor: formFilters.showStatisticsFor,
          billerId: formFilters.billerId,
          settlementType: formFilters.settlementType,
          sortResultsBy: formFilters.sortResultsBy,
        },
      });
      history.push(`/statistics-enquiry/view-statistics`);
    }
  };

  const handleReset = () => {
    setFormFilters(defaultFormValues);
    setErrors([]);
  };

  return (
    <div role="main">
      <Box ml={3}>
        {getErrorMessages().length ? (
          <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
            <ErrorMessage error={getErrorMessages()} errorRef={errorRef} />
          </Box>
        ) : (
          ""
        )}
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.statisticsEnquiry.page.title")}
          </Typography>
        </Box>
        <Stack mt={2} direction="row" spacing={2}>
          <ERPSBaseTextField
            id="reportType"
            type="text"
            select={true}
            label={t("erps.statisticsEnquiry.page.label.statisticsReport")}
            className="statistics_select_reportType"
            required="true"
            size="small"
            value={formFilters.reportType}
            items={reportsList}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: formFilters.reportType ? true : false,
            }}
            onChange={handleFormChange}
            error={getErrorFields().includes("reportType")}
          />
        </Stack>
        {(formFilters.reportType === FIS ||
          formFilters.reportType === CBAS ||
          formFilters.reportType === CBANS ||
          formFilters.reportType === DSR) && (
          <Stack direction="row" spacing={2} mt={2}>
            {formFilters.reportType !== DSR && (
              <ERPSBaseTextField
                id="periodType"
                type="text"
                select={true}
                label={t("erps.statisticsEnquiry.page.label.period")}
                className="statistics_select_period"
                required="true"
                size="small"
                value={formFilters.periodType}
                items={periodsList}
                SelectProps={{ native: true }}
                onChange={handleFormChange}
              />
            )}
            {formFilters.periodType === "week" ? (
              <ERPSWeekPicker
                translation={t}
                label={findPeriodByType().label}
                value={formFilters.periodValue}
                required="true"
                onChange={handlePeriodChange}
                error={getErrorFields().includes("periodValue")}
                className="statistics__datepicker"
                minDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 7))
                }
                maxDate={new Date()}
              />
            ) : (
              <ERPSBaseDatePicker
                label={findPeriodByType().label}
                value={formFilters.periodValue}
                required="true"
                onChange={handlePeriodChange}
                format={findPeriodByType().format}
                views={findPeriodByType().views}
                error={getErrorFields().includes("periodValue")}
                className="statistics__datepicker"
                minDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 7))
                }
                maxDate={new Date()}
                animateYearScrolling={false}
              />
            )}
          </Stack>
        )}
        {(formFilters.reportType === CBAS ||
          formFilters.reportType === BSR ||
          formFilters.reportType === CBANS) && (
          <Stack direction="row" spacing={2} mt={2}>
            {formFilters.reportType === CBAS && (
              <Box mt={2}>
                <ERPSBaseRadioGroup
                  name="showStatisticsFor"
                  marginTop={"true"}
                  InputLabelProps={{ shrink: true }}
                  value={formFilters.showStatisticsFor}
                  label={t(
                    "erps.statisticsEnquiry.page.label.showStatisticsFor"
                  )}
                  onChange={(e) => handleRadioChange("showStatisticsFor", e)}
                  items={showStatisticsForItems}
                  required={"true"}
                  labelPlacement="start"
                  className={"transactionEnquiry__radioButton_direction"}
                  error={getErrorFields().includes("showStatisticsFor")}
                />
              </Box>
            )}
            <Box style={{ marginTop: "-16px" }}>
              <ERPSBaseTextField
                id="billerId"
                type="text"
                label={t("erps.statisticsEnquiry.page.label.billerId")}
                className="statistics_text_field"
                size="small"
                onChange={handleFormChange}
                value={formFilters.billerId}
                isDisabled={disableBillerId()}
                required={"true"}
                error={getErrorFields().includes("billerId")}
                inputProps={{ maxLength: 9 }}
              />
            </Box>
          </Stack>
        )}

        {formFilters.reportType === DSR && (
          <Stack direction="row" spacing={2}>
            <Box mt={2}>
              <ERPSBaseRadioGroup
                name="settlementType"
                marginTop={"true"}
                InputLabelProps={{ shrink: true }}
                value={formFilters.settlementType}
                label={t("erps.statisticsEnquiry.page.label.settlementType")}
                onChange={(e) => handleRadioChange("settlementType", e)}
                items={settlementTypeItems(fiNumber)}
                required={"true"}
                labelPlacement="start"
                className={"transactionEnquiry__radioButton_direction"}
                error={getErrorFields().includes("settlementType")}
              />
            </Box>
          </Stack>
        )}
        {(formFilters.reportType === DSR ||
          formFilters.reportType === CBAS) && (
          <Stack direction="row" spacing={2}>
            <Box mt={2}>
              <ERPSBaseRadioGroup
                name="sortResultsBy"
                marginTop={"true"}
                InputLabelProps={{ shrink: true }}
                value={formFilters.sortResultsBy}
                label={t("erps.statisticsEnquiry.page.label.sortResultsBy")}
                onChange={(e) => handleRadioChange("sortResultsBy", e)}
                items={sortResultsByItems}
                required={"true"}
                labelPlacement="start"
                className={"transactionEnquiry__radioButton_direction"}
                disabled={formFilters.showStatisticsFor === SPECIFIC_BILLER}
                error={getErrorFields().includes("sortResultsBy")}
              />
            </Box>
          </Stack>
        )}
        <Box display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.statisticsEnquiry.page.label.reset")}
            onClick={handleReset}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.statisticsEnquiry.page.label.viewStatistics")}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(StatisticsEnquiry);

import moment from "moment";
import { t } from "i18next";
import {
  BSR,
  reportsList,
  settlementTypeItems,
  sortResultsByItems,
  periodsList,
  CBAS,
} from "./data";

export const parseFilters = ({ reportType, periodType, periodValue }) => {
  let fromDate = "";
  let toDate = "";
  const dateFormat = "YYYY-MM-DD";
  if (reportType === BSR) {
    toDate = moment();
    fromDate = moment().subtract(90, "days");
  } else {
    fromDate = moment(periodValue).startOf(periodType);
    if (
      (periodType === "month" &&
        moment(periodValue).isSame(new Date(), "month")) ||
      (periodType === "year" && moment(periodValue).isSame(new Date(), "year"))
    ) {
      toDate = moment();
    } else {
      toDate = moment(periodValue).endOf(periodType);
    }
  }
  return {
    fromDate: fromDate.format(dateFormat),
    toDate: toDate.format(dateFormat),
  };
};

export const findReportLabel = (reportType) => {
  return reportsList.find((report) => report.value == reportType);
};

export const findSettlementLabel = (settlementType, fiNumber) => {
  return settlementTypeItems(fiNumber)?.find(
    (settlement) => settlement.value == settlementType
  );
};

export const findSortByLabel = (sortResultsBy) => {
  return sortResultsByItems.find((sortBy) => sortBy.value == sortResultsBy);
};

export const findPeriodLabel = (periodType) => {
  return periodsList.find((period) => period.value == periodType);
};

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatAmount = (amount) => {
  // if (amount) {
  // return `$${amount?.toFixed(2)}`;
  return USDollar.format(amount);
  // }
};

export const skipFirstRecord = (data) => {
  return data.filter(function (el, i) {
    return i !== 0;
  });
};

export const resultsTitle = (reportType, viewStatsCountBsr) => {
  if (reportType == BSR) {
    return `${t(
      "erps.statisticsEnquiry.page.label.results"
    )} (${viewStatsCountBsr} items)`;
  } else {
    return t("erps.statisticsEnquiry.page.label.results");
  }
};

export const cbasAddRows = (viewStatisticsData) => {
  if (viewStatisticsData) {
    let copyData = [...viewStatisticsData];
    copyData.push({
      id: "totalForPage",
      billerName: "Totals for page",
      totalTransactionCount: viewStatisticsData[0]?.totalTransactionCount,
      totalTransactionAmount: viewStatisticsData[0]?.totalTransactionAmount,
    });
    copyData.push({
      id: "totalForPeriod",
      billerName: "Totals for period",
      totalTransactionCount:
        viewStatisticsData[0]?.totalForPeroidTransactionCount,
      totalTransactionAmount:
        viewStatisticsData[0]?.totalForPeroidTransactionAmount,
    });
    return copyData;
  } else {
    return [];
  }
};

const sumOfValue = (data, prop) => {
  return data.reduce(function (a, b) {
    return a + b[prop];
  }, 0);
};

export const cbasTotalRows = (viewStatisticsFirstData, data) => {
  if (viewStatisticsFirstData) {
    return [
      {
        id: "totalForPage",
        billerName: "Totals for page",
        totalTransactionCount: sumOfValue(data, "totalTransactionCount"),
        totalTransactionAmount: sumOfValue(data, "totalTransactionAmount"),
      },
      {
        id: "totalForPeriod",
        billerName: "Totals for period",
        totalTransactionCount:
          viewStatisticsFirstData?.totalForPeroidTransactionCount,
        totalTransactionAmount:
          viewStatisticsFirstData?.totalForPeroidTransactionAmount,
      },
    ];
  } else {
    return [];
  }
};

export const validatePeriod = (formFilters) => {
  let error = false;
  let errorMessage = "";

  if (formFilters.periodValue == "Invalid date") {
    error = true;
    errorMessage = t(
      "erps.statisticsEnquiry.page.errorMessage.period.invalid",
      { periodLabel: findPeriodLabel(formFilters.periodType)?.label }
    );
  } else if (formFilters.periodValue == "") {
    error = true;
    errorMessage = t(
      "erps.statisticsEnquiry.page.errorMessage.period.required",
      { periodLabel: findPeriodLabel(formFilters.periodType)?.label }
    );
  } else if (
    !moment(formFilters.periodValue).isBetween(
      moment().subtract(7, "y"),
      moment(),
      []
    )
  ) {
    error = true;
    errorMessage = t(
      "erps.statisticsEnquiry.page.errorMessage.period.invalidDate"
    );
  }

  return {
    error,
    errorMessage,
  };
};

export const getBillerIdWithName = (billerId, name) => {
  return name ? `${billerId} - ${name}` : billerId;
};

export const printPaginationFooter = (
  rowPerPage,
  pageNumber,
  initialDataLength
) => {
  return `Rows per page: ${rowPerPage}\u00A0\u00A0\u00A0\u00A0${
    pageNumber * rowPerPage + 1
  }-${
    (pageNumber + 1) * rowPerPage
  } to ${initialDataLength}\u00A0\u00A0\u00A0\u00A0|<\u00A0\u00A0\u00A0<\u00A0\u00A0\u00A0${
    pageNumber + 1
  }\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0>|`;
};

import axios from "axios";
import batchHolidayActionType from "../actions/types/batchHoliday.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  API_BASE_URL,
  formatDate,
} from "../utils/helper";
import i18next from "i18next";
import actions from "../actions";

export const batchHolidayService = {
  createHoliday,
  getHoliday,
  deleteHoliday,
};

function createHoliday(values, setValues, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/holiday/add",
        {
          holidayDate: Number(formatDate(values.holidayDate)),
          holidayDescription: values.holidayDescription,
          languageCode: "E",
          holidayDateFormatStr: values?.holidayDateFormatStr,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        props.setLoader(false);
        props.setShowSuccessMessage(true);
        dispatch({
          type: batchHolidayActionType.CREATE_BATCH_HOLIDAY_SUCCESS,
          message: res.data,
        });
        setValues(props.defaultValues);
        dispatch(actions.batchHolidayActions.getHoliday(0, 25, 0, props.t, 25));
      })
      .catch((error) => {
        const errMessage =
          error.response.data || getErrorMessageFromException(error, props.t);
        props.setLoader(false);
        props.setShowSuccessMessage(false);
        dispatch({
          type: batchHolidayActionType.CREATE_BATCH_HOLIDAY_ERROR,
          error: errMessage,
        });
        setValues(props.defaultValues);
        dispatch(actions.batchHolidayActions.getHoliday(0, 25, 0, props.t, 25));
      });
  };
}

function getHoliday(
  count,
  pageSize,
  offset,
  translation,
  endOffset,
  setInitialLoading
) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber != null ? currentFiNumber : userFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/holiday/list",
        {
          count: count,
          fiNumber: fiNumber,
          limit: pageSize,
          offset: offset,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setInitialLoading) {
          setInitialLoading(false);
        }
        const holidayDetails = {
          startOffset: offset,
          endOffset: endOffset,
        };
        dispatch(success({ ...res, holidayDetails })); //, budgetDetails
      })
      .catch((error) => {
        if (setInitialLoading) {
          setInitialLoading(false);
        }
        const errorResponse =
          getErrorMessageFromException(error, translation) || error;
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return { type: batchHolidayActionType.LIST_BATCH_HOLIDAY_SUCCESS, data };
    }

    function failure(error) {
      return { type: batchHolidayActionType.LIST_BATCH_HOLIDAY_ERROR, error };
    }
  };
}

function deleteHoliday(values, setValues, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    (values.languageCode = i18next.language == "en" ? "E" : "F"),
      axios
        .post(API_BASE_URL() + "/holiday/delete", values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          props.setDeleteLoader(false);
          props.setDisplayDialog(false);
          dispatch({
            type: batchHolidayActionType.DELETE_BATCH_HOLIDAY_SUCCESS,
            message: res.data,
          });
          props.setShowSuccessMessage(true);
          dispatch(
            actions.batchHolidayActions.getHoliday(0, 25, 0, props.t, 25)
          );
        })
        .catch((error) => {
          const errMessage =
            getErrorMessageFromException(error, props.t) ||
            error.response?.data?.data;
          props.setDeleteLoader(false);
          props.setDisplayDialog(false);
          props.setShowSuccessMessage(true);
          dispatch({
            type: batchHolidayActionType.DELETE_BATCH_HOLIDAY_ERROR,
            error: errMessage,
          });
        });
  };
}

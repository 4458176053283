const pricePlanActionType = {
  SUCCESS: "SUCCESS",
  FAIL: "FAILURE",
  NEW_PRICE_PLAN_SUCCESS: "NEW_PRICE_PLAN_SUCCESS",
  UPDATE_PRICE_PLAN_SUCCESS: "UPDATE_PRICE_PLAN_SUCCESS",
  UPDATE_PRICE_PLAN_FAIL: "UPDATE_PRICE_PLAN_FAIL",
  NEW_PRICE_PLAN_REQUEST: "NEW_PRICE_PLAN_REQUEST",
  NEW_PRICE_PLAN_FAILURE: "NEW_PRICE_PLAN_FAILURE",
  UPDATE_PRICE_PLAN_REQUEST: "UPDATE_PRICE_PLAN_REQUEST",
  SEARCH_CRITERIA:"SEARCH_CRITERIA"
};

export default pricePlanActionType;

import axios from "axios";
import passwordActionTypes from "../actions/types/password.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL,
} from "../utils/helper";

export const passwordService = {
  generatedAutoPasswordAndSendEmailForForgetPassword,
  generatedAutoPasswordAndSendEmailForLoggedInUser,
};

function generatedAutoPasswordAndSendEmailForLoggedInUser(userData) {
  const { setLoading, setShowMessage, t, ...param } = userData;
  const token = store.getState()?.loginUserReducer.token;
  return (dispatch) => {
    axios
      .post(
        API_BASE_URL() + "/generate-password-send-email-for-logged-user",
        { ...param },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (setShowMessage) {
          setShowMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        setLoading(false);
        if (setShowMessage) {
          setShowMessage(false);
        }
        const errMessage =
          getErrorMessageFromException(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return { type: passwordActionTypes.GENRATE_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: passwordActionTypes.GENRATE_PASSWORD_FAILURE, error };
  }
}

function generatedAutoPasswordAndSendEmailForForgetPassword(userData) {
  const {
    setLoading,
    history,
    emailId,
    t,
    setShowApiExceptionMessage,
    ...param
  } = userData;
  return (dispatch) => {
    axios
      .post(
        API_BASE_URL() + "/generate-password-send-email",
        { ...param, emailAddress: emailId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        dispatch(success(res));

        const data = JSON.parse(JSON.stringify(res.data));
        if (data) {
          const { userId } = param;
          if (data.statusCode === 200) {
            dispatch(success({ ...data, userId, emailId }));
            if (data.statusText) {
              history.push("/credential-match");
            }
          } else if (data.statusCode === 201 || data.statusCode === 202) {
            dispatch(success({ ...data, userId, emailId }));
            if (data.statusText) {
              history.push("/credential-match");
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowApiExceptionMessage(true);
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };

  function success(data) {
    return { type: passwordActionTypes.FORGOT_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: passwordActionTypes.FORGOT_PASSWORD_FAILURE, error };
  }
}

import availableDocumentTypes from "../actions/types/availableDocument.action.type";

export const availableDocumentInitialState = {
  availableDocument: null,
  reportNames: null,
  error: null,
  downloadDocument: null,
  downloadDocumentError: null,
  downloadReportListData: null,
  downloadReportListError: null,
  downloadReport: null,
  downloadReportError: null,
  reportListDropDown: null,
  reportListDropDownError: null,
};

export default function availableDocumentReducer(
  state = availableDocumentInitialState,
  action
) {
  switch (action.type) {
    case availableDocumentTypes.GET_AVAILABLE_DOCUMENT_SUCCESS:
      return {
        ...state,
        availableDocument: action.data,
        error: null,
        downloadDocumentError: null,
        downloadReportError: null,
        downloadReportListData: null,
        downloadReportListError: null,
      };
    case availableDocumentTypes.GET_AVAILABLE_DOCUMENT_ERROR:
      return {
        availableDocument: null,
        error: action.error,
        downloadDocumentError: null,
        downloadReportError: null,
        downloadReportListData: null,
        downloadReportListError: null,
      };
    case availableDocumentTypes.AVAILABLE_DOCUMENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadDocument: action.data,
        downloadDocumentError: null,
        downloadReportError: null,
      };
    case availableDocumentTypes.AVAILABLE_DOCUMENT_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadDocument: null,
        downloadDocumentError: action.error,
      };

    case availableDocumentTypes.AVAILABLE_REPORTS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadReport: action.data,
        downloadDocumentError: null,
        downloadReportError: null,
      };
    case availableDocumentTypes.AVAILABLE_REPORTS_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadReport: null,
        downloadReportError: action.error,
      };

    case availableDocumentTypes.GET_AVAILABLE_REPORTS_SUCCESS:
      return {
        ...state,
        downloadReportListData: action.data,
        downloadReportListError: null,
        downloadDocumentError: null,
        downloadReportError: null,
        error: null,
        availableDocument: null,
      };
    case availableDocumentTypes.GET_AVAILABLE_REPORTS_ERROR:
      return {
        ...state,
        downloadReportListData: null,
        downloadReportListError: action.error,
      };
    case availableDocumentTypes.GET_REPORTS_NAME_SUCCESS:
      return {
        ...state,
        reportNames: action.data,
        error: null,
        downloadDocumentError: null,
        downloadReportError: null,
      };
    case availableDocumentTypes.GET_REPORTS_NAME_ERROR:
      return {
        ...state,
        reportNames: null,
        error: action.error,
      };
    case availableDocumentTypes.GET_AVAILABLE_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        reportListDropDown: action.data,
        reportListDropDownError: null,
        downloadDocumentError: null,
        downloadReportError: null,
      };
    case availableDocumentTypes.GET_AVAILABLE_REPORTS_LIST_ERROR:
      return {
        ...state,
        reportListDropDown: null,
        reportListDropDownError: action.error,
      };

    case availableDocumentTypes.RESET_AVAILABLE_REPORTS_LIST:
      return {
        ...state,
        availableDocument: null,
        error: null,
        downloadDocument: null,
        downloadDocumentError: null,
        downloadReportListData: null,
        downloadReportListError: null,
        downloadReport: null,
        downloadReportError: null,
      };

    default:
      return state;
  }
}

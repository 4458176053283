import services from "../services";

export const pricePlanActions = {
  submitPricePlanDetails,
  updatePricePlanDetails,
  getPricePlanList,
  getFilteredPricePlan,
};

function submitPricePlanDetails(values, setValues, props) {
  return services.pricePlaneService.submitPricePlanDetails(
    values,
    setValues,
    props
  );
}

function getPricePlanList(t) {
  return services.pricePlaneService.getPricePlanList(t);
}

function getFilteredPricePlan(data) {
  return services.pricePlaneService.getFilteredPricePlan(data);
}

function updatePricePlanDetails(values, props, pricePlanList) {
  return services.pricePlaneService.updatePricePlanDetails(
    values,
    props,
    pricePlanList
  );
}

import { useState } from "react";
import axios from "axios";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import userActionTypes from ".././../actions/types/user.action.type";
import availableDocumentTypes from "../../actions/types/availableDocument.action.type";
import { useTranslation } from "react-i18next";
import billerProfileTypes from "../../actions/types/billerProfile.action.type";
import moment from "moment";

export const useCustomHook = ({
  initialStates,
  tableHeadings,
  tableResults,
  setErrorMessage,
  errorMessage,
  userRoleList,
  setLoading,
  setInitialLoading,
  propsComp,
  setUserData,
  setErrorMssg,
  setManageUserState,
  pageName,
  userDetails,
  setPricePlaneData,
  billerId,
  roleId,
  loginUser,
  setPageSize,
  setRowPerPage,
  rowPerPage,
  pageNumber,
  setHighlightError,
  fiNumber,
  minDateValue,
  maxDateValue,
  handleCloseSuccessMessage,
  hasSuccessMessageAvailable,
  pageLimitSize,
}) => {
  const [searchCriteria, setSearchCriteria] = useState(initialStates);
  const [isSearchValid, setIsSearchValid] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  const [hasSearch, setHasSearch] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let pricePlaneResult = {};
  if (pageName == "pricePlan") {
    pricePlaneResult = useSelector(
      (state) => state?.pricePlanReducer?.pricePlanList
    );
  }

  const gridData = {
    columns: tableHeadings,
    rows: [],
    count: 0,
  };

  const searchCriteriaChangeHandler = (field) => (event) => {
    if (field === "startDate" || field === "endDate") {
      setSearchCriteria({ ...searchCriteria, [field]: event });
    } else if (
      pageName === "manageUser" ||
      pageName === "pricePlan" ||
      pageName === "manageBudget"
    ) {
      setManageUserState({ ...userDetails, [field]: event.target.value });
    } else {
      setSearchCriteria({ ...searchCriteria, [field]: event.target.value });
    }
  };
  const searchCriteriaBlurHandler = (field) => (event) => {
    let upperValue = "";
    if (event?.target?.value && typeof event.target.value === "string") {
      upperValue = event.target.value.toUpperCase();
    }
    if (field === "startDate" || field === "endDate") {
      setSearchCriteria({ ...searchCriteria, [field]: event });
    } else if (
      pageName === "manageUser" ||
      pageName === "pricePlan" ||
      pageName === "manageBudget"
    ) {
      setManageUserState({ ...userDetails, [field]: event.target.value });
    } else {
      setSearchCriteria({ ...searchCriteria, [field]: upperValue });
    }
  };
  const hasSearchValue = () => {
    const searchValues =
      pageName === "manageUser" ||
      pageName === "pricePlan" ||
      pageName === "manageBudget"
        ? userDetails
        : searchCriteria;
    const keys = Object.keys(searchValues);
    return !keys.some(
      (key) => typeof searchValues[key] === "string" && searchValues[key].trim()
    );
  };

  const resetHandler = () => {
    setIsSearchValid(true);
    setSearchResults(null);
    setManageUserState(initialStates);
    dispatch({ type: userActionTypes.GET_USER_DETAIL_SUCCESS, data: [] });
    setErrorMessage([]);
    if (setUserData) {
      setUserData(null);
    }
  };

  const resetBudgetCategoryHandler = () => {
    setIsSearchValid(true);
    dispatch(
      actions.budgetCategoryActions.getBudgetCategory(0, "", "", 25, 0, t, 25)
    );
    setManageUserState(initialStates);
    setSearchCriteria(initialStates);
  };

  const resetPricePlanHandler = () => {
    setIsSearchValid(true);
    dispatch(actions.pricePlanActions.getPricePlanList(t));
    setManageUserState(initialStates);
    setErrorMssg(null);
    setRowPerPage(pageLimitSize);
    setPageSize(0);
  };

  const sanitizeQueryParams = (jsonObj) => {
    let updatedReqBody;
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      } else {
        if (typeof jsonObj[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key] };
        }
      }
    });

    return updatedReqBody;
  };

  const initiateSearch = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    axios
      .get(`https://www.erps-backend.com`, {
        params: { ...actualCriteria },
      })
      .then((response) => {
        setSearchResults({
          ...gridData,
          rows: [...response.data.rows],
          count: response.data.count,
        });
      })
      .catch((err) => {
        //TODO - error to be handled here and below rendering to be removed
        setSearchResults({
          ...gridData,
          rows: [
            ...tableResults.data.slice(
              actualCriteria.startOffset,
              actualCriteria.endOffset
            ),
          ],
          count: tableResults.count,
        });
      });
  };

  const paginateBudgetCategories = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const budgetCategoryResultCount = tableResults.count;
    const pagSize = actualCriteria.endOffset - actualCriteria.startOffset;
    if (actualCriteria.startOffset < budgetCategoryResultCount) {
      setManageUserState((prevState) => ({
        ...prevState,
        startOffset: actualCriteria.startOffset,
        endOffset: actualCriteria.endOffset,
      }));
      setInitialLoading(true);
      dispatch(
        actions.budgetCategoryActions.getBudgetCategory(
          budgetCategoryResultCount,
          "",
          "",
          pagSize,
          actualCriteria.startOffset,
          t,
          actualCriteria.endOffset,
          setInitialLoading
        )
      );
    }
  };

  const searchBudgetCategoryByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const budgetCode = actualCriteria.budgetCode
      ? actualCriteria.budgetCode
      : "";
    const description = actualCriteria.description
      ? actualCriteria.description
      : "";
    dispatch(
      actions.budgetCategoryActions.getBudgetCategory(
        0,
        budgetCode,
        description,
        25,
        0,
        t,
        25
      )
    );
  };

  const paginateBillerProfilesList = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const billerProfileResultCount = tableResults.count;
    const billerId = searchCriteria.billerId ? searchCriteria.billerId : "";
    const billerName = searchCriteria.billerName
      ? searchCriteria.billerName
      : "";
    const pagSize = actualCriteria.endOffset - actualCriteria.startOffset;
    if (actualCriteria.startOffset < billerProfileResultCount)
      dispatch(
        actions.billerProfileActions.getBillerProfiles(
          billerProfileResultCount,
          billerId,
          billerName,
          pagSize,
          actualCriteria.startOffset,
          t
        )
      );
  };

  const searchBillerProfileByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const billerId = actualCriteria.billerId ? actualCriteria.billerId : "";
    const billerName = actualCriteria.billerName
      ? actualCriteria.billerName
      : "";
    dispatch(
      actions.billerProfileActions.getBillerProfiles(
        0,
        billerId,
        billerName,
        25,
        0,
        t,
        setLoading
      )
    );
  };

  const resetBillerProfileHandler = () => {
    setIsSearchValid(true);
    dispatch({
      type: billerProfileTypes.GET_BILLER_PROFILE_LIST_SUCCESS,
      data: null,
    });
    setSearchCriteria(initialStates);
    setErrorMssg(
      t("erps.billermanagement.filterbillercategory.error.emptyinput")
    );
  };

  const searchPricePlanByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const pricePlanId = actualCriteria.pricePlanId
      ? actualCriteria.pricePlanId
      : "";
    const pricePlanName = actualCriteria.pricePlanName
      ? actualCriteria.pricePlanName
      : "";
    let errors = [];
    const numericRegEx = /^[0-9]+$/;
    if (pricePlanId && !numericRegEx.test(pricePlanId)) {
      errors.push(t("erps.managePricePlan.priceplanid.error.numeric"));
    }

    if (errors && errors.length) {
      if (setLoading) {
        setLoading(false);
      }
      setErrorMssg(errors);
    } else {
      if (errorMessage && errorMessage.length) {
        setErrorMssg([]);
      }
      dispatch(
        actions.pricePlanActions.getFilteredPricePlan({
          pricePlanId,
          pricePlanName,
          setLoading,
          t,
          setPricePlaneData,
        })
      );
    }
  };

  const handleBudgetCategorySearch = () => {
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
    } else {
      setIsSearchValid(true);

      //TODO - Below if condition to be removed [Once actual api is available]
      //if (checkSearchValue()) {
      searchBudgetCategoryByParams({ ...userDetails });
      //} else {
      //setSearchResults({ count: 0, rows: [] });
      //}
    }
  };

  const handleBillerProfileSearch = () => {
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
    } else {
      const billerName = searchCriteria?.billerName?.trim();
      const billerId = searchCriteria?.billerId?.trim();
      const numericRegEx = /^[0-9]+$/;
      const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
      const err = [];
      setIsSearchValid(true);

      if (
        billerName &&
        billerName.includes("%") &&
        billerName.length < 3 &&
        setErrorMssg
      ) {
        err.push(
          t(
            "erps.billermanagement.filterbillercategory.error.invaliduseofwildcardcharacter"
          )
        );
      }
      if (billerId && fiNumber != 50 && !alphaNumericRegEx.test(billerId)) {
        err.push(
          t(
            "erps.billermanagement.filterbillercategory.error.billerIdAlphaNumeric"
          )
        );
      } else if (billerId && fiNumber == 50 && !numericRegEx.test(billerId)) {
        err.push(
          t("erps.billermanagement.filterbillercategory.error.invalidBillerid")
        );
      }
      if (err.length) {
        setErrorMssg(err);
        setSearchResults(null);
      } else {
        setErrorMssg(null);
        if (setLoading) {
          setLoading(true);
        }
        //TODO - Below if condition to be removed [Once actual api is available]
        //if (checkSearchValue()) {
        searchBillerProfileByParams({ ...searchCriteria });
        //} else {
        //setSearchResults({ count: 0, rows: [] });
        //}
      }
    }
  };

  const handleAvailableDocumentsSearch = () => {
    const date1 = new Date(searchCriteria?.startDate);
    const date2 = new Date(searchCriteria?.endDate);
    const errors = [];
    let highlightErrorV1 = {};
    if (searchCriteria?.startDate || searchCriteria?.endDate) {
      setIsSearchValid(true);
      if (
        (searchCriteria?.startDate && date1 == "Invalid Date") ||
        (searchCriteria?.endDate && date2 == "Invalid Date")
      ) {
        if (searchCriteria?.startDate && date1 == "Invalid Date") {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.invalid.fromDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
          };
        }
        if (searchCriteria?.endDate && date2 == "Invalid Date") {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.invalid.toDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            endDate: true,
          };
        }
      } else if (searchCriteria?.startDate || searchCriteria?.endDate) {
        if (searchCriteria?.startDate && !searchCriteria?.endDate) {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.required.endDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            endDate: true,
          };
        } else if (searchCriteria?.endDate && !searchCriteria?.startDate) {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.required.startDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
          };
        } else if (
          searchCriteria?.endDate &&
          searchCriteria?.startDate &&
          date2.getTime() < date1.getTime()
        ) {
          errors.push(t("erps.downloadreports.filter.errorMessage.fromDate"));
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
            endDate: true,
          };
        } else if (minDateValue && maxDateValue) {
          const minDate = new Date(minDateValue);
          const maxDate = new Date(maxDateValue);
          if (
            searchCriteria?.startDate &&
            date1.getTime() < minDate.getTime()
          ) {
            errors.push(
              t("erps.common.datepicker.startDatenotbeforemaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              startDate: true,
            };
          } else if (
            searchCriteria?.startDate &&
            date1.getTime() > maxDate.getTime()
          ) {
            errors.push(
              t("erps.common.datepicker.startdatenotaftermaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              startDate: true,
            };
          }

          if (searchCriteria?.endDate && date2.getTime() < minDate.getTime()) {
            errors.push(
              t("erps.common.datepicker.endDatenotbeforemaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              endDate: true,
            };
          } else if (
            searchCriteria?.endDate &&
            date2.getTime() > maxDate.getTime()
          ) {
            errors.push(t("erps.common.datepicker.endDatenotaftermaximaldate"));
            highlightErrorV1 = {
              ...highlightErrorV1,
              endDate: true,
            };
          }
        }
      }
    }
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
      setHasSearch(false);
      setErrorMessage([]);
      setHighlightError({});
    } else if (errors.length) {
      setErrorMessage(errors);
      setHighlightError(highlightErrorV1);
      setIsSearchValid(true);
    } else {
      setIsSearchValid(true);
      setErrorMessage([]);
      setHighlightError({});
      setHasSearch(true);
      //TODO - Below if condition to be removed [Once actual api is available]
      //if (checkSearchValue()) {
      searchAvailableDocumentsByParams({ ...searchCriteria });
      //} else {
      //setSearchResults({ count: 0, rows: [] });
      //}
    }
  };

  const searchAvailableDocumentsByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const searchBillerFilesDto = {
      biller_id: propsComp.billerId ? propsComp.billerId : "",
      biller_name: propsComp.billerName ? propsComp.billerName : "",
      source: actualCriteria?.source,
      start_off_set: 0,
      end_off_set: 25,
      start_date:
        actualCriteria?.startDate != null ? actualCriteria?.startDate : "",
      end_date: actualCriteria?.endDate != null ? actualCriteria?.endDate : "",
    };
    if (setInitialLoading) {
      setInitialLoading(true);
    }
    if (setLoading) {
      setLoading(true);
    }
    if (setPageSize) {
      setPageSize(0);
    }
    if (setRowPerPage) {
      setRowPerPage(25);
    }
    setHasSearch(true);

    dispatch(
      actions.availableDocumentActions.getAvailableDocument(
        searchBillerFilesDto,
        t,
        setInitialLoading,
        setLoading
      )
    );
  };

  const paginateAvailableDocuments = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const availableDocumentResultCount = actualCriteria.count;
    if (actualCriteria.startOffset < availableDocumentResultCount) {
      const { startOffset, endOffset } = actualCriteria;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      if (setPageSize) {
        setPageSize(pageNumber);
      }
      if (setRowPerPage) {
        setRowPerPage(rowPerPage);
      }
      const searchBillerFilesDto = {
        source: actualCriteria?.source || searchCriteria?.source,
        start_off_set: startOffset,
        end_off_set: endOffset,
        start_date: actualCriteria?.startDate || searchCriteria?.startDate,
        end_date: actualCriteria?.endDate || searchCriteria?.endDate,
        ...(propsComp.billerId && { biller_id: propsComp.billerId }),
      };
      setHasSearch(true);
      if (setInitialLoading) {
        setInitialLoading(true);
      }
      dispatch(
        actions.availableDocumentActions.getAvailableDocument(
          searchBillerFilesDto,
          t,
          setInitialLoading
        )
      );
    }
  };

  const resetAvailableDocumentsHandler = () => {
    setIsSearchValid(true);
    setHasSearch(false);
    setSearchCriteria({
      source: "",
      startDate: "",
      endDate: "",
      startOffset: 0,
      endOffset: 25,
      fileDate: "",
    });
    if (setErrorMessage) {
      setErrorMessage([]);
    }
    if (setHighlightError) {
      setHighlightError({});
    }
    const searchBillerFilesDto = {
      source: "",
      start_off_set: 0,
      end_off_set: 25,
      start_date: "",
      end_date: "",
      ...(propsComp.billerId && { biller_id: propsComp.billerId }),
    };
    if (setPageSize) {
      setPageSize(0);
    }
    if (setRowPerPage) {
      setRowPerPage(25);
    }
    dispatch(
      actions.availableDocumentActions.getAvailableDocument(
        searchBillerFilesDto,
        t
      )
    );
  };

  const handleAvailableReportsSearch = () => {
    const date1 = new Date(searchCriteria?.startDate);
    const date2 = new Date(searchCriteria?.endDate);
    const errors = [];
    let highlightErrorV1 = {};
    if (
      searchCriteria?.startDate ||
      searchCriteria?.endDate ||
      searchCriteria?.reportName
    ) {
      setIsSearchValid(true);
      if (
        (searchCriteria?.startDate && date1 == "Invalid Date") ||
        (searchCriteria?.endDate && date2 == "Invalid Date")
      ) {
        if (searchCriteria?.startDate && date1 == "Invalid Date") {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.invalid.fromDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
          };
        }
        if (searchCriteria?.endDate && date2 == "Invalid Date") {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.invalid.toDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            endDate: true,
          };
        }
      } else if (searchCriteria?.startDate || searchCriteria?.endDate) {
        if (searchCriteria?.startDate && !searchCriteria?.endDate) {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.required.endDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            endDate: true,
          };
        } else if (searchCriteria?.endDate && !searchCriteria?.startDate) {
          errors.push(
            t("erps.downloadreports.filter.errorMessage.required.startDate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
          };
        } else if (
          searchCriteria?.endDate &&
          searchCriteria?.startDate &&
          date2.getTime() < date1.getTime()
        ) {
          errors.push(t("erps.downloadreports.filter.errorMessage.fromDate"));
          highlightErrorV1 = {
            ...highlightErrorV1,
            startDate: true,
            endDate: true,
          };
        } else if (minDateValue && maxDateValue) {
          const minDate = new Date(minDateValue);
          const maxDate = new Date(maxDateValue);
          if (
            searchCriteria?.startDate &&
            date1.getTime() < minDate.getTime()
          ) {
            errors.push(
              t("erps.common.datepicker.startDatenotbeforemaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              startDate: true,
            };
          } else if (
            searchCriteria?.startDate &&
            date1.getTime() > maxDate.getTime()
          ) {
            errors.push(
              t("erps.common.datepicker.startdatenotaftermaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              startDate: true,
            };
          }

          if (searchCriteria?.endDate && date2.getTime() < minDate.getTime()) {
            errors.push(
              t("erps.common.datepicker.endDatenotbeforemaximaldate")
            );
            highlightErrorV1 = {
              ...highlightErrorV1,
              endDate: true,
            };
          } else if (
            searchCriteria?.endDate &&
            date2.getTime() > maxDate.getTime()
          ) {
            errors.push(t("erps.common.datepicker.endDatenotaftermaximaldate"));
            highlightErrorV1 = {
              ...highlightErrorV1,
              endDate: true,
            };
          }
        }
      }
    }
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
      setErrorMessage([]);
      setHighlightError({});
    } else if (errors.length) {
      setErrorMessage(errors);
      setHighlightError(highlightErrorV1);
    } else {
      setIsSearchValid(true);
      setErrorMessage([]);
      setHighlightError({});
      if (setLoading) {
        setLoading(true);
      }
      setHasSearch(true);
      searchAvailableReportsByParams({ ...searchCriteria });
    }
  };

  const searchAvailableReportsByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const { startDate, endDate } = actualCriteria;
    const formatStartDate = startDate
      ? moment(startDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z"
      : "";
    const formatEndDate = endDate
      ? moment(endDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z"
      : "";
    const searchBillerFilesDto = {
      start_off_set: 0,
      end_off_set: actualCriteria?.endOffset,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };
    if (setPageSize) {
      setPageSize(0);
    }
    if (setRowPerPage) {
      setRowPerPage(25);
    }

    if (roleId == 1 || roleId == 2) {
      dispatch(
        actions.availableDocumentActions.getAvailableDocument(
          {
            ...searchBillerFilesDto,
            start_off_set: 0,
            end_off_set: rowPerPage,
            biller_id: 0,
            source: actualCriteria?.reportName,
          },
          t,
          setLoading
        )
      );
    } else {
      const { start_date, end_date } = searchBillerFilesDto;
      const formatStartDate = startDate
        ? new Date(start_date)?.toISOString()
        : "";
      const formatEndDate = endDate ? new Date(end_date)?.toISOString() : "";
      dispatch(
        actions.availableDocumentActions.getAvailableReports(
          {
            ...searchBillerFilesDto,
            start_off_set: 0,
            end_off_set: rowPerPage,
            languageCode: loginUser?.languageCode,
            reportName: actualCriteria?.reportName,
            start_date: formatStartDate,
            end_date: formatEndDate,
          },
          t,
          setLoading
        )
      );
    }
  };

  const paginateAvailableReports = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const availableDocumentResultCount = actualCriteria.count;
    if (actualCriteria.startOffset < availableDocumentResultCount) {
      const { endOffset, startOffset } = actualCriteria;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      const rowPerPage = endOffset - startOffset;
      const { startDate, endDate } = searchCriteria;
      const formatStartDate = startDate
        ? new Date(startDate)?.toISOString()
        : "";
      const formatEndDate = endDate ? new Date(endDate)?.toISOString() : "";

      if (setPageSize) {
        setPageSize(pageNumber);
      }
      if (setRowPerPage) {
        setRowPerPage(rowPerPage);
      }

      if (roleId == 1 || roleId == 2) {
        dispatch(
          actions.availableDocumentActions.getAvailableDocument(
            {
              start_off_set:
                actualCriteria?.startOffset === 0
                  ? actualCriteria?.startOffset
                  : actualCriteria?.startOffset + 1,
              end_off_set: actualCriteria?.endOffset,
              start_date: formatStartDate,
              end_date: formatEndDate,
              biller_id: 0,
              source: searchCriteria?.reportName.trim(),
            },
            t
          )
        );
      } else {
        dispatch(
          actions.availableDocumentActions.getAvailableReports(
            {
              start_off_set: actualCriteria?.startOffset,
              end_off_set: actualCriteria?.endOffset,
              start_date: formatStartDate,
              end_date: formatEndDate,
              languageCode: loginUser?.languageCode,
              reportName: searchCriteria?.reportName.trim(),
            },
            t
          )
        );
      }
    }
  };

  const resetAvailableReportsHandler = () => {
    setIsSearchValid(true);
    setSearchCriteria(initialStates);
    if (setPageSize) {
      setPageSize(0);
    }
    if (setRowPerPage) {
      setRowPerPage(25);
    }
    setErrorMessage([]);
    setHighlightError({});
    setHasSearch(false);
    dispatch({ type: availableDocumentTypes.RESET_AVAILABLE_REPORTS_LIST });
  };

  const handlePricePlanSearch = () => {
    let errors = [];
    if (hasSuccessMessageAvailable) {
      handleCloseSuccessMessage();
    }
    if (hasSearchValue()) {
      setErrorMssg(errors);

      setIsSearchValid(false);
      setSearchResults(null);
    } else {
      setIsSearchValid(true);
      if (setLoading) {
        setLoading(true);
      }
      searchPricePlanByParams({ ...userDetails });
    }
  };

  const searchManageUserDetailsByParams = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const alphaNumericRegExWithSpace = /^[a-zA-Z0-9.\-' À-ÿ%]+$/;

    let errors = [];
    // const userId = actualCriteria.userId ? actualCriteria.userId : "";
    const { firstName, lastName, userId } = actualCriteria;

    if (userId && (firstName || lastName)) {
      errors.push(t("erps.manageusers.error.enteruseridorname"));
    }
    if (
      (firstName && firstName.length > 25) ||
      (lastName && lastName.length > 25)
    ) {
      errors.push(t("erps.manageusers.error.namenotmorethan25"));
    }
    if (firstName && firstName.match(alphaNumericRegExWithSpace) === null) {
      errors.push(
        t(
          "erps.manageusers.alphanumeric.firstname.msg",
          "First name must be alpha numeric."
        )
      );
    }
    if (lastName && lastName.match(alphaNumericRegExWithSpace) === null) {
      errors.push(
        t(
          "erps.manageusers.alphanumeric.lastname.msg",
          "Last name  must be alpha numeric."
        )
      );
    }
    if (userId && userId.length > 12) {
      errors.push(t("erps.manageusers.error.useridnotmorethan8"));
    }
    if (errors && errors.length) {
      if (setLoading) {
        setLoading(false);
      }
      setErrorMessage(errors);
    } else {
      if (errorMessage && errorMessage.length) {
        setErrorMessage([]);
      }
      const roleList = userRoleList?.map(({ role_type }) => role_type);
      dispatch(
        actions.userActions.getUserDetails({
          count: 0,
          ...actualCriteria,
          startOffset: 0,
          endOffset: 25,
          roleList,
          setLoading,
          t,
          billerId,
        })
      );
    }
  };
  const handleManageUserDetailsSearch = () => {
    if (hasSuccessMessageAvailable) {
      handleCloseSuccessMessage();
    }
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
    } else {
      setIsSearchValid(true);
      if (setLoading) {
        setLoading(true);
      }
      searchManageUserDetailsByParams({ ...userDetails });
    }
  };
  const paginateManageUsers = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const { firstName, lastName, userId } = userDetails;
    const roleList = userRoleList?.map(({ role_type }) => role_type);
    const searchRequest = {
      count: actualCriteria.count,
      startOffset: actualCriteria.startOffset,
      endOffset: actualCriteria.endOffset,
      roleList,
      firstName,
      lastName,
      userId,
      t,
      billerId,
    };

    if (actualCriteria.startOffset < actualCriteria.count) {
      setManageUserState((prevState) => ({
        ...prevState,
        startOffset: actualCriteria.startOffset,
        endOffset: actualCriteria.endOffset,
      }));
      setInitialLoading(true);
      dispatch(
        actions.userActions.getUserDetails({
          ...searchRequest,
          setLoading: setInitialLoading,
        })
      );
    }
  };

  const paginatePricePlan = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    if (actualCriteria.startOffset < pricePlaneResult?.data.length) {
      const rowPerPage = actualCriteria.endOffset - actualCriteria.startOffset;
      const pageNumber =
        actualCriteria.endOffset /
          (actualCriteria.endOffset - actualCriteria.startOffset) -
        1;
      setPageSize(pageNumber);
      setRowPerPage(rowPerPage);
      const updatedData = pricePlaneResult?.data
        .slice(pageNumber * rowPerPage, (pageNumber + 1) * rowPerPage)
        .map(({ billingModelNumber, ...rest }) => ({
          ...rest,
          billingModelNumber,
          id: billingModelNumber,
        }));
      setPricePlaneData(updatedData);
    }
  };

  const handleSearch = () => {
    if (hasSearchValue()) {
      setIsSearchValid(false);
      setSearchResults(null);
    } else {
      setIsSearchValid(true);

      //TODO - Below if condition to be removed [Once actual api is available]
      //if (checkSearchValue()) {
      initiateSearch({ ...searchCriteria });
      //} else {
      //setSearchResults({ count: 0, rows: [] });
      //}
    }
  };

  return {
    // handleSearch,
    searchCriteriaChangeHandler,
    searchCriteriaBlurHandler,
    resetHandler,
    isSearchValid,
    searchResults,
    initiateSearch,
    searchCriteria,
    paginateBudgetCategories,
    handleBudgetCategorySearch,
    resetBudgetCategoryHandler,
    handleManageUserDetailsSearch,
    paginateManageUsers,
    handlePricePlanSearch,
    resetPricePlanHandler,
    handleAvailableDocumentsSearch,
    paginateAvailableDocuments,
    searchAvailableDocumentsByParams,
    resetAvailableDocumentsHandler,
    handleBillerProfileSearch,
    resetBillerProfileHandler,
    paginateBillerProfilesList,
    handleAvailableReportsSearch,
    resetAvailableReportsHandler,
    paginateAvailableReports,
    paginatePricePlan,
    hasSearch,
  };
};

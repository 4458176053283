import userActionTypes from "../actions/types/user.action.type";

export const userDetailInitialState = {
  error: null,
  userData: null,
  count: 0,
  userDetails: null,
};

export default function userDetailReducer(
  state = userDetailInitialState,
  action
) {
  switch (action.type) {
    case userActionTypes.GET_USER_DETAIL_SUCCESS:
      return { userData: action.data.data, count: action.data.count, error: null, userDetails: action.data.userDetails };
    case userActionTypes.GET_USER_DETAIL_FAIL:
      return { userData: null, count:0 , error: action.error};
    default:
      return state;
  }
}
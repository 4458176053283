import axios from "axios";
import faxManagementActionTypes from "../actions/types/faxManagement.action.type";
import store from "../store/index";
import { getErrorMessageFromException, API_BASE_URL } from "../utils/helper";
export const faxManagementService = {
  getSentFaxLists,
  getFailedFax,
  getViewFaxFile,
  resendFax,
};

function getSentFaxLists(data, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(`${API_BASE_URL()}/fax-management/archived-or-sent-faxes`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.refreshGrid && props?.setRefreshGrid) {
          dispatch({
            type: faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_SUCCESS,
            data: res.data,
            hasRefreshGrid: true,
          });
          props?.setRefreshGrid(false);
        } else {
          dispatch({
            type: faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_SUCCESS,
            data: res.data,
          });
        }
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.refreshGrid && props?.setRefreshGrid) {
          props?.setRefreshGrid(false);
        }
        if (props?.setErrorMessage) {
          props?.setErrorMessage(
            getErrorMessageFromException(error, props?.t) || parsedError.message
          );
        }

        dispatch({
          type: faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_FAILURE,
          error:
            getErrorMessageFromException(error, props?.t) ||
            parsedError.message,
        });
      });
  };
}

function resendFax(data, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(`${API_BASE_URL()}/fax-management/resend-or-sent-fax`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (props?.setLoading && props.loadingArr) {
          const idx = props?.index;
          const arr = props.loadingArr?.slice();
          arr.splice(idx, 1, false);
          props?.setLoading(arr);
        } else if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(true);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.setRefreshGrid) {
          props?.setRefreshGrid(true);
        }
        dispatch({
          type: faxManagementActionTypes.RESEND_FAX_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        const updatedErrorMessage =
          getErrorMessageFromException(error, props?.t) || parsedError.message;
        if (props?.setLoading && props.loadingArr) {
          const idx = props?.index;
          const arr = props.loadingArr?.slice();
          arr.splice(idx, 1, false);
          props?.setLoading(arr);
        } else if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setErrorMessage) {
          props?.setErrorMessage(updatedErrorMessage);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.setRefreshGrid) {
          props?.setRefreshGrid(false);
        }

        dispatch({
          type: faxManagementActionTypes.RESEND_FAX_FAILURE,
          error: updatedErrorMessage,
        });
      });
  };
}

function getFailedFax(data, props) {
  return (dispatch) => {
    const reqData = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/fax-management/failed-faxes`,
        { ...reqData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (props?.setLoader) {
          props?.setLoader(false);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.setHasValidFilter) {
          props?.setHasValidFilter(true);
        }
        if (props?.refreshGrid && props?.setRefreshGrid) {
          dispatch({
            type: faxManagementActionTypes.GET_FAILED_FAX_LISTS_SUCCESS,
            data: res.data,
            hasRefreshGrid: true,
          });
          props?.setRefreshGrid(false);
        } else {
          dispatch({
            type: faxManagementActionTypes.GET_FAILED_FAX_LISTS_SUCCESS,
            data: res.data,
          });
        }
      })
      .catch((error) => {
        if (props?.setLoader) {
          props?.setLoader(false);
        }
        if (props?.setHasValidFilter) {
          props?.setHasValidFilter(false);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        const errorMessage = getErrorMessageFromException(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        if (props?.refreshGrid && props?.setRefreshGrid) {
          props?.setRefreshGrid(false);
        }
        dispatch({
          type: faxManagementActionTypes.GET_FAILED_FAX_LISTS_FAILURE,
          error: errorMessage,
          data: null,
        });
      });
  };
}

function getViewFaxFile(data, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(`${API_BASE_URL()}/fax-management/view-file`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        if (props?.setText) {
          props?.setText(res.data);
        }
        // dispatch({
        //   type: faxManagementActionTypes.GET_VIEW_FAX_DETAIL_SUCCESS,
        //   data: "Successfully view fax detail",
        // });
      })
      .catch((error) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        const errorMessage = getErrorMessageFromException(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        if (props?.setInitialLoading) {
          props?.setInitialLoading(false);
        }
        dispatch({
          type: faxManagementActionTypes.GET_VIEW_FAX_DETAIL_FAILURE,
          error: errorMessage,
        });
      });
  };
}

import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import { ERROR_BUDGET_CATEGORY } from "../../constants/BudgetCategoryErrorConstant";
import budgetCategoryActionType from "../../actions/types/budgetCategory.action.type";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";

const NewBudgetCategory = (props) => {
  const { t } = useTranslation();
  const {
    history,
    location: { state },
  } = props;
  const errorRef = useRef();

  const [values, setValues] = useState({
    budgetCode: "",
    description: "",
    budgetCodeErrorHighlight: false,
    descriptionErrorHighlight: false,
  });
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [error, setError] = useState([]);
  const duplicateBudgetCodeError = useSelector(
    (state) => state.budgetCategoryReducer.error
  );
  const successMessage = useSelector(
    (state) => state.budgetCategoryReducer.newBudgetCategoryAdded
  );
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, duplicateBudgetCodeError]);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();
  const handleChange = (prop) => (event) => {
    if (duplicateBudgetCodeError && prop === "budgetCode") {
      dispatch({
        type: budgetCategoryActionType.BUDGET_CATEGORY_FAIL,
        error: null,
      });
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const selectedFiNumber = useSelector((state) => state.fiReducer.selectedFi);
  const fiNumber = useSelector(
    (state) => state.loginUserReducer.user?.fiNumber
  );

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const submitBudget = (event, setOpenCancelDialog) => {
    dispatch({
      type: budgetCategoryActionType.NEW_BUDGET_CATEGORY_SUCCESS,
      newBudgetCategoryAdded: null,
    });
    const errormsg = validateBudgetCategory();
    setError(errormsg);
    if (!errormsg.length) {
      setLoader(true);
      dispatch(
        actions.budgetCategoryActions.submitCategory(
          {
            ...values,
            fiNumber: selectedFiNumber ? selectedFiNumber : fiNumber,
          },
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            setOpenCancelDialog,
            t,
          }
        )
      );
    } else {
      if (setOpenCancelDialog) {
        setOpenCancelDialog(false);
      }
    }
  };

  function validateBudgetCategory() {
    let errormsg = [];
    var regEx = /^[0-9A-Za-z]+$/;
    var regExWithSpace = /^[0-9A-Za-z ]+$/;
    values.budgetCodeErrorHighlight = false;
    values.descriptionErrorHighlight = false;

    if (!values.budgetCode) {
      values.budgetCodeErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.BudgetCodeError);
    }
    if (!values.description) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.BudgetDescriptionError);
    }
    if (
      values.budgetCode &&
      (values.budgetCode.trim().length < 3 ||
        values.budgetCode.trim().length > 4)
    ) {
      values.budgetCodeErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.BudgetCodeLengthError);
    }
    if (
      values.description &&
      (values.description.trim().length < 3 ||
        values.description.trim().length > 25)
    ) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.DescriptionLengthError);
    }
    if (values.budgetCode && !values.budgetCode.match(regEx)) {
      values.budgetCodeErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.BudgetCodeAlphaNumericError);
    }
    if (values.description && !values.description.match(regExWithSpace)) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.DescriptionAlphaNumericError);
    }
    return errormsg;
  }
  const newBudgetCategoryAccess = state?.componentAccess.filter(
    ({ componentType, childComponents }) => componentType === "NBC"
  );

  const newBudgetCategoryChildComponent =
    newBudgetCategoryAccess[0]?.childComponents;

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleRedirect = () => {
    history.push("/manage-budget-category", {
      componentAccess: state?.componentAccess,
    });
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={state?.componentAccess} />
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t(
              "erps.budgetmanagement.button.newbudgetcategory",
              "New buget category"
            )}
          </Typography>
        </Box>
        {(error.length > 0 || duplicateBudgetCodeError) && (
          <Box
            className="manageUsers__errorMsg__container"
            mt={error.length > 0 || duplicateBudgetCodeError ? 2 : 0}
            role="alert"
          >
            {(error.length > 0 || duplicateBudgetCodeError) && (
              <Box tabIndex={0} ref={errorRef} style={{ display: "flex" }}>
                <Icon className="manageUsers__error__icon" aria-hidden={false}>
                  <img src={warningIcon} alt="Error notification" />
                </Icon>
                <div style={{ marginTop: "-12px" }}>
                  <ul>
                    <li
                      style={{
                        color: "#C12334",
                        listStyle: "none",
                        marginLeft: "-20px",
                        marginTop: "20px",
                      }}
                    >
                      {"The following error(s) need to be fixed:"}
                    </li>{" "}
                    {error.length > 0 &&
                      error.map((err) => (
                        <li style={{ color: "#C12334" }}> {err} </li>
                      ))}
                    {duplicateBudgetCodeError && (
                      <li style={{ color: "#C12334" }}>
                        {" "}
                        {duplicateBudgetCodeError}{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </Box>
            )}
          </Box>
        )}

        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="BudgetCode"
            type="text"
            error={values.budgetCodeErrorHighlight}
            value={values.budgetCode}
            onChange={handleChange("budgetCode")}
            label={t("erps.budgetmanagement.label.budgetcode", "Budget Code")}
            className="common__textField__width"
            size="small"
            required="true"
            componentAccess={newBudgetCategoryChildComponent}
            actionType={"hasBudgetCodeTextField"}
            inputProps={{ maxLength: 4 }}
          />
          <ERPSBaseTextField
            id="Description"
            type="text"
            value={values.description}
            error={values.descriptionErrorHighlight}
            onChange={handleChange("description")}
            label={t("erps.budgetmanagement.label.description", "Description")}
            className="common__textField__width"
            size="small"
            required="true"
            componentAccess={newBudgetCategoryChildComponent}
            actionType={"hasBudgetDesciptionTextField"}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.newuser.button.cancel", "Cancel")}
            componentAccess={newBudgetCategoryChildComponent}
            actionType={"hasCancelButton"}
            onClick={handleOpenCancelDialog}
          />
          <ERPSBaseButton
            variant="primary"
            onClick={(e) => submitBudget(e)}
            className="button_with_margin48"
            label={t("erps.newuser.button.save", "Save")}
            loading={loader}
            componentAccess={newBudgetCategoryChildComponent}
            actionType={"hasSaveBudgetCategoryAccess"}
          />
        </Stack>
      </Box>
      <div>
        <CustomModelDialog
          handleClose={handleCloseCancelDialog}
          open={openCancelDialog}
          dialogHeaderClassName={"dialogHeaderClassName"}
          dialogActionsClassName={"leftAlignDialogActions"}
          handleRedirect={handleRedirect}
          tanslation={t}
        />
      </div>
    </div>
  );
};
export default withSidebar(NewBudgetCategory);

import userPendingApproval from "../actions/types/userPendingApproval.action.type";

export const userApprovalInitialState = {
  userPendingApprovalData: null,
  userPendingApprovalError: null,

  userActionMessage: null,
  userActionError: null,

  nextTransactionId: null
};

export default function newUserReducer(
  state = userApprovalInitialState,
  action
) {
  switch (action.type) {
    case userPendingApproval.GET_PENDING_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        userPendingApprovalData: action.data,
        userPendingApprovalError: null,
        userActionError: null,
        nextTransactionId: null
      };
    case userPendingApproval.GET_PENDING_USER_APPROVAL_FAILURE:
      return {
        ...state,
        userPendingApprovalData: null,
        userPendingApprovalError: action.error,
        nextTransactionId: null
      };
    case userPendingApproval.UPDATE_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        userActionMessage: action.data,
        userActionError: null,
        userPendingApprovalError: null,
        nextTransactionId: action.nextTransactionId,
        userPendingApprovalData: {...state.userPendingApprovalData, queueDataList: action.queueDataList }
      };
    case userPendingApproval.UPDATE_USER_APPROVAL_FAILURE:
      return {
        ...state,
        userActionMessage: null,
        userActionError: action.error,
      };
    case userPendingApproval.CREATE_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        userActionMessage: action.data,
        userActionError: null,
        userPendingApprovalError: null,
        nextTransactionId: action.nextTransactionId,
        userPendingApprovalData: {...state.userPendingApprovalData, queueDataList: action.queueDataList }
      };
    case userPendingApproval.CREATE_USER_APPROVAL_FAILURE:
      return {
        ...state,
        userActionMessage: null,
        userActionError: action.error,
      };
    case userPendingApproval.DELETE_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        userActionMessage: action.data,
        userActionError: null,
        userPendingApprovalError: null,
        nextTransactionId: action.nextTransactionId,
        userPendingApprovalData: {...state.userPendingApprovalData, queueDataList: action.queueDataList }
      };
    case userPendingApproval.DELETE_USER_APPROVAL_FAILURE:
      return {
        ...state,
        userActionMessage: null,
        userActionError: action.error,
      };
    case userPendingApproval.DECLINE_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        userActionMessage: action.data,
        userActionError: null,
        userPendingApprovalError: null,
        nextTransactionId: action.nextTransactionId,
        userPendingApprovalData: {...state.userPendingApprovalData, queueDataList: action.queueDataList }
      };
    case userPendingApproval.DECLINE_USER_APPROVAL_FAILURE:
      return {
        ...state,
        userActionMessage: null,
        userActionError: action.error,
      };
    default:
      return state;
  }
}

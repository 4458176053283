const marketingManagementTypes = {
  GET_DASHBOARD_MESSAGE_SUCCESS: "GET_DASHBOARD_MESSAGE_SUCCESS",
  GET_DASHBOARD_MESSAGE_FAILURE: "GET_DASHBOARD_MESSAGE_FAILURE",
  CREATE_DASHBOARD_MESSAGE_SUCCESS: "CREATE_DASHBOARD_MESSAGE_SUCCESS",
  CREATE_DASHBOARD_MESSAGE_FAILURE: "CREATE_DASHBOARD_MESSAGE_FAILURE",
  APPROVE_DASHBOARD_MESSAGE_SUCCESS: "APPROVE_DASHBOARD_MESSAGE_SUCCESS",
  APPROVE_DASHBOARD_MESSAGE_FAILURE: "APPROVE_DASHBOARD_MESSAGE_FAILURE",
  DECLINE_DASHBOARD_MESSAGE_SUCCESS: "APPROVE_DASHBOARD_MESSAGE_SUCCESS",
  DECLINE_DASHBOARD_MESSAGE_FAILURE: "APPROVE_DASHBOARD_MESSAGE_FAILURE",
  GET_DASHOBAORD_QUEUE_MESSAGE_FAILURE: "GET_DASHOBAORD_QUEUE_MESSAGE_FAILURE",
  GET_DASHBOARD_QUEUE_MESSAGE_SUCCESS: "GET_DASHBOARD_QUEUE_MESSAGE_SUCCESS",
  GET_LOGIN_MESSAGE_SUCCESS: "GET_LOGIN_MESSAGE_SUCCESS",
  GET_LOGIN_MESSAGE_FAILURE: "GET_LOGIN_MESSAGE_FAILURE",
  GET_LOGIN_QUEUE_MESSAGE_SUCCESS: "GET_LOGIN_QUEUE_MESSAGE_SUCCESS",
  GET_LOGIN_QUEUE_MESSAGE_FAILURE: "GET_LOGIN_QUEUE_MESSAGE_FAILURE",
  CREATE_LOGIN_MESSAGE_SUCCESS: "CREATE_LOGIN_MESSAGE_SUCCESS",
  CREATE_LOGIN_MESSAGE_FAILURE: "CREATE_DASHBOARD_MESSAGE_FAILURE",
  APPROVE_LOGIN_MESSAGE_SUCCESS: "APPROVE_DASHBOARD_MESSAGE_SUCCESS",
  APPROVE_LOGIN_MESSAGE_FAILURE: "APPROVE_DASHBOARD_MESSAGE_FAILURE",
  DECLINE_LOGIN_MESSAGE_SUCCESS: "APPROVE_DASHBOARD_MESSAGE_SUCCESS",
  DECLINE_LOGIN_MESSAGE_FAILURE: "APPROVE_DASHBOARD_MESSAGE_FAILURE",
};

export default marketingManagementTypes;

import { useState, useEffect } from "react";
import { serializer } from "./utils/serialise";
import { deserialize } from "./utils/deserialise";

export const useEditorState = (props) => {
  const [englishValue, setEnglishValue] = useState("");
  const [frenchValue, setFrenchValue] = useState("");
  const [htmlContent, setHtmlContent] = useState({});
  const initialState = [
    {
      type: "paragaph",
      children: [{ text: "" }],
    },
  ];

  useEffect(() => {
    if (props?.addNewBiller) {
      if (!englishValue) {
        setEnglishValue(initialState);
      }
      if (!frenchValue) {
        setFrenchValue(initialState);
      }
    } else {
      if (props?.hasEnglishInstructionTextValue) {
        if (!englishValue) {
          setEnglishValue(initialState);
        }
      }
      if (props?.hasFrenchInstructionTextValue) {
        if (!frenchValue) {
          setFrenchValue(initialState);
        }
      }
    }
  }, []);

  const handleEditorChange = (key) => (newValue) => {
    if (key === "en") {
      setEnglishValue(newValue);
    } else {
      setFrenchValue(newValue);
    }
    const serialiseData = serializer(newValue);
    setHtmlContent((preState) => ({
      ...preState,
      [key]: serialiseData,
    }));
  };

  const handleDeserialiseInitialValue = (key, serialiseData) => {
    const serialiseDataUpdated = serialiseData || "";
    const document = new DOMParser().parseFromString(
      serialiseDataUpdated,
      "text/html"
    );
    const deserialiseData = serialiseDataUpdated
      ? deserialize(document.body)
      : initialState;

    if (key === "en") {
      setEnglishValue(deserialiseData);
    } else {
      setFrenchValue(deserialiseData);
    }
    setHtmlContent((preState) => ({
      ...preState,
      [key]: serialiseDataUpdated,
    }));
  };

  return {
    htmlContent,
    englishValue,
    frenchValue,
    handleEditorChange,
    handleDeserialiseInitialValue,
    setEnglishValue,
    setFrenchValue,
  };
};

import React, { useState, useRef, useEffect } from "react";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import { withSidebar } from "../hoc/withSidebar";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useTranslation } from "react-i18next";
import { Box, Icon } from "@material-ui/core";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import { ERROR_BUDGET_CATEGORY } from "../../constants/BudgetCategoryErrorConstant";
import budgetCategoryActionType from "../../actions/types/budgetCategory.action.type";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";

const BudgetCategory = (props) => {
  const { t } = useTranslation();
  const {
    location: {
      state: { componentAccess, id },
    },
    history,
  } = props;
  const dispatch = useDispatch();
  const errorRef = useRef();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const successMessage = useSelector(
    (state) => state.budgetCategoryReducer.budgetCategoryUpdated
  );

  const deleteSuccessMessage = useSelector(
    (state) => state.budgetCategoryReducer.budgetCategoryUpdated?.message
  );

  const budgetList = useSelector((state) => state.budgetCategoryReducer.budget);
  const successRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, deleteSuccessMessage]);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [
    successMessage && typeof successMessage === "string" && showSuccessMessage,
  ]);

  useEffect(() => {
    dispatch({
      type: budgetCategoryActionType.DELETE_BUDGET_CATEGORY_SUCCESS,
      data: null,
      budget: budgetList,
    });
  }, []);

  const data = budgetList.data.data.find(({ budgetCode }) => budgetCode === id);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const {
    id: budgetId,
    budgetCode,
    description,
    createdDate,
    lastModifiedDate,
    lastModifiedBy,
    displayCreatedBy,
    displayUpdatedBy,
    createdBy,
  } = data;
  const [values, setValues] = React.useState({
    id: budgetId,
    budgetCode: budgetCode,
    description: description,
    createdDate: createdDate,
    createdBy: createdBy,
    lastModifiedDate: lastModifiedDate,
    lastModifiedBy: lastModifiedBy,
    descriptionErrorHighlight: false,
    originalDescription: description,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleRedirect = () => {
    history.push("/manage-budget-category", {
      componentAccess,
    });
  };

  const handleDeleteUser = () => {
    setSaveLoading(true);
    dispatch(
      actions.budgetCategoryActions.deleteCategory(
        values,
        setValues,
        {
          ...props,
          setLoader,
          setSaveLoading,
          loader,
          history,
          setOpen,
          componentAccess,
          t,
        },
        budgetList
      )
    );
  };

  const updateCategory = () => {
    dispatch({
      type: budgetCategoryActionType.UPDATE_BUDGET_CATEGORY_SUCCESS,
      budgetCategoryUpdated: null,
      budget: budgetList,
    });
    const errormsg = validateBudgetCategory();
    setError(errormsg);
    if (!errormsg.length) {
      setLoader(true);
      dispatch(
        actions.budgetCategoryActions.updateCategory(
          values,
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            t,
          },
          budgetList
        )
      );
    }
  };

  function validateBudgetCategory() {
    let errormsg = [];
    var regExWithSpace = /^[0-9A-Za-z ]+$/;
    values.descriptionErrorHighlight = false;

    if (!values.description) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.DescriptionEmptyError);
    }
    if (
      values.description &&
      (values.description.trim().length < 3 ||
        values.description.trim().length > 25)
    ) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.DescriptionLengthError);
    }

    if (values.description && !values.description.match(regExWithSpace)) {
      values.descriptionErrorHighlight = true;
      errormsg.push(ERROR_BUDGET_CATEGORY.DescriptionAlphaNumericError);
    }
    return errormsg;
  }

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const userComponentAccess = componentAccess?.find(
    ({ componentType }) => componentType === "MBCGFV"
  );

  const userComponentChildAccess = userComponentAccess?.childComponents;

  return (
    <div role="main">
      {successMessage &&
        typeof successMessage === "string" &&
        showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}

      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={componentAccess} />

        {deleteSuccessMessage && (
          <Box
            tabIndex={0}
            ref={errorRef}
            mt={4}
            className="manageUsers__errorMsg__container"
          >
            <Icon className="manageUsers__error__icon" aria-hidden={false}>
              <img src={warningIcon} alt="warning-icon" />
            </Icon>
            <Typography variant="body1" className="manageUsers__errorMsg__text">
              {deleteSuccessMessage}
            </Typography>
          </Box>
        )}
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.budgetcategory.page.title", `Budget category: `)} {id}
          </Typography>
        </Box>
        {error.length ? (
          <Box
            className="manageUsers__errorMsg__container"
            mt={error.length ? 2 : 0}
            role="alert"
          >
            {error.length > 0 && (
              <ErrorMessage error={error} errorRef={errorRef} />
            )}
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.newbudgetcategory.label.budgetcode", "Budget code")} <br />{" "}
            {values.budgetCode}
          </Typography>
          <ERPSBaseTextField
            id="Description"
            type="text"
            label={t("erps.newbudgetcategory.label.description", "Description")}
            size="small"
            value={values.description}
            error={values.descriptionErrorHighlight}
            onChange={handleChange("description")}
            componentAccess={userComponentChildAccess}
            actionType={"hasBudgetDesciptionTextField"}
            required={"true"}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.page.createdDate", "Created date")} <br />{" "}
            {values.createdDate}
          </Typography>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.label.createdBy", "Created By")} <br />{" "}
            {displayCreatedBy}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t(
              "erps.budgetcategory.page.lastModifiedDate",
              "Last modified date"
            )}
            <br /> {values.lastModifiedDate}
          </Typography>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.page.lastModifiedBy", "Last modified by")}
            <br /> {displayUpdatedBy}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.newuser.button.cancel", "Cancel")}
            onClick={handleOpenCancelDialog}
            componentAccess={userComponentChildAccess}
            actionType={"hasCancelButton"}
          />
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            onClick={handleOpen}
            label={t("erps.budgetcategory.tab.link", "Delete budget category")}
            loading={dialogLoading}
            componentAccess={userComponentChildAccess}
            actionType={"hasDeleteBudgetCategoryAccess"}
          />
          <ERPSBaseButton
            functionType="MBC"
            variant="primary"
            onClick={updateCategory}
            className="button_with_margin48"
            label={t("erps.newuser.button.save", "Save")}
            loading={loader}
            componentAccess={userComponentChildAccess}
            actionType={"hasSaveBudgetCategoryAccess"}
          />
        </Stack>

        <div>
          <ERPSBaseDialog
            role="dialog"
            onClose={handleClose}
            aria-labelledby="delete-profile-dialog"
            open={open}
          >
            <DialogHeader
              id="delete-profile-dialog-title"
              onClose={handleClose}
              style={{
                fontFamily: "Roboto,Regular",
                fontSize: "18px",
                color: "#2a2c2e",
              }}
            >
              {t("erps.budgetmanagement.model.title")}
            </DialogHeader>
            <DialogContent>
              <Typography gutterBottom aria-describedby="dialog-message">
                {t("erps.budgetmanagement.model.delete.description")}
                <br />
                {values.budgetCode}
              </Typography>
              <Typography gutterBottom aria-describedby="confirmation-message">
                <strong>
                  {t("erps.budgetmanagement.model.delete.confirmation")}
                </strong>
              </Typography>
            </DialogContent>
            <DialogActions className={"leftAlignDialogActions"}>
              <ERPSBaseButton
                variant="secondary"
                className="button_with_zeromargin"
                onClick={handleClose}
                label={t("erps.budgetmanagement.button.cancel")}
              />
              <ERPSBaseButton
                variant="primary"
                className="button_with_zeromargin"
                onClick={handleDeleteUser}
                label={t("erps.budgetmanagement.button.delete")}
                loading={saveLoading}
              />
            </DialogActions>
          </ERPSBaseDialog>
        </div>

        {/* for cancel dialog */}
        <CustomModelDialog
          handleClose={handleCloseCancelDialog}
          open={openCancelDialog}
          dialogHeaderClassName={"dialogHeaderClassName"}
          dialogActionsClassName={"leftAlignDialogActions"}
          handleRedirect={handleRedirect}
          tanslation={t}
        />
      </Box>
    </div>
  );
};
export default withSidebar(BudgetCategory);

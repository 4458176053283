import services from "../services";

export const billerPendingApprovalsActions = {
  getPendingBillerApprovals,
  getBillerChangesDetails,
  declineBillerApprovalData,
  createBillerApprovalData,
  updateBillerApprovalData,
};

function getPendingBillerApprovals(
  data,
  setLoading,
  translation,
  setPendingBillerApprovals
) {
  return services.billerPendingApprovalService.getPendingBillerApprovals(
    data,
    setLoading,
    translation,
    setPendingBillerApprovals
  );
}

function getBillerChangesDetails(
  data,
  setLoading,
  setShowExceptionMessage,
  translation
) {
  return services.billerPendingApprovalService.getBillerChangesDetails(
    data,
    setLoading,
    setShowExceptionMessage,
    translation
  );
}

function declineBillerApprovalData(declineBillerData, props) {
  return services.billerPendingApprovalService.declineBillerApprovalData(
    declineBillerData,
    props
  );
}

function updateBillerApprovalData(updateBillerData, props) {
  return services.billerPendingApprovalService.updateBillerApprovalData(
    updateBillerData,
    props
  );
}

function createBillerApprovalData(createBillerApproval, props) {
  return services.billerPendingApprovalService.createBillerApprovalData(
    createBillerApproval,
    props
  );
}

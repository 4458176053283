import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { Box, Grid, Icon } from "@material-ui/core";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSGrid from "../custom/containers/ERPSGrid";
import actions from "../../actions";
import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import {
  getBatchHolidayHeading,
  EMPTY_HOLIDAY_CRITERIA,
} from "../../constants/batchHolidayConstant";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { statisticsFormatDate } from "./../../utils/helper";
import moment from "moment";

const BatchHoliday = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    history,
    location: {
      pathname,
      state: { componentAccess, ...restProps },
    },
  } = props;
  const defaultValues = {
    holidayDate: null,
    holidayDescription: "",
    descriptionErrorHighlight: false,
    dateErrorHighlight: false,
  };

  const errorRef = useRef();
  const [error, setError] = useState([]);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [holidayDetails, setHolidayDetails] = useState(EMPTY_HOLIDAY_CRITERIA);
  const [values, setValues] = useState(defaultValues);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({});
  const [displayDialog, setDisplayDialog] = useState(false);
  const [holidayData, setHolidayData] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);

  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer.selectedFi);
  const batchHolidayResult = useSelector(
    (state) => state.batchHolidayReducer.holidayList
  );
  const fiNumber = useSelector(
    (state) => state.loginUserReducer.user?.fiNumber
  );
  const successMessage = useSelector(
    (state) => state.batchHolidayReducer?.success
  );

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);
  const fetchHoliday = () => {
    dispatch(actions.batchHolidayActions.getHoliday(0, 25, 0, t, 25));
  };

  const handleOpenDialog = () => {
    setDisplayDialog(true);
  };

  const handleCloseDialog = () => {
    setDisplayDialog(false);
    setDeleteRecord({});
  };

  useEffect(() => {
    fetchHoliday();
  }, []);

  useEffect(() => {
    if (batchHolidayResult) {
      setHolidayData(batchHolidayResult.data);
    }
    if (batchHolidayResult?.holidayDetails) {
      const { startOffset, endOffset } = batchHolidayResult?.holidayDetails;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setHolidayDetails(batchHolidayResult?.holidayDetails);
    }
  }, [batchHolidayResult]);

  const handleFormChange = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [e.target.id]: value,
    });
  };

  const handlePeriodChange = (value) => {
    setValues({
      ...values,
      holidayDate: value,
    });
  };

  const cancelHoliday = () => {
    setValues(defaultValues);
    setError([]);
    setLoader(false);
    setDeleteRecord({});
  };

  const validateBatchHoliday = () => {
    let errormsg = [];
    values.descriptionErrorHighlight = false;
    values.dateErrorHighlight = false;
    if (!values.holidayDate) {
      values.dateErrorHighlight = true;
      errormsg.push(t("erps.batchholiday.page.error.dateNotBlank"));
    }
    if (values.holidayDate == "Invalid date") {
      values.dateErrorHighlight = true;
      errormsg.push(t("erps.batchholiday.page.error.dateInvalid"));
    } else {
      const enteredDate = moment(values.holidayDate);
      const todayDate = moment();
      if (enteredDate.isBefore(todayDate, "day")) {
        values.dateErrorHighlight = true;
        errormsg.push(t("erps.batchholiday.page.error.dateInvalid"));
      }
    }
    if (!values.holidayDescription) {
      values.descriptionErrorHighlight = true;
      errormsg.push(t("erps.batchholiday.page.error.descriptionNotBlank"));
    }
    return errormsg;
  };

  const createHolidayBatch = () => {
    const errormsg = validateBatchHoliday();
    setError(errormsg);
    if (!errormsg.length) {
      setLoader(true);
      dispatch(
        actions.batchHolidayActions.createHoliday(
          {
            ...values,
            fiNumber: selectedFiNumber ? selectedFiNumber : fiNumber,
            holidayDateFormatStr: statisticsFormatDate(values?.holidayDate),
          },
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            defaultValues,
            t,
          }
        )
      );
    }
  };

  const deleteHolidayAlert = (data) => {
    handleOpenDialog();
    setDeleteRecord({
      holidayDate: data?.row?.holidayDate,
      holidayDescription: data?.row?.holidayDescription,
      id: data?.row?.id,
    });
  };

  const deleteHoliday = () => {
    setDeleteLoader(true);
    dispatch(
      actions.batchHolidayActions.deleteHoliday(
        {
          ...deleteRecord,
          holidayDateFormatStr: statisticsFormatDate(deleteRecord?.holidayDate),
        },
        setValues,
        {
          ...props,
          setLoader,
          loader,
          setShowSuccessMessage,
          defaultValues,
          setDisplayDialog,
          setDeleteLoader,
          t,
        }
      )
    );
  };

  const sanitizeQueryParams = (jsonObj) => {
    let updatedReqBody;
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      } else {
        if (typeof jsonObj[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key] };
        }
      }
    });
    return updatedReqBody;
  };

  const paginateHoliday = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    const holidayDataResultCount = holidayData.count;
    const pagSize = actualCriteria.endOffset - actualCriteria.startOffset;
    if (actualCriteria.startOffset < holidayDataResultCount) {
      setHolidayDetails((prevState) => ({
        ...prevState,
        startOffset: actualCriteria.startOffset,
        endOffset: actualCriteria.endOffset,
      }));
      setInitialLoading(true);
      dispatch(
        actions.batchHolidayActions.getHoliday(
          holidayDataResultCount,
          pagSize,
          actualCriteria.startOffset,
          t,
          actualCriteria.endOffset,
          setInitialLoading
        )
      );
    }
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.batchholiday.page.title")}
          </Typography>
        </Box>
        {loginUser?.role?.role_type == "PA" ? (
          <>
            <Box className="manageUsers__wrap__box">
              <Typography variant="body1">
                {t("erps.batchholiday.page.title.description")}
              </Typography>
            </Box>
            {error.length > 0 && (
              <Box
                className="manageUsers__errorMsg__container"
                mt={error.length > 0 ? 2 : 0}
                role="alert"
              >
                {error.length > 0 && (
                  <Box tabIndex={0} ref={errorRef} style={{ display: "flex" }}>
                    <Icon
                      className="manageUsers__error__icon"
                      aria-hidden={false}
                    >
                      <img src={warningIcon} alt="Error notification" />
                    </Icon>
                    <div style={{ marginTop: "-12px" }}>
                      <ul>
                        <li
                          style={{
                            color: "#C12334",
                            listStyle: "none",
                            marginLeft: "-20px",
                            marginTop: "20px",
                          }}
                        >
                          {t("erps.batchholiday.page.error.needfix")}
                        </li>{" "}
                        {error.length > 0 &&
                          error.map((err) => (
                            <li style={{ color: "#C12334" }}> {err} </li>
                          ))}
                      </ul>
                    </div>
                  </Box>
                )}
              </Box>
            )}
            <Box style={{ width: "650px" }}>
              <Grid container mt={100}>
                <Grid item sm={4} lg={4}>
                  <ERPSBaseDatePicker
                    label={t("erps.batchholiday.page.holidayDate")}
                    value={values?.holidayDate}
                    onChange={handlePeriodChange}
                    minDate={new Date()}
                    maxDate={new Date("9999-12-31")}
                    required="true"
                  />
                </Grid>
                <Grid item sm={4} lg={4}>
                  <ERPSBaseTextField
                    id={"holidayDescription"}
                    type="text"
                    label={t("erps.batchholiday.page.description")}
                    className="holiday_description"
                    size="small"
                    onChange={handleFormChange}
                    value={values?.holidayDescription}
                    required="true"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={-3} display="flex">
              <ERPSBaseButton
                variant="secondary"
                className="button_with_margin48"
                label={t("erps.batchholiday.page.button.cancel")}
                onClick={cancelHoliday}
              />
              <Box pl={2}>
                <ERPSBaseButton
                  variant="primary"
                  className="button_with_margin48"
                  label={t("erps.batchholiday.page.button.add")}
                  onClick={createHolidayBatch}
                  loading={loader}
                />
              </Box>
            </Box>
            <Box mt={6}>
              <Typography variant="h2">
                {t("erps.batchholiday.page.holidayDates")}
              </Typography>
            </Box>
          </>
        ) : (
          ""
        )}
        <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
          <Typography variant="body1">
            {holidayData ? holidayData?.count : 0}{" "}
            {t("erps.batchholiday.page.records")}
          </Typography>
        </Box>
        {holidayData?.count > 0 && (
          <Box className="holiday_table">
            <ERPSGrid
              data={{
                rows: holidayData ? holidayData?.data : [],
                count: holidayData && holidayData.count,
                columns: getBatchHolidayHeading(
                  loginUser?.role?.role_type,
                  deleteHolidayAlert
                ),
              }}
              onAnyPaginationAction={paginateHoliday}
              history={history}
              dataGridWidth={
                loginUser?.role?.role_type == "PA"
                  ? "dataGrid__box__container__batchHolidayPA"
                  : "dataGrid__box__container__batchHoliday"
              }
              componentAccess={componentAccess}
              pageNumber={pageNumber}
              rowPerPage={rowPerPage}
              initialLoading={initialLoading}
              tableLabelText={t("erps.batchholiday.page.title")}
            />
          </Box>
        )}
        <ERPSBaseDialog
          role="dialog"
          onClose={handleCloseDialog}
          aria-labelledby="batchholiday-dialog"
          open={displayDialog}
        >
          <DialogHeader
            id="batchholiday-dialog-title"
            onClose={handleCloseDialog}
            style={{
              fontFamily: "Roboto,Regular",
              fontSize: "18px",
              color: "#2a2c2e",
            }}
          >
            {t("erps.batchholiday.page.dialog.title")}
          </DialogHeader>
          <DialogContent>
            <Typography aria-describedby="dialog-message">
              {t("erps.batchholiday.page.dialog.description1")}
            </Typography>
            <Typography aria-describedby="dialog-message">
              {statisticsFormatDate(deleteRecord?.holidayDate)}{" "}
              {deleteRecord?.holidayDescription}
            </Typography>
            <Typography
              mt={3}
              gutterBottom
              aria-describedby="confirmation-message"
            >
              <strong>
                {t("erps.batchholiday.page.dialog.confirmationMessage")}
              </strong>
            </Typography>
          </DialogContent>
          <DialogActions className={"rightAlignDialogActions"}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_zeromargin"
              onClick={handleCloseDialog}
              label={t("erps.batchholiday.page.button.cancel")}
            />
            <ERPSBaseButton
              variant="primary"
              className="button_with_zeromargin"
              onClick={deleteHoliday}
              label={t("erps.batchholiday.page.button.delete")}
              loading={deleteLoader}
            />
          </DialogActions>
        </ERPSBaseDialog>
      </Box>
    </div>
  );
};

export default withSidebar(BatchHoliday);

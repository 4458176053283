import services from "../services";

export const pricePlanPendingApprovalsActions = {
  getPricePlanPendingApprovals,
  updatePricePlanApprovalData,
  createPricePlanApprovalData,
  declinePricePlanApprovalData,
};

function getPricePlanPendingApprovals(data, setLoading, translation, setPricePendingApprovalData) {
  return services.pricePlanPendingApprovalService.getPendingPricePlanApprovals(
    data,
    setLoading,
    translation,
    setPricePendingApprovalData
  );
}

function updatePricePlanApprovalData(updateUserData, props) {
  return services.pricePlanPendingApprovalService.updatePricePlanApprovalData(
    updateUserData,
    props
  );
}

function createPricePlanApprovalData(createUserApproval, props) {
  return services.pricePlanPendingApprovalService.createPricePlanApprovalData(
    createUserApproval,
    props
  );
}

function declinePricePlanApprovalData(declineUserApproval, props) {
  return services.pricePlanPendingApprovalService.declinePricePlanApprovalData(
    declineUserApproval,
    props
  );
}

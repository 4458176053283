import billerPendingApproval from "../actions/types/billerPendingApproval.action.type";

export const billerApprovalInitialState = {
  billerPendingApprovalData: null,
  billerPendingApprovalError: null,

  billerUserApprovalData: null,
  billerUserApprovalError: null,

  billerActionMessage: null,
  billerActionError: null,
};

export default function billerApprovalReducer(state = billerApprovalInitialState,action) {
  switch (action.type) {
    case billerPendingApproval.GET_PENDING_BILLER_APPROVAL_SUCCESS:
      return {
        ...state,
        billerPendingApprovalData: action.data,
        billerPendingApprovalError: null,
        billerActionError: null,
        nextTransactionId: null,
      };
    case billerPendingApproval.GET_PENDING_BILLER_APPROVAL_FAILURE:
      return {
        ...state,
        billerPendingApprovalData: null,
        billerPendingApprovalError: action.error,
        nextTransactionId: null,
      };
    case billerPendingApproval.GET_BILLER_USER_APPROVAL_SUCCESS:
        return {
          ...state,
          billerUserApprovalData: action.data,
          billerUserApprovalError: null,
        };
    case billerPendingApproval.GET_BILLER_USER_APPROVAL_FAILURE:
        return {
          ...state,
          billerUserApprovalData: null,
          billerUserApprovalError: action.error,
        };
    case billerPendingApproval.UPDATE_BILLER_USER_APPROVAL_SUCCESS:
          return {
            ...state,
            billerActionMessage: action.data,
            billerActionError: null,
            billerPendingApprovalError: null,
            nextTransactionId: action.nextTransactionId,
            billerPendingApprovalData: action.queueDataList

          };
    case billerPendingApproval.UPDATE_BILLER_USER_APPROVAL_FAILURE:
          return {
            ...state,
            billerActionMessage: null,
            billerActionError: action.error,
          };
    case billerPendingApproval.CREATE_BILLER_USER_APPROVAL_SUCCESS:
          return {
            ...state,
            billerActionMessage: action.data,
            billerActionError: null,
            billerPendingApprovalError: null,
            nextTransactionId: action.nextTransactionId,
            billerPendingApprovalData: action.queueDataList

          };
    case billerPendingApproval.CREATE_BILLER_USER_APPROVAL_FAILURE:
          return {
            ...state,
            billerActionMessage: null,
            billerActionError: action.error,
          };
    case billerPendingApproval.DECLINE_BILLER_USER_APPROVAL_SUCCESS:
          return {
            ...state,
            billerActionMessage: action.data,
            billerActionError: null,
            billerPendingApprovalError: null,
            nextTransactionId: action.nextTransactionId,
            billerPendingApprovalData: action.queueDataList
          };
    case billerPendingApproval.DECLINE_BILLER_USER_APPROVAL_FAILURE:
          return {
            ...state,
            billerActionMessage: null,
            billerActionError: action.error,
          };
    default:
    return state;
  }
}

import passwordActionTypes from "../actions/types/password.action.type";

export const userPasswordInitialState = {
  user: null,
  error: null,
};

export default function userPasswordReducer(
  state = userPasswordInitialState,
  action
) {
  switch (action.type) {
    case passwordActionTypes.USER_PWD_UPDATE_SUCCESS:
      return { user: action.user.data, error: null };
    case passwordActionTypes.USER_PWD_UPDATE_ERROR:
      return { error: action.error, user: null };
    default:
      return state;
  }
}

import React from 'react';
import { useTranslation } from "react-i18next";
import "./accessDeniedIp.css";

const AccessDeniedIp = () => {
    const { t } = useTranslation();
    return (
      <div class="text-wrapper">
        <div class="title" data-content="404">
          403 - ACCESS DENIED
        </div>
        <div class="subtitle">
          You don't have permission to access this page.
        </div>
      </div>
    );
}

export default AccessDeniedIp;
import billerPendingApprovalsActionsType from "../actions/types/billerPendingApproval.action.type";
import store from "../store/index";
import axios from "axios";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL,
} from "../utils/helper";

export const billerPendingApprovalService = {
  getPendingBillerApprovals,
  getBillerChangesDetails,
  declineBillerApprovalData,
  createBillerApprovalData,
  updateBillerApprovalData,
};
function getPendingBillerApprovals(
  data,
  setLoading,
  translation,
  setPendingBillerApprovals
) {
  return (dispatch) => {
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-pending-approvals/list",
        {
          ...data,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        dispatch(success(parsedData.queueDataList));
        if (setPendingBillerApprovals) {
          setPendingBillerApprovals(parsedData.queueDataList);
        }
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedError = getExceptionMessage(error, translation);
        dispatch(failure(parsedError));
      });
  };

  function success(user) {
    return {
      type: billerPendingApprovalsActionsType.GET_PENDING_BILLER_APPROVAL_SUCCESS,
      data: user,
    };
  }

  function failure(error) {
    return {
      type: billerPendingApprovalsActionsType.GET_PENDING_BILLER_APPROVAL_FAILURE,
      error,
    };
  }
}

function getBillerChangesDetails(
  data,
  setLoading,
  setShowExceptionMessage,
  translation
) {
  return (dispatch) => {
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-pending-approvals/biller-changes-details",
        {
          ...data,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        dispatch(success(parsedData));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const parsedError = getExceptionMessage(error, translation);
        dispatch(failure(parsedError));
      });
  };

  function success(user) {
    return {
      type: billerPendingApprovalsActionsType.GET_BILLER_USER_APPROVAL_SUCCESS,
      data: user,
    };
  }

  function failure(error) {
    return {
      type: billerPendingApprovalsActionsType.GET_BILLER_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function declineBillerApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-pending-approvals/decline-biller",
        {
          ...data,
          hasLastRecord,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setLoading(false);
        dispatch(success(parsedData, props));
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props.t);
        props.setLoading(false);
        dispatch(failure(parsedError));
        if (props?.setShowMessage) {
          props?.setShowMessage(false);
        }
      });
  };

  function success(user) {
    return {
      type: billerPendingApprovalsActionsType.DECLINE_BILLER_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: billerPendingApprovalsActionsType.DECLINE_BILLER_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function updateBillerApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-pending-approvals/approve-pending-biller",
        {
          ...data,
          hasLastRecord,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setLoading(false);

        dispatch(success(parsedData, props));
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, props.t) ||
          JSON.parse(JSON.stringify(error))?.message;
        props.setLoading(false);
        dispatch(failure(parsedError));
        if (props?.setShowMessage) {
          props?.setShowMessage(false);
        }
      });
  };

  function success(user) {
    return {
      type: billerPendingApprovalsActionsType.UPDATE_BILLER_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: billerPendingApprovalsActionsType.UPDATE_BILLER_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function createBillerApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-pending-approvals/approve-pending-biller",
        {
          ...data,
          hasLastRecord,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setLoading(false);
        dispatch(success(parsedData, props));
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props.t);
        props.setLoading(false);
        if (props?.setShowMessage) {
          props?.setShowMessage(false);
        }
        dispatch(failure(parsedError));
      });
  };

  function success(user) {
    return {
      type: billerPendingApprovalsActionsType.CREATE_BILLER_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: billerPendingApprovalsActionsType.CREATE_BILLER_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

import React, { useEffect, useState, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { getManageUsersGridHeadings } from "../../constants/UserPendingApprovalsContant";
import { userPendingApprovalsActions } from "../../actions/userPendingApprovals.actions";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import CircularProgress from "@mui/material/CircularProgress";
import { sanitizeQueryParams } from "../../utils/helper";

const UserPendingApprovals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const userRoleTypes = useSelector((state) => state.userRoleTypeReducer);

  const userDetails = useSelector((state) => state.loginUserReducer?.user);
  const userRole = userDetails?.role?.role_type;
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const userPendingData = useSelector(
    (state) => state.userApprovalReducer?.userPendingApprovalData?.queueDataList
  );
  const userPendingError = useSelector(
    (state) => state.userApprovalReducer?.userPendingApprovalError
  );
  const errorRef = useRef();

  const [data, setUserPendingApprovalData] = useState(null);
  const [roleData, setRoleData] = useState(null);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [userPendingError]);
  const {
    location: {
      state: { componentAccess },
    },
  } = props;

  useEffect(() => {
    dispatch(
      userActions.getUserRoleTypeList(
        userDetails.fiNumber,
        userDetails.role.role_type,
        "manageusercomponent",
        t,
        setRoleData,
        false
      )
    );
  }, []);

  useEffect(() => {
    if (roleData) {
      setInitialLoading(true);
      dispatch(
        userPendingApprovalsActions.getPendingUserApprovals(
          {
            userId: userDetails.id,
            roleIdList: roleData,
            languageCode: userDetails.languageCode || "E",
            fiNumber: userDetails.fiNumber,
            selectedFiNumber: selectedFiNumber,
          },
          setInitialLoading,
          t,
          setUserPendingApprovalData
        )
      );
    }
  }, [roleData]);

  const paginateUsers = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    if (actualCriteria.startOffset < userPendingData.length) {
      const rowPerPage = actualCriteria.endOffset - actualCriteria.startOffset;
      const pageNumber =
        actualCriteria.endOffset /
          (actualCriteria.endOffset - actualCriteria.startOffset) -
        1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setUserPendingApprovalData(
        userPendingData.slice(
          pageNumber * rowPerPage,
          (pageNumber + 1) * rowPerPage
        )
      );
    }
  };

  return (
    <div role="main">
      {userPendingError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={userPendingError ? 2 : 0}
          pl={3}
        >
          {userPendingError && (
            <ErrorMessage error={userPendingError} errorRef={errorRef} />
          )}
        </Box>
      )}

      <CommonContainerBox
        marginTop="30px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
        title={t("erps.pendingapprovals.page.title.", "Pending approvals")}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h1">
              {t("erps.pendingapprovals.page.title.", "Pending approvals")}
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "erps.pendingapprovals.page.description",
                  "Click on a transaction ID, in the table, to view details for the pending approval and either accept or decline the change."
                )}
              </Typography>
            </Grid>
            <Box mt={3} marginBottom={1}>
              <Typography variant="body1">
                {(userPendingData && !initialLoading
                  ? userPendingData.length
                  : 0) +
                  t(
                    "erps.pendingapprovals.page.description.pendingapprovals",
                    " pending approvals"
                  )}
              </Typography>
            </Box>

            {userPendingData && data && !initialLoading ? (
              <>
                {userPendingData.length <= 100 && data.length <= 100 ? (
                  <ERPSGrid
                    data={{
                      rows: data ? data : [],
                      count: data.length,
                      columns: getManageUsersGridHeadings(
                        userDetails.userId,
                        componentAccess
                      ),
                    }}
                    dataGridWidth="dataGrid__box__container__pendingApproval_users"
                    hideFooterPagination={true}
                    initialLoading={!(data && data?.length) && initialLoading}
                    userRole={userRole}
                    tableLabelText={t(
                      "erps.pendingapprovals.biller.page.title"
                    )}
                  />
                ) : (
                  <ERPSGrid
                    data={{
                      rows: data ? data : [],
                      count: userPendingData ? userPendingData.length : "0",
                      columns: getManageUsersGridHeadings(
                        userDetails.userId,
                        componentAccess
                      ),
                    }}
                    dataGridWidth="dataGrid__box__container__pendingApproval_users"
                    initialLoading={!(data && data?.length) && initialLoading}
                    pageNumber={pageNumber}
                    rowPerPage={rowPerPage}
                    userRole={userRole}
                    showPaginationBottom={true}
                    onAnyPaginationAction={paginateUsers}
                    tableLabelText={t(
                      "erps.pendingapprovals.biller.page.title"
                    )}
                  />
                )}
              </>
            ) : (
              <Box ml={40} mt={10} sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </CommonContainerBox>
    </div>
  );
};
export default withSidebar(UserPendingApprovals);

import * as React from "react";
import { PropTypes } from "prop-types";
import { withERPSTabValidator } from "../../hoc/withERPSTabValidator";
import { Tab } from "@material-ui/core";

const ERPSBaseTab = (props) => {
  return <Tab {...props} />;
};
ERPSBaseTab.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withERPSTabValidator(ERPSBaseTab);

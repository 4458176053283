import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useTranslation } from "react-i18next";
import { Box, Icon } from "@material-ui/core";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";

const ViewBudgetCategory = (props) => {
  const { t } = useTranslation();
  const {
    location: {
      state: { componentAccess, id },
    },
    history,
  } = props;
  const dispatch = useDispatch();

  const budgetList = useSelector((state) => state.budgetCategoryReducer.budget);

  const data = budgetList.data.data.find(({ budgetCode }) => budgetCode === id);

  const {
    budgetCode,
    description,
    createdDate,
    createdBy,
    displayCreatedBy,
    lastModifiedDate,
    lastModifiedBy,
    displayUpdatedBy,
  } = data;

  const handleRedirect = () => {
    history.push("/manage-budget-category", {
      componentAccess,
      fromDetailPage: true,
    });
  };

  return (
    <div role="main">
      <Box ml={3}>
        <Breadcrumb
          {...props}
          componentAccess={componentAccess}
          pathValue={"/manage-budget-category/budget-category-details"}
        />
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.budgetcategory.page.title", `Budget category: `)} {id}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.newbudgetcategory.label.budgetcode", "Budget Code")} <br />{" "}
            {budgetCode}
          </Typography>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.newbudgetcategory.label.description", "Description")}{" "}
            <br /> {description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.page.createdDate", "Created date")} <br />{" "}
            {createdDate}
          </Typography>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.label.createdBy", "Created By")} <br />{" "}
            {displayCreatedBy}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="budgetCategory__description">
            {t(
              "erps.budgetcategory.page.lastModifiedDate",
              "Last modified date"
            )}
            <br /> {lastModifiedDate}
          </Typography>
          <Typography variant="label" className="budgetCategory__description">
            {t("erps.budgetcategory.page.lastModifiedBy", "Last modified by")}
            <br /> {displayUpdatedBy}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            label={t("erps.newuser.button.back", "Back")}
            onClick={handleRedirect}
          />
        </Stack>
      </Box>
    </div>
  );
};
export default withSidebar(ViewBudgetCategory);

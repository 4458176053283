import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { useTranslation } from "react-i18next";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ErrorMessage from "../custom/containers/ErrorMessage";
import {
  getRelatedBillerDetails,
  removeRelatedBillerDetails,
} from "../../constants/BillerManagementConstant";
import CardList from "../custom/containers/cardList";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { relatedBillerActions } from "../../actions/relatedBiller.action";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import CircularProgress from "@mui/material/CircularProgress";
export default function RelatedBiller(props) {
  const {
    //searchResults = null
    componentAccess,
  } = props;
  const relatedBillerProfileAccess = componentAccess?.find(
    ({ componentType }) => componentType === "RBTAB"
  );
  const dispatch = useDispatch();
  //const [loading, setLoading] = useState(false);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const { t, i18n } = useTranslation();
  const [openCancelDialog, setOpenCancelDialog] = useState({
    isOpne: false,
    removeRelatedBiller: false,
  });
  const [searchValues, setSearchValues] = useState({
    billerId: "",
    billerName: "",
    startOffset: 0,
    endOffset: 25,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [childBillerResults, setChildBillerResults] = useState([]);
  const [parentBillerResults, setParentBillerResults] = useState(null);

  const [isSearchValid, setHasSearchResults] = useState(true);
  const [errorMssg, setErrorMssg] = useState("");

  const [checkboxValue, setCheckboxValue] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  const successRef = useRef(null);

  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const billerProfileReducerValue = useSelector(
    (state) => state.billerProfileReducer
  );
  const billerProfileValue =
    billerProfileReducerValue?.billerProfileAssociatedData ||
    billerProfileReducerValue?.billerProfileData?.data;

  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const roleId = loginUser?.role?.role_id;
  const fiNumber = selectedFi || loginUser.fiNumber;

  const childBillerListResponse = useSelector(
    (state) => state.relatedBillerReducer?.childBillerList
  );

  const parentBillerResponse = useSelector(
    (state) => state.relatedBillerReducer?.parentBiller
  );

  const removeBillerStatus = useSelector(
    (state) => state.relatedBillerReducer?.removeBillerStatus
  );

  const updateMonthlyInvoiceChildBiller = useSelector(
    (state) => state.relatedBillerReducer?.updateMonthlyInvoiceChildBiller
  );

  const addBillerStatus = useSelector(
    (state) => state.relatedBillerReducer?.addBillerStatus
  );

  const errorMessage = useSelector(
    (state) => state.relatedBillerReducer?.error
  );

  const releatedBillerSearchResponse = useSelector(
    (state) => state.relatedBillerReducer
  );
  const [childRelatedBillerLoader, setChildRelatedBillerLoader] =
    useState(false);

  const [searchLoader, setSearchLoader] = useState(false);
  const checkMonthlyInvoiceForRelatedBiller = (childBillerResults) => {
    if (childBillerResults && childBillerResults.length) {
      const paymentPayFlag = childBillerResults.every(
        ({ paymentPayFlag }) => paymentPayFlag === "Y"
      );
      return paymentPayFlag;
    }
    return false;
  };

  const sanitizeReqBody = (reqBody) => {
    let updatedReqBody;
    Object.keys(reqBody).forEach((key) => {
      if (
        reqBody[key] === undefined ||
        ("string" === typeof reqBody[key] && reqBody[key].trim() === "")
      ) {
        delete reqBody[key];
      } else {
        if (typeof reqBody[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key] };
        }
      }
    });

    return updatedReqBody;
  };
  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [
    removeBillerStatus?.message,
    addBillerStatus?.message,
    updateMonthlyInvoiceChildBiller,
    showSuccessMessage,
  ]);

  useEffect(() => {
    // dispatch api call for Initial load
    setShowApiExceptionMessage(false);
    dispatch(
      relatedBillerActions.getParentBiller(
        { billerFiNumber: billerProfileValue?.billerFiNumber, fiNumber },
        { ...props, setShowApiExceptionMessage, setShowSuccessMessage },
        t
      )
    );
  }, []);

  useEffect(() => {
    if (addBillerStatus?.data) {
      const updatedChildBillerData = addBillerStatus?.data.map(
        (addBiller, idx) => ({
          ...addBiller,
          id: idx,
        })
      );
      setChildBillerResults(updatedChildBillerData);
    }
  }, [addBillerStatus]);

  useEffect(() => {
    if (removeBillerStatus?.data) {
      const updatedChildBillerData = removeBillerStatus?.data.map(
        (addBiller, idx) => ({
          ...addBiller,
          id: idx,
        })
      );
      setChildBillerResults(updatedChildBillerData);
    }
  }, [removeBillerStatus]);

  useEffect(() => {
    setChildRelatedBillerLoader(true);
    setShowApiExceptionMessage(false);

    dispatch(
      relatedBillerActions.getAllChildBiller(
        { billerFiNumber: billerProfileValue?.billerFiNumber, fiNumber },
        {
          ...props,
          setChildRelatedBillerLoader,
          setShowApiExceptionMessage,
          setShowSuccessMessage,
        },
        t
      )
    );
  }, []);

  useEffect(() => {
    if (childRelatedBillerLoader && searchResults && searchResults.length) {
      const actualCriteria = sanitizeReqBody({ ...searchValues });
      setShowApiExceptionMessage(false);

      dispatch(
        relatedBillerActions.getRelatedBillerSearch(
          {
            billerFiNumber: billerProfileValue?.billerFiNumber,
            ...actualCriteria,
            count: 0,
          },
          {
            ...props,
            setChildRelatedBillerLoader,
            setShowApiExceptionMessage,
            setShowSuccessMessage,
          },
          t
        )
      );
    }
  }, [childRelatedBillerLoader]);

  useEffect(() => {
    if (childBillerListResponse) {
      const updatedChildBillerListResponse = childBillerListResponse.map(
        (data, idx) => ({
          ...data,
          id: idx,
        })
      );
      const hasCheckboxDisable = checkMonthlyInvoiceForRelatedBiller(
        childBillerListResponse
      );
      const checkboxValue = hasCheckboxDisable ? "Y" : "N";
      setCheckboxValue(checkboxValue);
      setChildBillerResults(updatedChildBillerListResponse);
    }
  }, [childBillerListResponse]);

  useEffect(() => {
    if (parentBillerResponse) {
      setParentBillerResults(parentBillerResponse);
    } else {
      setParentBillerResults(null);
    }
  }, [parentBillerResponse]);

  useEffect(() => {
    if (releatedBillerSearchResponse) {
      setSearchResults(
        releatedBillerSearchResponse?.releatedBillerSearchData?.data
      );
    }
  }, [releatedBillerSearchResponse?.releatedBillerSearchData]);

  useEffect(() => {
    if (releatedBillerSearchResponse) {
      setShowApiExceptionMessage(false);

      dispatch({
        type: "GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS",
        data: null,
      });
    }
  }, []);

  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [!isSearchValid || errorMssg]);

  const hasSearchValue = () => {
    const keys = Object.keys(searchValues);
    return !keys.some(
      (key) => typeof searchValues[key] === "string" && searchValues[key].trim()
    );
  };

  const handleRemoveRelationship = (e, data, setOpenCancelDialog) => {
    if (data) {
      const { billerFiNumber, billerId, billerName } = data.row;
      setShowApiExceptionMessage(false);

      dispatch(
        relatedBillerActions.removeBillerRelationShip(
          {
            billerFiNumberChild: billerFiNumber,
            billerFiNumberParent: billerProfileValue?.billerFiNumber,
            billerId,
            billerName,
            fiNumber,
            parentBillerId: billerProfileValue?.billerId,
            parentBillerName: billerProfileValue?.billerName,
            parentBmoBillerId: billerProfileValue?.bmoBillerId,
          },
          {
            setOpenCancelDialog,
            setShowSuccessMessage,
            searchResults,
            setShowApiExceptionMessage,
          },
          t
        )
      );
    }
  };
  const handleRowClick = (data, key) => {
    if (key === "Remove relationship") {
      setOpenCancelDialog({ isOpen: true, data });
    } else if (key === "Add relationship") {
      const {
        billerId,
        billerName,
        fiNumber,
        billerFiNumber: billerFiNumberChild,
      } = data.row;
      setShowApiExceptionMessage(false);

      dispatch(
        relatedBillerActions.addBillerRelationship(
          {
            billerId,
            billerName,
            fiNumber,
            billerFiNumberChild,
            billerFiNumberParent: billerProfileValue?.billerFiNumber,
            paymentFlag: checkboxValue,
            parentBillerId: billerProfileValue?.billerId,
            parentBillerName: billerProfileValue?.billerName,
            parentBmoBillerId: billerProfileValue?.bmoBillerId,
          },
          {
            setShowSuccessMessage,
            setChildRelatedBillerLoader,
            setShowApiExceptionMessage,
          },
          t
        )
      );
    }
  };

  const handleClose = (e, key) => {
    if (key) {
      handleRemoveRelationship(e, openCancelDialog?.data, setOpenCancelDialog);
    } else {
      setOpenCancelDialog({ isOpen: false });
    }
  };

  const getDialogContent = () => {
    if (openCancelDialog?.data) {
      const { billerId, billerName } = openCancelDialog.data.row;
      return (
        <>
          <Typography aria-describedby="dialog-message">
            {t("erps.relatedbiller.description.abouttoremovebiller")}
          </Typography>
          <br />
          <Typography variant={"h5"} aria-describedby="dialog-message">
            {`${billerId} ${billerName}`}
          </Typography>
          <br /> <br />
          <br />
          <Typography
            style={{ fontWeight: "500" }}
            gutterBottom
            aria-describedby="dialog-message"
          >
            {t("erps.relatedbiller.alertmessage.abouttoremovebiller")}
          </Typography>
        </>
      );
    }
  };

  const handleSearch = () => {
    if (hasSearchValue()) {
      setHasSearchResults(false);
      setShowSuccessMessage(false);
      setErrorMssg(t("erps.relatedbiller.error.atleastonecrieria"));
    } else {
      let err = [];
      const numericRegEx = /^[0-9]+$/;
      const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
      setHasSearchResults(true);
      const regexForBillerId =
        fiNumber == 50 ? numericRegEx : alphaNumericRegEx;
      if (
        searchValues?.billerName &&
        searchValues?.billerName.includes("%") &&
        searchValues?.billerName.length < 3
      ) {
        err.push(
          t(
            "erps.billermanagement.filterbillercategory.error.invaliduseofwildcardcharacter"
          )
        );
      }

      if (
        searchValues?.billerId &&
        !regexForBillerId.test(searchValues?.billerId)
      ) {
        err.push(
          t("erps.billermanagement.filterbillercategory.error.invalidBillerid")
        );
      }
      if (err.length) {
        setErrorMssg(err);
      } else {
        setHasSearchResults(true);
        setErrorMssg("");

        setSearchLoader(true);
        const actualCriteria = sanitizeReqBody({ ...searchValues });
        setShowApiExceptionMessage(false);

        dispatch(
          relatedBillerActions.getRelatedBillerSearch(
            {
              billerFiNumber: billerProfileValue?.billerFiNumber,
              ...actualCriteria,
              count: 0,
            },
            {
              ...props,
              setSearchLoader,
              setShowApiExceptionMessage,
              setShowSuccessMessage,
            },
            t
          )
        );
      }
    }
  };

  const handleChange = (value, key) => {
    setSearchValues({ ...searchValues, [key]: value });
  };
  const handleBlur = (value, key) => {
    setSearchValues({
      ...searchValues,
      [key]: value && typeof value === "string" ? value.toUpperCase() : value,
    });
  };

  const handleReset = () => {
    // call dispatch action for clearing the state value
    setSearchResults([]);
    setSearchValues({
      billerId: "",
      billerName: "",
      startOffset: 0,
      endOffset: 25,
    });
    setShowApiExceptionMessage(false);

    dispatch({
      type: "GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS",
      data: null,
    });
    setErrorMssg("");
    setHasSearchResults(true);
    if (searchLoader) {
      setSearchLoader(false);
    }
  };

  const handleChangeCheckbox = (hasChecked) => {
    setShowApiExceptionMessage(false);
    dispatch(
      relatedBillerActions.updateMonthlyInvoiceForChildBiller(
        {
          billerFiNumber: billerProfileValue?.billerFiNumber,
          monthlyInvoiceChecked: hasChecked,
          fiNumber,
          parentBillerId: billerProfileValue?.billerId,
          parentBmoBillerId: billerProfileValue?.bmoBillerId,
        },
        {
          setShowSuccessMessage,
          setShowApiExceptionMessage,
        },
        t
      )
    );
    const isChecked = hasChecked ? "Y" : "N";
    setCheckboxValue(isChecked);
  };

  const hasBillerAlreadyRelatedAsChildToParent = () => {
    if (!childBillerResults?.length && parentBillerResults) {
      return true;
    }
    return false;
  };

  const paginateRelatedBillerUsers = (queryParams) => {
    const actualCriteria = sanitizeReqBody({ ...queryParams });
    if (actualCriteria.startOffset < actualCriteria.count) {
      dispatch(
        relatedBillerActions.getRelatedBillerSearch(
          {
            billerFiNumber: billerProfileValue?.billerFiNumber,
            ...actualCriteria,
            count: 0,
            billerName: searchValues?.billerName,
          },
          {
            ...props,
            setSearchLoader,
            setShowApiExceptionMessage,
            setShowSuccessMessage,
          },
          t
        )
      );
    }
  };

  return (
    <div>
      {removeBillerStatus?.message && showSuccessMessage && (
        <ConfirmationMessage
          message={removeBillerStatus?.message}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      {addBillerStatus?.message && showSuccessMessage && (
        <ConfirmationMessage
          message={addBillerStatus?.message}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      {updateMonthlyInvoiceChildBiller && showSuccessMessage && (
        <ConfirmationMessage
          message={updateMonthlyInvoiceChildBiller}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box>
        {errorMessage && showApiExceptionMessage && (
          <ErrorMessage error={errorMessage} errorRef={errorRef} />
        )}

        <Typography variant="h2">
          {hasBillerAlreadyRelatedAsChildToParent()
            ? t("erps.relatedbiller.title.parent")
            : t("erps.relatedbiller.title.child")}
        </Typography>
      </Box>
      {childBillerResults?.length ? (
        <Box mt={2}>
          <ERPSBaseCheckBoxGrp
            label={t("erps.relatedbiller.label.applymonthlyinvoicing")}
            id="relatedBiller"
            value={checkboxValue === "Y" ? true : false}
            onChange={(e) => handleChangeCheckbox(e.target.checked)}
            actionType={"hasUpdateParentBillerAccess"}
            componentType={relatedBillerProfileAccess?.childComponents}
            disabled={roleId === 10}
          />
        </Box>
      ) : (
        ""
      )}
      <Box mt={4}>
        <Typography variant="body1">
          {childBillerResults?.length
            ? t("erps.relatedbiller.description.listedbelow")
            : hasBillerAlreadyRelatedAsChildToParent()
            ? t("erps.relatedbiller.description.alreadyrelated")
            : t("erps.relatedbiller.description.norelatedbiller")}
        </Typography>
      </Box>
      {childBillerResults?.length ? (
        <>
          <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
            <Typography variant="body1">
              {childBillerResults?.length} {"child billers"}
            </Typography>
          </Box>
          <>
            <Box className="manageUsers_card_visible">
              {!childRelatedBillerLoader ? (
                <CardList
                  data={{
                    rows: childBillerResults,
                    count: childBillerResults?.length,
                    columns: removeRelatedBillerDetails(
                      handleRowClick,
                      "Remove relationship",
                      relatedBillerProfileAccess?.childComponents,
                      "hasRemoveChildBillerAccess",
                      roleId
                    ),
                  }}
                  hideFooterPagination={true}
                />
              ) : (
                <Box ml={40} sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
            <Box className="manageUsers_table_visible">
              {!childRelatedBillerLoader ? (
                <ERPSGrid
                  data={{
                    rows: childBillerResults,
                    count: childBillerResults?.length,
                    columns: removeRelatedBillerDetails(
                      handleRowClick,
                      "Remove relationship",
                      relatedBillerProfileAccess?.childComponents,
                      "hasRemoveChildBillerAccess",
                      roleId
                    ),
                  }}
                  hideFooterPagination={true}
                  dataGridWidth={
                    roleId != "10"
                      ? "dataGrid__box__container_relatedbiller_grid"
                      : "dataGrid__box__container_relatedbiller_grid_v2"
                  }
                  initialLoading={childBillerResults?.length ? false : true}
                  tableLabelText={t("erps.relatedbiller.tabletext")}
                />
              ) : (
                <Box ml={40} sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </>
        </>
      ) : (
        hasBillerAlreadyRelatedAsChildToParent() && (
          <Stack direction="row" spacing={18} mt={4}>
            <Box>
              <Typography variant="inherit">
                {t("erps.relatedbiller.label.parentbillerid")}
                <Typography variant="body2">
                  {parentBillerResults?.billerId}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant="inherit">
                {t("erps.relatedbiller.label.parentbillername")}
                <Typography variant="body2">
                  {parentBillerResults?.billerName}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant="inherit">
                {t("erps.relatedbiller.label.relationshipstatus")}
                <Typography variant="body2">
                  {parentBillerResults?.relationshipStatus ||
                    t("erps.relatedbiller.label.active")}
                </Typography>
              </Typography>
            </Box>
          </Stack>
        )
      )}
      {!hasBillerAlreadyRelatedAsChildToParent() && (
        <>
          {roleId != "10" && (
            <>
              <Box mt={4}>
                <Typography variant="h2">
                  {t("erps.relatedbiller.label.addbiller")}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1">
                  {t("erps.relatedbiller.label.addbiller.activebillernote")}
                </Typography>
              </Box>
            </>
          )}
          <Box mt={4}>
            <Typography variant="h4">
              {t("erps.relatedbiller.label.searchforbiller")}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="body1">
              {t("erps.relatedbiller.label.usewildcard")}
            </Typography>
          </Box>
          {!isSearchValid || (Array.isArray(errorMssg) && errorMssg.length) ? (
            <Box
              className={"manageUsers__errorMsg__container"}
              role="alert"
              mt={
                !isSearchValid || (Array.isArray(errorMssg) && errorMssg.length)
                  ? 2
                  : 0
              }
            >
              {!isSearchValid && (
                <ErrorMessage error={errorMssg} errorRef={errorRef} />
              )}
              {Array.isArray(errorMssg) && errorMssg.length && (
                <ErrorMessage error={errorMssg} errorRef={errorRef} />
              )}
            </Box>
          ) : (
            ""
          )}

          <Box style={{ width: "650px" }}>
            <Grid container>
              <Grid item sm={4} lg={4}>
                <ERPSBaseTextField
                  id={"billerId"}
                  type="text"
                  label={t("erps.relatedbiller.label.billerid")}
                  className="common__textField__width"
                  size="small"
                  onChange={(e) => handleChange(e.target.value, "billerId")}
                  value={searchValues?.billerId}
                />
              </Grid>
              <Grid item sm={4} lg={4}>
                <Box pl={1}>
                  <ERPSBaseTextField
                    id={"billerName"}
                    type="text"
                    label={t("erps.relatedbiller.label.billername")}
                    className="common__textField__width"
                    size="small"
                    onChange={(e) => handleChange(e.target.value, "billerName")}
                    value={searchValues?.billerName}
                    onBlur={(e) => handleBlur(e.target.value, "billerName")}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={-3} display="flex">
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.relatedbiller.label.reset")}
              onClick={handleReset}
            />
            <Box pl={2}>
              <ERPSBaseButton
                variant="primary"
                className="button_with_margin48"
                label={t("erps.relatedbiller.label.search")}
                onClick={handleSearch}
                loading={searchLoader}
              />
            </Box>
          </Box>
          <Box mt={4}>
            <Typography variant="h4">
              {t("erps.relatedbiller.label.searchresults")}
            </Typography>
          </Box>

          {releatedBillerSearchResponse?.releatedBillerSearchData ? (
            <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
              <Typography variant="body1">
                {releatedBillerSearchResponse?.releatedBillerSearchData?.count}{" "}
                {t("erps.relatedbiller.label.resultsfound")}
              </Typography>
            </Box>
          ) : (
            <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
              <Typography variant="body1">
                {t("erps.relatedbiller.label.searchnotstarted")}
              </Typography>
            </Box>
          )}
          {searchResults?.length ? (
            <>
              <Box className="manageUsers_card_visible">
                {!childRelatedBillerLoader ? (
                  <CardList
                    data={{
                      rows: searchResults,
                      count: searchResults?.length
                        ? releatedBillerSearchResponse?.releatedBillerSearchData
                            ?.count
                        : -1,
                      columns: getRelatedBillerDetails(
                        handleRowClick,
                        "Add relationship",
                        relatedBillerProfileAccess?.childComponents,
                        "hasAddChildBillerAccess",
                        roleId
                      ),
                    }}
                    dataGridWidth={
                      roleId != "10"
                        ? "dataGrid__box__container_relatedbiller_grid"
                        : "dataGrid__box__container_relatedbiller_grid_v2"
                    }
                    initialLoading={searchResults?.length ? false : true}
                    onAnyPaginationAction={paginateRelatedBillerUsers}
                  />
                ) : (
                  <Box ml={40} sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
              <Box className="manageUsers_table_visible">
                {!childRelatedBillerLoader ? (
                  <ERPSGrid
                    data={{
                      rows: searchResults,
                      count: searchResults?.length
                        ? releatedBillerSearchResponse?.releatedBillerSearchData
                            ?.count
                        : -1,
                      columns: getRelatedBillerDetails(
                        handleRowClick,
                        "Add relationship",
                        relatedBillerProfileAccess?.childComponents,
                        "hasAddChildBillerAccess",
                        roleId
                      ),
                    }}
                    dataGridWidth={
                      roleId != "10"
                        ? "dataGrid__box__container_relatedbiller_grid"
                        : "dataGrid__box__container_relatedbiller_grid_v2"
                    }
                    initialLoading={searchResults?.length ? false : true}
                    onAnyPaginationAction={paginateRelatedBillerUsers}
                    tableLabelText={t(
                      "erps.relatedbiller.activebiller.tabletext"
                    )}
                  />
                ) : (
                  <Box ml={40} sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </>
          ) : (
            ""
          )}
          <Dialog
            open={openCancelDialog?.isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
          >
            <DialogHeader
              id="alert-dialog-title"
              onClose={handleClose}
              className={"box-dialog-header"}
            >
              {"Remove biller relationship"}{" "}
            </DialogHeader>
            <DialogContent
              id="alert-dialog-description"
              className={"cancel-dialog-content-size"}
            >
              {getDialogContent()}
            </DialogContent>
            <DialogActions className={"box-dialog-buttons"}>
              <ERPSBaseButton
                variant="secondary"
                className="button_with_zeromargin"
                onClick={handleClose}
                label={t("erps.relatedbiller.label.cancel")}
              />
              <ERPSBaseButton
                variant="primary"
                className="button_with_zeromargin"
                onClick={(e) => handleClose(e, "cancelDialog")}
                label={"Remove relationship"}
              />
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

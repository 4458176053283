import synchronizationActionType from "../actions/types/synchronization.action.type";

export const synchronizationInitialState = {
  error: null,
  success: null,
};

export default function synchronizationReducer(
  state = synchronizationInitialState,
  action
) {
  switch (action.type) {
    case synchronizationActionType.SYNCHRONIZATION_SUCCESS:
      return { ...state, success: action?.data, error: null };
    case synchronizationActionType.SYNCHRONIZATION_ERROR:
      return { ...state, success: null, error: action.error };
    default:
      return state;
  }
}
import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFormattedPhoneNumber } from "../../utils/helper";

const ViewFIManagement = (props) => {
  const { t } = useTranslation();

  const financialInstitutionResponse = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const getFIData = () => {
    const fiNumber = selectedFi.toString() || loginUser.fiNumber.toString();
    const data =
      fiNumber &&
      financialInstitutionResponse.find(
        ({ fi_number }) => fi_number.toString() === fiNumber
      );

    return data;
  };

  const fiData = getFIData();

  return (
    <div role="main">
      <Box ml={3}>
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.viewfimanagement.page.title", { value: fiData?.fi_name })}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h2">
            {t("erps.viewfimanagement.page.heading.fiprofile")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.finumber")}
            <Typography variant="body2">{fiData?.fi_number}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.finame")}
            <Typography variant="body2">{fiData?.fi_name}</Typography>
          </Typography>
          <Typography className="view_finacial_institution_margin_left">
            {t("erps.viewfimanagement.page.subheading.fishortname")}
            <Typography variant="body2">{fiData?.fi_short_name}</Typography>
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.department")}
            <Typography variant="body2">{fiData?.fi_department}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.address")}
            <Typography variant="body2">{fiData?.fi_address}</Typography>
          </Typography>
          <Typography className="view_finacial_institution_margin_left">
            {t("erps.viewfimanagement.page.subheading.city")}
            <Typography variant="body2">{fiData?.fi_city}</Typography>
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.province")}
            <Typography variant="body2">{fiData?.fi_province}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.postalcode")}
            <Typography variant="body2">{fiData?.fi_postal_code}</Typography>
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.mainfi")}
            <Typography variant="body2">{fiData?.owning_fi_number}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.mainfiname")}
            <Typography variant="body2">{fiData?.main_fi}</Typography>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.viewfimanagement.page.heading.contactinfo")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.businesscontactname")}
            <Typography variant="body2">
              {fiData?.business_contact_name}
            </Typography>
          </Typography>
          <Typography className="view_finacial_institution_value">
            {t("erps.viewfimanagement.page.subheading.businesscontactnumber")}
            <Typography variant="body2">
              {fiData?.business_contact_phone_str}
            </Typography>
          </Typography>
          <Typography className="view_finacial_institution_value">
            {t("erps.viewfimanagement.page.subheading.ext")}
            <Typography variant="body2">
              {fiData?.business_contact_extension}
            </Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.businesscontactfax")}
            <Typography variant="body2">
              {getFormattedPhoneNumber(fiData?.business_contact_fax)}
            </Typography>
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.technicalcontactname")}
            <Typography variant="body2">
              {fiData?.technical_contact_name}
            </Typography>
          </Typography>
          <Typography className="view_finacial_institution_value">
            {t("erps.viewfimanagement.page.subheading.technicalcontactnumber")}
            <Typography variant="body2">
              {getFormattedPhoneNumber(fiData?.technical_contact_phone)}
            </Typography>
          </Typography>
          <Typography className="view_finacial_institution_value">
            {t("erps.viewfimanagement.page.subheading.ext")}
            <Typography variant="body2">
              {fiData?.technical_contact_extension}
            </Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.technicalcontactfax")}
            <Typography variant="body2">
              {getFormattedPhoneNumber(fiData?.technical_contact_fax)}
            </Typography>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.viewfimanagement.page.heading.helpdesk")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.helpdeskphone")}{" "}
            <Typography variant="body2">{fiData?.help_desk_phone_str}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.helpdeskfax")}
            <Typography variant="body2">{getFormattedPhoneNumber(fiData?.help_desk_fax)}</Typography>
          </Typography>
          <Typography className="view_finacial_institution_margin_left">
            {t("erps.viewfimanagement.page.subheading.deliveryprogram")}
            <Typography variant="body2">{fiData?.fi_delivery_program}</Typography>
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.viewfimanagement.page.heading.servicename")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="view_finacial_institution_servicename_margin_left">
            {t("erps.viewfimanagement.page.subheading.servicename_english")}
            <Typography variant="body2">
              {fiData?.fi_english_service_name}
            </Typography>
          </Typography>
          <Typography className="view_finacial_institution_servicename_margin_left_v1">
            {t("erps.viewfimanagement.page.subheading.servicename_french")}
            <Typography variant="body2">
              {fiData?.fi_french_service_name}
            </Typography>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.viewfimanagement.page.heading.EFTinfo")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.eftfilenumber")}
            <Typography variant="body2">
              {fiData?.eft_file_number}
            </Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.groupnumber")}
            <Typography variant="body2">{fiData?.fi_group_number}</Typography>
          </Typography>
          <Typography className="view_finacial_institution_margin_left">
            {t("erps.viewfimanagement.page.subheading.deliveryaddress")}
            <Typography variant="body2">{fiData?.fi_delivery_address}</Typography>
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.transformattype")}
            <Typography variant="body2">{fiData?.transmission_format_type}</Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.transsupporttype")}
            <Typography variant="body2">{getFormattedPhoneNumber(fiData?.transmission_support_type)}</Typography>
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.viewfimanagement.page.heading.fiparameter")}
          </Typography>
        </Box>
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.passwordexpiry")}
            <Typography variant="body2">
              {fiData?.regular_password_expiration_period === 0
                ? "No expiry"
                : t("erps.viewfimanagement.page.subheading.days", {
                    value: fiData?.regular_password_expiration_period,
                  })}
            </Typography>
          </Typography>
          <Typography className="available__documents__value">
            {t("erps.viewfimanagement.page.subheading.ttl")}
            <Typography variant="body2">
              {fiData?.temp_password_expiration_period === 0
                ? "No expiry"
                : t("erps.viewfimanagement.page.subheading.days", {
                    value: fiData?.temp_password_expiration_period,
                  })}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(ViewFIManagement);

import axios from "axios";
import store from "../store/index";
import synchronizationActionType from "../actions/types/synchronization.action.type";
import { API_BASE_URL } from "../utils/helper";

export const synchronizationService = {
  runSynchronization,
};

function runSynchronization(props) {
  return (dispatch) => {
    const userId = store.getState()?.loginUserReducer.user?.userId;
    const apiEnpoint = `${API_BASE_URL()}/refresh/update?fiNumber=10&userId=${userId}`;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        apiEnpoint,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch(success(res));
        props.setDisplayDialog(false);
        props.setShowSuccessMessage(true);
      })
      .catch((err) => {
        dispatch(failure(err));
        props.setDisplayDialog(false);
        props.setShowSuccessMessage(true);
      });
  };

  function success(data) {
    return {
      type: synchronizationActionType.SYNCHRONIZATION_SUCCESS,
      data: data?.data,
    };
  }

  function failure(error) {
    return { type: synchronizationActionType.SYNCHRONIZATION_ERROR, error };
  }
}

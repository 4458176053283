import userActionTypes from "../actions/types/user.action.type";

export const subscribingEntitiesInitialState = {
  subscribingEntitiesList: null,
  error: null,
};

export default function subscribingEntitiesReducer(
  state = subscribingEntitiesInitialState,
  action
) {
  switch (action.type) {
    case userActionTypes.GET_SUBSCRIBING_ENTITIES_LIST:
      return { subscribingEntitiesList: action.data, error: null };
    case userActionTypes.GET_SUBSCRIBING_ENTITIES_LIST_ERROR:
      return { subscribingEntitiesList: null, error: action.error };
    default:
      return state;
  }
}

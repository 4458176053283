import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { formatDate } from "../utils/helper";
import { t } from "i18next";

export const getAvailableReportsHeading = (fileDownload, roleId) => {
  if (roleId == 1 || roleId == 2) {
    return [
      {
        field: "file_type",
        headerName: t("erps.downloadreports.label.reportname"),
        width: 300,
        sortable: false,
      },
      {
        field: "file_date",
        headerName: t("erps.downloadreports.label.reportdate"),
        width: 160,
        sortable: false,
        renderCell: (params) => {
          if (params.value) {
            return params.value?.split("T")[0];
          }
        },
      },
      {
        field: "pdf_link",
        headerName: t("erps.downloadreports.label.download"),
        width: 110,
        sortable: false,
        renderCell: (params) => {
          const regExp = /(webportal|document|reports|LegacyReports)/i;
          const pdfLink = params.row.pdf_link;
          const csvLink = params.row.csv_link;
          let pdfWithReportPath = false;
          let csvWithReportPath = false;
          if (pdfLink && pdfLink.match(regExp) !== null)
            pdfWithReportPath = true;
          if (csvLink && csvLink.match(regExp) !== null)
            csvWithReportPath = true;
          if (pdfLink && csvLink) {
            return (
              <>
                {pdfWithReportPath && (
                  <ERPSBaseLink
                    label={t("erps.downloadreports.label.pdf")}
                    onClick={() => fileDownload(params.row, "PDF")}
                  />
                )}
                &nbsp;&nbsp;
                {csvWithReportPath && (
                  <ERPSBaseLink
                    label={t("erps.downloadreports.label.csv")}
                    onClick={() => fileDownload(params.row, "CSV")}
                  />
                )}
              </>
            );
          } else if (pdfLink && !csvLink) {
            return (
              <>
                {pdfWithReportPath && (
                  <ERPSBaseLink
                    label={t("erps.downloadreports.label.pdf")}
                    onClick={() => fileDownload(params.row, "PDF")}
                  />
                )}
              </>
            );
          } else if (!pdfLink && csvLink) {
            return (
              <>
                {csvWithReportPath && (
                  <ERPSBaseLink
                    label={t("erps.downloadreports.label.csv")}
                    onClick={() => fileDownload(params.row, "CSV")}
                  />
                )}
              </>
            );
          }
        },
      },
    ];
  } else {
    return [
      {
        field: "reportDescription",
        headerName: t("erps.downloadreports.label.reportname"),
        width: 300,
        sortable: false,
      },
      {
        field: "reportDate",
        headerName: t("erps.downloadreports.label.reportdate"),
        width: 160,
        sortable: false,
        renderCell: (params) => {
          if (params.value) {
            return params.value?.split("T")[0];
          }
        },
      },
      {
        field: "reportPath",
        headerName: t("erps.downloadreports.label.download"),
        width: 110,
        sortable: false,
        renderCell: (params) => {
          const regExp = /(reports|LegacyReports)/i;
          let hasReportPath = false;
          let hasReportPathCsv = false;
          let reportPath = params?.row?.reportPath;
          let reportPathCsv = params?.row?.reportPathCsv;

          if (reportPath && reportPath.match(regExp) !== null) {
            hasReportPath = true;
          }

          if (reportPathCsv && reportPathCsv.match(regExp) !== null) {
            hasReportPathCsv = true;
          }

          return (
            <>
              {hasReportPath && (
                <ERPSBaseLink
                  label={t("erps.downloadreports.label.pdf")}
                  onClick={() => fileDownload(params.row, "PDF")}
                />
              )}
              {hasReportPathCsv && (
                <ERPSBaseLink
                  label={t("erps.downloadreports.label.txt")}
                  onClick={() => fileDownload(params.row, "TXT")}
                />
              )}
            </>
          );
        },
      },
    ];
  }
};

export const EMPTY_DOWNLOAD_REPORT_CRITERIA = {
  startDate: "",
  endDate: "",
  reportId: "",
  reportName: "",
  startOffset: 0,
  endOffset: 25,
};

export const MOCK_DOWNLOAD_REPORT_DATA = [
  {
    id: 0,
    reportName: "Monthly billing volume file",
    reportDate: "2020-10-01",
    download: "CSV",
  },
  {
    id: 1,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-31",
    download: "CSV",
  },
  {
    id: 2,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-31",
    download: "CSV",
  },
  {
    id: 3,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-30",
    download: "CSV",
  },
];

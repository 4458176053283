import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { getUpdateBillerFinancialInstitutionHeadings } from "../../constants/FinancialInstitutionConstant";
import actions from "../../actions";
import {
  lpad,
  convertDateStringIntoERPSDateFormat,
  convertDateIntoERPSDateFormat,
  getFormattedPhoneNumber,
  formatDate,
} from "../../utils/helper";
import parse from "html-react-parser";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import CircularProgress from "@material-ui/core/CircularProgress";
import { findInstructType } from "./helpers/instructText";

const BillerProfileUserReadView = (props) => {
  const { t } = useTranslation();
  const {
    data,
    componentAccess,
    billerId,
    displayBillerId,
    location: { state },
  } = props;
  const dispatch = useDispatch();

  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const fiNumber = selectedFiNumber || loginUser.fiNumber;
  const [billerFinancialInstitutionList, setBillerFinancialInstitutionList] =
    useState([]);
  const masterfinancialInstitutionList = useSelector(
    (state) => state.fiReducer?.masterFiDescriptions
  );

  const [billerProfile, setBillerProfile] = useState({});
  const [initialDeliveryMethods, setInitialDeliveryMethods] = useState({});
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [masterRule, setMasterRule] = useState({});
  const [lineOfbusinessData, setLineOfbusinessData] = useState({});
  const [budgetCategoryCodes, setBudgetCategoryCodes] = useState([]);
  const budgetCategory = useSelector((state) => state.budgetCategoryReducer);
  const customRoutineListData = useSelector(
    (state) => state.billerProfileReducer?.customRoutineListData
  );
  const billerProfileReadAccess = componentAccess.find(
    ({ componentType }) => componentType === "BPRV"
  );

  const formattedMethodReducerValue = useSelector(
    (state) => state.formatMethodReducer
  );
  const [provinceList, setProvinceList] = useState([]);
  const provinceEntities = useSelector((state) => state.provinceReducer);

  const getFiLabel = (fiVal) => {
    if (masterfinancialInstitutionList) {
      return masterfinancialInstitutionList.filter(
        ({ fiNumber }) => fiNumber == fiVal
      )[0]?.fiName;
    }
    return "";
  };
  const billerProfileData = useSelector((state) => state.billerProfileReducer);
  const billerProfileExtensionData =
    billerProfileData?.billerProfileAssociatedData?.billerProfileExtensions?.filter(
      ({ fiNumber }) => fiNumber === 99
    )[0];
  const [selectedRow, setSelectedRow] = useState([]);
  const [financialInsititutionData, setFinancialInstitutionTableList] =
    useState(billerProfileData?.billerProfileExtensions);
  const financialInstitutionList = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );

  useEffect(() => {
    if (masterfinancialInstitutionList) {
      const data = masterfinancialInstitutionList
        .map(({ fiNumber, fiName }) => ({
          value: fiNumber,
          label: lpad(fiNumber, 4) + "-" + fiName,
        }))
        .filter(({ value }) => value != 0);
      setBillerFinancialInstitutionList(data);
    }
  }, [financialInstitutionList, masterfinancialInstitutionList]);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getAllProvince(t));
  }, []);

  useEffect(() => {
    if (provinceEntities) {
      if (provinceEntities.provinceEntitiesList) {
        const peData = Object.keys(provinceEntities.provinceEntitiesList).map(
          (key) => ({
            value: key,
            label: provinceEntities.provinceEntitiesList[key],
          })
        );
        setProvinceList(peData);
      }
    }
  }, [provinceEntities]);

  useEffect(() => {
    if (billerProfile?.billerProfileExtensions) {
      const fidata = billerProfile?.billerProfileExtensions
        ?.map(({ billerStatus, id }) => {
          if (billerStatus === "A") {
            return id;
          }
          return "";
        })
        .filter((e) => Boolean(e));
      setSelectedRow(fidata);
    }
  }, [billerProfile?.billerProfileExtensions]);

  useEffect(() => {
    dispatch(actions.fiActions.getMasterFiDescriptionList(t));
    if (billerProfileData) {
      if (billerProfileData.billerProfileAssociatedData) {
        const setBillerData = billerProfileData.billerProfileAssociatedData;
        const updatedBillerProfileExtension =
          setBillerData?.billerProfileExtensions?.map((data, idx) => ({
            ...data,
            id: idx,
          })) || [];
        setBillerProfile({
          ...setBillerData,
          bmoBillerId:
            billerProfileData?.billerId?.split("-") > 1
              ? billerProfileData?.billerId?.split("-")[0]
              : setBillerData?.bmoBillerId,
          billerProfileExtensions: updatedBillerProfileExtension,
          ...(fiNumber == "10" && {
            billerLastDeactivateDate:
              setBillerData?.billerProfileExtensions?.[0]
                ?.billerLastDeactivateDate,
            billerLastActivateDate:
              setBillerData?.billerProfileExtensions?.[0]
                ?.billerLastActivateDate,
          }),
        });

        if (billerProfileData.billerProfileAssociatedData?.lineOfbusinessData) {
          setLineOfbusinessData(
            billerProfileData.billerProfileAssociatedData.lineOfbusinessData
          );
        }

        if (billerProfileData.billerProfileAssociatedData?.distributionList) {
          const [initialDeliveryMethodResponse, ...restDeliveryMethods] =
            billerProfileData.billerProfileAssociatedData?.distributionList;

          setInitialDeliveryMethods({
            ...initialDeliveryMethodResponse,
            deliveryMethod:
              initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
          });
          setDeliveryMethods(restDeliveryMethods);
        }
      }
    }
  }, [billerProfileData]);

  useEffect(() => {
    if (props?.handleTabLoading) {
      props?.handleTabLoading(true);
    }
    dispatch(
      actions.billerProfileActions.getBillerProfileWithAssociatedObjects(
        billerId,
        t,
        props?.handleTabLoading
      )
    );
  }, []);

  const navigateToManageBillerPage = () => {
    props.history.push("/manage-billers", {
      componentAccess: state.componentAccess,
    });
  };
  function hasValidHtmlTags(inputString) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(inputString, "text/html");
    return doc.body.childElementCount > 0 && doc.body.innerHTML === inputString;
  }

  const findFiLabel = (val) => {
    return billerFinancialInstitutionList.find((fi) => fi.value == val)?.label;
  };

  const findProvinceLabel = (val) => {
    return provinceList.find((province) => province.value == val)?.label;
  };

  const findStatusLabel = (val) => {
    if (val == "I") {
      return t("erps.billermanagement.label.inactive");
    } else if (val == "A") {
      return t("erps.billermanagement.label.active");
    } else {
      return "";
    }
  };

  const getValue = (billerLink, billerLinkGroupDtos) => {
    const billerLinkObj = billerLinkGroupDtos?.find(
      ({ billerId }) => billerId === billerLink
    );
    return billerLinkObj || billerLink;
  };

  const getLastDeliveryMethod = (oldDeliveryMethodData) => {
    let deliveryMethodLength;
    let restDeliveryMethods;

    if (oldDeliveryMethodData && oldDeliveryMethodData?.length) {
      deliveryMethodLength = oldDeliveryMethodData?.length - 1;
      if (deliveryMethodLength >= 0) {
        restDeliveryMethods = oldDeliveryMethodData[deliveryMethodLength];
      }
    } else if (deliveryMethods && deliveryMethods?.length) {
      deliveryMethodLength = deliveryMethods?.length - 1;
      if (deliveryMethodLength >= 0) {
        restDeliveryMethods = deliveryMethods[deliveryMethodLength];
      }
    } else if (
      Object.keys(initialDeliveryMethods) &&
      Object.keys(initialDeliveryMethods)?.length
    ) {
      restDeliveryMethods = initialDeliveryMethods;
    }
    return restDeliveryMethods;
  };

  const getSettlementTypeValue = (oldDeliveryMethodData) => {
    const lastDeliveryMethod = getLastDeliveryMethod(oldDeliveryMethodData);

    if (
      lastDeliveryMethod &&
      Object.keys(lastDeliveryMethod) &&
      Object.keys(lastDeliveryMethod)?.length
    ) {
      const hasEDISettlementType =
        lastDeliveryMethod?.deliveryMethod === "EDI" &&
        (lastDeliveryMethod?.distributionFormat === "H6 3030" ||
          lastDeliveryMethod?.distributionFormat === "CBSA 3030");
      return hasEDISettlementType ? "EDI" : "EFT";
    }
  };

  const getBillerGroupLists = () => {
    const listData = billerProfile?.billerLinkGroupDtos?.map((link) => {
      return {
        label: link?.billerLinkGroupDetail,
        value: link?.billerId,
      };
    });
    listData?.unshift({
      label: "",
      value: "",
    });
    return listData;
  };

  const findAppliesToLabel = (val) => {
    if (val == "G") {
      return t("erps.billermanagement.label.appliesToItems.link");
    } else if (val == "C") {
      return t("erps.billermanagement.label.appliesToItems.biller");
    } else {
      return "";
    }
  };

  const findNilLabel = (val) => {
    if (val == "Y") {
      return t("erps.billermanagement.label.nilReports.yes");
    } else {
      return t("erps.billermanagement.label.nilReports.no");
    }
  };

  const findValidationLabel = (val) => {
    if (val == "NONE") {
      return t("erps.billermanagement.label.noneValidation");
    } else if (val == "URN") {
      return t("erps.billermanagement.label.uniqueRoutine");
    } else if (val == "CDV") {
      return t("erps.billermanagement.label.checkDigitValidation");
    } else if (val == "CDV_URN") {
      return t("erps.billermanagement.label.both");
    } else {
      return "";
    }
  };

  const hasAccess = (key) => {
    if (key === "faxHeader") {
      return (
        (initialDeliveryMethods?.deliveryMethod === "FAX" &&
          initialDeliveryMethods?.deliveryMethod === "FAX") ||
        deliveryMethods.some((method) => method?.deliveryMethod === "FAX")
      );
    }
    if (key === "recipientCcin") {
      return (
        initialDeliveryMethods?.deliveryMethod ||
        deliveryMethods.some((method) => method?.deliveryMethod)
      );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item lg={8} sm={12}>
          {/* Heading - Details */}
          <Box>
            <Box className="manageUsers__page__title">
              <Typography variant="h2">
                {t("erps.billermanagement.page.formtitle", "Biller Profile")}
              </Typography>
              {props?.isLoading && (
                <Box ml={40}>
                  <CircularProgress size={30} />
                </Box>
              )}
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.updatebillerapproval.label.updatedby")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.displayUpdatedBy}
                </Typography>
              </Typography>
              <Typography>
                {t("erps.updatebillerapproval.label.updatedon")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {convertDateStringIntoERPSDateFormat(
                    billerProfile?.updatedDate
                  )}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* Biller Profile */}
          <Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {(loginUser?.role?.role_type === "PA" ||
                loginUser?.role?.role_type === "CE") && (
                <Typography variant="inherit">
                  {t(
                    "erps.billermanagement.label.internalBillerId",
                    "Internal biller ID"
                  )}
                  <Typography variant="body2">
                    {fiNumber == 99
                      ? billerId
                      : billerId?.split("-")[1] || billerId}
                  </Typography>
                </Typography>
              )}
              {loginUser?.role?.role_type !== "PA" &&
                loginUser?.role?.role_type !== "CE" && (
                  <Typography variant="inherit">
                    {t("erps.billermanagement.label.billerId", "Biller ID")}
                    <Typography variant="body2">
                      {displayBillerId || billerId}
                    </Typography>
                  </Typography>
                )}
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.billermanagement.label.billerName")}
                <Typography variant="body2">
                  {billerProfile?.billerName}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.billermanagement.label.billerShortName")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.billerShortName}
                </Typography>
              </Typography>
              {fiNumber != "10" && fiNumber != "16" && (
                <Typography>
                  {t("erps.billermanagement.label.settlementType")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {getSettlementTypeValue()}
                  </Typography>
                </Typography>
              )}
              {(fiNumber == "10" || fiNumber == "16") && (
                <Typography>
                  {t("erps.billermanagement.label.billerLink")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {
                      getValue(
                        billerProfileData.billerProfileAssociatedData
                          ?.billerLink,
                        billerProfile?.billerLinkGroupDtos
                      )?.billerFiNumber
                    }
                  </Typography>
                </Typography>
              )}
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {fiNumber != "10" && fiNumber != "16" ? (
                <Typography>
                  {t("erps.billermanagement.label.attention")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerDepartment}
                  </Typography>
                </Typography>
              ) : (
                <Typography>
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    <ERPSBaseTextField
                      id="billerLinkGroup"
                      value={
                        getValue(
                          billerProfileData.billerProfileAssociatedData
                            ?.billerLink,
                          billerProfile?.billerLinkGroupDtos
                        )?.billerId
                      }
                      type="text"
                      label={t("erps.billermanagement.label.billerLinkGroup")}
                      className="width_linkmember_cibc"
                      size="small"
                      select={true}
                      items={getBillerGroupLists()}
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      listDisabled={true}
                    />
                  </Typography>
                </Typography>
              )}
              {fiNumber != 16 ? (
                <>
                  <Typography>
                    {t("erps.billermanagement.label.address", "Address")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerAddress}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.city", "City")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerCity}
                    </Typography>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography>
                    {t("erps.viewfimanagement.page.subheading.department")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerDepartment}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.address", "Address")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerAddress}
                    </Typography>
                  </Typography>
                </>
              )}
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {fiNumber == "16" && (
                <Typography>
                  {t("erps.billermanagement.label.city", "City")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerCity}
                  </Typography>
                </Typography>
              )}
              <Typography>
                {t("erps.billermanagement.label.country", "Country")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  CA
                </Typography>
              </Typography>
              <Typography>
                {t("erps.billermanagement.label.province", "Province")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {findProvinceLabel(billerProfile?.billerProvince)}
                </Typography>
              </Typography>
              {fiNumber != "16" && (
                <Typography>
                  {t("erps.billermanagement.label.postalcode", "Postal code")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerPostalCode}
                  </Typography>
                </Typography>
              )}
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.userprofile.language.text", "Language")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.billerLanguageCode == "E"
                    ? "English"
                    : "French"}
                </Typography>
              </Typography>
              <Typography>
                {t("erps.billermanagement.label.priority", "Group priority")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.groupPriority || ""}
                </Typography>
              </Typography>
              {fiNumber == "16" ? (
                <Typography>
                  {t("erps.billermanagement.label.postalcode", "Postal code")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerPostalCode}
                  </Typography>
                </Typography>
              ) : fiNumber != "10" ? (
                <Typography>
                  {t("erps.billermanagement.label.bmoBillerId")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.bmoBillerId || ""}
                  </Typography>
                </Typography>
              ) : (
                <Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "120px" }}
                  ></Typography>
                </Typography>
              )}
            </Box>
            {fiNumber == "10" ? (
              <>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t("erps.billermanagement.label.billerLastActivateDate")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {convertDateIntoERPSDateFormat(
                        billerProfile?.billerLastActivateDate
                      ) || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.billerLastDeactivateDate")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {convertDateIntoERPSDateFormat(
                        billerProfile?.billerLastDeactivateDate
                      ) || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.settlementType")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {getSettlementTypeValue()}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t("erps.billermanagement.label.status")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {findStatusLabel(billerProfile?.billerStatus)}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.liveDate")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {convertDateIntoERPSDateFormat(
                        formatDate(billerProfile?.billerLiveDate)
                      ) || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.remittanceType")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.remitType || ""}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t("erps.billermanagement.label.productType")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.productType || "APA"}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.budgetCategory")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerBudgetCategory || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    <Typography
                      variant="body2"
                      sx={{ width: "120px" }}
                    ></Typography>
                  </Typography>
                </Box>
              </>
            ) : fiNumber != "16" ? (
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {t("erps.billermanagement.label.liveDate")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {convertDateIntoERPSDateFormat(
                      formatDate(billerProfile?.billerLiveDate)
                    ) || ""}
                  </Typography>
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Box>
          {/* Contact Info */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.button.contactInfo",
                  "Contact information"
                )}
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t(
                  "erps.billermanagement.label.primarycontact",
                  "Primary contact"
                )}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.primaryContactName}
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                }}
                ml={9}
              >
                <Typography style={{ width: "80%" }}>
                  {t("erps.billermanagement.label.telNum", "Telephone number")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.primaryContactPhone
                      ? getFormattedPhoneNumber(
                          billerProfile?.primaryContactPhone
                        )
                      : ""}
                  </Typography>
                </Typography>
                <Typography>
                  {t("erps.billermanagement.label.ext", "Ext.")}
                  <Typography variant="body2">
                    {billerProfile?.primaryContactExtension || ""}
                  </Typography>
                </Typography>
              </Box>

              <Typography>
                {t("erps.billermanagement.label.faxNumber", "Fax number")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.primaryContactFax || ""}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t(
                  "erps.billermanagement.label.secondarycontact",
                  "Secondary contact"
                )}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.secondaryContactName}
                </Typography>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                }}
                ml={9}
              >
                <Typography style={{ width: "80%" }}>
                  {t("erps.billermanagement.label.telNum", "Telephone number")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.secondaryContactPhone
                      ? getFormattedPhoneNumber(
                          billerProfile?.secondaryContactPhone
                        )
                      : ""}
                  </Typography>
                </Typography>
                <Typography>
                  {t("erps.billermanagement.label.ext", "Ext.")}
                  <Typography variant="body2">
                    {billerProfile?.secondaryContactExtension || ""}
                  </Typography>
                </Typography>
              </Box>
              <Typography>
                {t("erps.billermanagement.label.faxNumber", "Fax number")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.secondaryContactFax || ""}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* ToDo - Bank Account FI == 10 */}
          {fiNumber == "10" && (
            <>
              <Box>
                <Box mt={4}>
                  <Typography variant="h2">
                    {t("erps.billermanagement.label.debit")}
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t(
                      "erps.billermanagement.label.billerFinancialInstitution"
                    )}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {findFiLabel(billerProfile?.debitFiNumber) || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.transitNumber")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.debitTransitNumber || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.accountNumber")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.debitAccountNumber || ""}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box mt={4}>
                  <Typography variant="h2">
                    {t("erps.billermanagement.label.credit")}
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t(
                      "erps.billermanagement.label.billerFinancialInstitution"
                    )}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {findFiLabel(billerProfile?.creditorFiNumber) || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.transitNumber")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.creditorTransitNumber || ""}
                    </Typography>
                  </Typography>
                  <Typography>
                    {t("erps.billermanagement.label.accountNumber")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.creditorAccountNumber || ""}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box mt={4}>
                  <Typography variant="h2">
                    {t("erps.billermanagement.label.vendorAlias")}
                    <Typography mt={1} variant="body2" sx={{ width: "120px" }}>
                      {billerProfile?.billerAlias || ""}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {/* Bank Account FI != 10 */}
          {fiNumber != "10" && (
            <Box>
              <Box mt={4}>
                <Typography variant="h2">
                  {t("erps.billermanagement.label.bankAccount", "Bank account")}
                </Typography>
              </Box>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {t(
                    "erps.billermanagement.label.billerFinancialInstitution",
                    "Biller financial institution"
                  )}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {getFiLabel(billerProfile?.creditorFiNumber)}
                  </Typography>
                </Typography>
                <Typography>
                  {t(
                    "erps.billermanagement.label.transitNumber",
                    "Transit number"
                  )}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.creditorTransitNumber}
                  </Typography>
                </Typography>
                <Typography>
                  {t(
                    "erps.billermanagement.label.accountNumber",
                    "Account number"
                  )}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.creditorAccountNumber}
                  </Typography>
                </Typography>
              </Box>
              {fiNumber == 16 && (
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {t("erps.billermanagement.label.status")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {findStatusLabel(billerProfile?.billerStatus)}
                    </Typography>
                  </Typography>

                  <Typography>
                    {t("erps.billermanagement.label.liveDate", "Live date")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {convertDateIntoERPSDateFormat(
                        billerProfile?.billerProfileExtensions?.[0]
                          ?.billerLiveDate
                      )}
                    </Typography>
                  </Typography>

                  <Typography>
                    {t("erps.billermanagement.label.settlementType")}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {getSettlementTypeValue()}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {/* Financial Institution */}
          {fiNumber != 10 && fiNumber != 16 && (
            <Box>
              <Box mt={4}>
                <Typography variant="h2">
                  {t("erps.updatebillerapproval.heading.financialInstitution")}
                </Typography>
              </Box>

              <Box mt={2}>
                <ERPSGrid
                  data={{
                    rows:
                      billerProfile && billerProfile?.billerProfileExtensions
                        ? billerProfile?.billerProfileExtensions
                        : [],
                    //count: billerProfile ? billerProfile.billerProfileExtensionDtos.length : "0",
                    count: "20",
                    columns: getUpdateBillerFinancialInstitutionHeadings(false),
                  }}
                  dataGridWidth="dataGrid__box__container__updateBiller__financial_institution_table"
                  hideFooterPagination={true}
                  checkboxSelection={true}
                  selectedRows={selectedRow}
                  tableLabelText={t(
                    "erps.billermanagement.label.financialInstitution",
                    "Financial institutions"
                  )}
                />
              </Box>
            </Box>
          )}
          {/* Payment advice file details */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.label.paymentAdviceFileDetails",
                  "Payment advice file details"
                )}
              </Typography>
            </Box>
            {billerProfile?.distributionList &&
              billerProfile.distributionList.length &&
              billerProfile.distributionList.map((value, index) => {
                return (
                  <>
                    <Box mt={3}>
                      <Typography variant="h4">
                        {`${t(
                          "erps.billermanagement.label.deilveryMethod",
                          "Delivery method"
                        )} ${index + 1}`}
                      </Typography>
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        {t(
                          "erps.billermanagement.label.deilveryMethod",
                          "Delivery method"
                        )}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.deliveryMethod}
                        </Typography>
                      </Typography>
                      <Typography>
                        {t("erps.billermanagement.label.format", "Format")}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.distributionFormat}
                        </Typography>
                      </Typography>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.destination",
                          "Destination"
                        )}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.distributionListCode}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        {t(
                          "erps.billermanagement.label.attention",
                          "Attention"
                        )}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.destinationAttention}
                        </Typography>
                      </Typography>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.telNum",
                          "Telephone number"
                        )}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.distributionPhoneNumber || ""}
                        </Typography>
                      </Typography>
                      <Typography>
                        {t("erps.billermanagement.label.ext", "Ext.")}
                        <Typography variant="body2" sx={{ width: "120px" }}>
                          {value?.distributionExtensionNumber || ""}
                        </Typography>
                      </Typography>
                    </Box>
                    {value?.deliveryMethod === "EDI" && (
                      <Box mt={3} sx={{ display: "flex" }}>
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.ediMailBox",
                            "EDI mailbox"
                          )}
                          <Typography variant="body2">
                            {value?.distributionEDIMailbox}
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                    {(fiNumber == "10" || fiNumber == "16") && (
                      <Box mt={3} sx={{ display: "flex" }}>
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.distributionGroupRcpIndex.text",
                            "Applies to"
                          )}
                          <Typography variant="body2">
                            {findAppliesToLabel(
                              value?.distributionGroupRcpIndex
                            )}
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </>
                );
              })}
            {hasAccess("recipientCcin") && (
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography>
                  {t(
                    "erps.billermanagement.label.ccin",
                    "Cooperate creditor id number"
                  )}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.recipientCcin}
                  </Typography>
                </Typography>
                {(fiNumber == 10 || fiNumber == 16) && (
                  <>
                    <Typography>
                      {t("erps.billermanagement.label.nextAuditNumber")}
                      <Typography variant="body2" sx={{ width: "120px" }}>
                        {billerProfile?.billerProfileExtensions[0]
                          ?.nextFileNumber || ""}
                      </Typography>
                    </Typography>
                    <Typography>
                      <Typography
                        variant="body2"
                        sx={{ width: "120px" }}
                      ></Typography>
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
          {/* Fax/Email details */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.label.deliveryDetails",
                  "Delivery details"
                )}
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.billermanagement.label.nilReports", "Nil reports")}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {findNilLabel(billerProfile?.nilReportIndex)}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* Billing Account validations */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.label.billingAccountValidation",
                  "Billing account validations"
                )}
              </Typography>
            </Box>

            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {fiNumber != 10 ? (
                <Typography>
                  {t(
                    "erps.billermanagement.label.accountFormat",
                    "Account format"
                  )}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerCustomerNumberFormat}
                  </Typography>
                </Typography>
              ) : (
                <Typography>
                  {t("erps.billermanagement.label.accountMask")}
                  <Typography variant="body2" sx={{ width: "120px" }}>
                    {billerProfile?.billerAccountMask}
                  </Typography>
                </Typography>
              )}
              <Typography>
                {t(
                  "erps.billermanagement.label.minimumLength",
                  "Minimum length"
                )}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.billerCustomerNumberMinimum || ""}
                </Typography>
              </Typography>
              <Typography>
                {t(
                  "erps.billermanagement.label.maximumLength",
                  "Maximum length"
                )}
                <Typography variant="body2" sx={{ width: "120px" }}>
                  {billerProfile?.billerCustomerNumberMaximum || ""}
                </Typography>
              </Typography>
            </Box>
            {fiNumber != 16 && (
              <>
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {fiNumber != 10 && (
                    <Typography>
                      <ERPSBaseCheckBoxGrp
                        label={t(
                          "erps.billermanagement.label.alphaAllowed",
                          "Alpha allowed"
                        )}
                        isDisabled={true}
                        onChange={(e) => {}}
                        value={
                          billerProfile?.billerMasterRules?.masterRules
                            ?.alpha_allowed === "Y"
                            ? true
                            : false
                        }
                        id="alphaAllowed"
                      />{" "}
                    </Typography>
                  )}
                  <Typography>
                    {t(
                      "erps.billermanagement.label.additionalValidation",
                      "Additional validation"
                    )}
                    <Typography variant="body2" sx={{ width: "120px" }}>
                      {findValidationLabel(
                        billerProfile?.accountValidationValue
                      )}
                    </Typography>
                  </Typography>
                  {billerProfile?.billerMasterRules?.masterRules
                    ?.unique_check_digit_routine === "Y" && (
                    <Typography>
                      {t(
                        "erps.billermanagement.label.uniqueRoutineName",
                        "Unique routine name"
                      )}
                      <Typography variant="body2" sx={{ width: "120px" }}>
                        {
                          billerProfile?.billerMasterRules?.masterRules
                            ?.routine_name
                        }
                      </Typography>
                    </Typography>
                  )}
                </Box>
                {billerProfile?.accountValidationValue &&
                  billerProfile?.accountValidationValue !== "NONE" && (
                    <>
                      <Box
                        mt={3}
                        sx={{
                          display: "flex",
                          width: "85%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.modCheck",
                            "Mod check"
                          )}
                          <Typography variant="body2">
                            {
                              billerProfile?.billerMasterRules?.masterRules
                                ?.mod_check
                            }
                          </Typography>
                        </Typography>
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.digitLocation",
                            "Digit location"
                          )}
                          <Typography variant="body2">
                            {
                              billerProfile?.billerMasterRules?.masterRules
                                ?.check_digit_location
                            }
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        mt={3}
                        sx={{
                          display: "flex",
                          width: "85%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.validationFromDigit",
                            "Validation from digit"
                          )}
                          <Typography variant="body2">
                            {
                              billerProfile?.billerMasterRules?.masterRules
                                ?.validation_from_digit
                            }
                          </Typography>
                        </Typography>
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.validationToDigit",
                            "Validation to digit"
                          )}
                          <Typography variant="body2">
                            {
                              billerProfile?.billerMasterRules?.masterRules
                                ?.validation_to_digit
                            }
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        mt={3}
                        sx={{
                          display: "flex",
                          width: "125%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="available__documents__value_v1">
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.fold",
                              "Fold"
                            )}
                            isDisabled={true}
                            onChange={(e) => {}}
                            value={
                              billerProfile?.billerMasterRules?.masterRules
                                ?.rule_fold === "Y"
                                ? true
                                : false
                            }
                            id="ruleFold"
                          />{" "}
                        </Typography>
                        <Typography className="available__documents__value_v1">
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.subtract",
                              "Subtract"
                            )}
                            isDisabled={true}
                            onChange={(e) => {}}
                            value={
                              billerProfile?.billerMasterRules?.masterRules
                                ?.rule_subtract === "Y"
                                ? true
                                : false
                            }
                            id="ruleFold"
                          />{" "}
                        </Typography>
                        <Typography className="available__documents__value_v1">
                          {t(
                            "erps.billermanagement.label.weighting",
                            "Weighting"
                          )}
                          <Typography variant="body2">
                            {
                              billerProfile?.billerMasterRules?.masterRules
                                ?.rule_weighting
                            }
                          </Typography>
                        </Typography>
                      </Box>
                    </>
                  )}

                {/* Prefix */}
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "52%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <ERPSBaseCheckBoxGrp
                      label={t(
                        "erps.updatebillerapproval.checkbox.label.prefixCheck",
                        "Exclude prefix"
                      )}
                      isDisabled={true}
                      value={billerProfile?.prefixCheck == "Y" ? true : false}
                    />{" "}
                  </Typography>
                  <Typography>
                    {t(
                      "erps.updatebillerapproval.label.prefixtext",
                      "Prefix text"
                    )}
                    <Typography variant="body2">
                      {billerProfile?.prefixText}
                    </Typography>
                  </Typography>
                </Box>
                {/* Suffix */}
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "52%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <ERPSBaseCheckBoxGrp
                      label={t(
                        "erps.updatebillerapproval.checkbox.label.suffixCheck",
                        "Exclude suffix"
                      )}
                      isDisabled={true}
                      value={billerProfile?.suffixCheck == "Y" ? true : false}
                    />{" "}
                  </Typography>
                  <Typography>
                    {t(
                      "erps.updatebillerapproval.label.suffixtext",
                      "Suffix text"
                    )}
                    <Typography variant="body2">
                      {billerProfile?.suffixText}
                    </Typography>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {/* Image and instruction text */}
          {fiNumber != 16 && (
            <Box mt={4} className="manageUsers__page__title">
              <Typography variant="h2">
                {t(
                  "erps.updatebillerapproval.heading.imageinstructiontext",
                  "Image and instruction text"
                )}
              </Typography>
            </Box>
          )}
          {fiNumber == "50" && (
            <Box mt={3} mb={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value">
                {t("erps.billermanagement.label.instructTextType")}
                <Typography variant="body2">
                  {
                    findInstructType(billerProfileExtensionData?.instructType)
                      ?.label
                  }
                </Typography>
              </Typography>
            </Box>
          )}
          {/* English */}
          {fiNumber != 10 ? (
            fiNumber == 16 ? (
              ""
            ) : (
              <Typography className="available__documents__value_v1">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl",
                  "English image url"
                )}
                <Typography variant="body2">
                  {billerProfile?.englishImageUrl}
                </Typography>
              </Typography>
            )
          ) : (
            <>
              {billerProfile?.imageENBase64Content && (
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="inherit">
                    {" "}
                    <img
                      src={billerProfile?.imageENBase64Content}
                      height="240"
                      width="550"
                      alt={t(
                        "erps.updatebillerapproval.imageinstructiontext.label.englishimage"
                      )}
                    />
                  </Typography>
                </Box>
              )}
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="inherit">
                  {t(
                    "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl",
                    "English image url"
                  )}
                  <Typography variant="body2">
                    {billerProfile?.englishImageUrl}
                  </Typography>
                </Typography>
              </Box>
            </>
          )}
          {fiNumber != 16 && (
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value_v1">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.englishinstructiontext",
                  "English instruction text"
                )}
                <Typography variant="body2">
                  {billerProfile?.instructTextEnglish &&
                    parse(billerProfile?.instructTextEnglish)}
                </Typography>
              </Typography>
            </Box>
          )}
          {/* French */}
          {fiNumber == 10 && (
            <>
              {billerProfile?.imageFRBase64Content && (
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="inherit">
                    {" "}
                    <img
                      src={billerProfile?.imageFRBase64Content}
                      height="240"
                      width="550"
                      alt={t(
                        "erps.updatebillerapproval.imageinstructiontext.label.frenchimage"
                      )}
                    />
                  </Typography>
                </Box>
              )}
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="inherit">
                  {t(
                    "erps.updatebillerapproval.imageinstructiontext.label.frenchimage",
                    "French image url"
                  )}
                  <Typography variant="body2">
                    {billerProfile?.frenchImageUrl}
                  </Typography>
                </Typography>
              </Box>
            </>
          )}
          {fiNumber != 16 && (
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value_v1">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.frenchinstructiontext",
                  "French instruction text"
                )}
                <Typography variant="body2">
                  {billerProfile?.instructTextFrench &&
                    parse(billerProfile?.instructTextFrench)}
                </Typography>
              </Typography>
            </Box>
          )}
          {/* Line of business settings for CIBC */}
          {fiNumber == "10" && (
            <Box>
              <Box mt={4}>
                <Typography variant="h2">
                  {t("erps.billermanagement.label.lineofbusiness")}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="h4">
                  {t("erps.billermanagement.label.image")}
                </Typography>
              </Box>
              <Box style={{ width: "500px" }}>
                <Box
                  mt={3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box>
                    <Typography>
                      {t("erps.billermanagement.label.cibc", "CIBC:")}
                    </Typography>
                    <br />
                    <Box mt={-3}>
                      <Box>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingIVR",
                            "Telephone banking IVR"
                          )}
                          id="imageCibcTelephoneBankingIVR"
                          value={
                            lineOfbusinessData?.isImgCIBCTelephoneBankingIVRChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcBranchBanking",
                            "Branch banking"
                          )}
                          id="imageCibcBranchBanking"
                          value={
                            lineOfbusinessData?.isImgCIBCBranchBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingDesk",
                            "Telephone banking (Desktop)"
                          )}
                          id="imageCibcTelephoneBankingDesktop"
                          value={
                            lineOfbusinessData?.isImgCIBCTelePhoneBankingDeskChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcOnlineBanking",
                            "Online banking"
                          )}
                          id="imageCibcOnlineBanking"
                          value={
                            lineOfbusinessData?.isImgCIBCOnlineBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography>
                      {t(
                        "erps.billermanagement.label.pcfinancial",
                        "PC Financial:"
                      )}
                    </Typography>
                    <br />
                    <Box mt={-3}>
                      <Box>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingIVR",
                            "Telephone banking IVR"
                          )}
                          id="imagePcFinanceTelephoneBankingIVR"
                          value={
                            lineOfbusinessData?.isImgPCFinancialTelephoneBankingIVRChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>

                      <Box mt={3}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingDesk",
                            "Telephone banking (Desktop)"
                          )}
                          id="imagePcFinanceTelephoneBankingDesktop"
                          value={
                            lineOfbusinessData?.isImgPCFinancialTelePhoneBankingDeskChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcOnlineBanking",
                            "Online banking"
                          )}
                          id="imagePcFinanceOnlineBanking"
                          value={
                            lineOfbusinessData?.isImgPCFinancialOnlineBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box mt={3}>
                <Typography variant="h4">
                  {t(
                    "erps.billermanagement.label.instructional",
                    "Instructional"
                  )}
                </Typography>
              </Box>
              <Box style={{ width: "500px" }}>
                <Box
                  mt={3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box>
                    <Typography>
                      {t("erps.billermanagement.label.cibc", "CIBC:")}
                    </Typography>
                    <br />
                    <Box mt={-3}>
                      <Box>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingIVR",
                            "Telephone banking IVR"
                          )}
                          id="instructionalCibcTelephoneBankingIVR"
                          value={
                            lineOfbusinessData?.isInstCIBCTelephoneBankingIVRChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcBranchBanking",
                            "Branch banking"
                          )}
                          id="instructionalCibcBranchBanking"
                          value={
                            lineOfbusinessData?.isInstCIBCBranchBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingDesk",
                            "Telephone banking (Desktop)"
                          )}
                          id="instructionalCibcTelephoneBankingDesktop"
                          value={
                            lineOfbusinessData?.isInstCIBCTelePhoneBankingDeskChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcOnlineBanking",
                            "Online banking"
                          )}
                          id="instructionalCibcOnlineBanking"
                          value={
                            lineOfbusinessData?.isInstCIBCOnlineBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography>
                      {t(
                        "erps.billermanagement.label.pcfinancial",
                        "PC Financial:"
                      )}
                    </Typography>
                    <br />
                    <Box mt={-3}>
                      <Box>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingIVR",
                            "Telephone banking IVR"
                          )}
                          id="instructionalPcFinanceTelephoneBankingIVR"
                          value={
                            lineOfbusinessData?.isInstPCFinancialTelephoneBankingIVRChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>

                      <Box mt={3}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcTelphoneBankingDesk",
                            "Telephone banking (Desktop)"
                          )}
                          id="instructionalPcFinanceTelephoneBankingDesktop"
                          value={
                            lineOfbusinessData?.isInstPCFinancialTelePhoneBankingDeskChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                      <Box mt={-1}>
                        <ERPSBaseCheckBoxGrp
                          label={t(
                            "erps.billermanagement.label.cibcOnlineBanking",
                            "Online banking"
                          )}
                          id="instructionalPcFinanceOnlineBanking"
                          value={
                            lineOfbusinessData?.isInstPCFinancialOnlineBankingChecked ===
                            "Y"
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />{" "}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* Comments */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.billermanagement.label.comment", "Comments")}
              </Typography>
              <Typography variant="body2" mt={1}>
                {billerProfile?.billerComment &&
                hasValidHtmlTags(billerProfile?.billerComment)
                  ? parse(billerProfile?.billerComment)
                  : billerProfile?.billerComment}
              </Typography>
            </Box>
          </Box>
          {/* Action Buttons */}
          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.newuser.button.back", "Back")}
              onClick={() => navigateToManageBillerPage()}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default BillerProfileUserReadView;

import loginActionTypes from "../actions/types/login.action.type";

export const serviceAgreementInitialState = {
  serviceAggreementData: null,
  serviceAggreementeError: null,
};

export default function serviceAgreementReducer(
  state = serviceAgreementInitialState,
  action
) {
  switch (action.type) {
    case loginActionTypes.UPDATE_SERVICE_AGREEMENT_SUCCESS:
      return {
        serviceAggreementData: action.data,
        serviceAggreementeError: null,
      };
    case loginActionTypes.UPDATE_SERVICE_AGREEMENT_ERROR:
      return {
        serviceAggreementData: null,
        serviceAggreementeError: action.error,
      };
    default:
      return state;
  }
}

import { t } from "i18next";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "./../components/custom/inputs/ERPSBaseButton";
import { statisticsFormatDate } from "./../utils/helper";
import moment from "moment";

export const getFailedFaxHeading = (data, helperObj) => {
  const { handleResend, buttonLoading } = helperObj;
  return [
    {
      field: "faxDatetime",
      headerName: t("erps.failedfax.page.faxDatesent"),
      width: 160,
      sortable: false,
      renderCell: (params) => {
        if (params?.value) {
          return moment(params?.value).format("YYYY-MM-DD kk:mm:ss");
        }
        return "";
      },
    },
    {
      field: "operationDate",
      headerName: t("erps.failedfax.page.originalfaxdate"),
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return statisticsFormatDate(params?.value);
      },
    },
    {
      field: "fiName",
      headerName: t("erps.failedfax.page.fiName"),
      width: 150,
      sortable: false,
    },
    {
      field: "billerFiNumber",
      headerName: "Biller",
      width: 110,
      sortable: false,
    },
    {
      field: "auditNumber",
      headerName: t("erps.failedfax.page.audit"),
      width: 100,
      sortable: false,
    },
    {
      field: "faxNumber",
      headerName: t("erps.failedfax.page.fax"),
      width: 150,
      sortable: false,
      align: "left",
      headerAlign: "left",
      editable: true,
      type: "number",
      valueParser: (value) => {
        const inputValue = value?.toString().replace(/^0+/, "");
        const updatedValue =
          inputValue.length > 11
            ? +Math.max(0, parseInt(inputValue)).toString().slice(0, 11)
            : +inputValue;
        return updatedValue || "";
      },
      renderCell: (params) => {
        if (params.value) {
          return params.value;
        }
        return "";
      },
    },
    {
      field: "action",
      headerName: t("erps.failedfax.page.action"),
      align: "right",
      width: 270,
      sortable: false,
      renderCell: (params) => (
        <Box mt={-3} display="flex" mr={10} justifyContent={"center"}>
          <ERPSBaseButton
            variant="secondary"
            className="button_failed_fax"
            label={t("erps.failedfax.page.viewFax")}
            onClick={() => {
              if (data?.history && data?.pathname) {
                data.history?.push(`${data.pathname}/view-fax`, {
                  data: params?.row,
                  hasArchivedSentPage: data?.hasArchivedSentPage,
                  searchedValues: data?.searchValues,
                });
              }
            }}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_failed_fax"
              label={t("erps.failedfax.page.resend")}
              onClick={() => handleResend("resend", params)}
              loading={buttonLoading?.[params.id]}
            />
          </Box>
        </Box>
      ),
    },
  ];
};

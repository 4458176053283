import viewStatisticsActionTypes from "../actions/types/viewStatistics.action.type";

export const viewStatisticsInitialState = {
  viewStatisticsData: null,
  viewStatisticsError: null,
  viewStatisticsFilters: null,
  viewStatisticsBiller: null,
};

export default function viewStatisticsReducer(
  state = viewStatisticsInitialState,
  action
) {
  switch (action.type) {
    case viewStatisticsActionTypes.GET_VIEW_STATISTICS_SUCCESS:
      return {
        ...state,
        viewStatisticsData: action.data,
        viewStatisticsError: null,
      };
    case viewStatisticsActionTypes.GET_VIEW_STATISTICS_FAILURE:
      return {
        ...state,
        viewStatisticsData: null,
        viewStatisticsError: action.error,
      };
    case viewStatisticsActionTypes.GET_VIEW_STATISTICS_INITIAL:
      return {
        ...state,
        viewStatisticsData: null,
        viewStatisticsError: null,
        viewStatisticsFilters: null,
        viewStatisticsBiller: null,
      };
    case viewStatisticsActionTypes.SET_VIEW_STATISTICS_FILTERS:
      return {
        ...state,
        viewStatisticsData: null,
        viewStatisticsError: null,
        viewStatisticsFilters: action.data,
        viewStatisticsBiller: null,
      };
      case viewStatisticsActionTypes.SET_VIEW_STATISTICS_BILLERS:
      return {
        ...state,
        viewStatisticsBiller: action.data,
      };
    default:
      return state;
  }
}

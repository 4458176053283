import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import i18n from "./i18n";
import store, { persistor } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import loginActionTypes from "./actions/types/login.action.type";
import { t } from "i18next";
import UserService from "./utils/keycloak.service";
// setup fake backend

// Request interceptors for API calls
axios.interceptors.request.use(
  async (config) => {
    if (!config?.headers["Login-UserId"]) {
      config.headers["Login-UserId"] =
        store.getState()?.loginUserReducer?.user?.userId || "";
    }
    if (UserService?.isLoggedIn()) {
      await UserService?.updateToken();
      config.headers.Authorization = `Bearer ${UserService?.getToken()}`;
    }
    return config;
  },
  (error) => {
    console.log("Error in request Interceptor");
  }
);

/** Intercept any unauthorized and token expired request.
 * dispatch logout action accordinglyionStorage **/
// const UNAUTHORIZED = 401;
// const { dispatch } = store;
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status, data } = error.response;
//     if (status === UNAUTHORIZED && data?.error == "invalid_token") {
//       // && !error?.config?.url.includes('logoutUser')
//       dispatch({
//         type: loginActionTypes.LOGOUT_SUCCESS,
//         message: t("erps.error.message.sessionexpired"),
//       });
//       localStorage.removeItem("persist:erps");
//     }
//     return Promise.reject(error);
//   }
// );

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssBaseline />
        <App />
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const userActionTypes = {
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  BILLER_INFO_UPDATE_SUCCESS: "BILLER_INFO_UPDATE_SUCCESS",
  USER_UPDATE_ERROR: "USER_UPDATE_ERROR",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAIL: "GET_USER_DETAIL_FAIL",
  GET_USER_ROLE_TYPE_LIST: "GET_USER_ROLE_TYPE_LIST",
  GET_USER_ROLE_TYPE_LIST_ERROR: "GET_USER_ROLE_TYPE_LIST_ERROR",
  GET_SUBSCRIBING_ENTITIES_LIST: "GET_SUBSCRIBING_ENTITIES_LIST",
  GET_SUBSCRIBING_ENTITIES_LIST_ERROR: "GET_SUBSCRIBING_ENTITIES_LIST_ERROR",
  NEW_USER_SUCCESS: "NEW_USER_SUCCESS",
  NEW_USER_REQUEST: "NEW_USER_REQUEST",
  RESET_ERROR: "RESET_ERROR",
  FAIL: "FAILURE",
  GET_USER_ROLE_LIST_SUCCESS: "GET_USER_ROLE_LIST_SUCCESS",
  GET_USER_ROLE_LIST_ERROR: "GET_USER_ROLE_LIST_ERROR",
  USER_QUEUE_UPDATE_SUCCESS: "USER_QUEUE_UPDATE_SUCCESS",
  USER_QUEUE_UPDATE_ERROR: "USER_QUEUE_UPDATE_ERROR",
  USER_QUEUE_DELETE_SUCCESS: "USER_QUEUE_DELETE_SUCCESS",
  USER_QUEUE_DELETE_ERROR: "USER_QUEUE_DELETE_ERROR",
};

export default userActionTypes;

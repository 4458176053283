import { PropTypes } from "prop-types";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { withERPSTabValidator } from "../../hoc/withERPSTabValidator";

function TabPanel(props) {
  const { children, value, index, tabId, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={tabId || `user-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default withERPSTabValidator(TabPanel);

import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { getFormattedPhoneNumber } from "../utils/helper";
import { t } from "i18next";

export const getManageUsersGridHeadings = (
  roleType,
  componentAccess,
  handleOpenUserProfile,
  billerId,
  parentComponentAccess,
  pageName,
  checkUserExists
) => {
  const getComponentRolewise = (params) => {
    if (roleType === "CE" || roleType === "BA") {
      if (pageName === "biller-profile") {
        return (
          <ERPSBaseLink
            className="link__blue"
            label={params.value.toString()}
            onClick={() => {
              const args = { userId: params.value, billerId };
              checkUserExists(
                params?.row?.roleId,
                params.value,
                null,
                null,
                args,
                handleOpenUserProfile
              );
            }}
          />
        );
      } else {
        return (
          <ERPSBaseLink
            onClick={() => {
              const redirectPath = "/manage-users/view-user-profile";
              const pathValues = {
                id: params.value,
                componentAccess: parentComponentAccess || componentAccess,
              };
              checkUserExists(
                params?.row?.roleId,
                params.value,
                redirectPath,
                pathValues
              );
            }}
            className="link__blue"
            label={params.value.toString()}
          />
        );
      }
    } else if (pageName === "biller-profile") {
      return (
        <ERPSBaseLink
          className="link__blue"
          label={params.value.toString()}
          actionType={"hasBillerUserProfileAccess"}
          componentAccess={componentAccess}
          onClick={() => {
            const args = { userId: params.value.toString(), billerId };
            checkUserExists(
              params?.row?.roleId,
              params.value.toString(),
              null,
              null,
              args,
              handleOpenUserProfile
            );
          }}
        />
      );
    } else {
      return (
        <ERPSBaseLink
          onClick={() => {
            const redirectPath = "/manage-users/user-profile";
            const pathValues = {
              id: params.value,
              componentAccess: parentComponentAccess || componentAccess,
            };
            checkUserExists(
              params?.row?.roleId,
              params.value,
              redirectPath,
              pathValues
            );
          }}
          className="link__blue"
          label={params.value.toString()}
          actionType={"hasManageUserListAccess"}
          componentAccess={componentAccess}
        />
      );
    }
  };

  const columnHeaders = [
    {
      field: "userId",
      headerName: t("erps.manageusers.label.userid"),
      width: 110,
      renderCell: (params) => {
        return getComponentRolewise(params);
      },
      sortable: false,
    },
    {
      field: "firstName",
      headerName: t("erps.manageusers.label.firstname"),
      width: 120,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: t("erps.manageusers.label.lastname"),
      width: 120,
      sortable: false,
    },
    {
      field: "phone",
      headerName: t("erps.newuser.label.phonenumber"),
      width: 135,
      sortable: false,
      renderCell: (params) => getFormattedPhoneNumber(params.value),
    },
    {
      field: "emailAddress",
      headerName: t("erps.newuser.label.emailaddress"),
      width: 220,
      sortable: false,
    },
    {
      field: "statusDescription",
      headerName: t("erps.newuser.label.status"),
      width: 90,
      sortable: false,
    },
    {
      field: "roleName",
      headerName: t("erps.newuser.label.role"),
      width: 140,
      sortable: false,
    },
  ];

  if (roleType === "CA") {
    columnHeaders.push({
      field: "fiCompanyName",
      headerName: t("erps.newuser.label.subscribingentityid"),
      width: 200,
      sortable: false,
    });
  }
  return columnHeaders;
};

export const EMPTY_SEARCH_CRITERIA = {
  userId: "",
  firstName: "",
  lastName: "",
  startOffset: 0,
  endOffset: 25,
};

import { Box, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { withContainerBox } from "../../hoc/withContainerBox";
const CommonContainerBox = (props) => {
  const { t } = useTranslation();
  const fiName = localStorage.getItem("fiName");
  return (
    <Box
      sx={{
        marginTop: `${props.marginTop}`,
        padding: `${props.padding}`,
        minHeight: `${props.minHeight}`,
      }}
    >
      <Helmet>
        <title>
          {fiName === "bmo"
            ? `${t(
                "erps.app.title.message.bmo",
                "Merchant Bill Pay Service"
              )}-${props.title}`
            : `${t(
                "erps.app.title.message",
                "Bill payment remittance reporting"
              )}-${props.title}`}
        </title>
      </Helmet>
      <Grid container>{props.children}</Grid>
    </Box>
  );
};

export default withContainerBox(CommonContainerBox);

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import actions from "../../actions";
import { withSidebar } from "../hoc/withSidebar";
import ErrorMessage from "../custom/containers/ErrorMessage";

const ViewFax = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [text, setText] = useState();

  const {
    history,
    location: { pathname, state },
  } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  useEffect(() => {
    const { fileLocation, ...rest } = state?.data;
    dispatch(
      actions.faxManagementActions.getViewFaxFile(
        {
          ...rest,
          fileLocation:
            fileLocation?.charAt(0) === "/"
              ? fileLocation?.slice(1)
              : fileLocation,
        },
        {
          ...props,
          setLoading: setInitialLoading,
          setErrorMessage,
          t,
          setText,
        }
      )
    );
  }, []);

  const handleClick = () => {
    const route = state?.hasArchivedSentPage
      ? "/archived-sent-faxes"
      : "/failed-faxes";
    const stateProps = { ...state, fromViewFax: true };
    history.push(route, stateProps);
  };

  return (
    <main>
      {errorMessage ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={2}
          ml={3}
        >
          {errorMessage ? (
            <ErrorMessage error={errorMessage} errorRef={errorRef} />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      <Stack direction="row">
        <Box mt={6} mr={10} pl={3}>
          <Typography variant="h1">
            {`${t("erps.failedfax.page.viewFax")} - ${state?.data?.fileName}`}
          </Typography>
        </Box>
        <ERPSBaseButton
          variant="primary"
          className="button_with_margin48"
          label={t("erps.newuser.button.back")}
          onClick={handleClick}
        />
      </Stack>
      {text && !initialLoading && (
        <Box
          ml={3}
          mt={4}
          className="fax_management_view_fax_detail"
          tabIndex={0}
        >
          <pre>{text}</pre>
        </Box>
      )}{" "}
      {initialLoading && (
        <Box ml={40} mt={10} sx={{ display: "flex" }}>
          {<CircularProgress />}
        </Box>
      )}
    </main>
  );
};
export default withSidebar(ViewFax);

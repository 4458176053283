import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const ERPSBaseDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width:448
    },
    '& .MuiDialogContent-root': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
    },
    '& .MuiDialogActions-root': {
      paddingTop: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    '& .MuiTypography-gutterBottom' :{
      marginBottom: theme.spacing(6)
    }
}));

export default ERPSBaseDialog;
  

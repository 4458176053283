import services from "../services";

export const loginActions = {
  login,
  logout,
  updateServiceAggrement,
  updateUserRolesPermission,
};

function login(username, password, props) {
  return services.loginService.login(username, password, props);
}

function updateServiceAggrement(data, props) {
  return services.loginService.updateServiceAggrement(data, props);
}

function logout(props) {
  return services.loginService.logout(props);
}

function updateUserRolesPermission(fiNumber, roleId, props) {
  return services.loginService.updateUserRolesPermission(
    fiNumber,
    roleId,
    props
  );
}

import passwordActionTypes from "../actions/types/password.action.type";

export const forgetPasswordInitialState = {
  data: null,
  error: null,
};

export default function forgetPasswordReducer(
  state = forgetPasswordInitialState,
  action
) {
  switch (action.type) {
    case passwordActionTypes.FORGOT_PASSWORD_SUCCESS:
      return { ...state, error: null, data: action.data };
    case passwordActionTypes.FORGOT_PASSWORD_FAILURE:
      return { ...state, error: action.error };
    case passwordActionTypes.GENRATE_PASSWORD_SUCCESS:
      return { ...state, error: null, data: action.data };
    case passwordActionTypes.GENRATE_PASSWORD_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

import transactionEnquiryTypes from "../actions/types/transactionEnquiry.action.type";

export const transactionEnquiryInitialState = {
  error: null,
  transactionEnquiryData: null,
  transactionEnquiryDetails: null,
  count: 0,
  retraceRecallData: null,
  retraceRecallError: null,
};

export default function transactionEnquiryReducer(
  state = transactionEnquiryInitialState,
  action
) {
  switch (action.type) {
    case transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_SUCCESS:
      return {
        transactionEnquiryData: action.data.data,
        error: null,
        transactionEnquiryDetails: action.data.transactionEnquiryDetails,
        count: action.data.count,
      };
    case transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_ERROR:
      return { transactionEnquiryData: null, count: 0, error: action.error };
    case transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_SUCCESS:
      return {
        ...state,
        retraceRecallData: action.data,
        retraceRecallError: null,
      };
    case transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE:
      return {
        ...state,
        retraceRecallError: action.error,
        retraceRecallData: null,
      };

    default:
      return state;
  }
}

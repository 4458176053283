import { createTheme } from "@mui/material/styles";

export const createBaseMuiTheme = (props) =>
  createTheme({
    typography: {
      h1: {
        fontSize: "32px",
        lineHeight: "normal",
        fontWeight: "300",
        fontStyle: "normal",
        fontFamily: "Roboto, Light",
      },
      h2: {
        fontSize: "22px",
        lineHeight: "normal",
        fontWeight: "300",
        fontStyle: "normal",
        fontFamily: "Roboto, Light",
      },
      h3: {
        fontSize: "20px",
        lineHeight: "normal",
        fontWeight: "300",
        fontStyle: "normal",
        fontFamily: "Roboto, Regular",
      },
      h4: {
        fontSize: "18px",
        lineHeight: "normal",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Roboto, Medium",
        color: "#2A2C2E",
      },
      h5: {
        fontSize: "16px",
        lineHeight: "normal",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Roboto, Medium",
        color: "#2A2C2E",
      },
      label: {
        fontSize: "14px",
        color: "#2a2c2e",
        fontStyle: "normal",
        fontFamily: "Roboto, Light",
      },
      labelOutline: {
        fontSize: "12px",
        color: "#2a2c2e",
        fontStyle: "normal",
        fontFamily: "Roboto, Light",
      },
      display: {
        fontSize: "14px",
        color: "#2a2c2e",
        fontStyle: "normal",
        fontWeight: "300",
        fontFamily: "Roboto, Regular",
      },
      body1: {
        fontSize: "14px",
        color: "#2a2c2e",
        fontStyle: "normal",
        fontWeight: "300",
        fontFamily: "Roboto, Light",
      },
      body2: {
        fontSize: "14px",
        color: "#2a2c2e",
        fontStyle: "normal",
        fontWeight: "400",
        fontFamily: "Roboto, Regular",
      },
      error: {
        fontSize: "14px",
        color: "#C12334",
        fontStyle: "normal",
      },
      link: {
        fontSize: "14px",
        fontFamily: "Roboto, Regular",
        textDecoration: "underline",
        color: "#C12334",
      },
      subtitle1: {
        fontSize: "24px",
        fontWeight: "300",
        fontFamily: "Roboto, Light",
        color: "#FFFFFF",
      },
      updatedH3: {
        fontSize: "18px",
        lineHeight: "normal",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Roboto, Regular",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--secondary-bg-color)",
            color: "var(--main-bg-color)",
            height: "84px",
            boxSizing: "border-box",
            overflow: "hidden",
            boxShadow: "none",
          },
        },
      },
    },
  });

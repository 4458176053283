export const hasShowComponent = ({ componentAccess, actionType, user }) => {
  if (user && componentAccess) {
    if (componentAccess.length) {
      return componentAccess.some((component) => {
        return actionType === component.componentActionTypeCode;
      });
    }
    return false;
  }
  return true;
};

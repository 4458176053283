import userPendingApprovalsActionsType from "../actions/types/userPendingApproval.action.type";
import store from "../store/index";
import axios from "axios";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL,
} from "../utils/helper";

export const userPendingApprovalService = {
  getPendingUserApprovals,
  updateUserApprovalData,
  createUserApprovalData,
  deleteUserApprovalData,
  declineUserApprovalData,
};
function getPendingUserApprovals(
  data,
  setLoading,
  translation,
  setUserPendingApprovalData
) {
  return (dispatch) => {
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/user-pending-approvals/list",
        {
          ...data,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        if (setUserPendingApprovalData && parsedData.queueDataList) {
          setUserPendingApprovalData(parsedData.queueDataList);
        }
        dispatch(success(parsedData));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedError = getExceptionMessage(error, translation);
        dispatch(failure(parsedError));
      });
  };

  function success(user) {
    return {
      type: userPendingApprovalsActionsType.GET_PENDING_USER_APPROVAL_SUCCESS,
      data: user,
    };
  }

  function failure(error) {
    return {
      type: userPendingApprovalsActionsType.GET_PENDING_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function updateUserApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const user = store.getState()?.loginUserReducer?.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/user-pending-approvals/approve-update-user",
        {
          ...data,
          hasLastRecord,
          loggedInLanguageCode: user?.languageCode,
          loggedInUserId: user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setUpdateLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(success(parsedData, props));
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError =
          getExceptionMessage(error, props.t) ||
          JSON.parse(JSON.stringify(error))?.message;
        props.setUpdateLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: userPendingApprovalsActionsType.UPDATE_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: userPendingApprovalsActionsType.UPDATE_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function createUserApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const user = store.getState()?.loginUserReducer?.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/user-pending-approvals/approve-new-user",
        {
          ...data,
          hasLastRecord,
          loggedInLanguageCode: user?.languageCode,
          loggedInUserId: user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
        dispatch(success(parsedData, props));
      })
      .catch((error) => {
        const parsedError =
          getExceptionMessage(error, props.t) ||
          JSON.parse(JSON.stringify(error))?.message;
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: userPendingApprovalsActionsType.CREATE_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: userPendingApprovalsActionsType.CREATE_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function deleteUserApprovalData(data, props) {
  const { hasLastRecord, setLastRecordDisable } = props;
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer?.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/user-pending-approvals/approve-delete-user",
        {
          ...data,
          hasLastRecord,
          loggedInLanguageCode: user?.languageCode,
          loggedInUserId: user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
        dispatch(success(parsedData, props));
      })
      .catch((error) => {
        const parsedError =
          getExceptionMessage(error, props.t) ||
          JSON.parse(JSON.stringify(error))?.message;
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: userPendingApprovalsActionsType.DELETE_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: userPendingApprovalsActionsType.DELETE_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

function declineUserApprovalData(data, props) {
  return (dispatch) => {
    const { hasLastRecord, setLastRecordDisable } = props;
    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/user-pending-approvals/decline-user",
        {
          ...data,
          hasLastRecord,
          loggedInLanguageCode: languageCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setDeclineLoading(false);
        props.setShowSuccessMessage(true);
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
        dispatch(success(parsedData, props));
      })
      .catch((error) => {
        const parsedError =
          getExceptionMessage(error, props.t) ||
          JSON.parse(JSON.stringify(error))?.message;
        props.setDeclineLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: userPendingApprovalsActionsType.DECLINE_USER_APPROVAL_SUCCESS,
      data: user,
      nextTransactionId: props?.nextTransactionId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: userPendingApprovalsActionsType.DECLINE_USER_APPROVAL_FAILURE,
      error,
    };
  }
}

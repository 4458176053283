import axios from "axios";
import store from "../store/index";
import { API_BASE_URL, getExceptionMessage } from "../utils/helper";
import marketingManagementTypes from "../actions/types/marketingManagement.action.type";

export const loginMessageSevice = {
  getLoginMessage,
  getLoginQueueMessage,
  createdLoginMessage,
  loginPendingApprovalMessage,
  loginPendingDeclineMessage,
};

function getLoginMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const token = loginUser?.token;
    const fiNumber =
      fiDetail?.selectedFi ||
      loginUser.user?.fiNumber ||
      localStorage.getItem("fiNumber");
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    axios
      .get(
        API_BASE_URL() +
          `/dashboard-message-login/get-messages?fiNumber=` +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: loginUser?.isAuthorized
              ? "Bearer " + token
              : "dashboard-message",
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })

      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.GET_LOGIN_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.GET_LOGIN_MESSAGE_FAILURE,
      error,
    };
  }
}
function getLoginQueueMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          `/dashboard-message-login/get-queue-messages?fiNumber=` +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })

      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.GET_LOGIN_QUEUE_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.GET_LOGIN_QUEUE_MESSAGE_FAILURE,
      error,
    };
  }
}

function createdLoginMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    const {
      setLoading,
      setSuccessMessage,
      t,
      setShowExceptionMessage,
      ...rest
    } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message-login/save-user",
        {
          ...rest,
          fiNumber,
          actionCode: "A",
          languageCode,
          loggedInUserId: loginUser?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.CREATE_LOGIN_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.CREATE_LOGIN_MESSAGE_FAILURE,
      error,
    };
  }
}

function loginPendingApprovalMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    const {
      setLoading,
      setSuccessMessage,
      t,
      setShowExceptionMessage,
      ...rest
    } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message-login/approve-pending-message",
        {
          ...rest,
          fiNumber,
          languageCode,
          loggedInUserId: loginUser?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.APPROVE_LOGIN_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.APPROVE_LOGIN_MESSAGE_FAILURE,
      error,
    };
  }
}

function loginPendingDeclineMessage(data) {
  return (dispatch) => {
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message-login/decline-pending-message",
        { fiNumber, languageCode, loggedInUserId: loginUser?.user?.userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.DECLINE_LOGIN_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.DECLINE_LOGIN_MESSAGE_FAILURE,
      error,
    };
  }
}

import services from "../services";

export const relatedBillerActions = {
  getAllChildBiller,
  getParentBiller,
  updateMonthlyInvoiceForChildBiller,
  removeBillerRelationShip,
  getRelatedBillerSearch,
  addBillerRelationship,
};

function getAllChildBiller(reqData, props, t) {
  return services.relatedBillerService.getAllChildBiller(reqData, props, t);
}
function addBillerRelationship(reqData, props, t) {
  return services.relatedBillerService.addBillerRelationship(reqData, props, t);
}
function getParentBiller(reqData, props, t) {
  return services.relatedBillerService.getParentBiller(reqData, props, t);
}

function updateMonthlyInvoiceForChildBiller(reqData, props, t) {
  return services.relatedBillerService.updateMonthlyInvoiceForChildBiller(
    reqData,
    props,
    t
  );
}

function removeBillerRelationShip(reqData, props, t) {
  return services.relatedBillerService.removeBillerRelationShip(
    reqData,
    props,
    t
  );
}

function getRelatedBillerSearch(reqData, props, t) {
  return services.relatedBillerService.getRelatedBillerSearch(
    reqData,
    props,
    t
  );
}

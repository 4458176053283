import provinceActionType from "../actions/types/province.action.type";

export const provinceInitialState = {
    provinceEntitiesList: null,
    error: null,
  };

  export default function provinceReducer(
    state = provinceInitialState,
    action
  ) {
    switch (action.type) {
      case provinceActionType.GET_PROVINCE_LIST_SUCCESS:
        return { provinceEntitiesList: action.data, error: null };
      case provinceActionType.GET_PROVINCE_LIST_ERROR:
        return { provinceEntitiesList: null, error: action.error };
      default:
        return state;
    }
  }
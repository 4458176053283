import deliveryMethodActionType from "../actions/types/deliveryMethod.action.type";

export const formatMethodInitialState = {
    formatMethodEntitiesList: null,
    error: null,
  };

  export default function formatMethodReducer(
    state = formatMethodInitialState,
    action
  ) {
    switch (action.type) {
      case deliveryMethodActionType.GET_FORMAT_METHOD_LIST_SUCCESS:
        return { formatMethodEntitiesList: action.data, error: null };
      case deliveryMethodActionType.GET_FORMAT_METHOD_LIST_ERROR:
        return { formatMethodEntitiesList: null, error: action.error };
      default:
        return state;
    }
  }
import services from "../services";

export const userActions = {
  changePassword,
  getUserDetails,
  saveUser,
  getUserRoleTypeList,
  getSubscribingEntitiesList,
  //deleteUserDetails,
  updateUserDetails,
  checkUserExists,
};

function changePassword(values, currentUserId, props) {
  return services.userService.changePassword(values, currentUserId, props);
}
function getUserDetails(userDetails) {
  return services.userService.getUserDetails(userDetails);
}

// function deleteUserDetails(userInfo, setterMethods) {
//   return services.userService.deleteUserDetails(userInfo, setterMethods);
// }

function updateUserDetails(userInfo, setterMethods) {
  return services.userService.updateUserDetails(userInfo, setterMethods);
}

function saveUser(
  finalObject,
  setValues,
  props,
  userRoleTypeList,
  subscribingEntitiesList
) {
  return services.userService.saveUser(
    finalObject,
    setValues,
    props,
    userRoleTypeList,
    subscribingEntitiesList
  );
}

function getUserRoleTypeList(
  fiNumber,
  roleType,
  functionName,
  translation,
  setRole,
  personalUser
) {
  return services.userService.getUserRoleTypeList(
    fiNumber,
    roleType,
    functionName,
    translation,
    setRole,
    personalUser
  );
}

function getSubscribingEntitiesList(fiNumber) {
  return services.userService.getSubscribingEntitiesList(fiNumber);
}
function checkUserExists(data) {
  return services.userService.checkUserExists(data);
}

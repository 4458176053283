import { fiActionTypes } from "../actions/types/fi.action.types";

export const financialInstitutionInitialState = {
  financialInstitutionList: null,
  fiListByOwningFi: null,
  error: null,
  selectedFi: null,
  masterFiDescriptions: null,
};

export default function fiReducer(
  state = financialInstitutionInitialState,
  action
) {
  switch (action.type) {
    case fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST:
      return {
        ...state,
        financialInstitutionList: action.data,
        error: null,
      };
    case fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_ERROR:
      return {
        financialInstitutionList: null,
        error: action.error,
        selectedFi: null,
      };
      case fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_SUCCESS:
        return {
          ...state,
          fiListByOwningFi: action.data,
          error: null,
        };
      case fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_ERROR:
        return {
          ...state,
          fiListByOwningFi: null,
          error: action.error,
        };
    case fiActionTypes.GET_FINANCIAL_INSTITUTION:
      return {
        ...state,
        selectedFi: action.data,
      };
    case fiActionTypes.GET_MASTER_FI_DESCRIPTION_LIST:
      return {
        ...state,
        masterFiDescriptions: action.data,
        error: null,
      };
    case fiActionTypes.GET_MASTER_FI_DESCRIPTION_LIST_ERROR:
      return {
        ...state,
        masterFiDescriptions: null,
        error: action.error
      };
    default:
      return state;
  }
}

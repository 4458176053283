const budgetCategoryActionType = {
  BUDGET_CATEGORY_SUCCESS: "BUDGET_CATEGORY_SUCCESS",
  BUDGET_CATEGORY_FAIL: "BUDGET_CATEGORY_FAIL",
  NEW_BUDGET_CATEGORY_SUCCESS: "NEW_BUDGET_CATEGORY_SUCCESS",
  UPDATE_BUDGET_CATEGORY_SUCCESS: "UPDATE_BUDGET_CATEGORY_SUCCESS",
  UPDATE_FAIL: "UPDATE_FAIL",
  DELETE_BUDGET_CATEGORY_SUCCESS: "DELETE_BUDGET_CATEGORY_SUCCESS",
  DELETE_BUDGET_CATEGORY_ERROR: "DELETE_BUDGET_CATEGORY_ERROR",
  BUDGET_CATEGORY_ASSOCIATED_FINUMBER_SUCCESS: "BUDGET_CATEGORY_ASSOCIATED_FINUMBER_SUCCESS",
  BUDGET_CATEGORY_ASSOCIATED_FINUMBER_FAILURE: "BUDGET_CATEGORY_ASSOCIATED_FINUMBER_FAILURE",
};

export default budgetCategoryActionType;

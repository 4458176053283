import React, { useEffect } from "react";
import UserService from "../../utils/keycloak.service.js";
import LoadingIcon from "../custom/inputs/ERPSLoadingIcon.js";
import { Box } from "@material-ui/core";
export default function LogoutPage(props) {
  useEffect(() => {
    const hasCodeRun = localStorage.getItem("codeHasRun");
    async function initKc() {
      if (hasCodeRun) {
        await UserService.initKeycloak(props);
        localStorage.clear();
        UserService.doLogin();
      }
    }
    initKc();
  }, []);
  return (
    <Box textAlign={"center"} mt={30}>
      <LoadingIcon />
    </Box>
  );
}

import billerProfileTypes from "../actions/types/billerProfile.action.type";

export const billerProfileInitialState = {
  billerProfileData: null,
  billerProfileError: null,
  billerProfileUpdatedData: null,
  billerProfileUpdateError: null,
  billerProfileListData: null,
  billerProfileListError: null,
  billerProfileAssociatedData: null,
  billerProfileAssociatedError: null,
  newBillerProfileError: null,
  newBillerProfileData: null,
  billerProfileAssociatedUpdateData: null,
  billerProfileAssociatedUpdateError: null,
  customRoutineListData: null,
  customRoutineListError: null,
  billerProfileImageData: null,
  billerProfileImageError: null,
};

export default function billerProfileReducer(
  state = billerProfileInitialState,
  action
) {
  switch (action.type) {
    case billerProfileTypes.GET_BILLER_PROFILE_SUCCESS:
      return { billerProfileData: action.data, billerProfileError: null };
    case billerProfileTypes.GET_BILLER_PROFILE_ERROR:
      return { billerProfileData: null, billerProfileError: action.error };
    case billerProfileTypes.BILLER_PROFILE_UPDATE_SUCCESS:
      return {
        billerProfileUpdatedData: action.data,
        billerProfileUpdateError: null,
      };
    case billerProfileTypes.GET_BILLER_PROFILE_ERROR:
      return {
        billerProfileUpdatedData: null,
        billerProfileUpdateError: action.error,
      };
    case billerProfileTypes.GET_BILLER_PROFILE_LIST_SUCCESS:
      return {
        billerProfileListData: action.data,
        billerProfileListError: null,
      };
    case billerProfileTypes.GET_CUSTOM_ROUTINE_LIST_SUCCESS:
      return {
        ...state,
        customRoutineListData: action.data,
        customRoutineListError: null,
      };

    case billerProfileTypes.GET_CUSTOM_ROUTINE_LIST_ERROR:
      return {
        ...state,
        customRoutineListError: action.data,
        customRoutineListData: null,
      };
    case billerProfileTypes.GET_BILLER_PROFILE_LIST_ERROR:
      return {
        billerProfileListData: null,
        billerProfileListError: action.error,
      };
    case billerProfileTypes.GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_SUCCESS:
      return {
        billerProfileAssociatedData: action.data,
        billerProfileAssociatedError: null,
        ...(action?.billerProfileAssociatedUpdateData && {
          billerProfileAssociatedUpdateData:
            action?.billerProfileAssociatedUpdateData,
        }),
      };
    case billerProfileTypes.GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_ERROR:
      return {
        billerProfileAssociatedData: null,
        billerProfileAssociatedError: action.error,
      };
    case billerProfileTypes.BILLER_PROFILE_CREATE_SUCCESS:
      return { newBillerProfileData: action.data, newBillerProfileError: null };
    case billerProfileTypes.BILLER_PROFILE_CREATE_ERROR:
      return {
        newBillerProfileData: null,
        newBillerProfileError: action.error,
      };
    case billerProfileTypes.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        billerProfileAssociatedUpdateData: action.data,
        billerProfileAssociatedUpdateError: null,
      };
    case billerProfileTypes.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_ERROR:
      return {
        ...state,
        billerProfileAssociatedUpdateData: null,
        billerProfileAssociatedUpdateError: action.error,
      };
    case billerProfileTypes.BILLER_PROFILE_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        billerProfileImageData: action.data,
        billerProfileImageError: null,
      };
    case billerProfileTypes.BILLER_PROFILE_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        billerProfileImageData: null,
        billerProfileImageError: action.error,
      };
    default:
      return state;
  }
}

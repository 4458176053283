const userPendingApprovalActionTypes = {
  GET_PENDING_USER_APPROVAL_SUCCESS: "GET_PENDING_USER_APPROVAL_SUCCESS",
  GET_PENDING_USER_APPROVAL_FAILURE: "GET_PENDING_USER_APPROVAL_FAILURE",
  UPDATE_USER_APPROVAL_SUCCESS: "UPDATE_USER_APPROVAL_SUCCESS",
  UPDATE_USER_APPROVAL_FAILURE: "UPDATE_USER_APPROVAL_FAILURE",
  CREATE_USER_APPROVAL_SUCCESS: "CREATE_USER_APPROVAL_SUCCESS",
  CREATE_USER_APPROVAL_FAILURE: "CREATE_USER_APPROVAL_FAILURE",
  DELETE_USER_APPROVAL_SUCCESS: "DELETE_USER_APPROVAL_SUCCESS",
  DELETE_USER_APPROVAL_FAILURE: "DELETE_USER_APPROVAL_FAILURE",
  DECLINE_USER_APPROVAL_SUCCESS: "DECLINE_USER_APPROVAL_SUCCESS",
  DECLINE_USER_APPROVAL_FAILURE: "DECLINE_USER_APPROVAL_FAILURE",
};

export default userPendingApprovalActionTypes;

import React, { useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { getPricePlanPendingApprovals } from "../../constants/PricePlanPendingApprovalConstant";
import { pricePlanPendingApprovalsActions } from "../../actions/pricePlanPendingApprovals.actions";
import { userActions } from "../../actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { sanitizeQueryParams } from "../../utils/helper";

const PricePlanPendingApprovals = (props) => {
  const { t } = useTranslation();
  const {
    location: {
      state: { componentAccess },
    },
  } = props;
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const userDetails = useSelector((state) => state.loginUserReducer?.user);
  const userRoleTypes = useSelector((state) => state.userRoleTypeReducer);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const pricePlanPendingData = useSelector(
    (state) =>
      state.pricePlanQueueReducer?.pricePlanPendingApprovalData?.queueDataList
  );

  const [data, setPricePendingApprovalData] = useState(null);
  const [roleData, setRoleData] = useState(null);
  useEffect(() => {
    dispatch(
      userActions.getUserRoleTypeList(
        userDetails.fiNumber,
        userDetails.role.role_type,
        "manageusercomponent",
        t,
        setRoleData,
        false
      )
    );
  }, []);

  useEffect(() => {
    if (roleData) {
      setInitialLoading(true);
      dispatch(
        pricePlanPendingApprovalsActions.getPricePlanPendingApprovals(
          {
            userId: userDetails.id,
            roleIdList: roleData,
            languageCode: userDetails.languageCode || "E",
            fiNumber: selectedFiNumber || userDetails.fiNumber,
          },
          setInitialLoading,
          t,
          setPricePendingApprovalData
        )
      );
    }
  }, [roleData]);

  const paginatePrice = (queryParams) => {
    const actualCriteria = sanitizeQueryParams({ ...queryParams });
    if (actualCriteria.startOffset < pricePlanPendingData.length) {
      const rowPerPage = actualCriteria.endOffset - actualCriteria.startOffset;
      const pageNumber =
        actualCriteria.endOffset /
          (actualCriteria.endOffset - actualCriteria.startOffset) -
        1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setPricePendingApprovalData(
        pricePlanPendingData.slice(
          pageNumber * rowPerPage,
          (pageNumber + 1) * rowPerPage
        )
      );
    }
  };

  return (
    <div>
      <CommonContainerBox
        marginTop="30px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
        title={t(
          "erps.priceplan.pendingapprovals.page.title",
          "Price Plan Pending approvals"
        )}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h1">
              {t(
                "erps.priceplan.pendingapprovals.page.title",
                "Price Plan Pending approvals"
              )}
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "erps.priceplan.pendingapprovals.page.description",
                  "Click on a price plan ID, in the table, to view details for the pending approval and either accept or decline the change"
                )}
              </Typography>
            </Grid>
            <Box mt={3} marginBottom={1}>
              <Typography variant="body1">
                {pricePlanPendingData && !initialLoading
                  ? pricePlanPendingData.length
                  : 0}
                {t(
                  "erps.priceplan.pendingapprovals.heading",
                  " pending approvals"
                )}
              </Typography>
            </Box>

            <>
              {data && pricePlanPendingData && !initialLoading ? (
                <>
                  {pricePlanPendingData.length <= 100  && data.length <= 100 ? (
                    <ERPSGrid
                      data={{
                        rows: data ? data : [],
                        count: data ? data.length : "0",
                        columns: getPricePlanPendingApprovals(
                          userDetails.userId,
                          componentAccess
                        ),
                      }}
                      dataGridWidth="dataGrid__box__container__pendingApproval__pricePlan"
                      hideFooterPagination={true}
                      initialLoading={!(data && data?.length) && initialLoading}
                      tableLabelText={t(
                        "erps.priceplan.pendingapprovals.tableText.heading"
                      )}
                    />
                  ) : (
                    <ERPSGrid
                      data={{
                        rows: data ? data : [],
                        count: pricePlanPendingData ? pricePlanPendingData.length : "0",
                        columns: getPricePlanPendingApprovals(
                          userDetails.userId,
                          componentAccess
                        ),
                      }}
                      dataGridWidth="dataGrid__box__container__pendingApproval__pricePlan"
                      initialLoading={!(data && data?.length) && initialLoading}
                      pageNumber={pageNumber}
                      rowPerPage={rowPerPage}
                      showPaginationBottom={true}
                      onAnyPaginationAction={paginatePrice}
                      tableLabelText={t(
                        "erps.priceplan.pendingapprovals.tableText.heading"
                      )}
                    />
                  )}
                </>
              ) : (
                <Box ml={40} mt={10} sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </>
          </Box>
        </Grid>
      </CommonContainerBox>
    </div>
  );
};
export default withSidebar(PricePlanPendingApprovals);

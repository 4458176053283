import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import { withSidebar } from "../hoc/withSidebar";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { useTranslation } from "react-i18next";
import { viewRelatedBillerDocuments } from "../../constants/AvailableDocumentsConstant";

import AvailableDocuments from "./AvailableDocuments";

import { relatedBillerActions } from "../../actions/relatedBiller.action";
import { useDispatch, useSelector } from "react-redux";

function DownloadDocuments(props) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [childBillerResults, setChildBillerResults] = useState(null);

  const billerProfileValueRes = useSelector(
    (state) => state.billerProfileReducer
  );

  const billerProfileValue =
    billerProfileValueRes?.billerProfileAssociatedData ||
    billerProfileValueRes?.billerProfileData?.data;

  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);

  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const roleId = loginUser?.role?.role_id;

  const fiNumber = selectedFi || loginUser.fiNumber;

  const parentBillerResults = loginUser?.searchBillerFilesDto;

  const childBillerListResponse = useSelector(
    (state) => state.relatedBillerReducer?.childBillerList
  );

  useEffect(() => {
    dispatch(
      relatedBillerActions.getAllChildBiller(
        {
          billerFiNumber:
            loginUser?.billerNonVolatileDto?.biller_fi_number ||
            billerProfileValue?.billerFiNumber,
          fiNumber,
        },
        { ...props },
        t
      )
    );
  }, []);

  useEffect(() => {
    if (childBillerListResponse && childBillerListResponse.length) {
      const updatedChildBillerListResponse = childBillerListResponse.map(
        (data, idx) => ({
          ...data,
          id: idx,
        })
      );
      setChildBillerResults(updatedChildBillerListResponse);
    }
  }, [childBillerListResponse]);

  const getParentChildRelatedBiller = () => {
    return (
      <Box
        ml={3}
        role="main"
        sx={{ minHeight: !childBillerResults?.length ? "60vh" : "" }}
      >
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.downloaddocuments.page.title", "Download documents")}
          </Typography>
        </Box>

        <Box className="manageUsers__wrap__box">
          <Typography variant="body1">
            {t(
              "erps.availabledocuments.page.description",
              "Download reports and invoices"
            )}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h2">{"Parent biller"}</Typography>
        </Box>
        <Stack direction="row" spacing={18} mt={4}>
          <Box>
            <Typography variant="inherit">
              {t("erps.availabledocuments.label.billerId")}
              <Typography variant="body2">
                {fiNumber == 99 && props?.stateId
                  ? props?.stateId
                  : parentBillerResults?.fi_biller_with_biller_id ||
                    `${
                      billerProfileValue?.billerNonVolatileDto
                        ?.biller_fi_number || billerProfileValue?.billerFiNumber
                    }-${billerProfileValue?.billerId}`}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography variant="inherit">
              {t("erps.availabledocuments.label.description")}
              <Typography variant="body2">
                {parentBillerResults?.biller_name ||
                  billerProfileValue?.billerName}
              </Typography>
            </Typography>
          </Box>
          <Box>
            {props?.pageName === "biller-profile" ? (
              <ERPSBaseLink
                onClick={() =>
                  props?.handleOpenViewDocument({
                    billerId:
                      parentBillerResults?.biller_id ||
                      billerProfileValue?.billerId,
                    billerType: "parent",
                  })
                }
                className="link__blue"
                label={t("erps.availabledocuments.label.viewdocuments")}
              />
            ) : (
              <ERPSBaseLink
                to={{
                  pathname: `/downloads-documents/view-documents`,
                  state: {
                    billerId:
                      parentBillerResults?.biller_id ||
                      billerProfileValue?.billerId,
                    billerType: "parent",
                    searchedBillerId:
                      parentBillerResults?.biller_id ||
                      billerProfileValue?.billerId,
                  },
                }}
                className="link__blue"
                label={t("erps.availabledocuments.label.viewdocuments")}
              />
            )}
          </Box>
        </Stack>
        {childBillerResults?.length ? (
          <>
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.availabledocuments.label.relatedbiller.title")}
              </Typography>
            </Box>
            <Box mt={4}>
              <Typography variant="body1">
                {t("erps.availabledocuments.label.relatedbiller.description")}{" "}
              </Typography>
            </Box>

            <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
              <Typography variant="body1">
                {childBillerResults?.length}{" "}
                {t("erps.availabledocuments.label.childebillers")}
              </Typography>
            </Box>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: childBillerResults,
                  count: childBillerResults?.length,
                  columns: viewRelatedBillerDocuments(props),
                }}
                dataGridWidth="dataGrid__box__container_downloadDocuments"
                initialLoading={childBillerResults?.length ? false : true}
                tableLableText={t(
                  "erps.availabledocuments.label.childebillers"
                )}
              />
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const getAvailableDocument = () => {
    return <AvailableDocuments {...props} pageName="download-document" />;
  };

  return (
    <div>
      {childBillerResults
        ? getParentChildRelatedBiller()
        : getAvailableDocument()}
    </div>
  );
}

export default withSidebar(DownloadDocuments);

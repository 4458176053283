const billerPendingApprovalActionTypes = {
  GET_PENDING_BILLER_APPROVAL_SUCCESS: "GET_PENDING_BILLER_APPROVAL_SUCCESS",
  GET_PENDING_BILLER_APPROVAL_FAILURE: "GET_PENDING_BILLER_APPROVAL_FAILURE",

  GET_BILLER_USER_APPROVAL_SUCCESS: "GET_BILLER_USER_APPROVAL_SUCCESS",
  GET_BILLER_USER_APPROVAL_FAILURE: "GET_BILLER_USER_APPROVAL_FAILURE",

  DECLINE_BILLER_USER_APPROVAL_SUCCESS: "DECLINE_BILLER_USER_APPROVAL_SUCCESS",
  DECLINE_BILLER_USER_APPROVAL_FAILURE: "DECLINE_BILLER_USER_APPROVAL_FAILURE",

  UPDATE_BILLER_USER_APPROVAL_SUCCESS: "UPDATE_BILLER_USER_APPROVAL_SUCCESS",
  UPDATE_BILLER_USER_APPROVAL_FAILURE: "UPDATE_BILLER_USER_APPROVAL_FAILURE",

  CREATE_BILLER_USER_APPROVAL_SUCCESS: "CREATE_BILLER_USER_APPROVAL_SUCCESS",
  CREATE_BILLER_USER_APPROVAL_FAILURE: "CREATE_BILLER_USER_APPROVAL_FAILURE",
};

export default billerPendingApprovalActionTypes;

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ERPSGrid from "../custom/containers/ERPSGrid";
import {
  BILLER_PROFILE_DETAILS,
  BILLER_IMAGE_URL,
} from "../../constants/BillerManagementConstant";
import { getFinancialInstitutionHeadings } from "../../constants/FinancialInstitutionConstant";
import i18next from "i18next";
import {
  getNextFilterId,
  convertDateStringIntoERPSDateFormat,
  getLabelName,
} from "../../utils/helper";
import CircularProgress from "@mui/material/CircularProgress";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";

const UpdateBillerApproval = (props) => {
  const { t } = useTranslation();
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const fiNum = selectedFi || loginUser.fiNumber;

  const errorMessage = useSelector(
    (state) => state.billerApprovalReducer?.billerActionError
  );

  const {
    billerData: { data, updatedData },
    componentAccess,
    billerPendingData,
    userId: loggedInUser,
  } = props;
  const dispatch = useDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const errorRef = useRef();

  const [financialInsititutionData, setFinancialInstitutionTableList] =
    useState([]);

  const userId = useSelector((state) => state.loginUserReducer?.user?.userId);
  useEffect(() => {
    if (data?.billerProfileExtensions) {
      const billerFiUpdatedData = updatedData?.billerProfileExtensions.map(
        (prevData, idx) => {
          const matchedData = data?.billerProfileExtensions?.find(
            (updatedData) => updatedData?.fiNumber == prevData?.fiNumber
          );
          if (
            matchedData &&
            prevData?.billerStatus !== matchedData?.billerStatus
          ) {
            return {
              ...prevData,
              id: idx,
              ...(prevData?.billerStatus !== matchedData?.billerStatus && {
                updatedStatus: matchedData?.billerStatus,
              }),
            };
          }
          // return { ...prevData, id: idx };
        }
      );
      setFinancialInstitutionTableList(billerFiUpdatedData.filter((e) => e));
    }
  }, [data?.billerProfileExtensions]);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const bankDetailKeyLabel = {
    creditorFiNumber: t(
      "erps.billermanagement.label.billerFinancialInstitution",
      "Biller financial institution"
    ),
    creditorTransitNumber: t(
      "erps.billermanagement.label.transitNumber",
      "Transit number"
    ),
    creditorAccountNumber: t(
      "erps.billermanagement.label.accountNumber",
      "Account number"
    ),
  };

  const getAccontSettingData = ({ data, updatedData, allowedKeys }) => {
    let obj = [];
    for (let key in data) {
      if (allowedKeys.includes(key)) {
        const updatedKeyValue = bankDetailKeyLabel[key];
        if (data[key] !== updatedData?.[key]) {
          obj.push({
            id: key,
            attribute: updatedKeyValue,
            currentValue: updatedData?.[key],
            updatedValue: data?.[key],
          });
        }
        // else {
        //   obj.push({
        //     id: key,
        //     attribute: updatedKeyValue,
        //     currentValue: updatedData?.[key],
        //     updatedValue: "",
        //   });
        // }
      }
    }
    return obj;
  };

  const getImageUrlData = () => {
    let obj = [];
    if (
      data?.billerMasterRules?.masterRules?.image_url !=
      updatedData?.billerMasterRules?.masterRules?.image_url
    ) {
      obj.push({
        id: 1,
        currentValue: updatedData?.billerMasterRules?.masterRules?.image_url,
        updatedValue: data?.billerMasterRules?.masterRules?.image_url,
      });
    }
    return obj;
  };

  const navigateToUserPendingApporvalPage = () => {
    props.history.push("/biller-pending-approvals", { componentAccess });
  };

  const hasLastActiveRecord = () => {
    if (billerPendingData && billerPendingData.length === 1) {
      return true;
    }
    return false;
  };

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();
    const { id, fiNumber, billerId } = data;
    const declineUserApproval = {
      transactionId: id,
      userId,
      fiNumber: fiNum,
      billerId,
      languageCode: i18next.language === "en" ? "E" : "F",
    };
    const filteredQueueDataList = billerPendingData.filter(
      ({ id: billerId }) => billerId != id
    );

    const currentIndexVal = billerPendingData.findIndex(
      ({ id: billerId }) => billerId == id
    );
    const slicedArr = billerPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "id"
    );
    const hasLastRecord = hasLastActiveRecord();
    if (props?.setShowMessage) {
      props?.setShowMessage(false);
    }

    dispatch(
      actions.billerPendingApprovalsActions.declineBillerApprovalData(
        declineUserApproval,
        {
          ...props,
          setLoading: setDeclineLoading,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === ""
              ? billerPendingData
              : filteredQueueDataList,
          componentAccess,
          setLastRecordDisable,
          hasLastRecord,
        }
      )
    );
  };

  const handleSubmit = (event) => {
    setUpdateLoading(true);
    if (event) event.preventDefault();
    // let userUpdatedFields = getAccontSettingData();
    // const updateApprovalRequest = userUpdatedFields.reduce((prev, curr) => {
    //   if (curr.updatedValue) {
    //     return { ...prev, [curr.id]: curr.updatedValue };
    //   } else {
    //     return { ...prev, [curr.id]: curr.currentValue };
    //   }
    // }, {});
    const { id, fiNumber, billerId } = data;

    let updateBillerApproval = {
      transactionId: id,
      userId,
      fiNumber: fiNum,
      billerId,
      languageCode: i18next.language === "en" ? "E" : "F",
      actionCode: "U",
    };
    const filteredQueueDataList = billerPendingData.filter(
      ({ id: billerId }) => billerId != id
    );

    const currentIndexVal = billerPendingData.findIndex(
      ({ id: billerId }) => billerId == id
    );
    const slicedArr = billerPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "id"
    );
    const hasLastRecord = hasLastActiveRecord();
    if (props?.setShowMessage) {
      props?.setShowMessage(false);
    }

    dispatch(
      actions.billerPendingApprovalsActions.updateBillerApprovalData(
        updateBillerApproval,
        {
          ...props,
          setLoading: setUpdateLoading,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === ""
              ? billerPendingData
              : filteredQueueDataList,
          componentAccess,
          setLastRecordDisable,
          hasLastRecord,
        }
      )
    );
  };

  const billerPendingApprovalChildAccess = componentAccess.find(
    ({ componentType }) => componentType === "BPA"
  );
  const billerApproverComponentAccess =
    billerPendingApprovalChildAccess?.childComponents;

  const contactInfo = [
    {
      id: 0,
      attribute: "Primary contact",
      value: data?.primaryContactName,
    },
    {
      id: 1,
      attribute: "Primary fax number",
      value: data?.primaryContactFax,
    },
    {
      id: 2,
      attribute: "Secondary telephone number and extension",
      value:
        data?.secondaryContactExtension + ":" + data?.secondaryContactPhone,
    },
  ];

  return (
    <>
      {errorMessage && (
        <Box className="manageUsers__errorMsg__container" role="alert" mt="4">
          <ErrorMessage error={errorMessage} errorRef={errorRef} />
        </Box>
      )}
      <Grid container>
        <Grid item lg={8} sm={12}>
          <Box className="manageUsers__page__title">
            <Typography variant="h1">
              {t("erps.billermanagement.pendingBiller.heading", {
                transactionId: data.id,
                billerId: data.billerId,
              })}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t("erps.updatebillerapproval.heading.level2.approvaldetails")}
            </Typography>
          </Box>
          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="available__documents__value">
              {t("erps.updatebillerapproval.label.updatedby")}
              <Typography variant="body2">{data?.displayUpdatedBy}</Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t("erps.updatebillerapproval.label.updatedon")}
              <Typography variant="body2">
                {convertDateStringIntoERPSDateFormat(data.updatedDate)}
              </Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t("erps.updatebillerapproval.label.action")}
              <Typography variant="body2">{"Update"}</Typography>
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t("erps.updatebillerapproval.heading.level2.userprofile")}
            </Typography>
            <Box>
              <p>{t("erps.updatebillerapproval.userprofile.description")}</p>
            </Box>
          </Box>
          {/* Bank details */}
          {getAccontSettingData({
            data,
            allowedKeys: [
              "creditorFiNumber",
              "creditorTransitNumber",
              "creditorAccountNumber",
            ],
            updatedData,
          })?.length ? (
            <>
              <Box mt={4}>
                <Typography variant="h2" id="bankAccount">
                  {t("erps.billermanagement.label.bankAccount", "Bank account")}
                </Typography>
              </Box>
              <Box mt={2}>
                <ERPSGrid
                  data={{
                    rows:
                      getAccontSettingData({
                        data,
                        allowedKeys: [
                          "creditorFiNumber",
                          "creditorTransitNumber",
                          "creditorAccountNumber",
                        ],
                        updatedData,
                      }) || [],
                    count: contactInfo.length || "0",
                    columns: BILLER_PROFILE_DETAILS,
                  }}
                  dataGridWidth="dataGrid__box__container__billerProfileData_update"
                  hideFooterPagination={true}
                  getCellClassName={(params) => {
                    if (params.field === "currentValue") {
                      return "column_separator_border-right";
                    }
                  }}
                  tableLabelText={t(
                    "erps.billermanagement.label.bankAccount",
                    "Bank account"
                  )}
                />
              </Box>
            </>
          ) : (
            ""
          )}
          {/* Financial institution table */}
          {financialInsititutionData?.length ? (
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.updatebillerapproval.heading.financialInstitution")}
              </Typography>
              <Box>
                <p>
                  {t(
                    "erps.updatebillerapproval.heading.financialInstitution.description"
                  )}
                </p>
              </Box>
              <ERPSGrid
                data={{
                  rows: financialInsititutionData,
                  count: financialInsititutionData.length,
                  columns: getFinancialInstitutionHeadings(
                    false,
                    "updateApprovalPage"
                  ),
                }}
                dataGridWidth="dataGrid__box__container__billerProfileData_update"
                hideFooterPagination={true}
                getCellClassName={(params) => {
                  if (
                    params.field === "fiNumber" ||
                    params.field === "fiName" ||
                    params.field === "updatedStatusCurrent"
                  ) {
                    return "column_separator_border-right-only";
                  }
                }}
                tableLabelText={t(
                  "erps.updatebillerapproval.heading.financialInstitution.description"
                )}
              />
            </Box>
          ) : (
            ""
          )}
          {/* English Image url */}
          {getImageUrlData().length ? (
            <Box mt={4}>
              <Typography variant="h4">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl"
                )}
              </Typography>
              <Box class="image__url__table">
                <ERPSGrid
                  data={{
                    rows: getImageUrlData() || [],
                    count: getImageUrlData().length || "0",
                    columns: BILLER_IMAGE_URL,
                  }}
                  dataGridWidth="dataGrid__box__container__billerProfileData_update"
                  hideFooterPagination={true}
                  autoRowHeight={true}
                  getCellClassName={(params) => {
                    if (params.field === "currentValue") {
                      return "column_separator_border-right-only";
                    }
                  }}
                  tableLabelText={t(
                    "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl"
                  )}
                />
              </Box>
            </Box>
          ) : (
            ""
          )}

          {fiNum == "10" && (
            <>
              {" "}
              <Box className="manageUsers__page__title">
                {
                  <>
                    {" "}
                    <Box className="manageUsers__page__title">
                      <Typography variant="h2">
                        {t(
                          "erps.billermanagement.label.lineofbusiness",
                          "Line of business settings"
                        )}
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography variant="h4">
                        {t("erps.billermanagement.label.image", "Image")}
                      </Typography>
                    </Box>
                    <Box style={{ width: "500px" }}>
                      <Box
                        mt={3}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography>
                            {t("erps.billermanagement.label.cibc", "CIBC:")}
                          </Typography>
                          <br />
                          <Box mt={-3}>
                            <Box>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingIVR",
                                  "Telephone banking IVR"
                                )}
                                id="imageCibcTelephoneBankingIVR"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgCIBCTelephoneBankingIVRChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcBranchBanking",
                                  "Branch banking"
                                )}
                                id="imageCibcBranchBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgCIBCBranchBankingChecked === "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingDesk",
                                  "Telephone banking (Desktop)"
                                )}
                                id="imageCibcTelephoneBankingDesktop"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgCIBCTelePhoneBankingDeskChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcOnlineBanking",
                                  "Online banking"
                                )}
                                id="imageCibcOnlineBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgCIBCOnlineBankingChecked === "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography>
                            {t(
                              "erps.billermanagement.label.pcfinancial",
                              "PC Financial:"
                            )}
                          </Typography>
                          <br />
                          <Box mt={-3}>
                            <Box>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingIVR",
                                  "Telephone banking IVR"
                                )}
                                id="imagePcFinanceTelephoneBankingIVR"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgPCFinancialTelephoneBankingIVRChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>

                            <Box mt={3}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingDesk",
                                  "Telephone banking (Desktop)"
                                )}
                                id="imagePcFinanceTelephoneBankingDesktop"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgPCFinancialTelePhoneBankingDeskChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcOnlineBanking",
                                  "Online banking"
                                )}
                                id="imagePcFinanceOnlineBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isImgPCFinancialOnlineBankingChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box mt={3}>
                      <Typography variant="h4">
                        {t(
                          "erps.billermanagement.label.instructional",
                          "Instructional"
                        )}
                      </Typography>
                    </Box>
                    <Box style={{ width: "500px" }}>
                      <Box
                        mt={3}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography>
                            {t("erps.billermanagement.label.cibc", "CIBC:")}
                          </Typography>
                          <br />
                          <Box mt={-3}>
                            <Box>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingIVR",
                                  "Telephone banking IVR"
                                )}
                                id="instructionalCibcTelephoneBankingIVR"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstCIBCTelephoneBankingIVRChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcBranchBanking",
                                  "Branch banking"
                                )}
                                id="instructionalCibcBranchBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstCIBCBranchBankingChecked === "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingDesk",
                                  "Telephone banking (Desktop)"
                                )}
                                id="instructionalCibcTelephoneBankingDesktop"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstCIBCTelePhoneBankingDeskChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcOnlineBanking",
                                  "Online banking"
                                )}
                                id="instructionalCibcOnlineBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstCIBCOnlineBankingChecked === "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography>
                            {t(
                              "erps.billermanagement.label.pcfinancial",
                              "PC Financial:"
                            )}
                          </Typography>
                          <br />
                          <Box mt={-3}>
                            <Box>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingIVR",
                                  "Telephone banking IVR"
                                )}
                                id="instructionalPcFinanceTelephoneBankingIVR"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstPCFinancialTelephoneBankingIVRChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>

                            <Box mt={3}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcTelphoneBankingDesk",
                                  "Telephone banking (Desktop)"
                                )}
                                id="instructionalPcFinanceTelephoneBankingDesktop"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstPCFinancialTelePhoneBankingDeskChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                            <Box mt={-1}>
                              <ERPSBaseCheckBoxGrp
                                label={t(
                                  "erps.billermanagement.label.cibcOnlineBanking",
                                  "Online banking"
                                )}
                                id="instructionalPcFinanceOnlineBanking"
                                value={
                                  data?.lineOfbusinessData
                                    ?.isInstPCFinancialOnlineBankingChecked ===
                                  "Y"
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />{" "}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                }
              </Box>
            </>
          )}

          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={() => navigateToUserPendingApporvalPage()}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingCancelAccess"}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="secondary"
              onClick={(e) => handleDecline(e)}
              loading={declineLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "decline",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingDeclineAccess"}
              isDisabled={lastRecordDisable}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="primary"
              onClick={(e) => handleSubmit(e)}
              loading={updateLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "approve",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingApprovalAccess"}
              isDisabled={loggedInUser == data?.updatedBy || lastRecordDisable}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateBillerApproval;

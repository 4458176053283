export const fiActionTypes = {
  GET_FINANCIAL_INSTITUTION_LIST: "GET_FINANCIAL_INSTITUTION_LIST",
  GET_FINANCIAL_INSTITUTION_LIST_ERROR: "GET_FINANCIAL_INSTITUTION_LIST_ERROR",
  GET_FINANCIAL_INSTITUTION: "GET_FINANCIAL_INSTITUTION",
  FI_HELPDESK_NUMBER_SUCCESS: "FI_HELPDESK_NUMBER_SUCCESS",
  FI_HELPDESK_NUMBER_ERROR: "FI_HELPDESK_NUMBER_ERROR",
  GET_MASTER_FI_DESCRIPTION_LIST: "GET_MASTER_FI_DESCRIPTION_LIST",
  GET_MASTER_FI_DESCRIPTION_LIST_ERROR: "GET_MASTER_FI_DESCRIPTION_LIST_ERROR",
  GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_SUCCESS: "GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_SUCCESS",
  GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_ERROR: "GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_ERROR",
};

import axios from "axios";
import store from "../store/index";
import { API_BASE_URL, getExceptionMessage } from "../utils/helper";
import marketingManagementTypes from "../actions/types/marketingManagement.action.type";

export const marketManagementSevice = {
  getDashboardMessage,
  getDashboardQueueMessage,
  createdDashboardMessage,
  dashboardPendingApprovalMessage,
  dashboardPendingDeclineMessage,
};

function getDashboardMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() + `/dashboard-message/get-messages?fiNumber=` + fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })

      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.GET_DASHBOARD_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.GET_DASHBOARD_MESSAGE_FAILURE,
      error,
    };
  }
}
function getDashboardQueueMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          `/dashboard-message/queue-messages?fiNumber=` +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })

      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.GET_DASHBOARD_QUEUE_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.GET_DASHBOARD_QUEUE_MESSAGE_FAILURE,
      error,
    };
  }
}
function createdDashboardMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    const {
      setLoading,
      setSuccessMessage,
      t,
      setShowExceptionMessage,
      ...rest
    } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message/save-to-queue",
        {
          ...rest,
          fiNumber,
          actionCode: "A",
          languageCode,
          loggedInUserId: loginUser?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.CREATE_DASHBOARD_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.CREATE_DASHBOARD_MESSAGE_FAILURE,
      error,
    };
  }
}

function dashboardPendingApprovalMessage(data) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    const {
      setLoading,
      setSuccessMessage,
      t,
      setShowExceptionMessage,
      ...rest
    } = data;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message/approve-pending-message",
        {
          ...rest,
          fiNumber,
          languageCode,
          loggedInUserId: loginUser?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_FAILURE,
      error,
    };
  }
}

function dashboardPendingDeclineMessage(data) {
  return (dispatch) => {
    const { setLoading, setSuccessMessage, setShowExceptionMessage, t } = data;
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = loginUser.user?.languageCode;
    axios
      .post(
        API_BASE_URL() + "/dashboard-message/decline-pending-message",
        { fiNumber, languageCode, loggedInUserId: loginUser?.user?.userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setSuccessMessage) {
          setSuccessMessage(false);
        }
        if (setShowExceptionMessage) {
          setShowExceptionMessage(true);
        }
        const errMessage =
          getExceptionMessage(error, t) ||
          JSON.parse(JSON.stringify(error)).message;
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_FAILURE,
      error,
    };
  }
}

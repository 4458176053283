import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { passwordActions } from "../../actions/password.actions";
import warningIcon from "../../assets/images/warning-icon.png";
import ErrorMessage from "../custom/containers/ErrorMessage";
import passwordActionTypes from "../../actions/types/password.action.type";
import Footer from "../Footer/Footer";

const ForgotPassword = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const errorRef = useRef();
  const apiExceptionRef = useRef();

  const forgetPasswordResponse = useSelector(
    (state) => state.forgetPasswordReducer?.data
  );

  const apiExceptionMessage = useSelector(
    (state) => state.forgetPasswordReducer?.error
  );

  const [values, setValues] = useState({
    emailId: "",
    userId: "",
    hasEmailError: false,
    hasUserId: false,
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState([]);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage, forgetPasswordResponse?.errorMessage]);

  useEffect(() => {
    if (forgetPasswordResponse) {
      dispatch({
        type: passwordActionTypes.FORGOT_PASSWORD_SUCCESS,
        data: null,
      });
    }
  }, []);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [apiExceptionMessage && showApiExceptionMessage]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const backButtonClickHandler = () => {
    props.history.push("/");
  };

  const handleClick = () => {
    let errormsg = [];
    const inputErrors = { hasUserIdError: false, hasEmailError: false };
    const { history } = props;
    setLoading(true);
    setShowApiExceptionMessage(false);

    var alphaNumericRegEx = /^[0-9A-Za-z]+$/;

    if (!values.userId || values.userId.trim() == "") {
      errormsg.push(
        t("erps.manageusers.blank.userid.msg", "UserId cannot be blank.")
      );
      inputErrors.hasUserIdError = true;
    } else if (values.userId && values.userId.trim().length < 6) {
      errormsg.push(
        t(
          "erps.manageusers.length.userid.msg",
          "UserId must be greater than 6 characters"
        )
      );
      inputErrors.hasUserIdError = true;
    } else if (
      values.userId &&
      values.userId.match(alphaNumericRegEx) === null
    ) {
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.userid.msg",
          "UserId must be alpha numeric."
        )
      );
      inputErrors.hasUserIdError = true;
    }

    if (!values.emailId || values.emailId.trim() == "") {
      errormsg.push(
        t(
          "erps.manageusers.blank.emailaddress.msg",
          "Email address cannot be blank."
        )
      );
      inputErrors.hasEmailError = true;
    } else if (values.emailId && values.emailId.length > 60) {
      errormsg.push(
        t(
          "erps.manageusers.length.emailaddress.msg",
          "Email address length should not be greater than 60 characters."
        )
      );
      inputErrors.hasEmailError = true;
    } else if (values.emailId && !validateEmail(values.emailId)) {
      errormsg.push(
        t(
          "erps.manageusers.format.emailaddress.msg",
          "Please enter your e-mail address in this format 'name@example.com'"
        )
      );
      inputErrors.hasEmailError = true;
    }
    if (Object.keys(inputErrors) && Object.keys(inputErrors).length) {
    }
    setValues((prevState) => ({ ...prevState, ...inputErrors }));

    setError(errormsg);

    if (errormsg.length == 0) {
      const req = {
        ...values,
        language: i18n.language,
        fiNumber: localStorage.getItem("fiNumber"),
        loggedInUserId: values?.userId,
        changePasswordIndex: "Y",
      };
      dispatch(
        passwordActions.generatedAutoPasswordAndSendEmailForForgetPassword({
          ...req,
          setLoading,
          history,
          t,
          setShowApiExceptionMessage,
        })
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Header {...props} />
      <CommonContainerBox
        title={t("erps.forgotpassword.formtitle.message", "Forgot password")}
        marginTop="150px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
      >
        <Grid item lg={8} sm={12} role="main">
          <Box role="form">
            <Typography variant="h1">
              {t("erps.forgotpassword.formtitle.message", "Forgot password")}
            </Typography>

            <Box className="forgotpassword__paragraph">
              <p>
                {t(
                  "erps.login.forgotpassword.message",
                  "To reset your password, enter your User ID and email address associated with your account, then click 'Reset password'. An email will be sent with a link to allow you to reset your password."
                )}
              </p>
            </Box>
            {errorMessage.length || forgetPasswordResponse?.errorMessage ? (
              <Box
                className={"manageUsers__errorMsg__container"}
                role="alert"
                mt={2}
              >
                {errorMessage.length || forgetPasswordResponse?.errorMessage ? (
                  <ErrorMessage
                    error={
                      errorMessage.length > 0
                        ? errorMessage
                        : forgetPasswordResponse?.errorMessage
                    }
                    errorRef={errorRef}
                  />
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
            {apiExceptionMessage && showApiExceptionMessage && (
              <Box
                className="manageUsers__errorMsg__container"
                role="alert"
                mt="2"
              >
                <ErrorMessage
                  error={apiExceptionMessage}
                  errorRef={apiExceptionRef}
                />
              </Box>
            )}
            <Box>
              <ERPSBaseTextField
                id="outlined-userID-adornment"
                required="true"
                type="text"
                value={values.userId}
                className="three_column__textField"
                size="small"
                label={t(
                  "erps.forgotpassword.formcontrol.userid.label",
                  "User ID"
                )}
                onChange={handleChange("userId")}
                error={values?.hasUserIdError}
              />
            </Box>
            <Box>
              <ERPSBaseTextField
                id="outlined-email-adornment"
                required="true"
                type="email"
                value={values.emailId}
                className="three_column__textField"
                size="small"
                label={t(
                  "erps.forgotpassword.formcontrol.email.label",
                  "Email address"
                )}
                onChange={handleChange("emailId")}
                error={values?.hasEmailError}
              />
            </Box>

            <Box display="flex">
              <ERPSBaseButton
                variant="secondary"
                className="button_with_margin48"
                onClick={backButtonClickHandler}
                label={t("erps.forgotpassword.backbutton.text", "Back")}
              />

              <Box pl={2}>
                <ERPSBaseButton
                  variant="primary"
                  className="button_with_margin48"
                  label={t(
                    "erps.forgotpassword.resetbutton.text",
                    "Reset password"
                  )}
                  loading={loading}
                  onClick={handleClick}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </CommonContainerBox>
      <Footer />
    </>
  );
};

export default ForgotPassword;

import faxManagementActionTypes from "../actions/types/faxManagement.action.type";

export const faxManagementInitialState = {
  faxSearchList: null,
  error: null,
  success: null,
  failedFaxList: null,
  failedFaxError: null,
  viewFaxDetail: null,
  viewFaxDetailError: null,
  resendFax: null,
  resendFaxError: null,
};

export default function faxManagementReducer(
  state = faxManagementInitialState,
  action
) {
  switch (action.type) {
    case faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_SUCCESS:
      return {
        faxSearchList: action.data,
        error: null,
        failedFaxList: null,
        failedFaxListError: null,
        ...(action?.hasRefreshGrid && { resendFax: state?.resendFax }),
      };
    case faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_FAILURE:
      return {
        faxSearchList: null,
        error: action.error,
        failedFaxList: null,
        failedFaxListError: null,
      };
    case faxManagementActionTypes.GET_FAILED_FAX_LISTS_SUCCESS:
      return {
        faxSearchList: null,
        error: null,
        failedFaxList: action.data,
        failedFaxError: null,
        ...(action?.hasRefreshGrid && { resendFax: state?.resendFax }),
      };
    case faxManagementActionTypes.GET_FAILED_FAX_LISTS_FAILURE:
      return {
        faxSearchList: null,
        error: null,
        failedFaxList: null,
        failedFaxError: action.error,
      };
    case faxManagementActionTypes.GET_VIEW_FAX_DETAIL_SUCCESS:
      return {
        ...state,
        failedFaxList: null,
        failedFaxError: null,
        viewFaxDetail: action.data,
        viewFaxDetailError: null,
      };
    case faxManagementActionTypes.GET_VIEW_FAX_DETAIL_FAILURE:
      return {
        ...state,
        failedFaxList: null,
        failedFaxError: null,
        viewFaxDetail: null,
        viewFaxDetailError: action.error,
      };
    case faxManagementActionTypes.RESEND_FAX_SUCCESS:
      return {
        ...state,
        resendFax: action.data,
        resendFaxError: null,
      };
    case faxManagementActionTypes.RESEND_FAX_FAILURE:
      return {
        ...state,
        resendFax: null,
        resendFaxError: action.error,
      };
    case faxManagementActionTypes.RESET_FAX_MANAGEMENT_REQUEST_VALUES:
      return {
        faxSearchList: null,
        error: null,
        success: null,
        failedFaxList: null,
        failedFaxError: null,
        viewFaxDetail: null,
        viewFaxDetailError: null,
      };

    default:
      return state;
  }
}

import services from "../services";

export const billerProfileActions = {
  getBillerProfile,
  updateBillerProfile,
  getBillerProfiles,
  getAllProvince,
  getAllDeliveryMethod,
  getFormatMethod,
  getBillerProfileWithAssociatedObjects,
  updateBillerProfileWithAssociatesChildObjects,
  addBillerProfile,
  getBudgetCategory,
  getCustomRoutineList,
  updateBillerProfileForWebPortalUser,
};

function addBillerProfile(data, props) {
  return services.billerProfileService.addBillerProfile(data, props);
}

function getCustomRoutineList(billerId, fiNumber, props) {
  return services.billerProfileService.getCustomRoutineList(
    billerId,
    fiNumber,
    props
  );
}

function getBillerProfile(billerId, fiNumber, props) {
  return services.billerProfileService.getBillerProfile(
    billerId,
    fiNumber,
    props
  );
}

function updateBillerProfile(userInfo, setterMethods) {
  return services.billerProfileService.updateBillerProfile(
    userInfo,
    setterMethods
  );
}

function getBillerProfiles(
  count,
  billerId,
  billerName,
  pageSize,
  offset,
  translation,
  setLoading
) {
  return services.billerProfileService.getBillerProfiles(
    count,
    billerId,
    billerName,
    pageSize,
    offset,
    translation,
    setLoading
  );
}

function getAllProvince(translation) {
  return services.billerProfileService.getAllProvince(translation);
}

function getBudgetCategory(translation) {
  return services.billerProfileService.getBudgetCategory(translation);
}
function getAllDeliveryMethod(translation, billerId) {
  return services.billerProfileService.getAllDeliveryMethod(
    translation,
    billerId
  );
}

function getFormatMethod(translation, billerId) {
  return services.billerProfileService.getFormatMethod(translation, billerId);
}

function getBillerProfileWithAssociatedObjects(billerId, props, setLoading) {
  return services.billerProfileService.getBillerProfileWithAssociatedObjects(
    billerId,
    props,
    setLoading
  );
}

function updateBillerProfileWithAssociatesChildObjects(
  billerProfile,
  oldBillerProfile,
  props
) {
  return services.billerProfileService.updateBillerProfileWithAssociatesChildObjects(
    billerProfile,
    oldBillerProfile,
    props
  );
}

function updateBillerProfileForWebPortalUser(billerProfile, props) {
  return services.billerProfileService.updateBillerProfileForWebPortalUser(
    billerProfile,
    props
  );
}

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

const DialogHeader = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
      </DialogTitle>
      {onClose ? (
        <Tooltip title={"Close"}>
          <IconButton
            aria-label="close"
            alt="cancel"
            onClick={onClose}
            autoFocus
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

DialogHeader.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default DialogHeader;

import services from "../services";

export const viewStatisticsActions = {
  getViewStatistics,
  setStatisticsBiller,
};

function getViewStatistics(data, setLoading, translation) {
  return services.viewStatisticsService.getViewStatistics(
    data,
    setLoading,
    translation
  );
}

function setStatisticsBiller(billerId, fiNumber, props) {
  return services.viewStatisticsService.setStatisticsBiller(
    billerId,
    fiNumber,
    props
  );
}

export const relatedBillerActionTypes = {
  GET_ALL_CHILD_BILLER_SUCCESS: "GET_ALL_CHILD_BILLER_SUCCESS",
  GET_PARENT_PARENT_SUCCESS: "GET_PARENT_PARENT_SUCCESS",
  UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_SUCCESS:
    "UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_SUCCESS",
  REMOVE_BILLER_RELATIONSHIP_SUCCESS: "REMOVE_BILLER_RELATIONSHIP_SUCCESS",

  GET_ALL_CHILD_BILLER_FAILURE: "GET_ALL_CHILD_BILLER_FAILURE",
  GET_PARENT_PARENT_FAILURE: "GET_PARENT_PARENT_FAILURE",
  UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE:
    "UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE",
  REMOVE_BILLER_RELATIONSHIP_FAILURE: "REMOVE_BILLER_RELATIONSHIP_FAILURE",

  UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE:
    "UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE",
  GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS:
    "GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS",
  GET_RELATED_BILLER_SEARCH_RESPONSE_FAILURE:
    "GET_RELATED_BILLER_SEARCH_RESPONSE_FAILURE",

  REMOVE_BILLER_RELATIONSHIP_FAILURE: "REMOVE_BILLER_RELATIONSHIP_FAILURE",

  ADD_BILLER_RELATIONSHIP_FAILURE: "ADD_BILLER_RELATIONSHIP_FAILURE",

  ADD_BILLER_RELATIONSHIP_SUCCESS: "ADD_BILLER_RELATIONSHIP_SUCCESS",
};

import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { requiredFormatDate, formatAmount } from "../utils/helper";

export const transactionEnquiryHeading = (
  componentAccess,
  fiNumber,
  roleId,
  loggedInUserFi
) => {
  return !bmoWithWebPortal(fiNumber, roleId)
    ? [
        {
          field: "payment_id",
          headerName: t("erps.transactionEnquiry.label.serialNo"),
          width: 70,
          sortable: false,
          renderCell: (params) => (
            <ERPSBaseLink
              to={{
                pathname: `/review-transactions/transaction-details`,
                state: { id: params.row?.payment_id, componentAccess },
              }}
              className="link__blue"
              label={params?.value?.toString()}
            />
          ),
        },
        {
          field: "transaction_amount",
          headerName: t("erps.transactionEnquiry.page.paymentamout"),
          width: 135,
          sortable: false,
          renderCell: (params) => {
            if (params.value) {
              return formatAmount(params.value);
            }
          },
        },

        {
          field: "effective_date_display_format",
          headerName: t("erps.transactionEnquiry.page.effectivedate"),
          width: 110,
          sortable: false,
          renderCell: (params) => {
            return requiredFormatDate(params.value);
          },
        },
        {
          field: "verification_number",
          headerName: t("erps.transactionEnquiry.page.confirmationnumber"),
          width: 160,
          sortable: false,
        },
        {
          field: fiNumber == "99" ? "bmo_biller_id" : "biller_fi_number",
          headerName: t("erps.transactionEnquiry.page.billerid"),
          width: 100,
          sortable: false,
          renderCell: (params) => {
            return params?.row?.display_biller_id;
          },
        },
        {
          field: "biller_customer_number",
          headerName: t("erps.transactionEnquiry.page.accountnumber"),
          width: 240,
          sortable: false,
        },
        {
          field: "fiCustomerNumberStr",
          headerName: t(
            "erps.transactionEnquiry.componentToPrint.customerDetails.label.bankCardNumber"
          ),
          width: 180,
          sortable: false,
          valueParser: (value) => {
            return value;
          },
          renderCell: (params) => {
            return params.value;
          },
        },
      ]
    : [
        {
          field: "payment_id",
          headerName: t("erps.transactionEnquiry.label.serialNo"),
          width: 70,
          sortable: false,
          renderCell: (params) => (
            <ERPSBaseLink
              to={{
                pathname: `/review-transactions/transaction-details`,
                state: { id: params.row?.payment_id, componentAccess },
              }}
              className="link__blue"
              label={params?.value?.toString()}
            />
          ),
        },
        {
          field: "transaction_amount",
          headerName: t("erps.transactionEnquiry.page.paymentamout"),
          width: 140,
          sortable: false,
          renderCell: (params) => {
            if (params.value) {
              return formatAmount(params.value);
            }
          },
        },
        {
          field: "effective_date_display_format",
          headerName: t("erps.transactionEnquiry.page.effectivedate"),
          width: 130,
          sortable: false,
          renderCell: (params) => {
            return requiredFormatDate(params.value);
          },
        },
        {
          field: "verification_number",
          headerName: t("erps.transactionEnquiry.page.confirmationnumber"),
          width: 160,
          sortable: false,
        },
        {
          field: fiNumber == "99" ? "bmo_biller_id" : "biller_id",
          headerName: t("erps.transactionEnquiry.page.billerid"),
          width: 100,
          sortable: false,
          renderCell: (params) => {
            return params?.row?.display_biller_id;
          },
        },
        {
          field: "biller_customer_number",
          headerName: t("erps.transactionEnquiry.page.accountnumber"),
          width: 240,
          sortable: false,
        },
      ];
};

export const bmoWithWebPortal = (fiNumber, roleId) => {
  const webPortalRoleIds = [1, 2, 3, 4];
  return fiNumber == 99 && webPortalRoleIds.includes(roleId);
};

export const EMPTY_SEARCH_CRITERIA = {
  startDate: null,
  endDate: null,
  subscribeEntityId: "",
  paymentFromAmount: "",
  paymentToAmount: "",
  confirmationNumber: "",
  billerId: "",
  billerName: "",
  customerAccountNumber: "",
  customerName: "",
  customerBankCardNumber: "",
  startOffset: 0,
  endOffset: 100,
};

export const generateBulkMockData = (limit = 1) => {
  const data = {
    id: 234170721,
    sequenceNumber: 234170721,
    address_line1: null,
    address_line2: null,
    biller_address: "ADDRESS LINE 1",
    biller_customer_number: "RCPCSTNBTESTDATA00600",
    biller_name: "NORTHERN VILLAGE OF AIR RONGE",
    biller_department: "",
    bmo_biller_id: null,
    biller_file_number: 3501,
    biller_id: 600,
    cap_type: "CB",
    caption_type_description: "CIBC Branch",
    city: null,
    biller_city: "CITYX",
    biller_fi_number: "000000600",
    display_biller_id: "000000600",
    delivery_date: 20230804,
    delivery_date_display_format: "04/08/2023",
    effective_date: 20230804,
    effective_date_display_format: "04/08/2023",
    fi_company_number: " ",
    fi_customer_first_name: null,
    fi_customer_last_name: null,
    fi_customer_middle_initial: null,
    fi_customer_name: "FN00600 LN00600",
    fi_customer_number: 4506000000000600,
    fi_file_number: 8032,
    fi_file_type: null,
    fi_number: null,
    fi_transaction_record_sequence: 7,
    file_date: null,
    file_time: null,
    payment_date: 20230803,
    payment_time: 12000000,
    payment_time_display_format: "12:00:00:00",
    postal_code: null,
    biller_postal_code: "M1M1M1",
    province: null,
    biller_province: "ON",
    primary_contact_extension: 0,
    primary_contact_fax: 9051234567,
    primary_contact_name: "CONTACT600PERSON_PRY",
    primary_contact_phone: 9051234567,
    transaction_amount: 1,
    transaction_status: null,
    verification_number: "0600",
    transaction_count: null,
    tran_count: null,
    fi_short_name: null,
    report_month: null,
    operation_date: null,
  };
  const res = [];
  for (let i = 0; i < limit; i++) {
    res.push({
      ...data,
      id: data?.id + i,
    });
  }
  return res;
};

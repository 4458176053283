import deliveryMethodActionType from "../actions/types/deliveryMethod.action.type";

export const deliveryMethodInitialState = {
    deliveryMethodList: null,
    error: null,
  };

  export default function deliveryMethodReducer(
    state = deliveryMethodInitialState,
    action
  ) {
    switch (action.type) {
      case deliveryMethodActionType.GET_DELIVERY_METHOD_LIST_SUCCESS:
        return { deliveryMethodList: action.data, error: null };
      case deliveryMethodActionType.GET_DELIVERY_METHOD_LIST_ERROR:
        return { deliveryMethodList: null, error: action.error };
      default:
        return state;
    }
  }
import { FormControlLabel, Checkbox, FormControl } from "@mui/material";
import { PropTypes } from "prop-types";
import { withERPSCheckBoxActionValidator } from "../../hoc/withERPSCheckBoxActionValidator";

function ERPSBaseCheckBoxGrp(props) {
  const { name, label, value, className, onChange, disabled } = props;

  return (
    <FormControl>
      <FormControlLabel
        label={label}
        disabled={disabled}
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={onChange}
            className={className}
          />
        }
      />
    </FormControl>
  );
}

ERPSBaseCheckBoxGrp.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withERPSCheckBoxActionValidator(ERPSBaseCheckBoxGrp);

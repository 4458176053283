import React, { useState, useEffect } from "react";

import {
  getBillerManagementGridHeadings,
  EMPTY_BILLER_CRITERIA,
} from "../../constants/BillerManagementConstant";
import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import CommonLayout from "../custom/containers/CommonLayout";
import { useCustomHook } from "../custom/useCustomHook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import billerProfileTypes from "../../actions/types/billerProfile.action.type";
import { getComponentAccess } from "../../utils/helper";
import { relatedBillerActionTypes } from "../../actions/types/relatedBiller.action.type";

const BillerManagement = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const fiNumber = selectedFi || loginUser.fiNumber;
  const roleId = loginUser?.role?.role_id;
  const {
    history,
    location: { pathname, state },
  } = props;
  const componentAccessData =
    state?.componentAccess ||
    getComponentAccess(
      {
        function_type_code: "MB",
        label_type_code: "BM",
      },
      loginUser
    );

  const roleType = loginUser?.role?.role_type;

  const billerProfileResult = useSelector(
    (state) => state.billerProfileReducer?.billerProfileListData
  );
  const [loading, setLoading] = useState(false);

  const [billerProfileList, setBillerProfileList] = useState(null);
  const [errorMssg, setErrorMssg] = useState(
    t("erps.billermanagement.filterbillercategory.error.emptyinput")
  );

  useEffect(() => {
    dispatch({
      type: billerProfileTypes.GET_BILLER_PROFILE_LIST_SUCCESS,
      data: null,
    });
    dispatch({
      type: relatedBillerActionTypes.GET_ALL_CHILD_BILLER_SUCCESS,
      data: null,
    });
  }, []);

  useEffect(() => {
    if (billerProfileResult) {
      setBillerProfileList(billerProfileResult.data);
    } else {
      setBillerProfileList(billerProfileResult);
    }
  }, [billerProfileResult]);

  const {
    handleBillerProfileSearch,
    searchCriteriaChangeHandler,
    searchCriteriaBlurHandler,
    isSearchValid,
    searchResults,
    searchCriteria,
    paginateBillerProfilesList,
    resetBillerProfileHandler,
  } = useCustomHook({
    tableResults: billerProfileList,
    tableHeadings: getBillerManagementGridHeadings(componentAccessData, roleId),
    initialStates: EMPTY_BILLER_CRITERIA,
    setErrorMssg,
    setLoading,
    fiNumber,
  });

  const navigateToNewUserComponent = () => {
    history.push(pathname + "/new-biller", {
      componentAccess: componentAccessData,
    });
  };
  const pageName = pathname.split("/")[1];
  const hasButtonVisible = roleType === "CE" && pageName === "manage-billers";

  return (
    <CommonLayout
      pageTitle={t("erps.billermanagement.page.title")}
      isRequired={false}
      pageDescription={
        roleType === "PA" || roleType === "WA"
          ? t("erps.billermanagement.page.full.description")
          : t("erps.billermanagement.page.description")
      }
      primaryButtonLabel={t("erps.billermanagement.button.newbiller")}
      pageSubHeading={t("erps.billermanagement.filterbillercategory.heading")}
      pageSubHeadingDescription={t(
        "erps.billermanagement.filterbillercategory.subheading"
      )}
      errorMssg={errorMssg}
      primaryButtonLabelV2={t("erps.billermanagement.button.reset")}
      secondaryButtonLabel={t("erps.billermanagement.button.filter")}
      searchResultLabel={t(
        "erps.billermanagement.billercategorycodes.billercategorycodesresults"
      )}
      searchResultFoundLabel={t(
        "erps.billermanagement.billercategorycodes.recordsfound"
      )}
      firstTextInputFieldLabel={t("erps.billermanagement.label.billerId")}
      firstTextInputFieldId="billerId"
      secondTextInputFieldLabel={t("erps.billermanagement.label.billerName")}
      secondTextInputFieldId="billerName"
      startSearchHandler={handleBillerProfileSearch}
      searchCriteriaChangeHandler={searchCriteriaChangeHandler}
      searchCriteriaBlurHandler={searchCriteriaBlurHandler}
      resetHandler={resetBillerProfileHandler}
      navigateToNewUserComponent={navigateToNewUserComponent}
      isSearchValid={isSearchValid}
      warningIcon={warningIcon}
      loading={loading}
      searchCriteria={searchCriteria}
      initiateSearch={paginateBillerProfilesList}
      searchNotStarted={t("erps.billermanagement.searchuser.notstarted")}
      searchResults={
        billerProfileList
          ? {
              rows: billerProfileList ? [...billerProfileList.data] : [],
              count: billerProfileList && billerProfileList.count,
              columns: getBillerManagementGridHeadings(
                componentAccessData,
                roleId
              ),
            }
          : null
      }
      pageName={pageName}
      labelLinkValue={["billerId"]}
      componentAccess={componentAccessData}
      actionType={{
        primaryButtonActionTypeV1: "hasNewBillerAccess",
        firstTextInputFieldActionType: "hasBillerIDTextField",
        secondTextInputFieldActionType: "hasBillerNameTextField",
        primaryButtonActionTypeV2: "hasResetBtn",
        secondaryButtonActionType: "hasSearchBillerAccess",
      }}
      isDisabled={hasButtonVisible}
      dataGridWidth="dataGrid__box__container__manageBillerSearch"
    />
  );
};

export default withSidebar(BillerManagement);

import { jsx } from "slate-hyperscript";

const getUpdatedAttributes = (type, markAttributes) => {
  const nodeAttributes = { ...markAttributes };
  switch (type) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;

    case "BLOCKQUOTE":
      nodeAttributes.quote = true;
      break;
    case "P":
      nodeAttributes.paragraph = true;
      break;

    case "EM":
      nodeAttributes.italic = true;
      break;

    case "A":
      nodeAttributes.link = true;
      break;

    case "SPAN":
      nodeAttributes.span = true;
      break;
    case "H1":
      nodeAttributes.headingOne = true;
      break;
    case "H2":
      nodeAttributes.headingTwo = true;
      break;
    case "H3":
      nodeAttributes.headingThree = true;
      break;
    case "B":
      nodeAttributes.bold = true;
      break;
    case "LI":
      nodeAttributes.listItem = true;
      break;

    case "OL":
      nodeAttributes.orderedList = true;
      break;

    case "UL":
      nodeAttributes.unorderedList = true;
      break;

    case "U":
      nodeAttributes.underline = true;
      break;

    case "SUB":
      nodeAttributes.subscript = true;
      break;
    case "SUP":
      nodeAttributes.superscript = true;
      break;

    default:
      nodeAttributes.paragraph = true;
      break;
  }
  return nodeAttributes;
};
export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    const objKeys = Object.keys(markAttributes);
    if (objKeys?.length === 1 && objKeys[0] === "paragraph") {
      return jsx(
        "element",
        {
          type: "paragraph",
        },
        [
          {
            text: el.textContent,
            paragraph: true,
          },
        ]
      );
    } else {
      return jsx("text", markAttributes, el.textContent);
    }
  }

  const nodeAttributes = getUpdatedAttributes(el.nodeName, markAttributes);

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();
  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }
  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx(
        "element",
        { type: "quote", style: el.getAttribute("style") },
        children
      );
    case "P":
      return jsx(
        "element",
        { type: "paragraph", style: el.getAttribute("style") },
        children
      );
    case "H1":
      return jsx(
        "element",
        { type: "headingOne", style: el.getAttribute("style") },
        children
      );
    case "H2":
      return jsx(
        "element",
        { type: "headingTwo", style: el.getAttribute("style") },
        children
      );
    case "H3":
      return jsx(
        "element",
        { type: "headingThree", style: el.getAttribute("style") },
        children
      );
    case "A":
      return jsx(
        "element",
        {
          type: "link",
          url: el.getAttribute("href"),
          style: el.getAttribute("style"),
        },
        children
      );

    case "STRONG":
      return jsx(
        "element",
        {
          type: "bold",
          style: el.getAttribute("style"),
        },
        children
      );

    case "B":
      return jsx(
        "element",
        {
          type: "bold",
          style: el.getAttribute("style"),
        },
        children
      );

    case "SPAN":
      return jsx(
        "element",
        {
          type: "span",
          style: el.getAttribute("style"),
        },
        children
      );

    case "U":
      return jsx(
        "element",
        {
          type: "underline",
          style: el.getAttribute("style"),
        },
        children
      );

    case "LI":
      return jsx(
        "element",
        {
          type: "listItem",
          style: el.getAttribute("style"),
        },
        children
      );

    case "OL":
      return jsx(
        "element",
        {
          type: "orderedList",
          style: el.getAttribute("style"),
        },
        children
      );

    case "UL":
      return jsx(
        "element",
        {
          type: "unorderedList",
          style: el.getAttribute("style"),
        },
        children
      );

    case "SUP":
      return jsx(
        "element",
        {
          type: "superscript",
          style: el.getAttribute("style"),
        },
        children
      );

    case "SUB":
      return jsx(
        "element",
        {
          type: "subscript",
          style: el.getAttribute("style"),
        },
        children
      );

    case "DIV":
      return jsx(
        "element",
        {
          style: el.getAttribute("style"),
        },
        children
      );

    default:
      return children;
  }
};

import marketingManagementTypes from "../actions/types/marketingManagement.action.type";

export const marketingManagementInitialState = {
  getDashboardMessage: null,
  getDashboardError: null,

  createDashboardMessage: null,
  createDashboardError: null,

  approveDashboardMessage: null,
  approveDashboardError: null,

  declineDashboardMessage: null,
  declineDashboardError: null,

  getDashboardQueueError: null,
  getDashboardQueueMessage: null,
};

export default function marketingManagementReducer(
  state = marketingManagementInitialState,
  action
) {
  switch (action.type) {
    case marketingManagementTypes.GET_DASHBOARD_MESSAGE_SUCCESS:
      return {
        getDashboardMessage: action.data,
        getDashboardError: null,
        approveDashboardMessage: null,
        approveDashboardError: null,
        createDashboardMessage: null,
        createDashboardError: null,
        declineDashboardMessage: null,
        declineDashboardError: null,
      };
    case marketingManagementTypes.GET_DASHBOARD_MESSAGE_FAILURE:
      return {
        getDashboardMessage: null,
        getDashboardError: action.error,
        getDashboardError: null,
        approveDashboardMessage: null,
        approveDashboardError: null,
        createDashboardMessage: null,
        createDashboardError: null,
        declineDashboardMessage: null,
        declineDashboardError: null,
      };
    case marketingManagementTypes.GET_DASHBOARD_QUEUE_MESSAGE_SUCCESS:
      return {
        ...state,
        getDashboardQueueMessage: action.data,
        getDashboardQueueError: null,

        approveDashboardMessage: null,
        approveDashboardError: null,

        declineDashboardMessage: null,
        declineDashboardError: null,
      };
    case marketingManagementTypes.GET_DASHOBAORD_QUEUE_MESSAGE_FAILURE:
      return {
        ...state,
        getDashboardQueueMessage: null,
        getDashboardQueueError: action.error,

        approveDashboardMessage: null,
        approveDashboardError: null,

        declineDashboardMessage: null,
        declineDashboardError: null,
      };

    case marketingManagementTypes.CREATE_DASHBOARD_MESSAGE_SUCCESS:
      return {
        ...state,
        createDashboardMessage: action.data,
        createDashboardError: null,
      };
    case marketingManagementTypes.CREATE_DASHBOARD_MESSAGE_FAILURE:
      return {
        ...state,
        createDashboardMessage: null,
        createDashboardError: action.error,
      };
    case marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_SUCCESS:
      return {
        ...state,
        approveDashboardMessage: action.data,
        approveDashboardError: null,

        declineDashboardMessage: null,
        declineDashboardError: null,
      };
    case marketingManagementTypes.APPROVE_DASHBOARD_MESSAGE_FAILURE:
      return {
        ...state,
        approveDashboardMessage: null,
        approveDashboardError: action.error,

        declineDashboardMessage: null,
        declineDashboardError: null,
      };
    case marketingManagementTypes.DECLINE_DASHBOARD_MESSAGE_SUCCESS:
      return {
        ...state,
        declineDashboardMessage: action.data,
        declineDashboardError: null,
        approveDashboardMessage: null,
        approveDashboardError: null,
      };
    case marketingManagementTypes.DECLINE_DASHBOARD_MESSAGE_FAILURE:
      return {
        ...state,
        declineDashboardMessage: null,
        declineDashboardError: action.error,
        approveDashboardMessage: null,
        approveDashboardError: null,
      };
    default:
      return state;
  }
}

import appConfigType from "../actions/types/app.config.type";

export const appConfigInitialState = {
  data: null,
  error: null,
};

export default function appConfigReducer(
  state = appConfigInitialState,
  action
) {
  switch (action.type) {
    case appConfigType.LOAD_ENV_PROPERTY_SUCCESS:
      return {
        data: action?.data?.data,
        error: null,
      };
    case appConfigType.LOAD_ENV_PROPERTY_ERROR:
      return {
        data: "",
        error: action.error,
      };
    default:
      return state;
  }
}

const batchHolidayActionType = {
  LIST_BATCH_HOLIDAY_SUCCESS: "LIST_BATCH_HOLIDAY_SUCCESS",
  LIST_BATCH_HOLIDAY_ERROR: "LIST_BATCH_HOLIDAY_ERROR",
  CREATE_BATCH_HOLIDAY_SUCCESS: "CREATE_BATCH_HOLIDAY_SUCCESS",
  CREATE_BATCH_HOLIDAY_ERROR: "CREATE_BATCH_HOLIDAY_ERROR",
  DELETE_BATCH_HOLIDAY_SUCCESS: "DELETE_BATCH_HOLIDAY_SUCCESS",
  DELETE_BATCH_HOLIDAY_ERROR: "DELETE_BATCH_HOLIDAY_ERROR",
};

export default batchHolidayActionType;

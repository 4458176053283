const faxManagementActionTypes = {
  GET_SEARCH_SENT_FAX_LISTS_SUCCESS: "GET_SEARCH_SENT_FAX_LISTS_SUCCESS",
  GET_SEARCH_SENT_FAX_LISTS_FAILURE: "GET_SEARCH_SENT_FAX_LISTS_FAILURE",
  GET_FAILED_FAX_LISTS_SUCCESS: "GET_FAILED_FAX_LISTS_SUCCESS",
  GET_FAILED_FAX_LISTS_FAILURE: "GET_FAILED_FAX_LISTS_FAILURE",

  GET_VIEW_FAX_DETAIL_FAILURE: "GET_VIEW_FAX_DETAIL_FAILURE",
  GET_VIEW_FAX_DETAIL_SUCCESS: "GET_VIEW_FAX_DETAIL_SUCCESS",
  RESET_FAX_MANAGEMENT_REQUEST_VALUES: "RESET_FAX_MANAGEMENT_REQUEST_VALUES",
  RESEND_FAX_SUCCESS: "RESEND_FAX_SUCCESS",
  RESEND_FAX_FAILURE: "RESEND_FAX_FAILURE",
};

export default faxManagementActionTypes;

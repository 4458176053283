import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { loginMessageManagementAction } from "../../actions/loginMessage.action";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import parse from "html-react-parser";
import { validateURL } from "../../utils/helper";

function DashboardLoginPendingApproval(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const successRef = useRef(null);

  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);

  const fiNumber = selectedFi || loginUser.user?.fiNumber;

  const successMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.approveDashboardMessage
  );
  const errorMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.approveDashboardError
  );
  const getDashboardErrorMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardQueueError
  );
  const getDashboardMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardQueueMessage
  );
  const [showExceptionMessage, setShowExceptionMessage] = useState(false);

  const [declineLoading, setDeclineLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const [messagesList, setMessagesList] = useState([
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.french_label"),
      },
      messageId: 1,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.french_label"),
      },
      messageId: 2,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.french_label"),
      },
      messageId: 3,
    },
  ]);

  useEffect(() => {
    dispatch(
      loginMessageManagementAction.getLoginQueueMessage({
        setShowExceptionMessage,
        t,
      })
    );
  }, []);

  useEffect(() => {
    if (getDashboardMessage && getDashboardMessage.length) {
      let dataV1 = [];
      getDashboardMessage.forEach((data) => {
        dataV1.push({
          en: {
            header: data?.messageHeaderEnglish,
            message: data?.messageTextEnglish,
            urlText: data?.messageLinkTextEnglish,
            urlLink: data?.messageUrlEnglish,
            type: t("erps.header.language.english_label"),
          },
          fr: {
            header: data?.messageHeaderFrench,
            message: data?.messageTextFrench,
            urlText: data?.messageLinkTextFrench,
            urlLink: data?.messageUrlFrench,
            type: t("erps.header.language.french_label"),
          },
          messageId: data?.id,
        });
      });
      setMessagesList(dataV1);
    }
  }, [getDashboardMessage]);

  const handleDecline = () => {
    setDeclineLoading(true);
    setShowExceptionMessage(false);
    dispatch(
      loginMessageManagementAction.loginPendingDeclineMessage({
        setLoading: setDeclineLoading,
        setSuccessMessage,
        t,
        setShowExceptionMessage,
        history: props?.history,
      })
    );
  };

  const handleApprove = () => {
    setApproveLoading(true);
    setShowExceptionMessage(false);
    dispatch(
      loginMessageManagementAction.loginPendingApprovalMessage({
        data: getDashboardMessage,
        setLoading: setApproveLoading,
        setSuccessMessage,
        t,
        setShowExceptionMessage,
      })
    );
  };

  const getCardComponent = (lan) => {
    const messagesContent = messagesList.map((data) => data[lan]);
    return (
      <>
        <Box mb={2}>
          <Typography variant="h3">
            {lan === "en"
              ? t("erps.header.language.english_label")
              : t("erps.header.language.french_label")}
          </Typography>
        </Box>

        <Card className="messageCenter__card__container">
          <CardContent>
            <Typography
              variant="h2"
              className="messageCenter__card__content__header"
            >
              {t("erps.login.message.card.title", "Messages")}
            </Typography>
            {messagesContent.map(
              ({ header, message, urlText, urlLink }, idx) => (
                <Box mt={2}>
                  {header && (
                    <Typography
                      variant="h3"
                      className="messageCenter__card__content__heading"
                    >
                      {header}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className="signIn__card__content__header"
                  >
                    {message ? parse(message) : ""}
                    {message && <br />}
                    <ERPSBaseLink
                      label={urlText || urlLink}
                      to={{
                        pathname: !validateURL(urlLink)
                          ? `http://${urlLink}`
                          : urlLink,
                      }}
                      target={"_blank"}
                      className="signIn__card__content__link"
                    />
                  </Typography>
                </Box>
              )
            )}
          </CardContent>
        </Card>
      </>
    );
  };
  const handleCloseSuccessMessage = () => {
    props?.history?.push("/manage-login-page");
  };
  return (
    <main>
      <Box ml={3} mt={2}>
        {successMessage && showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setSuccessMessage}
            inputRef={successRef}
            handleClose={handleCloseSuccessMessage}
            ariaText={t(
              "erps.login.message.confirmationmessage.redirectiontext"
            )}
          />
        )}
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.marketingManagement.loginPagePendingApproval.heading")}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body1">
            {(getDashboardMessage && getDashboardMessage.length) ||
            (showExceptionMessage && (errorMessage || getDashboardErrorMessage))
              ? t("erps.marketingManagement.loginPendingApproval.subheading")
              : t("erps.marketingManagement.nopendingapprovals")}
          </Typography>
        </Box>
        {showExceptionMessage || errorMessage || getDashboardErrorMessage ? (
          <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
            <ErrorMessage error={errorMessage || getDashboardErrorMessage} />
          </Box>
        ) : getDashboardMessage && getDashboardMessage.length ? (
          <>
            <Box>{getCardComponent("en")}</Box>
            <Box mt={3} mb={2}>
              {getCardComponent("fr")}
            </Box>
            <Stack direction="row" spacing={2}>
              <ERPSBaseButton
                variant="secondary"
                onClick={handleDecline}
                loading={declineLoading}
                className="button_with_margin48"
                label={t("erps.updatebillerapproval.button.reject")}
                isDisabled={
                  showSuccessMessage &&
                  (loginUser?.userId == getDashboardMessage?.[0]?.updatedBy ||
                    successMessage)
                }
              />
              <ERPSBaseButton
                variant="primary"
                onClick={handleApprove}
                loading={approveLoading}
                className="button_with_margin48"
                label={t("erps.marketingManagement.button.approve")}
                isDisabled={
                  loginUser?.userId == getDashboardMessage?.[0]?.updatedBy ||
                  (successMessage && showSuccessMessage)
                }
              />
            </Stack>
          </>
        ) : (
          ""
        )}
      </Box>
    </main>
  );
}
export default withSidebar(DashboardLoginPendingApproval);

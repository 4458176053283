import axios from "axios";
import budgetCategoryActionType from "../actions/types/budgetCategory.action.type";
import store from "../store/index";
import { getErrorMessageFromException, API_BASE_URL } from "../utils/helper";
import i18next from "i18next";
import { PaginationItem } from "@mui/material";

export const budgetCategoryService = {
  submitCategory,
  updateCategory,
  getBudgetCategory,
  deleteCategory,
};

function submitCategory(values, setValues, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const loginUserDetail = store.getState()?.loginUserReducer;
    const loggedInUserId = loginUserDetail?.user?.userId;

    axios
      .post(
        API_BASE_URL() + "/manage-budget-category/add",
        { ...values, createdBy: loggedInUserId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        props.setLoader(false);
        props.setShowSuccessMessage(true);
        if (props.setOpenCancelDialog) {
          props.setOpenCancelDialog(false);
        }
        dispatch({
          type: budgetCategoryActionType.NEW_BUDGET_CATEGORY_SUCCESS,
          data: res.data,
        });
        setValues({
          budgetCode: "",
          description: "",
        });
      })
      .catch((error) => {
        const errMessage =
          error.response.data || getErrorMessageFromException(error, props.t);
        props.setLoader(false);
        props.setShowSuccessMessage(false);
        if (props.setOpenCancelDialog) {
          props.setOpenCancelDialog(false);
        }
        setValues({
          ...values,
          budgetCodeErrorHighlight: true,
        });
        dispatch({
          type: budgetCategoryActionType.BUDGET_CATEGORY_FAIL,
          error: errMessage,
        });
      });
  };
}

function getBudgetCategory(
  count,
  budgetCode,
  description,
  pageSize,
  offset,
  translation,
  endOffset,
  setLoading
) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber != null ? currentFiNumber : userFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/manage-budget-category/search",
        {
          count: count,
          budgetCategoryCode: budgetCode,
          budgetDescription: description,
          fiNumber: fiNumber,
          limit: pageSize,
          offset: offset,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const budgetDetails = {
          budgetCode,
          description,
          startOffset: offset,
          endOffset: endOffset,
        };
        dispatch(success({ ...res, budgetDetails }));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const errorResponse =
          getErrorMessageFromException(error, translation) || error;
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return { type: budgetCategoryActionType.BUDGET_CATEGORY_SUCCESS, data };
    }

    function failure(error) {
      return { type: budgetCategoryActionType.BUDGET_CATEGORY_FAIL, error };
    }
  };
}

function updateCategory(values, setValues, props, budgetList) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer.user;
    var updatedValues = {
      ...values,
      lastModifiedBy: loginUser.userId,
    };
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(API_BASE_URL() + "/manage-budget-category/update", updatedValues, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        props.setLoader(false);
        props.setShowSuccessMessage(true);
        dispatch({
          type: budgetCategoryActionType.UPDATE_BUDGET_CATEGORY_SUCCESS,
          data: res.data,
          budget: budgetList,
        });
        setValues({ ...values, originalDescription: values.description });
      })
      .catch((error) => {
        const errMessage =
          getErrorMessageFromException(error, props.t) || error.response.data;
        props.setLoader(false);
        props.setShowSuccessMessage(false);
        dispatch({
          type: budgetCategoryActionType.UPDATE_FAIL,
          error: errMessage,
          budget: budgetList,
        });
      });
  };
}

function deleteCategory(values, setValues, props, budgetList) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    (values.languageCode = i18next.language == "en" ? "E" : "F"),
      axios
        .post(API_BASE_URL() + "/manage-budget-category/delete", values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          props.setLoader(false);
          if (props?.setSaveLoading) {
            props?.setSaveLoading(false);
          }
          props.setOpen(false);
          if (!res?.data?.status) {
            setValues({ ...values });
          } else {
            props.history.push("/manage-budget-category", {
              componentAccess: props.componentAccess,
            });
          }
          dispatch({
            type: budgetCategoryActionType.DELETE_BUDGET_CATEGORY_SUCCESS,
            data: res.data,
            budget: budgetList,
          });
        })
        .catch((error) => {
          if (props?.setSaveLoading) {
            props?.setSaveLoading(false);
          }
          const errMessage =
            getErrorMessageFromException(error, props.t) ||
            error.response?.data?.data;
          props.setLoader(false);
          props.setOpen(false);

          dispatch({
            type: budgetCategoryActionType.DELETE_BUDGET_CATEGORY_ERROR,
            error: errMessage,
            budget: budgetList,
          });
        });
  };
}

import React, { useState, useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import DeliveryMethodComponent from "./DeliveryMethod";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import i18next from "i18next";
import ErrorMessage from "../custom/containers/ErrorMessage";

import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useCustomDropDownHook } from "./useCustomDropDownHook";
import actions from "../../actions";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import { useDispatch, useSelector } from "react-redux";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";

import { getNumericValue, checkNonZeroValue } from "./../../helpers/biller";
import {
  formatDate,
  statisticsFormatDate,
  checkFieldsChange,
  getBillerGroupErrorMessage,
} from "./../../utils/helper";
import Alert from "@mui/material/Alert";

function EditBillerProfileHsbcUser(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billerId, componentAccess, displayBillerId } = props;
  const billerProfileAccess = componentAccess?.find(
    ({ componentType }) => componentType === "BPTAB"
  );
  const [showApiValidationErrorMessage, setShowApiValidationErrorMessage] =
    useState(false);
  const successMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileAssociatedUpdateData
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const fiNumber = loginUser.fiNumber;
  const [count, setCount] = useState(1);
  const [disabledApplieToField, setDisabledApplieToField] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const errorRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const apiExceptionRef = useRef();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const warningRef = useRef();

  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});
  const [billerProfile, setBillerProfile] = useState({});
  const [oldBillerProfile, setOldBillerProfile] = useState({});

  const [initialDeliveryMethods, setInitialDeliveryMethods] = useState({});
  const [deliveryMethods, setDeliveryMethods] = useState([]);

  const [reqValidationForDelivery, setValidationForDelivery] = useState([]);
  const updatedBillerId = billerId?.split("-")[1] || billerId;
  const {
    billerFinancialInstitutionList,
    provinceList,
    deliveryMethodLists,
    formatedMethodMapList,
    errorMessageList,
  } = useCustomDropDownHook({ t, billerId: updatedBillerId, setIsLoading });

  const languageItems = [
    {
      name: t("erps.userprofile.language.english.text", "English"),
      value: "E",
    },
    { name: t("erps.userprofile.language.french.text", "French"), value: "F" },
  ];

  const nilReports = [
    { name: t("erps.billermanagement.label.nilReports.yes"), value: "Y" },
    { name: t("erps.billermanagement.label.nilReports.no"), value: "N" },
  ];

  const statusList = [
    { label: t("erps.billermanagement.label.inactive"), value: "I" },
    { label: t("erps.billermanagement.label.active"), value: "A" },
  ];
  const appliesToItems = [
    {
      name: t("erps.billermanagement.label.appliesToItems.link"),
      value: "G",
      disabled: disabledApplieToField,
    },
    {
      name: t("erps.billermanagement.label.appliesToItems.biller"),
      value: "C",
      disabled: disabledApplieToField,
    },
  ];

  const apiExceptionMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileAssociatedUpdateError
  );
  const deliveryMethodReducerValue = useSelector(
    (state) => state.deliveryMethodReducer
  );

  const billerProfileReducerValue = useSelector(
    (state) => state.billerProfileReducer
  );

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [hasShowWarningMessage, setShowWarningMessage] = useState(false);

  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);

  const successRef = useRef(null);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [
    errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage,
  ]);
  const checkRequiredRecipientCcin = () => {
    const hasCcinRequired =
      (initialDeliveryMethods?.deliveryMethod === "EDI" &&
        initialDeliveryMethods?.distributionFormat === "H6 3030") ||
      deliveryMethods.some(
        (method) =>
          method?.deliveryMethod === "EDI" &&
          method?.distributionFormat === "H6 3030"
      );
    return hasCcinRequired;
  };
  const getBillerLinkGroupDetail = (data) => {
    const billerExternalId = displayBillerId;
    const billerGroupDetail = data?.billerLinkGroupDtos?.find(
      ({ billerFiNumber }) => billerFiNumber == billerExternalId
    );
    return billerGroupDetail;
  };
  const getValue = (billerLink, billerLinkGroupDtos) => {
    const billerLinkObj = billerLinkGroupDtos?.find(
      ({ billerId }) => billerId === billerLink
    );
    return billerLinkObj || billerLink;
  };
  const getLastDeliveryMethod = () => {
    let deliveryMethodLength;
    let restDeliveryMethods;
    if (deliveryMethods && deliveryMethods?.length) {
      deliveryMethodLength = deliveryMethods?.length - 1;
      if (deliveryMethodLength >= 0) {
        restDeliveryMethods = deliveryMethods[deliveryMethodLength];
      }
    } else if (
      Object.keys(initialDeliveryMethods) &&
      Object.keys(initialDeliveryMethods)?.length
    ) {
      restDeliveryMethods = initialDeliveryMethods;
    }
    return restDeliveryMethods;
  };

  const getSettlementTypeValue = () => {
    const lastDeliveryMethod = getLastDeliveryMethod();

    if (
      lastDeliveryMethod &&
      Object.keys(lastDeliveryMethod) &&
      Object.keys(lastDeliveryMethod)?.length
    ) {
      const hasEDISettlementType =
        lastDeliveryMethod?.deliveryMethod === "EDI" &&
        (lastDeliveryMethod?.distributionFormat === "H6 3030" ||
          lastDeliveryMethod?.distributionFormat === "CBSA 3030");

      return hasEDISettlementType ? "EDI" : "EFT";
    }
  };

  useEffect(() => {
    if (billerProfileReducerValue) {
      if (billerProfileReducerValue.billerProfileAssociatedData) {
        const billerData =
          billerProfileReducerValue.billerProfileAssociatedData;

        const updatedMinValue =
          billerData?.billerCustomerNumberMinimum === 0
            ? undefined
            : billerData?.billerCustomerNumberMinimum;
        const updatedMaxValue =
          billerData?.billerCustomerNumberMaximum === 0
            ? undefined
            : billerData?.billerCustomerNumberMaximum;

        const updatedPrimaryContactPhone =
          billerData?.primaryContactPhone === 0
            ? undefined
            : billerData?.primaryContactPhone;

        const updatedRecipientCcin =
          billerData?.recipientCcin === 0
            ? undefined
            : billerData?.recipientCcin;

        setBillerProfile({
          ...billerData,
          billerLink: getValue(
            billerData?.billerLink,
            billerData?.billerLinkGroupDtos
          )?.billerFiNumber,
          billerCustomerNumberMaximum: updatedMaxValue,
          billerCustomerNumberMinimum: updatedMinValue,
          primaryContactPhone: updatedPrimaryContactPhone,
          recipientCcin: updatedRecipientCcin,
        });
        setOldBillerProfile({
          ...billerData,

          appliesTo: billerData?.appliesTo || "Biller",
        });
        if (
          billerProfileReducerValue.billerProfileAssociatedData
            ?.distributionList
        ) {
          const [initialDeliveryMethodResponse, ...restDeliveryMethods] =
            billerProfileReducerValue.billerProfileAssociatedData
              ?.distributionList;
          const hasMember = getBillerLinkGroupDetail(
            billerProfileReducerValue.billerProfileAssociatedData
          )?.member;
          if (hasMember) {
            setInitialDeliveryMethods({
              ...initialDeliveryMethodResponse,
              distributionGroupRcpIndex: "C",
              deliveryMethod:
                initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
            });
            const updatedDeliveryMethods = restDeliveryMethods?.map(
              ({ distributionGroupRcpIndex, ...rest }) => ({
                ...rest,
                distributionGroupRcpIndex: "C",
              })
            );
            setDeliveryMethods(updatedDeliveryMethods);
            setDisabledApplieToField(true);
            setOldBillerProfile((preState) => ({
              ...preState,
              distributionList: [
                ...updatedDeliveryMethods,
                {
                  ...initialDeliveryMethodResponse,
                  distributionGroupRcpIndex: "C",
                  deliveryMethod:
                    initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
                },
              ],
            }));
          } else {
            setInitialDeliveryMethods({
              ...initialDeliveryMethodResponse,
              deliveryMethod:
                initialDeliveryMethodResponse?.deliveryMethod.toUpperCase(),
              ...(!initialDeliveryMethodResponse?.length && {
                distributionGroupRcpIndex: "C",
              }),
            });
            setDeliveryMethods(restDeliveryMethods);
          }
        }
      }
    }
  }, [billerProfileReducerValue?.billerProfileAssociatedData]);

  useEffect(() => {
    if (props?.handleTabLoading) {
      props?.handleTabLoading(true);
    }
    dispatch(
      actions.billerProfileActions.getBillerProfileWithAssociatedObjects(
        billerId,
        t,
        props?.handleTabLoading
      )
    );
  }, []);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && successMessage !== "ERPS0055" && showSuccessMessage]);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [apiExceptionMessage && showApiExceptionMessage]);
  useEffect(() => {
    if (warningRef.current) {
      warningRef.current.focus();
    }
  }, [hasShowWarningMessage]);

  useEffect(() => {
    const updatedBillerId = billerId?.split("-")[1] || billerId;
    dispatch(
      actions.billerProfileActions.getAllDeliveryMethod(t, updatedBillerId)
    );
  }, []);

  useEffect(() => {
    const updatedBillerId = billerId?.split("-")[1] || billerId;

    dispatch(actions.billerProfileActions.getFormatMethod(t, updatedBillerId));
  }, []);

  const newBillerUserComponent = componentAccess?.find(
    ({ componentType }) => componentType === "NB"
  );

  const getFormatListItem = (deliveryMethod) => {
    if (formatedMethodMapList) {
      if (deliveryMethod) {
        let data = [];
        const formtedMap = formatedMethodMapList[deliveryMethod];
        if (
          (deliveryMethod === "EDI" ||
            deliveryMethod === "FAX" ||
            deliveryMethod === "PC") &&
          formtedMap
        ) {
          let filteredObj;
          if (deliveryMethod === "EDI") {
            filteredObj = { value: "H6 3030", label: formtedMap["H6 3030"] };
          } else if (deliveryMethod === "FAX") {
            filteredObj = {
              value: "DTL FAX EN",
              label: formtedMap["DTL FAX EN"],
            };
          } else if (deliveryMethod === "PC") {
            filteredObj = {
              value: "KITCHENER",
              label: formtedMap["KITCHENER"],
            };
          }

          data = Object.keys(formtedMap)
            .filter((formatKey) => formatKey && formatKey !== filteredObj.value)
            .map((key) => {
              return {
                value: key,
                label: formtedMap[key],
              };
            });
          data.unshift(filteredObj);
          data.unshift({
            value: "",
            label: "",
          });
        } else {
          data = formtedMap
            ? Object.keys(formtedMap).map((key) => {
                if (formtedMap?.[key]) {
                  return {
                    value: key,
                    label: formtedMap[key],
                  };
                }
              })
            : [];
          data.unshift({
            value: "",
            label: "",
          });
        }
        data.shift();
        return data;
      }
    }
  };

  const handleClickDeliveryMethod = () => {
    setCount(count + 1);

    setDeliveryMethods([
      ...deliveryMethods,
      {
        deliveryMethod: "",
        distributionListCode: "",
        distributionFormat: "",
        distributionEDIMailbox: "",
        destinationAttention: "",
        distributionPhoneNumber: "",
        distributionExtensionNumber: "",
        distributionGroupRcpIndex: "C",
      },
    ]);
  };

  const handleRedirect = () => {
    props.history.push("/manage-billers");
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const getEdiMailBoxValue = (val) => {
    if (fiNumber == "16") {
      if (val === "AMEX") {
        return "AMXCIE1";
      } else if (val === "CBSA 3030") {
        return "CBSA";
      } else if (val === "REV CAN 2") {
        return "CCRAADRCPR";
      } else if (val === "REVENUE CA") {
        return "8199562933";
      } else if (val) {
        return "BMO";
      }
    }
    return "";
  };

  const handleChangeInitialDelivery = (key, value) => {
    if (
      key === "distributionFormat" &&
      initialDeliveryMethods?.deliveryMethod === "EDI"
    ) {
      const ediMailBoxValue = getEdiMailBoxValue(value);
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else if (key === "deliveryMethod") {
      let ediMailBoxValue = "";
      const distributionFormatValue = value
        ? getFormatListItem(value)[0]?.value
        : "";
      if (value === "EDI") {
        ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
      }

      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionFormat: distributionFormatValue,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else {
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
      });
    }
  };

  const handleChangeDeliveryMethods = (key, i) => (e) => {
    const value = e.target.value;
    const updatedDeliveryMethods = deliveryMethods.map((item, id) => {
      if (id === i) {
        if (key === "distributionFormat" && item.deliveryMethod === "EDI") {
          const ediMailBoxValue = getEdiMailBoxValue(value);
          return {
            ...item,
            [key]: value,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else if (key === "deliveryMethod") {
          let ediMailBoxValue = "";
          const distributionFormatValue = value
            ? getFormatListItem(value)[0]?.value
            : "";
          if (value === "EDI") {
            ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
          }
          return {
            ...item,
            [key]: value,
            distributionFormat: distributionFormatValue,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else {
          return {
            ...item,
            [key]: value,
          };
        }
      }
      return item;
    });

    setDeliveryMethods(updatedDeliveryMethods);
  };

  const handleRemoveDeliveryMethod = (index) => (e) => {
    setCount(count - 1);
    const slicedArr = deliveryMethods.filter((item, i) => index !== i);
    const slicedValidationForDelivery = reqValidationForDelivery.filter(
      (item, i) => index !== i
    );
    setDeliveryMethods(slicedArr);
    setValidationForDelivery(slicedValidationForDelivery);
  };

  const handleBillerProfile = (key) => (e) => {
    setBillerProfile((prevBillerProfile) => ({
      ...prevBillerProfile,
      [key]: key === "billerLiveDate" ? e : e.target.value,
    }));
  };

  const getRequiredValidationForDeliveryMethod = () => {
    const deliveryValueValidation = [];
    const numericRegEx10 = /^[0-9]{10}$/;
    const invalidContactNumber = "0000000000";
    const validContactNumberRegex = /^([1-9]){1}[0-9]{9}$/;

    deliveryMethods.forEach(
      ({ deliveryMethod, distributionListCode, distributionPhoneNumber }) => {
        let obj = {};
        if (!deliveryMethod) {
          obj = { ...obj, deliveryMethod: true };
        }
        if (!distributionListCode?.trim()) {
          obj = { ...obj, distributionListCode: true };
        }
        if (deliveryMethod === "FAX") {
          if (
            distributionListCode?.trim() &&
            !numericRegEx10.test(distributionListCode?.trim())
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (
          distributionPhoneNumber &&
          (!validContactNumberRegex.test(
            distributionPhoneNumber?.toString()?.trim()
          ) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactNumber)
        ) {
          obj = { ...obj, distributionPhoneNumber: true };
        }
        deliveryValueValidation.push(obj);
      }
    );
    return deliveryValueValidation;
  };

  const checkDistributionLengthValid = () => {
    const invalidContactPhone = "0000000000";
    const numericRegEx10 = /^([1-9]){1}[0-9]{9}$/;

    const initialDistributionNumber =
      initialDeliveryMethods?.distributionPhoneNumber !== 0
        ? initialDeliveryMethods?.distributionPhoneNumber?.toString().trim()
        : "";
    const hasValidDistributionNumber =
      initialDistributionNumber &&
      (!numericRegEx10.test(initialDistributionNumber) ||
        initialDistributionNumber == invalidContactPhone);
    const hasValidOtherDistributionNumber =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber !== 0 &&
          distributionPhoneNumber?.toString()?.trim() &&
          (!numericRegEx10.test(distributionPhoneNumber?.toString()?.trim()) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactPhone)
      );
    return { hasValidDistributionNumber, hasValidOtherDistributionNumber };
  };

  const hasAccess = (key) => {
    if (key === "recipientCcin") {
      return (
        initialDeliveryMethods?.deliveryMethod ||
        deliveryMethods.some((method) => method?.deliveryMethod)
      );
    }
  };

  const checkPhoneNumberValidationForDeliveryMethod = () => {
    const numericRegEx10 = /^[0-9]{10}$/;
    distributionPhoneNumber;
    const hasValidLengthForInitialMethod =
      initialDeliveryMethods?.distributionPhoneNumber !== 0
        ? initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim() &&
          !numericRegEx10.test(
            initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim()
          )
        : "";
    const hasValidLengthForOtherMethods =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber !== 0 &&
          distributionPhoneNumber?.toString()?.trim() &&
          !numericRegEx10.test(distributionPhoneNumber?.toString()?.trim())
      );
    return { hasValidLengthForInitialMethod, hasValidLengthForOtherMethods };
  };

  const validateBillerProfileUser = (billerProfile) => {
    const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
    const alphaNumericWithSpecialCharRegEx =
      /^[éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\&\#\(\)_\-:;"',\.\/ ]+$/;
    const invalidContactPhone = "0000000000";
    const validContactNumberRegEx = /^([1-9]){1}[0-9]{9}$/;

    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
    const billerNameRegexWithFrenchChar = /^[0-9A-Za-z-&()_:";',.\/À-ÿ ]+$/;

    const numericRegEx = /^[0-9]+$/;

    const numericRegEx10 = /^[0-9]{10}$/;

    const errors = [];
    let highlightErrorV1 = {};
    const regexForSpecialCharacter = /^[À-Ÿ!~@$%^*+=[]{}\|<>?]+$/;
    const regexForSpecialCharacterWithoutFrenchChar = /^[!~@$%^*+=[]{}\|<>?]+$/;

    const alphnumericWithHypen = /^[0-9A-Za-z\-]+$/;
    const billerCustomerNumberFormatRegEx = /^[B9A\-]+$/;

    if (!billerProfile?.billerName) {
      errors.push(
        t("erps.billermanagement.error.cannotblank.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (billerProfile?.billerName && billerProfile?.billerName.length > 45) {
      errors.push(
        t("erps.billermanagement.error.nameNotGreaterThan45.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (!billerNameRegex.test(billerProfile?.billerName)) {
      errors.push(
        t("erps.billermanagement.error.nameAlphaNumeric.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }

    if (
      billerProfile?.billerShortName &&
      billerProfile?.billerShortName.length > 20
    ) {
      errors.push(
        t(
          "erps.billermanagement.error.nameNotGreaterThan20.billerShortNameEnglish"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerNameRegex.test(billerProfile?.billerShortName)) {
      errors.push(
        t("erps.billermanagement.error.englishshortNameAlphaNumberic")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (fiNumber == 10 && !billerProfile?.billerShortName) {
      errors.push(
        t("erps.billermanagement.billershortnameenglish.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerProfile?.billerCity) {
      errors.push(t("erps.billermanagement.error.cityNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (billerProfile?.billerCity && billerProfile?.billerCity.length > 25) {
      errors.push(t("erps.billermanagement.error.cityNotGreaterThan25"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (!billerProfile?.billerProvince) {
      errors.push(t("erps.billermanagement.error.provinceNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerProvince: true };
    }

    if (
      billerProfile?.billerPostalCode &&
      !alphaNumericRegEx.test(billerProfile?.billerPostalCode)
    ) {
      errors.push(t("erps.billermanagement.error.postalCodeAlphaNumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerPostalCode: true };
    }

    if (!billerProfile?.billerLanguageCode) {
      errors.push(t("erps.billermanagement.error.languageNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerLanguageCode: true };
    }
    if (
      billerProfile?.primaryContactName &&
      billerProfile?.primaryContactName.length > 35
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameNotGreaterThan35")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(billerProfile?.primaryContactName)
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactPhone &&
      billerProfile?.primaryContactPhone?.toString()?.length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.telephoneNumberNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    } else if (
      billerProfile?.primaryContactPhone &&
      (billerProfile?.primaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.primaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidprimaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }

    if (
      billerProfile?.secondaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(
        billerProfile?.secondaryContactName
      )
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactName: true };
    }

    if (
      billerProfile?.secondaryContactPhone &&
      billerProfile?.secondaryContactPhone?.toString()?.length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryTelephoneNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    } else if (
      billerProfile?.secondaryContactPhone &&
      (billerProfile?.secondaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.secondaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidsecondaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    }

    if (!billerProfile?.creditorFiNumber) {
      errors.push(t("erps.billermanagement.error.billerFiNotBlank"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        creditorFiNumber: true,
      };

      if (
        billerProfile?.debitTransitNumber &&
        !numericRegEx.test(billerProfile?.debitTransitNumber)
      ) {
        errors.push(
          t("erps.billermanagement.error.debitTransitNumberNumbericValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }

      if (
        numericRegEx.test(billerProfile?.debitTransitNumber) &&
        billerProfile?.debitTransitNumber &&
        billerProfile?.debitTransitNumber.length > 5
      ) {
        errors.push(
          t(
            "erps.billermanagement.error.debitTransitNumberNotGreaterThan5Digit"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }
      if (!alphnumericWithHypen.test(billerProfile?.debitAccountNumber)) {
        errors.push(
          t("erps.billermanagement.error.debitAccountNumberInvalidValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
      }
    }

    if (!billerProfile?.creditorTransitNumber) {
      errors.push(t("erps.billermanagement.error.creditTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      numericRegEx.test(billerProfile?.creditorTransitNumber) &&
      billerProfile?.creditorTransitNumber.length > 5
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNotGreaterThan5Digit")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      !numericRegEx.test(billerProfile?.creditorTransitNumber)
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNumbericValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }

    if (!billerProfile?.creditorAccountNumber) {
      errors.push(t("erps.billermanagement.error.creditAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (!alphnumericWithHypen.test(billerProfile?.creditorAccountNumber)) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNumberInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (
      !initialDeliveryMethods?.deliveryMethod ||
      (deliveryMethods.length &&
        deliveryMethods.some(({ deliveryMethod }) => !deliveryMethod))
    ) {
      errors.push(t("erps.billermanagement.error.deliveryMethodNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, deliveryMethod: true };
    }
    if (
      !initialDeliveryMethods?.distributionListCode?.trim() ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode }) => !distributionListCode?.trim()
        ))
    ) {
      if (!initialDeliveryMethods?.distributionListCode?.trim()) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.destinationNotBlank"));
    }

    if (
      (initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)) ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode, deliveryMethod }) =>
            deliveryMethod === "FAX" &&
            distributionListCode &&
            !numericRegEx10.test(distributionListCode)
        ))
    ) {
      if (
        initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.invaliddestinationBlank"));
    }

    if (
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForInitialMethod ||
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForOtherMethods
    ) {
      if (
        checkPhoneNumberValidationForDeliveryMethod()
          ?.hasValidLengthForInitialMethod
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      errors.push(
        t(
          "erps.billermanagement.error.distributionTelephoneNumberShouldBe10Digit"
        )
      );
    }
    if (
      checkDistributionLengthValid()?.hasValidDistributionNumber ||
      checkDistributionLengthValid()?.hasValidOtherDistributionNumber
    ) {
      if (checkDistributionLengthValid()?.hasValidDistributionNumber) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      // if (
      //   checkDistributionLengthValid()?.hasValidDistributionNumber ||
      //   checkDistributionLengthValid()?.hasValidOtherDistributionNumber
      // ) {
      //   errors.push(
      //     t("erps.billermanagement.error.distributionTelephoneNumberInvalid")
      //   );
      // }
    }

    if (getRequiredValidationForDeliveryMethod().length) {
      const validationForDeliveryMethod =
        getRequiredValidationForDeliveryMethod();
      setValidationForDelivery(validationForDeliveryMethod);
    }
    if (checkRequiredRecipientCcin()) {
      if (
        billerProfile?.recipientCcin === undefined ||
        billerProfile?.recipientCcin === ""
      ) {
        errors.push(t("erps.billermanagement.error.cooperateCreditorNotBlank"));
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
    }
    if (
      billerProfile?.recipientCcin != undefined &&
      billerProfile?.recipientCcin <= 0
    ) {
      errors.push(
        t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
      );
      highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
    }
    if (
      billerProfile?.recipientCcin > 0 &&
      billerProfile?.recipientCcin.toString().length != 8 &&
      numericRegEx.test(billerProfile?.recipientCcin)
    ) {
      errors.push(
        t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
      );
      highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
    }
    if (
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMaximum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMaximum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMaxValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMaximum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMinimum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMinimum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMinValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      parseInt(billerProfile?.billerCustomerNumberMinimum) >
        parseInt(billerProfile?.billerCustomerNumberMaximum)
    ) {
      errors.push(
        t("erps.billermanagement.error.billingAccountMinLessThanMax")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
        billerCustomerNumberMaximum: true,
      };
    }

    if (fiNumber == 16) {
      const billerMinField = billerProfile?.billerCustomerNumberMinimum;
      const billerMaxField = billerProfile?.billerCustomerNumberMaximum;
      const min = parseInt(billerMinField);
      const max = parseInt(billerMaxField);
      if ((isNaN(min) && !isNaN(max)) || (!isNaN(min) && isNaN(max))) {
        const hasMinEmpty = isNaN(min) && !isNaN(max);
        const hasMaxEmpty = !isNaN(min) && isNaN(max);

        if (hasMinEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMinValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMinimum: true,
          };
        } else if (hasMaxEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMaxValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMaximum: true,
          };
        }
      }
    }

    if (
      billerProfile?.billerCustomerNumberFormat &&
      !billerCustomerNumberFormatRegEx.test(
        billerProfile?.billerCustomerNumberFormat
      )
    ) {
      errors.push(t("erps.billermanagement.error.accountFormatInvalidValue"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberFormat: true,
      };
    }

    if (
      billerProfile?.billerComment &&
      billerProfile?.billerComment.length > 160
    ) {
      errors.push(t("erps.billermanagement.error.commentLengthInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, billerComment: true };
    }
    return { errors, highlightErrorV1 };
  };

  const sanitizeBillerData = (jsonObj) => {
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      }
    });

    return jsonObj;
  };

  const handleSave = () => {
    setSaveLoading(true);
    setShowApiExceptionMessage(false);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (showApiValidationErrorMessage) {
      setShowApiValidationErrorMessage(false);
    }
    const billerProfileSanitizedData = sanitizeBillerData(billerProfile);

    const { errors, highlightErrorV1 } = validateBillerProfileUser(
      billerProfileSanitizedData
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      setSaveLoading(false);
      if (errorRef.current) {
        errorRef.current.focus();
      }
    } else {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      billerProfile.languageCode = i18next.language == "en" ? "E" : "F";
      billerProfile.fiNumber = fiNumber;
      billerProfile.userId = loginUser?.userId;
      billerProfile.createdBy = loginUser?.userId;

      let reqBody = {
        ...billerProfile,
        billerStatus: billerProfile?.billerStatus,
        distributionList: [initialDeliveryMethods, ...deliveryMethods],
        billerLiveDate: formatDate(billerProfile.billerLiveDate),
        billerCountry: "CA",
        billerFiNumber: displayBillerId,
        billerSettlementType: getSettlementTypeValue(),
      };
      const nilReportValue = !billerProfile?.nilReportIndex
        ? "N"
        : billerProfile?.nilReportIndex;

      if (nilReportValue) {
        reqBody = { ...reqBody, nilReportIndex: nilReportValue };
      }
      const billerData = billerProfileReducerValue.billerProfileAssociatedData;
      const hasFieldsChanged = checkFieldsChange(
        {
          ...oldBillerProfile,
          billerFiNumber: displayBillerId,
          billerLiveDate: formatDate(billerProfile.billerLiveDate),
          createdBy: reqBody?.reqBody,
          billerLink: getValue(
            billerData?.billerLink,
            billerData?.billerLinkGroupDtos
          )?.billerFiNumber,
          billerSettlementType: getSettlementTypeValue(),
        },
        reqBody
      );
      if (hasFieldsChanged) {
        if (hasShowWarningMessage) {
          setShowWarningMessage(false);
        }
        dispatch(
          actions.billerProfileActions.updateBillerProfileWithAssociatesChildObjects(
            { ...reqBody },
            {},
            {
              t,
              fiNumber,
              setSaveLoading,
              setShowSuccessMessage,
              setShowApiExceptionMessage,
              setInitialDeliveryMethods,
              setDeliveryMethods,
              setCount,
              setBillerProfile,
              setShowApiValidationErrorMessage,
              handleTabLoading: props?.handleTabLoading,
            }
          )
        );
      } else {
        setSaveLoading(false);
        setShowWarningMessage(true);
      }
    }
  };
  const getBillerGroupLists = () => {
    const listData = billerProfile?.billerLinkGroupDtos?.map((link) => {
      return {
        label: link?.billerLinkGroupDetail,
        value: link?.billerId,
      };
    });
    listData?.unshift({
      label: "",
      value: "",
    });
    return listData;
  };

  return (
    <Box ml={3} role={"main"} aria-labelledby="title">
      {successMessage &&
        successMessage !== "ERPS0055" &&
        showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}

      {errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={
            errorMessageList.length ||
            validationErrors.length ||
            showApiValidationErrorMessage
              ? 2
              : 0
          }
        >
          {errorMessageList.length ||
          validationErrors.length ||
          showApiValidationErrorMessage ? (
            <ErrorMessage
              error={
                showApiValidationErrorMessage
                  ? [
                      ...errorMessageList,
                      ...validationErrors,
                      ...getBillerGroupErrorMessage(successMessage),
                    ]
                  : [...errorMessageList, ...validationErrors]
              }
              errorRef={errorRef}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      {apiExceptionMessage && showApiExceptionMessage && (
        <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
          <ErrorMessage
            error={apiExceptionMessage}
            errorRef={apiExceptionRef}
          />
        </Box>
      )}
      {hasShowWarningMessage && (
        <Stack sx={{ width: "100%" }} spacing={2} tabIndex={0} ref={warningRef}>
          <Alert severity="warning">
            {t("erps.billermanagement.warning.nothinghaschanged")}
          </Alert>
        </Stack>
      )}
      <Box
        className="manageUsers__page__title"
        role="form"
        aria-labelledby="billerProfile"
      >
        <Typography variant="h2" id="billerProfile">
          {t("erps.billermanagement.page.formtitle", "Biller Profile")}
        </Typography>

        <Box>
          <Typography variant="label">
            {t("erps.billermanagement.label.billerId", "Biller ID")}
            <Typography variant="body2">
              {displayBillerId || billerId}
            </Typography>
          </Typography>
        </Box>

        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerName"
            type="text"
            label={t("erps.billermanagement.label.billerName")}
            className="common__textField__width45"
            size="small"
            onChange={handleBillerProfile("billerName")}
            onBlur={(e) => {
              const updatedValue = e?.target?.value;
              setBillerProfile((prevBillerProfile) => ({
                ...prevBillerProfile,
                billerName:
                  updatedValue && typeof updatedValue === "string"
                    ? updatedValue?.toUpperCase()
                    : updatedValue,
              }));
            }}
            value={billerProfile?.billerName || ""}
            required="true"
            error={highlightError?.billerName}
            inputProps={{ maxLength: 45 }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerShortName"
            type="text"
            label={t("erps.billermanagement.label.billerShortName")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerShortName")}
            value={billerProfile?.billerShortName || ""}
            error={highlightError?.billerShortName}
            inputProps={{ maxLength: 20 }}
            required={"true"}
          />

          <ERPSBaseTextField
            id="billerLink"
            type="number"
            label={t("erps.billermanagement.label.billerLink")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerLink")}
            value={billerProfile?.billerLink || ""}
            error={highlightError?.billerLink}
            inputProps={{ maxLength: 9 }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerLinkGroup"
            type="text"
            label={t("erps.billermanagement.label.billerLinkMember")}
            className="common__textField__width"
            size="small"
            error={highlightError?.billerLinkGroup}
            select={true}
            items={getBillerGroupLists()}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            listDisabled={true}
            value={
              getValue(
                billerProfileReducerValue.billerProfileAssociatedData
                  ?.billerLink,
                billerProfile?.billerLinkGroupDtos
              )?.billerId
            }
          />
          <ERPSBaseTextField
            id="billerDepartment"
            type="text"
            label={t(
              "erps.viewfimanagement.page.subheading.department",
              "Department"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerDepartment")}
            value={billerProfile?.billerDepartment || ""}
            error={highlightError?.billerDepartment}
            inputProps={{ maxLength: 35 }}
          />

          <ERPSBaseTextField
            id="billerAddress"
            type="text"
            label={t("erps.billermanagement.label.address", "Address")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerAddress")}
            value={billerProfile?.billerAddress || ""}
            error={highlightError?.billerAddress}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCity"
            type="text"
            label={t("erps.billermanagement.label.city", "City")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCity")}
            value={billerProfile?.billerCity || ""}
            required={"true"}
            error={highlightError?.billerCity}
          />
          <ERPSBaseTextField
            id="billerCountry"
            type="text"
            label={t("erps.billermanagement.label.county", "County")}
            className="common__textField__width"
            size="small"
            value={"CA"}
            inputProps={{ maxLength: 2 }}
            isDisabled={true}
          />

          <ERPSBaseTextField
            id="billerProvince"
            type="text"
            label={t("erps.billermanagement.label.province", "Province")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerProvince")}
            value={billerProfile?.billerProvince || ""}
            select={true}
            items={provinceList}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: Boolean(billerProfile?.billerProvince) }}
            error={highlightError?.billerProvince}
            required="true"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseRadioGroup
            marginTop=".4rem"
            value={billerProfile?.billerLanguageCode || ""}
            label={t("erps.userprofile.language.text", "Language")}
            onChange={handleBillerProfile("billerLanguageCode")}
            items={languageItems}
            required={"true"}
            error={highlightError?.billerLanguageCode}
          />
          <ERPSBaseTextField
            id="groupPriority"
            type="number"
            label={t("erps.billermanagement.label.priority", "Group priority")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("groupPriority")}
            value={billerProfile?.groupPriority || ""}
            error={highlightError?.groupPriority}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
          <ERPSBaseTextField
            id="billerPostalCode"
            type="text"
            label={t("erps.billermanagement.label.postalcode", "Postal code")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerPostalCode")}
            value={billerProfile?.billerPostalCode || ""}
            inputProps={{ maxLength: 7 }}
            error={highlightError?.billerPostalCode}
          />
        </Stack>

        <Box
          mt={4}
          mb={-2}
          role={"form"}
          aria-labelledby={"contactInformation"}
        >
          <Typography variant="h3" id={"contactInformation"}>
            {t(
              "erps.billermanagement.button.contactInfo",
              "Contact information"
            )}
          </Typography>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="primaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.primarycontact",
                "Primary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactName")}
              value={billerProfile?.primaryContactName || ""}
              error={highlightError?.primaryContactName}
              inputProps={{ maxLength: 35 }}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="primaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1 addNewBiller_label_fontSize"
                size="small"
                onChange={handleBillerProfile("primaryContactPhone")}
                value={billerProfile?.primaryContactPhone || ""}
                error={highlightError?.primaryContactPhone}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
              />
              <ERPSBaseTextField
                id="primaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("primaryContactExtension")}
                value={checkNonZeroValue(
                  billerProfile?.primaryContactExtension
                )}
                InputLabelProps={{ shrink: true }}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="primaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactFax")}
              value={checkNonZeroValue(billerProfile?.primaryContactFax)}
              InputLabelProps={{ shrink: true }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 18);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="secondaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.secondarycontact",
                "Secondary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactName")}
              value={billerProfile?.secondaryContactName || ""}
              inputProps={{ maxLength: 35 }}
              error={highlightError?.secondaryContactName}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="secondaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1"
                size="small"
                onChange={handleBillerProfile("secondaryContactPhone")}
                value={checkNonZeroValue(billerProfile?.secondaryContactPhone)}
                InputLabelProps={{ shrink: true }}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
              />
              <ERPSBaseTextField
                id="secondaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("secondaryContactExtension")}
                value={checkNonZeroValue(
                  billerProfile?.secondaryContactExtension
                )}
                InputLabelProps={{ shrink: true }}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="secondaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactFax")}
              value={checkNonZeroValue(billerProfile?.secondaryContactFax)}
              InputLabelProps={{ shrink: true }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 18);
              }}
            />
          </Stack>
        </Box>

        {
          <>
            {" "}
            <Box mt={4} mb={-2}>
              {
                <>
                  {" "}
                  <Box
                    mt={4}
                    mb={-2}
                    role={"form"}
                    aria-labelledby={"bankAccount"}
                  >
                    <Typography variant="h3" id="bankAccount">
                      {t(
                        "erps.billermanagement.label.bankAccount",
                        "Bank account"
                      )}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <ERPSBaseTextField
                        id="creditorFiNumber"
                        items={billerFinancialInstitutionList}
                        SelectProps={{ native: true }}
                        type="text"
                        select={true}
                        label={t(
                          "erps.billermanagement.label.billerFinancialInstitution",
                          "Biller financial institution"
                        )}
                        className="common__textField__width addNewBiller_label_fontSize"
                        size="small"
                        onChange={handleBillerProfile("creditorFiNumber")}
                        value={billerProfile?.creditorFiNumber || ""}
                        InputLabelProps={{
                          shrink: Boolean(billerProfile?.creditorFiNumber),
                        }}
                        required={"true"}
                        error={highlightError?.creditorFiNumber}
                      />
                      <ERPSBaseTextField
                        id="creditorTransitNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.transitNumber",
                          "Transit number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorTransitNumber")}
                        value={billerProfile?.creditorTransitNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorTransitNumber}
                        inputProps={{ maxLength: 5 }}
                      />
                      <ERPSBaseTextField
                        id="creditorAccountNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.accountNumber",
                          "Account number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorAccountNumber")}
                        value={billerProfile?.creditorAccountNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorAccountNumber}
                        inputProps={{ maxLength: 12 }}
                      />
                    </Stack>
                    <Stack direction="row" display={"flex"}>
                      <ERPSBaseTextField
                        id="billerStatus"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.status",
                          "Status"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("billerStatus")}
                        value={billerProfile?.billerStatus || ""}
                        error={highlightError?.billerStatus}
                        select={true}
                        items={statusList}
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: billerProfile?.billerStatus ? true : false,
                        }}
                      />
                      <Box
                        mt={3.5}
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"40%"}
                        ml={2}
                      >
                        <Box>
                          <Typography>
                            {t(
                              "erps.billermanagement.label.liveDate",
                              "Live date"
                            )}
                            <Typography variant="body2">
                              {billerProfile?.billerStatus === "A"
                                ? statisticsFormatDate(
                                    billerProfile?.billerLiveDate
                                  )
                                : 0}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box mr={4}>
                          <Typography>
                            {t("erps.billermanagement.label.settlementType")}
                            <Typography variant="body2">
                              {getSettlementTypeValue()}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </>
              }
            </Box>
          </>
        }
      </Box>
      <Box role={"form"} aria-Labelledby={"paymentAdviceFileDetails"} mt={8}>
        <div className="manageUsers__page__title">
          <Typography variant="h2" id="paymentAdviceFileDetails">
            {t(
              "erps.billermanagement.label.paymentAdviceFileDetails",
              "Payment advice file details"
            )}
          </Typography>
        </div>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="deliveryMethod"
            type="text"
            label={t(
              "erps.billermanagement.label.deilveryMethod",
              "Delivery method"
            )}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("deliveryMethod", e.target.value)
            }
            select={true}
            items={deliveryMethodLists}
            value={initialDeliveryMethods?.deliveryMethod}
            error={
              !initialDeliveryMethods.deliveryMethod &&
              highlightError?.deliveryMethod
            }
            required={"true"}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          />
          <ERPSBaseTextField
            id="distributionFormat"
            type="text"
            label={t("erps.billermanagement.label.format", "Format")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("distributionFormat", e.target.value)
            }
            select={true}
            value={initialDeliveryMethods?.distributionFormat}
            items={getFormatListItem(initialDeliveryMethods?.deliveryMethod)}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink:
                initialDeliveryMethods?.distributionFormat ||
                initialDeliveryMethods?.deliveryMethod
                  ? true
                  : false,
            }}
          />
          <ERPSBaseTextField
            id="distributionListCode"
            type="text"
            InputLabelProps={{ shrink: true }}
            label={t("erps.billermanagement.label.destination", "Destination")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionListCode",
                e.target.value
              )
            }
            value={initialDeliveryMethods.distributionListCode}
            required={"true"}
            error={highlightError?.initialDistributionListCode}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="destinationAttention"
            type="text"
            label={t("erps.billermanagement.label.attention", "Attention")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "destinationAttention",
                e.target.value
              )
            }
            InputLabelProps={{ shrink: true }}
            value={initialDeliveryMethods.destinationAttention}
            inputProps={{ maxLength: 30 }}
          />
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionPhoneNumber"
              type="number"
              label={t(
                "erps.billermanagement.label.telNum",
                "Telephone number"
              )}
              className="common__textField__width_v1"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionPhoneNumber",
                  e.target.value
                )
              }
              InputLabelProps={{ shrink: true }}
              value={checkNonZeroValue(
                initialDeliveryMethods.distributionPhoneNumber
              )}
              inputProps={{ maxLength: 10 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
              error={highlightError?.initialDistributionPhoneNumber}
            />
            <ERPSBaseTextField
              id="distributionExtensionNumber"
              type="number"
              label={t("erps.billermanagement.label.ext", "Ext.")}
              className="common__textField__width_ext"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionExtensionNumber",
                  e.target.value
                )
              }
              InputLabelProps={{ shrink: true }}
              value={checkNonZeroValue(
                initialDeliveryMethods.distributionExtensionNumber
              )}
              inputProps={{ maxLength: 5 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 5);
              }}
            />
          </Stack>
        </Stack>
        {initialDeliveryMethods.deliveryMethod === "EDI" && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionEDIMailbox"
              type="text"
              label={t("erps.billermanagement.label.ediMailBox", "EDI mailbox")}
              className="common__textField__width"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionEDIMailbox",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionEDIMailbox}
              isDisabled={true}
            />
          </Stack>
        )}
        <Stack direction="row" spacing={0}>
          <ERPSBaseRadioGroup
            name="distributionGroupRcpIndex"
            value={initialDeliveryMethods?.distributionGroupRcpIndex || "C"}
            InputLabelProps={{ shrink: true }}
            label={t(
              "erps.billermanagement.distributionGroupRcpIndex.text",
              "Applies to"
            )}
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionGroupRcpIndex",
                e.target.value
              )
            }
            items={appliesToItems}
            error={highlightError?.distributionGroupRcpIndex}
          />
        </Stack>
        {deliveryMethods && deliveryMethods.length ? (
          <DeliveryMethodComponent
            deliveryMethods={deliveryMethods}
            handleChangeDeliveryMethods={handleChangeDeliveryMethods}
            getFormatListItem={getFormatListItem}
            deliveryMethodLists={deliveryMethodLists}
            handleRemoveDeliveryMethod={handleRemoveDeliveryMethod}
            handleClickDeliveryMethod={handleClickDeliveryMethod}
            count={count}
            error={reqValidationForDelivery}
            appliesToItems={appliesToItems}
            fiNumber={fiNumber}
          />
        ) : (
          ""
        )}

        <Box mt={!(deliveryMethods && deliveryMethods.length) ? 3 : 0}>
          {!deliveryMethods.length && (
            <Stack direction="row" spacing={0}>
              <ERPSBaseLink
                className="link__blue"
                label={t(
                  "erps.billermanagement.label.addDeilveryMethod",
                  "Add a delivery method"
                )}
                target={""}
                onClick={() => handleClickDeliveryMethod()}
              />
            </Stack>
          )}
          {hasAccess("recipientCcin") && (
            <Stack direction={"row"}>
              <ERPSBaseTextField
                id="recipientCcin"
                type="number"
                label={t(
                  "erps.billermanagement.label.ccin",
                  "Cooperate creditor id number"
                )}
                className="common__textField__width_v4"
                size="small"
                onChange={handleBillerProfile("recipientCcin")}
                value={billerProfile?.recipientCcin || ""}
                error={highlightError?.recipientCcin}
                required={checkRequiredRecipientCcin() ? "true" : ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 8);
                }}
              />
            </Stack>
          )}
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.billermanagement.label.deliveryDetails",
              "Delivery details"
            )}
          </Typography>
        </Box>

        <ERPSBaseRadioGroup
          marginTop=".4rem"
          value={billerProfile?.nilReportIndex || "N"}
          label={t("erps.billermanagement.label.nilReports", "Nil reports")}
          id="nilReportIndex"
          onChange={handleBillerProfile("nilReportIndex")}
          items={nilReports}
          required={"true"}
          error={highlightError?.nilReportIndex}
        />
      </Box>
      <Box
        className="manageUsers__page__title"
        role={"form"}
        aria-Labelledby={"billingAccountValidation"}
      >
        <Typography variant="h2" id="billingAccountValidation">
          {t(
            "erps.billermanagement.label.billingAccountValidation",
            "Billing account validations"
          )}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCustomerNumberFormat"
            type="text"
            label={t(
              "erps.billermanagement.label.accountFormat",
              "Account format"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberFormat")}
            value={billerProfile?.billerCustomerNumberFormat || ""}
            error={highlightError?.billerCustomerNumberFormat}
            inputProps={{ maxLength: 45 }}
          />

          <ERPSBaseTextField
            id="billerCustomerNumberMinimum"
            type="number"
            label={t(
              "erps.billermanagement.label.minimumLength",
              "Minimum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMinimum")}
            value={checkNonZeroValue(
              billerProfile?.billerCustomerNumberMinimum
            )}
            InputLabelProps={{ shrink: true }}
            error={highlightError?.billerCustomerNumberMinimum}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
          <ERPSBaseTextField
            id="billerCustomerNumberMaximum"
            type="number"
            label={t(
              "erps.billermanagement.label.maximumLength",
              "Maximum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMaximum")}
            value={checkNonZeroValue(
              billerProfile?.billerCustomerNumberMaximum
            )}
            InputLabelProps={{ shrink: true }}
            error={highlightError?.billerCustomerNumberMaximum}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
        </Stack>
      </Box>
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.comment", "Comments")}
        </Typography>
      </Box>
      <Box mt={1}>
        <TextareaAutosize
          aria-label="Comments"
          placeholder={t("erps.billermanagement.label.comment", "Comments")}
          className={"common__textField__prefix_text"}
          id="billerComment"
          onChange={handleBillerProfile("billerComment")}
          value={billerProfile?.billerComment || ""}
          error={highlightError?.billerComment}
          maxLength={160}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.newuser.button.cancel", "Cancel")}
          onClick={handleOpenCancelDialog}
        />
        <ERPSBaseButton
          variant="primary"
          onClick={handleSave}
          className="button_with_margin48"
          label={t("erps.newuser.button.save", "Save")}
          loading={saveLoading}
        />
      </Stack>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </Box>
  );
}

export default EditBillerProfileHsbcUser;

import budgetCategoryActionType from "../actions/types/budgetCategory.action.type";

export const budgetCategoryInitialState = {
  budget: null,
  error: null,
  newBudgetCategoryAdded: null,
  budgetCategoryUpdated: null,
  budgetCategoryFiAssociated: null,
  budgetCategoryFiAssociatedError: null
};

export default function budgetCategoryReducer(
  state = budgetCategoryInitialState,
  action
) {
  switch (action.type) {
    case budgetCategoryActionType.BUDGET_CATEGORY_SUCCESS:
      return { ...state, budget: action.data, error: null };
      case budgetCategoryActionType.BUDGET_CATEGORY_ASSOCIATED_FINUMBER_SUCCESS:
      return { ...state, budgetCategoryFiAssociated: action.data, budgetCategoryFiAssociatedError: null };   
      case budgetCategoryActionType.BUDGET_CATEGORY_ASSOCIATED_FINUMBER_FAILURE:
      return { ...state, budgetCategoryFiAssociated: null, error: action.error };
    case budgetCategoryActionType.BUDGET_CATEGORY_FAIL:
      return { budget: null, error: action.error };
    case budgetCategoryActionType.NEW_BUDGET_CATEGORY_SUCCESS:
      return {
        ...budgetCategoryInitialState,
        error: null,
        newBudgetCategoryAdded: action.data,
      };
    case budgetCategoryActionType.UPDATE_BUDGET_CATEGORY_SUCCESS:
      return {
        ...budgetCategoryInitialState,
        error: null,
        budgetCategoryUpdated: action.data,
        budget: action.budget,
      };

    case budgetCategoryActionType.UPDATE_FAIL:
      return { ...budgetCategoryInitialState, error: action.error, budget: action.budget };

    case budgetCategoryActionType.DELETE_BUDGET_CATEGORY_SUCCESS:
        return {
          ...budgetCategoryInitialState,
          error: null,
          budgetCategoryUpdated: action.data,
          budget: action.budget,
      };
     case budgetCategoryActionType.DELETE_BUDGET_CATEGORY_ERROR:
        return {
          ...budgetCategoryInitialState,
          error: action.error,
          budgetCategoryUpdated: null,
          budget: action.budget,
      };

    default:
      return state;
  }
}

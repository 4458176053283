import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  formatAmount,
  printPaginationFooter,
  cbasTotalRows,
} from "./helper";

const CbasTable = (props) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.billerId")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.billerName")}
            </TableCell>
            <TableCell align="center">
              {t("erps.statisticsEnquiry.page.label.volume")}
            </TableCell>
            <TableCell align="right">
              {t("erps.statisticsEnquiry.page.label.amount")}
            </TableCell>
            <TableCell align="center">
              {t("erps.statisticsEnquiry.page.label.firstActivity")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.pageData.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row?.externalBillerId}</TableCell>
              <TableCell align="left">{row?.billerName}</TableCell>
              <TableCell align="center">{row?.totalTransactionCount}</TableCell>
              <TableCell align="right">
                {formatAmount(row?.totalTransactionAmount)}
              </TableCell>
              <TableCell align="center">
                {row?.billerFirstActivityDate}
              </TableCell>
            </TableRow>
          ))}
          {/* {props.initialDataLength > 100 && ( */}
            <>
              {/* <TableRow key="paginate-footer">
                <TableCell colSpan={5} align="right">
                  {printPaginationFooter(
                    props.rowPerPage,
                    props.pageNumber,
                    props.initialDataLength
                  )}
                </TableCell>
              </TableRow> */}
              {/* <TableRow key="paginate-footer2">
                <TableCell colSpan={5} align="right"></TableCell>
              </TableRow> */}
              {cbasTotalRows(props.viewStatisticsFirstData, props.pageData).map(
                (row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{row?.billerName}</TableCell>
                    <TableCell align="center">
                      {row?.totalTransactionCount}
                    </TableCell>
                    <TableCell align="right">
                      {formatAmount(row?.totalTransactionAmount)}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                )
              )}
            </>
          {/* )} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CbasTable;
import React, { useEffect, useState, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import UpdateBillerApproval from "./UpdateBillerApproval";
import { useDispatch, useSelector } from "react-redux";
import NewBillerApproval from "./NewBillerApproval";
import { billerPendingApprovalsActions } from "../../actions/billerPendingApprovals.actions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";

const ApprovalBiller = (props) => {
  const { t } = useTranslation();

  const {
    location: { state },
  } = props;
  const userDetails = useSelector((state) => state.loginUserReducer?.user);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const message = useSelector(
    (state) => state.billerApprovalReducer?.billerActionMessage
  );
  const nextTransactionId = useSelector(
    (state) => state.billerApprovalReducer?.nextTransactionId
  );
  const dispatch = useDispatch();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showExceptionMessage, setShowExceptionMessage] = useState(false);

  const [initialLoading, setInitialLoading] = useState(false);

  const billerPendingDataV1 = useSelector(
    (state) => state.billerApprovalReducer?.billerPendingApprovalData
  );
  const billerUserApprovalData = useSelector(
    (state) => state.billerApprovalReducer?.billerUserApprovalData
  );

  const billerUserApprovalError = useSelector(
    (state) => state.billerApprovalReducer?.billerUserApprovalError
  );

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [message && showSuccessMessage]);

  useEffect(() => {
    const getData = async () => {
      let id;
      if (nextTransactionId !== null && nextTransactionId !== "") {
        id = nextTransactionId;
      } else if (billerPendingDataV1 && billerPendingDataV1.length === 1) {
        id = billerPendingDataV1[0]?.id;
      } else if (state.transactionId !== undefined) {
        id = state.transactionId;
      }
      const data = billerPendingDataV1.find(
        ({ id: billerId }) => billerId == id
      );
      setInitialLoading(true);
      setShowExceptionMessage(false);
      if (nextTransactionId != "" && data) {
        dispatch(
          billerPendingApprovalsActions.getBillerChangesDetails(
            {
              transactionId: data.id,
              actionCode: data?.actionCode,
              languageCode: userDetails.languageCode || "E",
              fiNumber: userDetails.fiNumber,
              selectedFiNumber: selectedFiNumber,
              billerId: data.billerId,
            },
            setInitialLoading,
            setShowExceptionMessage,
            t
          )
        );
      } else {
        setInitialLoading(false);
      }
    };
    getData();
  }, [dispatch, nextTransactionId]);

  return (
    <div role="main">
      {message && showSuccessMessage && (
        <ConfirmationMessage
          message={message}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={state.componentAccess} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={initialLoading}
          onClick={() => setInitialLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {showExceptionMessage && (
          <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
            <ErrorMessage error={billerUserApprovalError} />
          </Box>
        )}
        {billerUserApprovalData &&
          (billerUserApprovalData?.mainData ? (
            <UpdateBillerApproval
              {...props}
              billerData={{
                updatedData: billerUserApprovalData?.mainData,
                data: billerUserApprovalData?.queueData,
              }}
              componentAccess={state.componentAccess}
              userId={loginUser?.userId}
              billerPendingData={billerPendingDataV1}
              setShowMessage={setShowSuccessMessage}
            />
          ) : (
            <NewBillerApproval
              {...props}
              data={billerUserApprovalData?.queueData}
              componentAccess={state.componentAccess}
              userId={loginUser?.userId}
              billerPendingData={billerPendingDataV1}
              setShowMessage={setShowSuccessMessage}
            />
          ))}
      </Box>
    </div>
  );
};

export default withSidebar(ApprovalBiller);

import React from "react";
import { useHistory } from "react-router-dom";
import loginActionTypes from "../../actions/types/login.action.type";
import { useDispatch } from "react-redux";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { useTranslation } from "react-i18next";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function goToLogin() {
    dispatch({ type: loginActionTypes.LOGOUT_REQUEST });
    dispatch({ type: loginActionTypes.LOGOUT_SUCCESS });
    localStorage.removeItem("persist:erps");
    history.push("/");
    window.location.href = "/";
  }

  return (
    <div style={{ marginLeft: "30px", marginTop: "30px" }}>
      <ErrorMessage error={t("erps.error.code.404")} />
    </div>
  );
};

export default ErrorFallback;

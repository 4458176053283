import header from "./header.json";
import footer from "./footer.json";
import login from "./login.json";
import manageUsers from "./manage-users.json";
import pagination from "./pagination.json";
import budgetManagement from "./budget-management.json";
import budgetCategory from "./budget-category.json";
import billerManagement from "./biller-management.json";
import pricePlan from "./manage-price-plan.json";
import billerRegistration from "./biller-registration.json";
import welcome from "./welcome.json";
import financialInstitution from "./financial-institution.json";
import erpsErrorCodes from "./erps-error-codes.json";
import navbar from "./navbar.json";
import marketingManagement from "./marketing-management.json";
import reports from "./reports.json";
import availableDocuments from "./available-documents.json";
import common from "./common.json";
import transactionEnquiry from "./transaction-enquiry.json";
import components from "./component.json";
import statisticsEnquiry from "./statistics-enquiry.json";
import synchronization from "./synchronization.json";
import batchHoliday from "./batch-holiday.json";
import faxManagement from "./fax-management.json";

const translation = {
  ...erpsErrorCodes,
  ...header,
  ...footer,
  ...login,
  ...manageUsers,
  ...pagination,
  ...budgetManagement,
  ...billerManagement,
  ...pricePlan,
  ...billerRegistration,
  ...welcome,
  ...financialInstitution,
  ...navbar,
  ...marketingManagement,
  ...reports,
  ...availableDocuments,
  ...common,
  ...transactionEnquiry,
  ...budgetCategory,
  ...components,
  ...statisticsEnquiry,
  ...synchronization,
  ...batchHoliday,
  ...faxManagement,
};

export default translation;

import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import {
  findReportLabel,
  findSettlementLabel,
  findSortByLabel,
  formatAmount,
  getBillerIdWithName,
  resultsTitle,
} from "./helper";
import {
  BSR,
  CBAS,
  CBANS,
  DSR,
  FIS,
  ALL_BILLERS,
  SPECIFIC_BILLER,
} from "./data";
import { statisticsFormatDate } from "../../utils/helper";
import dndEn from "../../assets/images/dndEn.png";
import CbasTable from "./CbasTable";
import BsrTable from "./BsrTable";
import CbansTable from "./CbansTable";
import DsrTable from "./DsrTable";

class ComponentToPrint extends React.Component {
  render() {
    const { data, fiNumber } = this.props;
    const t = data.t;
    const currentDate = new Date();
    const getFormattedDate = `${statisticsFormatDate(
      currentDate
    )}, ${currentDate.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div role="main" sx={{ paddingRight: "20px" }}>
        <Box ml={3}>
          <Box className="manageUsers__page__title">
            <Typography variant="label">{getFormattedDate}</Typography>
            <br />
            <Typography variant="h4">
              {`${t(
                "erps.statisticsEnquiry.page.label.billPaymentRemittanceReporting"
              )} - ${findReportLabel(data.reportType)?.label}`}
            </Typography>
          </Box>
          <Box className="manageUsers__page__title">
            <Typography variant="h1">
              {findReportLabel(data.reportType)?.label}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h3">
              {t("erps.statisticsEnquiry.page.label.statisticsParameters")}
            </Typography>
          </Box>

          {[FIS, CBAS, CBANS, DSR].includes(data.reportType) && (
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="view__statistics__value">
                {data.reportType === DSR
                  ? t("erps.statisticsEnquiry.page.label.day")
                  : t("erps.statisticsEnquiry.page.label.fromDate")}
                <Typography variant="body2">{data.fromDate}</Typography>
              </Typography>
              {[FIS, CBAS, CBANS].includes(data.reportType) && (
                <Typography className="view__statistics__value left_space">
                  {t("erps.statisticsEnquiry.page.label.toDate")}
                  <Typography variant="body2">{data.toDate}</Typography>
                </Typography>
              )}
              <Typography className="view__statistics__value left_space">
                {t("erps.statisticsEnquiry.page.label.fi")}
                <Typography variant="body2">
                  {`${data.fiDetails?.fi_number} - ${data.fiDetails?.fi_name}`}
                </Typography>
              </Typography>
            </Box>
          )}
          {[CBAS, CBANS, DSR, BSR].includes(data.reportType) && (
            <Box mt={3} sx={{ display: "flex" }}>
              {[CBAS, CBANS, BSR].includes(data.reportType) && (
                <Typography className="view__statistics__value">
                  {t("erps.statisticsEnquiry.page.label.biller")}
                  <Typography variant="body2">
                    {data.showStatisticsFor === ALL_BILLERS
                      ? t("erps.statisticsEnquiry.page.label.allBiller")
                      : data.viewStatisticsBiller
                      ? getBillerIdWithName(
                          data.billerId,
                          data.viewStatisticsBiller?.data?.billerName
                        )
                      : data.billerId}
                  </Typography>
                </Typography>
              )}
              {data.reportType === DSR && (
                <Typography className="view__statistics__value">
                  {t("erps.statisticsEnquiry.page.label.settlementType")}
                  <Typography variant="body2">
                    {findSettlementLabel(data.settlementType, fiNumber)?.name}
                  </Typography>
                </Typography>
              )}
              {[CBAS, DSR].includes(data.reportType) &&
                !(
                  data.reportType === CBAS &&
                  data.showStatisticsFor === SPECIFIC_BILLER
                ) && (
                  <Typography className="view__statistics__value left_space">
                    {t("erps.statisticsEnquiry.page.label.sortBy")}
                    <Typography variant="body2">
                      {findSortByLabel(data.sortResultsBy)?.name}
                    </Typography>
                  </Typography>
                )}
            </Box>
          )}
          <Box mt={4}>
            <Typography variant="h3">
              {resultsTitle(data.reportType, data.viewStatsCountBsr)}
            </Typography>
          </Box>
          {/* FIS */}
          {data.reportType === FIS &&
            (data?.viewStatisticsFirstData ? (
              <Box sx={{ paddingRight: "20px" }}>
                <Box mt={3} sx={{ display: "flex" }}>
                  <Typography className="view__statistics__value">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalVolumeForPeriod"
                    )}
                    <Typography variant="body2">
                      {data.viewStatisticsFirstData?.totalVolumnForPeriod}
                    </Typography>
                  </Typography>
                  <Typography className="view__statistics__value left_space">
                    {t(
                      "erps.statisticsEnquiry.page.label.totalAmountForPeriod"
                    )}
                    <Typography variant="body2">
                      {formatAmount(
                        data.viewStatisticsFirstData?.totalAmountForPeriod
                      )}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="label"
                    className="view__statistics__value left_space"
                  >
                    {t(
                      "erps.statisticsEnquiry.page.label.averageTransactionAmount"
                    )}
                    <Typography variant="body2">
                      {formatAmount(
                        data.viewStatisticsFirstData?.averageTransaction
                      )}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ) : (
              `0 ${t("erps.statisticsEnquiry.page.label.resultsFound")}`
            ))}

          {/* Reports not equal to FIS */}
          {data.reportType !== FIS &&
            (Array.isArray(data.pageData) && data.pageData.length > 0 ? (
              <Box sx={{ paddingRight: "20px" }}>
                {/* CBAS Results */}
                {data.reportType === CBAS && (
                  <Box mt={3}>
                    <Grid item xl={9} lg={12} md={12} sm={12} xs={12}>
                      <CbasTable
                        pageData={data.pageData}
                        initialDataLength={data.initialDataLength}
                        rowPerPage={data.rowPerPage}
                        pageNumber={data.pageNumber}
                        viewStatisticsFirstData={data.viewStatisticsFirstData}
                      />
                    </Grid>
                  </Box>
                )}
                {/* CBANS Results */}
                {data.reportType === CBANS && (
                  <Box mt={3}>
                    <CbansTable
                      pageData={data.pageData}
                      initialDataLength={data.initialDataLength}
                      rowPerPage={data.rowPerPage}
                      pageNumber={data.pageNumber}
                    />
                  </Box>
                )}
                {/* DSR Results */}
                {data.reportType === DSR && (
                  <>
                    <Box mt={3} sx={{ display: "flex" }}>
                      <Typography className="view__statistics__value">
                        {t(
                          "erps.statisticsEnquiry.page.label.totalSettlementCount"
                        )}
                        <Typography variant="body2">
                          {data.viewStatisticsFirstData?.totalSettlementCount}
                        </Typography>
                      </Typography>
                      <Typography className="view__statistics__value left_space">
                        {t(
                          "erps.statisticsEnquiry.page.label.totalSettlementAmount"
                        )}
                        <Typography variant="body2">
                          {formatAmount(
                            data.viewStatisticsFirstData?.totalSettlementAmount
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <DsrTable
                        pageData={data.pageData}
                        initialDataLength={data.initialDataLength}
                        rowPerPage={data.rowPerPage}
                        pageNumber={data.pageNumber}
                      />
                    </Box>
                  </>
                )}
                {/* BSR Results */}
                {data.reportType === BSR && (
                  <>
                    <Box mt={3} sx={{ display: "flex" }}>
                      <Typography className="view__statistics__value">
                        {t(
                          "erps.statisticsEnquiry.page.label.totalNumberOfTransactions"
                        )}
                        <Typography variant="body2">
                          {data.viewStatisticsFirstData?.totalTransactionCount}
                        </Typography>
                      </Typography>
                      <Typography className="view__statistics__value left_space">
                        {t(
                          "erps.statisticsEnquiry.page.label.totalTransactionAmount"
                        )}
                        <Typography variant="body2">
                          {formatAmount(
                            data.viewStatisticsFirstData?.totalTransactionAmount
                          )}
                        </Typography>
                      </Typography>
                      <Typography className="view__statistics__value left_space">
                        {t(
                          "erps.statisticsEnquiry.page.label.totalSettlementAmount"
                        )}
                        <Typography variant="body2">
                          {formatAmount(
                            data.viewStatisticsFirstData?.totalSettlementAmount
                          )}
                        </Typography>
                      </Typography>
                      <Typography className="view__statistics__value left_space">
                        {t(
                          "erps.statisticsEnquiry.page.label.discrepancyAmount"
                        )}
                        <Typography variant="body2">
                          {formatAmount(
                            data.viewStatisticsFirstData?.discrepancyAmount
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <BsrTable
                        pageData={data.pageData}
                        initialDataLength={data.initialDataLength}
                        rowPerPage={data.rowPerPage}
                        pageNumber={data.pageNumber}
                      />
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              `0 ${t("erps.statisticsEnquiry.page.label.resultsFound")}`
            ))}
          {/* Footer Logo */}
          <Box sx={{ paddingRight: "20px", marginTop: "40px" }}>
            <hr
              style={{
                height: "1px",
                border: "none",
                color: "rgba(224, 224, 224, 1)",
                backgroundColor: "rgba(224, 224, 224, 1)",
              }}
            />
            <Typography sx={{ width: "180px" }} variant="p">
              <Box display={"flex"}>
                <Box>
                  {/* <p
                    style={{
                      fontSize: "8px",
                      marginBottom: "-12px",
                      color: "#6D1652",
                      paddingLeft: "50px",
                    }}
                  >
                    powered by
                  </p> */}
                  <img
                    src={dndEn}
                    alt={"Dye and Durham"}
                    height="80"
                    width="80"
                  />
                </Box>
                <Box sx={{ marginLeft: "auto" }} mt={3}>
                  <Typography variant="label">
                    {window.location.href}
                  </Typography>
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
      </div>
    );
  }
}

export default ComponentToPrint;

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { getFinancialInstitutionHeadings } from "../../constants/FinancialInstitutionConstant";
import actions from "../../actions";
import i18next from "i18next";
import {
  getNextFilterId,
  convertDateStringIntoERPSDateFormat,
  convertDateIntoERPSDateFormat,
  getLabelName,
  getFormattedPhoneNumber,
} from "../../utils/helper";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";

import parse from "html-react-parser";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { findInstructType } from "./helpers/instructText";

const NewBillerApproval = (props) => {
  const { t } = useTranslation();
  const {
    data,
    componentAccess,
    billerPendingData,
    userId: loggedInUser,
  } = props;
  const dispatch = useDispatch();
  const successRef = useRef(null);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [financialInsititutionData, setFinancialInstitutionTableList] =
    useState(data?.billerProfileExtensions);
  const userId = useSelector((state) => state.loginUserReducer?.user?.userId);
  const user = useSelector((state) => state.loginUserReducer?.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const successMessage = useSelector(
    (state) => state.billerApprovalReducer?.billerActionMessage
  );
  const errorMessage = useSelector(
    (state) => state.billerApprovalReducer?.billerActionError
  );
  const errorRef = useRef();

  const masterfinancialInstitutionList = useSelector(
    (state) => state.fiReducer?.masterFiDescriptions
  );

  const getFiLabel = (fiVal) => {
    if (masterfinancialInstitutionList) {
      return masterfinancialInstitutionList.filter(
        ({ fiNumber }) => fiNumber == fiVal
      )[0]?.fiName;
    }
    return "";
  };

  const billerProfileExtensionData = data?.billerProfileExtensions.filter(
    ({ fiNumber }) => fiNumber === 99
  )[0];
  const [selectedRow, setSelectedRow] = useState([]);
  useEffect(() => {
    if (data?.billerProfileExtensions) {
      const dataV1 = data?.billerProfileExtensions
        .map(({ billerStatus, id }) => {
          if (billerStatus === "A") {
            return id;
          }
          return "";
        })
        .filter((e) => Boolean(e));
      setFinancialInstitutionTableList(data?.billerProfileExtensions);
      setSelectedRow(dataV1);
    }
  }, [data?.billerProfileExtensions && data?.billerProfileExtensions?.length]);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const navigateToUserPendingApporvalPage = () => {
    props.history.push("/biller-pending-approvals", { componentAccess });
  };

  const hasLastActiveRecord = () => {
    if (billerPendingData && billerPendingData.length === 1) {
      return true;
    }
    return false;
  };

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();
    const { id, fiNumber, billerId } = data;
    let declineBillerApproval = {
      transactionId: id,
      userId,
      fiNumber: selectedFiNumber ? selectedFiNumber : fiNumber,
      billerId,
      languageCode: i18next.language === "en" ? "E" : "F",
    };
    const filteredQueueDataList = billerPendingData.filter(
      ({ id: billerId }) => billerId != id
    );

    const currentIndexVal = billerPendingData.findIndex(
      ({ id: billerId }) => billerId == id
    );
    const slicedArr = billerPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "id"
    );
    const hasLastRecord = hasLastActiveRecord();
    if (props?.setShowMessage) {
      props?.setShowMessage(false);
    }

    dispatch(
      actions.billerPendingApprovalsActions.declineBillerApprovalData(
        declineBillerApproval,
        {
          ...props,
          setLoading: setDeclineLoading,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === ""
              ? billerPendingData
              : filteredQueueDataList,
          componentAccess,
          setLastRecordDisable,
          hasLastRecord,
        }
      )
    );
  };

  const handleSubmit = (event) => {
    setUpdateLoading(true);
    if (event) event.preventDefault();
    const { id, fiNumber, billerId } = data;
    let createBillerApproval = {
      transactionId: id,
      userId,
      fiNumber: selectedFiNumber ? selectedFiNumber : fiNumber,
      billerId,
      languageCode: i18next.language === "en" ? "E" : "F",
      actionCode: "A",
    };

    const filteredQueueDataList = billerPendingData.filter(
      ({ id: billerId }) => billerId != id
    );

    const currentIndexVal = billerPendingData.findIndex(
      ({ id: billerId }) => billerId == id
    );
    const slicedArr = billerPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "id"
    );
    const hasLastRecord = hasLastActiveRecord();
    if (props?.setShowMessage) {
      props?.setShowMessage(false);
    }

    dispatch(
      actions.billerPendingApprovalsActions.createBillerApprovalData(
        createBillerApproval,
        {
          ...props,
          setLoading: setUpdateLoading,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === ""
              ? billerPendingData
              : filteredQueueDataList,
          componentAccess,
          setLastRecordDisable,
          hasLastRecord,
        }
      )
    );
  };

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const billerPendingApprovalChildAccess = componentAccess.find(
    ({ componentType }) => componentType === "BPA"
  );
  const billerApproverComponentAccess =
    billerPendingApprovalChildAccess?.childComponents;

  return (
    <>
      <Box>
        {successMessage && showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}
      </Box>
      {errorMessage && (
        <Box className="manageUsers__errorMsg__container" role="alert" mt="4">
          <ErrorMessage error={errorMessage} errorRef={errorRef} />
        </Box>
      )}
      <Grid container>
        <Grid item lg={8} sm={12}>
          {/* Heading - Details */}
          <Box>
            <Box className="manageUsers__page__title">
              <Typography variant="h1">
                {t("erps.billermanagement.pendingBiller.heading", {
                  transactionId: data.id,
                  billerId: data.billerId,
                })}
              </Typography>
            </Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.updatebillerapproval.heading.level2.newapprovaldetails"
                )}
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value">
                {t("erps.updatebillerapproval.label.updatedby")}
                <Typography variant="body2">
                  {data?.displayUpdatedBy}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.updatebillerapproval.label.updatedon")}
                <Typography variant="body2">
                  {convertDateStringIntoERPSDateFormat(data.updatedDate)}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.updatebillerapproval.label.action")}
                <Typography variant="body2">
                  {t("erps.billermanagement.value.newaction")}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* Biller Profile */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.billermanagement.page.formtitle", "Biller Profile")}
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {t("erps.billermanagement.label.billerName")}
                <Typography variant="body2">{data?.billerName}</Typography>
              </Typography>
            </Box>

            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ width: "30%" }}>
                {t("erps.billermanagement.label.billerShortName")}
                <Typography variant="body2">{data?.billerShortName}</Typography>
              </Typography>
              {selectedFiNumber == "10" && (
                <Typography className="view_finacial_institution_margin_left">
                  {t("erps.billermanagement.label.billerLink")}
                  <Typography variant="body2">{data?.billerLink}</Typography>
                </Typography>
              )}
              <Typography sx={{ width: "30%", marginLeft: "20px" }}>
                {t("erps.billermanagement.label.address", "Address")}
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {data?.billerAddress}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "88%",
                justifyContent: "space-between",
              }}
            >
              {selectedFiNumber != "10" && (
                <Typography sx={{ width: "20%" }}>
                  {t("erps.billermanagement.label.country", "Country")}
                  <Typography variant="body2">CA</Typography>
                </Typography>
              )}
              <Typography sx={{ width: "20%" }}>
                {t("erps.billermanagement.label.province", "Province")}
                <Typography variant="body2">{data?.billerProvince}</Typography>
              </Typography>
              <Typography sx={{ width: "20%" }}>
                {t("erps.billermanagement.label.postalcode", "Postal code")}
                <Typography variant="body2">
                  {data?.billerPostalCode}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "88%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ width: "20%" }}>
                {t("erps.userprofile.language.text", "Language")}
                <Typography variant="body2">
                  {data?.billerLanguageCode == "E"
                    ? t("erps.common.label.english")
                    : t("erps.common.label.french")}
                </Typography>
              </Typography>
              <Typography sx={{ width: "20%" }}>
                {t("erps.billermanagement.label.priority", "Group priority")}
                <Typography variant="body2">
                  {data?.groupPriority || ""}
                </Typography>
              </Typography>
              <Typography sx={{ width: "20%" }}>
                {t("erps.billermanagement.label.bmoBillerId", "BMO biller ID")}
                <Typography variant="body2">{data?.bmoBillerId}</Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography sx={{ width: "32%" }}>
                {t("erps.billermanagement.label.attention", "Attention")}
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {data?.billerDepartment}
                </Typography>
              </Typography>
              <Typography sx={{ width: "30%", marginLeft: "20px" }}>
                {t("erps.billermanagement.label.city", "City")}
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {data?.billerCity}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* Contact Information */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.button.contactInfo",
                  "Contact information"
                )}
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "85%",
                justifyContent: "space-between",
                wordWrap: "break-word",
              }}
            >
              <Typography>
                {t(
                  "erps.billermanagement.label.primarycontact",
                  "Primary contact"
                )}
                <Typography variant="body2" sx={{ width: "100px" }}>
                  {data?.primaryContactName}
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                }}
                ml={9}
              >
                <Typography style={{ width: "90%" }}>
                  {t("erps.billermanagement.label.telNum", "Telephone number")}
                  <Typography variant="body2" sx={{ width: "100px" }}>
                    {data?.primaryContactPhone
                      ? getFormattedPhoneNumber(data?.primaryContactPhone)
                      : ""}
                  </Typography>
                </Typography>
                <Typography>
                  {t("erps.billermanagement.label.ext", "Ext.")}
                  <Typography variant="body2">
                    {data?.primaryContactExtension || ""}
                  </Typography>
                </Typography>
              </Box>

              <Typography>
                {t("erps.billermanagement.label.faxNumber", "Fax number")}
                <Typography variant="body2" sx={{ width: "100px" }}>
                  {data?.primaryContactFax || ""}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                width: "85%",
                justifyContent: "space-between",
                wordWrap: "break-word",
              }}
            >
              <Typography>
                {t("erps.billermanagement.label.secondarycontact")}
                <Typography variant="body2" sx={{ width: "100px" }}>
                  {data?.secondaryContactName}
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                }}
                ml={9}
              >
                <Typography style={{ width: "90%" }}>
                  {t("erps.billermanagement.label.telNum", "Telephone number")}
                  <Typography variant="body2" sx={{ width: "100px" }}>
                    {data?.secondaryContactPhone
                      ? getFormattedPhoneNumber(data?.secondaryContactPhone)
                      : ""}
                  </Typography>
                </Typography>
                <Typography>
                  {t("erps.billermanagement.label.ext", "Ext.")}
                  <Typography variant="body2">
                    {data?.secondaryContactExtension || ""}
                  </Typography>
                </Typography>
              </Box>

              <Typography>
                {t("erps.billermanagement.label.faxNumber", "Fax number")}
                <Typography variant="body2" sx={{ width: "100px" }}>
                  {data?.secondaryContactFax || ""}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* ToDo - Bank Account FI == 10 */}
          {/* Bank Account FI != 10 */}
          {selectedFiNumber != "10" && (
            <Box>
              <Box mt={4}>
                <Typography variant="h2">
                  {t("erps.billermanagement.label.bankAccount", "Bank account")}
                </Typography>
              </Box>
              <Box mt={3} sx={{ display: "flex" }}>
                <Typography className="available__documents__value">
                  {t(
                    "erps.billermanagement.label.billerFinancialInstitution",
                    "Biller financial institution"
                  )}
                  <Typography variant="body2">
                    {getFiLabel(data?.creditorFiNumber)}
                  </Typography>
                </Typography>
                <Typography className="available__documents__value">
                  {t(
                    "erps.billermanagement.label.transitNumber",
                    "Transit number"
                  )}
                  <Typography variant="body2">
                    {data?.creditorTransitNumber}
                  </Typography>
                </Typography>
                <Typography className="view_finacial_institution_margin_left">
                  {t(
                    "erps.billermanagement.label.accountNumber",
                    "Account number"
                  )}
                  <Typography variant="body2">
                    {data?.creditorAccountNumber}
                  </Typography>
                </Typography>
              </Box>
              <Box mt={3} sx={{ display: "flex" }}>
                <Typography className="available__documents__value">
                  {t("erps.billermanagement.label.liveDate", "Live date")}
                  <Typography variant="body2">
                    {convertDateIntoERPSDateFormat(
                      data?.billerProfileExtensions?.[0]?.billerLiveDate
                    )}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          )}
          {/* Financial Institution */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.updatebillerapproval.heading.financialInstitution")}
              </Typography>
            </Box>
            <Box mt={2}>
              <ERPSGrid
                data={{
                  rows: financialInsititutionData,
                  count: financialInsititutionData
                    ? financialInsititutionData.length
                    : 0,
                  columns: getFinancialInstitutionHeadings(false),
                }}
                dataGridWidth="dataGrid__box__container__pendingApproval__newBiller"
                hideFooterPagination={true}
                selectedRows={selectedRow}
                isRowSelectable={() => false}
                tableLabelText={t(
                  "erps.updatebillerapproval.heading.financialInstitution"
                )}
              />
            </Box>
          </Box>
          {/* Payment advice file details */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.label.paymentAdviceFileDetails",
                  "Payment advice file details"
                )}
              </Typography>
            </Box>
            {data?.distributionList &&
              data.distributionList.length &&
              data.distributionList.map((value, index) => {
                return (
                  <>
                    <Box mt={3}>
                      <Typography variant="h4">
                        {`${t(
                          "erps.billermanagement.label.deilveryMethod",
                          "Delivery method"
                        )} ${index + 1}`}
                      </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: "flex" }}>
                      <Typography className="available__documents__value">
                        {t(
                          "erps.billermanagement.label.deilveryMethod",
                          "Delivery method"
                        )}
                        <Typography variant="body2">
                          {value?.deliveryMethod}
                        </Typography>
                      </Typography>
                      <Typography className="available__documents__value">
                        {t("erps.billermanagement.label.format", "Format")}
                        <Typography variant="body2">
                          {value?.distributionFormat}
                        </Typography>
                      </Typography>
                      <Typography className="view_finacial_institution_margin_left">
                        {t(
                          "erps.billermanagement.label.destination",
                          "Destination"
                        )}
                        <Typography variant="body2">
                          {value?.distributionListCode}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography className="available__documents__value">
                        {t(
                          "erps.billermanagement.label.attention",
                          "Attention"
                        )}
                        <Typography
                          variant="body2"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {value?.destinationAttention}
                        </Typography>
                      </Typography>
                      <Typography className="available__documents__value">
                        {t(
                          "erps.billermanagement.label.telNum",
                          "Telephone number"
                        )}
                        <Typography variant="body2">
                          {value?.distributionPhoneNumber || ""}
                        </Typography>
                      </Typography>
                      <Typography className="view_finacial_institution_margin_left">
                        {t("erps.billermanagement.label.ext", "Ext.")}
                        <Typography variant="body2">
                          {value?.distributionExtensionNumber || ""}
                        </Typography>
                      </Typography>
                    </Box>
                    {value?.deliveryMethod === "EDI" && (
                      <Box mt={3} sx={{ display: "flex" }}>
                        <Typography className="available__documents__value">
                          {t(
                            "erps.billermanagement.label.ediMailBox",
                            "EDI mailbox"
                          )}
                          <Typography variant="body2">
                            {value?.distributionEDIMailbox}
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </>
                );
              })}
          </Box>
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.billermanagement.label.billingAccountValidation",
                  "Billing account validations"
                )}
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.accountFormat",
                  "Account format"
                )}
                <Typography variant="body2">
                  {data?.billerCustomerNumberFormat}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.minimumLength",
                  "Minimum length"
                )}
                <Typography variant="body2">
                  {data?.billerCustomerNumberMinimum || ""}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.maximumLength",
                  "Maximum length"
                )}
                <Typography variant="body2">
                  {data?.billerCustomerNumberMaximum || ""}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value">
                <ERPSBaseCheckBoxGrp
                  label={t(
                    "erps.billermanagement.label.alphaAllowed",
                    "Alpha allowed"
                  )}
                  disabled={true}
                  value={
                    data?.billerMasterRules?.masterRules?.alpha_allowed === "Y"
                      ? true
                      : false
                  }
                  id="alphaAllowed"
                />{" "}
              </Typography>
              {data?.billerMasterRules?.masterRules
                ?.unique_check_digit_routine === "Y" && (
                <Typography className="available__documents__value">
                  {t(
                    "erps.billermanagement.label.uniqueRoutineName",
                    "Unique routine name"
                  )}
                  <Typography variant="body2">
                    {data?.billerMasterRules?.masterRules?.routine_name}
                  </Typography>
                </Typography>
              )}
              {data?.billerMasterRules?.masterRules?.check_digit_validation ===
                "Y" && (
                <Typography className="available__documents__value">
                  {t(
                    "erps.billermanagement.label.checkDigitValidation",
                    "Check Digit Validation"
                  )}
                  <Typography variant="body2">
                    {
                      data?.billerMasterRules?.masterRules
                        ?.check_digit_validation
                    }
                  </Typography>
                </Typography>
              )}
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t("erps.billermanagement.label.modCheck", "Mod check")}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.mod_check}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.digitLocation",
                  "Digit location"
                )}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.check_digit_location}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.validationFromDigit",
                  "Validation from digit"
                )}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.validation_from_digit}
                </Typography>
              </Typography>
              <Typography className="available__documents__value">
                {t(
                  "erps.billermanagement.label.validationToDigit",
                  "Validation to digit"
                )}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.validation_to_digit}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                <ERPSBaseCheckBoxGrp
                  label={t("erps.billermanagement.label.fold", "Fold")}
                  disabled={true}
                  value={
                    data?.billerMasterRules?.masterRules?.rule_fold === "Y"
                      ? true
                      : false
                  }
                  id="ruleFold"
                />{" "}
              </Typography>
              <Typography className="available__documents__value">
                <ERPSBaseCheckBoxGrp
                  label={t("erps.billermanagement.label.subtract", "Subtract")}
                  disabled={true}
                  value={
                    data?.billerMasterRules?.masterRules?.rule_subtract === "Y"
                      ? true
                      : false
                  }
                  id="ruleFold"
                />{" "}
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.billermanagement.label.weighting", "Weighting")}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.rule_weighting}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                <ERPSBaseCheckBoxGrp
                  label={t(
                    "erps.updatebillerapproval.checkbox.label.prefixCheck",
                    "Exclude prefix"
                  )}
                  disabled={true}
                  value={
                    data?.billerMasterRules?.masterRules?.rule_prefix ==
                    "EXCLUDE"
                      ? true
                      : false
                  }
                />{" "}
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.updatebillerapproval.label.prefixtext", "Prefix text")}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.beanPrefix}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                <ERPSBaseCheckBoxGrp
                  label={t(
                    "erps.updatebillerapproval.checkbox.label.suffixCheck",
                    "Exclude suffix"
                  )}
                  disabled={true}
                  value={
                    data?.billerMasterRules?.masterRules?.rule_suffix ==
                    "EXCLUDE"
                      ? true
                      : false
                  }
                />{" "}
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.updatebillerapproval.label.suffixtext", "Suffix text")}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.beanSuffix}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* Image and instruction text */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t(
                  "erps.updatebillerapproval.heading.imageinstructiontext",
                  "Image and instruction text"
                )}
              </Typography>
            </Box>
            {selectedFiNumber == "50" && (
              <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
                <Typography className="available__documents__value">
                  {t("erps.billermanagement.label.instructTextType")}
                  <Typography variant="body2">
                    {
                      findInstructType(billerProfileExtensionData?.instructType)
                        ?.label
                    }
                  </Typography>
                </Typography>
              </Box>
            )}
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl",
                  "English image url"
                )}
                <Typography variant="body2">
                  {data?.billerMasterRules?.masterRules?.image_url}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography
                style={{ width: "85%" }}
                className="available__documents__value"
              >
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.englishinstructiontext",
                  "English instruction text"
                )}
                <Typography variant="body2">
                  {billerProfileExtensionData?.instructTextEnglish &&
                    parse(billerProfileExtensionData?.instructTextEnglish)}
                </Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.label.frenchimageurl",
                  "French image url"
                )}
                <Typography variant="body2">{data?.frenchImageUrl}</Typography>
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography
                style={{ width: "85%" }}
                className="available__documents__value"
              >
                {t(
                  "erps.updatebillerapproval.imageinstructiontext.frenchinstructiontext",
                  "French instruction text"
                )}
                <Typography variant="body2">
                  {billerProfileExtensionData?.instructTextFrench &&
                    parse(billerProfileExtensionData?.instructTextFrench)}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* ToDo - Bank Account FI == 10 */}
          {/* Comments */}
          <Box>
            <Box mt={4}>
              <Typography variant="h2">
                {t("erps.billermanagement.label.comment", "Comments")}
              </Typography>
            </Box>
            <Box mt={3} sx={{ display: "flex", wordWrap: "break-word" }}>
              <Typography className="available__documents__value">
                {t("erps.billermanagement.label.comment", "Comments")}
                <Typography variant="body2">{data?.billerComment}</Typography>
              </Typography>
            </Box>
          </Box>
          {/* Action Buttons */}
          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={() => navigateToUserPendingApporvalPage()}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingCancelAccess"}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="secondary"
              onClick={(e) => handleDecline(e)}
              loading={declineLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "decline",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingDeclineAccess"}
              isDisabled={lastRecordDisable}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="primary"
              onClick={(e) => handleSubmit(e)}
              loading={updateLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "approve",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={billerApproverComponentAccess}
              actionType={"hasBillerPendingApprovalAccess"}
              isDisabled={loggedInUser == data?.updatedBy || lastRecordDisable}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default NewBillerApproval;

import axios from "axios";
import availableDocumentTypes from "../actions/types/availableDocument.action.type";
import store from "../store/index";
import { saveAs } from "file-saver";
import {
  getErrorMessageFromException,
  API_BASE_URL,
  getExceptionMessage,
  formatDate,
  getFileNameToDownload,
} from "../utils/helper";

export const availableDocumentService = {
  getAvailableDocument,
  downloadDocuments,
  getAvailableReports,
  getReportNames,
  downloadReports,
  getReportsList,
};

function downloadDocuments(billerFilesDto, fileFormat, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer?.user;
    const PAGE_NAME = "downloadDocment";
    const roleId = loginUser?.role?.role_id;
    const data = {
      ...billerFilesDto,
      fileFormat,
    };
    axios
      .post(
        API_BASE_URL() + "/available-documents/download",
        {
          ...billerFilesDto,
          downloaded_file_format: fileFormat,
          download_by: loginUser.userId,
          role_type: loginUser?.role?.role_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        if (fileFormat) {
          var blob = new Blob([res.data]);
          const fileName = getFileNameToDownload(PAGE_NAME, roleId, data);
          saveAs(blob, fileName);
          dispatch(success("Successfully download document"));
          if (
            props?.searchBillerFilesDto &&
            props?.t &&
            props?.setInitialLoading
          ) {
            props?.setInitialLoading(true);
            dispatch(
              getAvailableDocument(
                props?.searchBillerFilesDto,
                props?.t,
                props?.setInitialLoading
              )
            );
          }
        }
      })
      .catch((error) => {
        const errMessage = getExceptionMessage(error, props?.t);
        props?.setShowApiExceptionMessage(true);
        dispatch(failure(errMessage));
      });
    function success(data) {
      return {
        type: availableDocumentTypes.AVAILABLE_DOCUMENT_DOWNLOAD_SUCCESS,
        data,
      };
    }
    function failure(error) {
      return {
        type: availableDocumentTypes.AVAILABLE_DOCUMENT_DOWNLOAD_ERROR,
        error,
      };
    }
  };
}

function downloadReports(reportDocData, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const roleId = store.getState()?.loginUserReducer?.user?.role?.role_id;
    const PAGE_NAME = "report";
    axios
      .post(
        API_BASE_URL() + "/available-reports/download",
        {
          ...reportDocData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var blob = new Blob([res.data]);
        const fileName = getFileNameToDownload(
          PAGE_NAME,
          roleId,
          reportDocData
        );

        saveAs(blob, fileName);
        dispatch(success("Successfully download document"));
      })
      .catch((error) => {
        const errMessage = getExceptionMessage(error, props?.t);
        props?.setShowApiExceptionMessage(true);
        dispatch(failure(errMessage));
      });
    function success(data) {
      return {
        type: availableDocumentTypes.AVAILABLE_REPORTS_DOWNLOAD_SUCCESS,
        data,
      };
    }
    function failure(error) {
      return {
        type: availableDocumentTypes.AVAILABLE_REPORTS_DOWNLOAD_ERROR,
        error,
      };
    }
  };
}

function getAvailableDocument(
  searchBillerFilesDto,
  t,
  setLoading,
  setBtnLoading
) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer?.user;
    const selectedFi = store.getState()?.fiReducer?.selectedFi;
    axios
      .post(
        API_BASE_URL() + "/available-documents/list",
        {
          ...searchBillerFilesDto,
          biller_id:
            searchBillerFilesDto.biller_id !== undefined
              ? searchBillerFilesDto.biller_id
              : loginUser?.searchBillerFilesDto?.biller_id,
          fi_number: selectedFi || loginUser?.fiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setBtnLoading) {
          setBtnLoading(false);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setBtnLoading) {
          setBtnLoading(false);
        }
        const errorResponse = getErrorMessageFromException(error, t) || error;
        dispatch(failure(errorResponse, t));
      });

    function success(data) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_DOCUMENT_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_DOCUMENT_ERROR,
        error,
      };
    }
  };
}

function getReportNames(searchBillerFilesDto, t) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer?.user;
    const selectedFi = store.getState()?.fiReducer?.selectedFi;
    axios
      .post(
        API_BASE_URL() + "/available-reports/reportNames",
        {
          ...searchBillerFilesDto,
          biller_id:
            searchBillerFilesDto.biller_id !== undefined
              ? searchBillerFilesDto.biller_id
              : loginUser?.searchBillerFilesDto?.biller_id,
          fiNumber: selectedFi || loginUser?.fiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        const errorResponse = getErrorMessageFromException(error, t) || error;
        dispatch(failure(errorResponse, t));
      });

    function success(data) {
      return {
        type: availableDocumentTypes.GET_REPORTS_NAME_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: availableDocumentTypes.GET_REPORTS_NAME_ERROR,
        error,
      };
    }
  };
}

function getAvailableReports(searchBillerFilesDto, t, setLoading) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    const loginUser = store.getState()?.loginUserReducer?.user;
    const selectedFi = store.getState()?.fiReducer?.selectedFi;
    axios
      .post(
        API_BASE_URL() + "/available-reports/list",
        {
          ...searchBillerFilesDto,
          biller_id:
            searchBillerFilesDto.biller_id !== undefined
              ? searchBillerFilesDto.biller_id
              : loginUser?.searchBillerFilesDto?.biller_id,
          fiNumber:
            loginUser?.searchBillerFilesDto?.fi_number ||
            selectedFi ||
            loginUser?.fiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const errorResponse = getErrorMessageFromException(error, t) || error;
        dispatch(failure(errorResponse, t));
      });

    function success(data) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_REPORTS_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_REPORTS_ERROR,
        error,
      };
    }
  };
}

function getReportsList(searchBillerFilesDto, t) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer?.user;
    const selectedFi = store.getState()?.fiReducer?.selectedFi;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/available-documents/report/list",
        {
          ...searchBillerFilesDto,
          biller_id:
            searchBillerFilesDto.biller_id !== undefined
              ? searchBillerFilesDto.biller_id
              : loginUser?.searchBillerFilesDto?.biller_id,
          fi_number: selectedFi || loginUser?.fiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        const errorResponse = getErrorMessageFromException(error, t) || error;
        dispatch(failure(errorResponse, t));
      });

    function success(data) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_REPORTS_LIST_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: availableDocumentTypes.GET_AVAILABLE_REPORTS_LIST_ERROR,
        error,
      };
    }
  };
}

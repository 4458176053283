import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import translationFrJson from "./translations/fr/translation.js";
import translationEnJson from "./translations/en/translation.js";

const options = {
  order: ["querystring", "navigator", "path", "cookie"],
  lookupQuerystring: "lng",
  lookupFromPathIndex: 0,
  lookupCookie: "meine_nanny_i18next",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //lng: 'en',
    fallbackLng: "en",
    supportedLngs: ["en", "fr"],
    resources: {
      fr: {
        common: translationFrJson,
      },
      en: {
        common: translationEnJson,
      },
    },
    ns: ["common"],
    defaultNS: "common",
    detection: options,
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    react: {
      useSuspense: false,
      wait: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

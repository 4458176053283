import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withERPSTabValidator = (Component) => (props) => {
  const { actionType, componentAccess, setTabIndex, id, value, index } = props;
  const user = useSelector((state) => state.loginUserReducer.user);

  if (hasShowComponent({ componentAccess, actionType, user })) {
    return <Component {...props} />;
  } else {
    if (setTabIndex) {
      if (id === "user-profile-tab-0") {
        setTabIndex(1);
      } else if (id === "user-profile-tab-0") {
        setTabIndex(0);
      }
    }

    return null;
  }
};

const toolbarGroups = [
  [
    {
      id: 1,
      format: "fontFamily",
      toolTipText: "Font family",
      type: "dropdown",
      options: [
        { text: "Sans Serif", value: "sans" },
        { text: "Serif", value: "serif" },
        { text: "MonoSpace", value: "monospace" },
      ],
    },
    {
      id: 2,
      format: "fontSize",
      toolTipText: "Font size",
      type: "dropdown",
      options: [
        { text: "Small", value: "small" },
        { text: "Normal", value: "normal" },
        { text: "Medium", value: "medium" },
        { text: "Huge", value: "huge" },
      ],
    },
  ],
  [
    {
      id: 3,
      format: "bold",
      toolTipText: "Bold",
      type: "mark",
    },
    {
      id: 4,
      format: "italic",
      toolTipText: "Italic",
      type: "mark",
    },
    {
      id: 5,
      format: "underline",
      toolTipText: "Underline",
      type: "mark",
    },
    {
      id: 6,
      format: "strikethrough",
      toolTipText: "Strikethrough",
      type: "mark",
    },
  ],
  [
    {
      id: 9,
      format: "superscript",
      toolTipText: "Superscript",
      type: "mark",
    },
    {
      id: 10,
      format: "subscript",
      toolTipText: "Subscript",
      type: "mark",
    },
  ],
  [
    {
      id: 11,
      format: "headingOne",
      toolTipText: "Heading one",
      type: "block",
    },
    {
      id: 12,
      format: "headingTwo",
      toolTipText: "Heading two",
      type: "block",
    },
    {
      id: 13,
      format: "headingThree",
      toolTipText: "Heading three",
      type: "block",
    },
    {
      id: 14,
      format: "blockquote",
      toolTipText: "Blockquote",
      type: "block",
    },
  ],
  [
    {
      id: 15,
      format: "orderedList",
      toolTipText: "Ordered list",
      type: "block",
    },
    {
      id: 16,
      format: "unorderedList",
      toolTipText: "Unordered list",
      type: "block",
    },
  ],
  [
    {
      id: 17,
      format: "alignLeft",
      toolTipText: "Align left",

      type: "block",
    },
    {
      id: 18,
      format: "alignCenter",
      toolTipText: "Align center",
      type: "block",
    },
    {
      id: 19,
      format: "alignRight",
      toolTipText: "Align right",
      type: "block",
    },
  ],
];

export default toolbarGroups;

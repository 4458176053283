import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSGrid from "../custom/containers/ERPSGrid";
import ErrorMessage from "../custom/containers/ErrorMessage";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import CardList from "../custom/containers/cardList";
import faxManagementActionTypes from "../../actions/types/faxManagement.action.type";
import { getFailedFaxHeading } from "../../constants/FaxConstant";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import moment from "moment";
import { getNumericValue } from "./../../helpers/biller";
const ArchivedSentFaxes = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { state, pathname },
  } = props;
  const [validationErrors, setValidationErrors] = useState([]);
  const [buttonLoading, setButtonLoading] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [financialInstitutionList, setFinancialInstitutionList] = useState([]);
  const successRef = useRef(null);

  const financialInstitutionResponse = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );
  const errorRef = useRef();
  const faxResponse = useSelector((state) => state.faxManagementReducer);
  const successMessage = faxResponse?.resendFax;
  const [hasCellError, setCellError] = useState({});

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [validationErrors.length, errorMessage]);
  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const fetchSentFax = () => {
    if (!initialLoading) {
      setInitialLoading(true);
    }
    const {
      startOffset,
      endOffset,
      searchFaxesBy,
      searchBy,
      operationDate,
      ...rest
    } = searchValues;
    if (setPageSize) {
      setPageSize(0);
    }
    if (setRowPerPage) {
      setRowPerPage(100);
    }
    const formatOperationDate = operationDate
      ? moment(operationDate)?.format("YYYYMMDD")
      : "";

    dispatch(
      actions.faxManagementActions.getSentFaxLists(
        {
          ...rest,
          limit: endOffset,
          offset: startOffset,
          count: 0,
          operationDate: formatOperationDate,
        },
        {
          ...props,
          t,
          history,
          setLoading,
          setErrorMessage,
          setInitialLoading,
          setRefreshGrid,
          refreshGrid,
        }
      )
    );
  };

  useEffect(() => {
    if (refreshGrid) {
      fetchSentFax();
    }
  }, [refreshGrid]);

  const [highlightError, setHighlightError] = useState({});
  const [faxData, setFaxData] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [pageNumber, setPageSize] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const roleId = loginUser?.role?.role_id;

  useEffect(() => {
    dispatch(actions.fiActions.getFinancialInstitutionList(t));
    if (!state?.fromViewFax) {
      dispatch({
        type: faxManagementActionTypes?.RESET_FAX_MANAGEMENT_REQUEST_VALUES,
      });
      setFaxData(null);
    }
    if (state?.searchedValues) {
      setSearchValue(state?.searchedValues);
    }
  }, []);
  useEffect(() => {
    if (financialInstitutionResponse && financialInstitutionResponse.length) {
      const data = financialInstitutionResponse.map(
        ({ fi_name, fi_number }) => ({
          value: fi_number,
          label: `${fi_number} - ${fi_name}`,
        })
      );
      data.unshift({
        value: "",
        label: "",
      });
      setFinancialInstitutionList(data);
    }
  }, [financialInstitutionResponse]);
  const [searchValues, setSearchValue] = useState({
    startOffset: 0,
    endOffset: 100,
    billerId: null,
    billerName: null,
    auditNumber: null,
    operationDate: getOneDayBeforeCurrentDate(),
    status: "C",
    fileName: null,
    fileLocation: null,
    faxNumber: null,
    searchFaxesBy: "viewEntireBillerList",
    searchBy: "billerId",
  });

  const searchFaxesDetails = [
    {
      name: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.label.searchFaxes.viewEntireBillerList"
      ),
      value: "viewEntireBillerList",
    },
    {
      name: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.label.searchFaxes.advancedSearch"
      ),
      value: "advancedSearch",
    },
  ];
  const billerDetails = [
    {
      name: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.label.searchFaxes.billerId"
      ),
      value: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.value.searchFaxes.billerId"
      ),
    },
    {
      name: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.label.searchFaxes.billerName"
      ),
      value: t(
        "erps.faxManagement.archivedSentFaxes.radioButton.value.searchFaxes.billerName"
      ),
    },
  ];
  useEffect(() => {
    const faxResponseData = faxResponse?.faxSearchList?.data;
    if (faxResponseData?.length) {
      const updatedData = faxResponseData?.map((faxData, idx) => ({
        ...faxData,
        id: idx,
      }));
      setFaxData(updatedData);
    } else if (faxResponseData) {
      setFaxData(faxResponseData);
    } else {
      setFaxData(null);
    }
  }, [faxResponse?.faxSearchList]);

  function getOneDayBeforeCurrentDate() {
    const currentDate = new Date();
    const oneDayBefore = new Date(currentDate - 24 * 60 * 60 * 1000);
    return oneDayBefore;
  }

  const validateSearchValues = (searchReqValues) => {
    const {
      billerId,
      billerName,
      operationDate,
      fiNumber,
      searchFaxesBy,
      auditNumber,
    } = searchReqValues;
    let errors = [];
    let highlightErrorV1 = {};
    const date1 = operationDate ? new Date(operationDate) : "";
    const { minDateValue, maxDateValue } = getMinDateValue();

    const billerNameRegex = /^[0-9A-Za-z-&%()_:";',.\/ ]+$/;
    const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
    const validAuditNumberRegEx = /^([1-9]){1}[0-9]{0,8}$/;

    if (date1) {
      if (date1 == "Invalid Date") {
        errors.push(
          t(
            "erps.faxManagement.archivedSentFaxes.page.errorMessage.date.invalid"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, operationDate: true };
      } else if (date1 < minDateValue) {
        errors.push(
          t(
            "erps.faxManagement.archivedSentFaxes.page.datepicker.beforeminimaldate"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, operationDate: true };
      } else if (date1 > maxDateValue) {
        errors.push(
          t(
            "erps.faxManagement.archivedSentFaxes.page.datepicker.aftermaximaldate"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, operationDate: true };
      }
    }

    if (!fiNumber) {
      errors.push(
        t(
          "erps.faxManagement.archivedSentFaxes.page.errorMessage.fiValue.required"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, fiNumber: true };
    }

    if (searchFaxesBy === "advancedSearch") {
      if (billerName && !billerNameRegex.test(billerName)) {
        errors.push(
          t("erps.transactionEnquiry.page.errorMessage.billerName.invalid")
        );
        highlightErrorV1 = { ...highlightErrorV1, billerName: true };
      }
      if (billerName && billerName.includes("%") && billerName.length < 3) {
        errors.push(
          t(
            "erps.billermanagement.filterbillercategory.error.invaliduseofwildcardcharacter"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, billerName: true };
      }
      if (!(billerId || billerName || operationDate || auditNumber)) {
        errors.push(
          t(
            "erps.faxManagement.archivedSentFaxes.page.errorMessage.minimumtwofields.required"
          )
        );
      }
      if (fiNumber == 2 && billerId && !alphaNumericRegEx.test(billerId)) {
        errors.push(
          t(
            "erps.billermanagement.filterbillercategory.error.billerIdAlphaNumeric"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, billerId: true };
      }
      if (auditNumber && !validAuditNumberRegEx.test(auditNumber)) {
        errors.push(
          t("erps.faxManagement.archivedSentFaxes.page.invalidauditnumber")
        );
        highlightErrorV1 = { ...highlightErrorV1, auditNumber: true };
      }
    }

    return { errors, highlightErrorV1 };
  };

  const sanitizeReqBody = (reqBody) => {
    let updatedReqBody;
    Object.keys(reqBody).forEach((key) => {
      if (
        reqBody[key] === undefined ||
        ("string" === typeof reqBody[key] && reqBody[key].trim() === "")
      ) {
        delete reqBody[key];
      } else {
        if (typeof reqBody[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key] };
        }
      }
    });

    return updatedReqBody;
  };

  const handleSearch = () => {
    //const actualCriteria = sanitizeReqBody({ ...searchValues });
    const { errors, highlightErrorV1 } = validateSearchValues(searchValues);
    const {
      startOffset,
      endOffset,
      searchFaxesBy,
      searchBy,
      operationDate,
      ...rest
    } = searchValues;

    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
    } else {
      const formatOperationDate = operationDate
        ? moment(operationDate)?.format("YYYYMMDD")
        : "";
      setValidationErrors([]);
      setHighlightError({});
      setLoading(true);
      if (setPageSize) {
        setPageSize(0);
      }
      if (setRowPerPage) {
        setRowPerPage(100);
      }
      if (errorMessage) {
        setErrorMessage(null);
      }
      dispatch(
        actions.faxManagementActions.getSentFaxLists(
          {
            ...rest,
            limit: endOffset,
            offset: startOffset,
            count: 0,
            operationDate: formatOperationDate,
            ...(searchFaxesBy === "viewEntireBillerList"
              ? { searchType: "V" }
              : { searchType: "A" }),
          },
          { ...props, t, history, setLoading, setErrorMessage }
        )
      );
    }
  };

  const paginateFaxResult = (values) => {
    const actualCriteria = sanitizeReqBody({ ...values });
    const { startOffset, endOffset } = actualCriteria;
    const {
      operationDate,
      billerId,
      billerName,
      auditNumber,
      fiNumber,
      status,
      fileName,
      fileLocation,
      faxNumber,
    } = searchValues;

    if (actualCriteria.startOffset < actualCriteria.count) {
      if (!initialLoading) {
        setInitialLoading(true);
      }
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      const rowPerPage = endOffset - startOffset;
      if (setPageSize) {
        setPageSize(pageNumber);
      }
      if (setRowPerPage) {
        setRowPerPage(rowPerPage);
      }
      const formatOperationDate = operationDate
        ? moment(operationDate)?.format("YYYYMMDD")
        : "";
      const searchRequest = {
        billerId,
        billerName,
        auditNumber,
        fiNumber,
        status,
        fileName,
        fileLocation,
        faxNumber,
        limit: endOffset,
        offset: startOffset,
        count: 0,
        operationDate: formatOperationDate,
      };

      if (errorMessage) {
        setErrorMessage(null);
      }

      dispatch(
        actions.faxManagementActions.getSentFaxLists(searchRequest, {
          ...props,
          t,
          setErrorMessage,
          setLoading: setInitialLoading,
        })
      );
    }
  };

  const handleChange = (e, key) => {
    const value = e?.target?.value;
    if (key === "searchBy") {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
        billerName: null,
        billerId: null,
      }));
    } else if (key === "searchFaxesBy") {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
        billerName: null,
        billerId: null,
        auditNumber: null,
        operationDate:
          value === "viewEntireBillerList"
            ? getOneDayBeforeCurrentDate()
            : null,
      }));
    } else if (key === "operationDate") {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: e,
      }));
    } else {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };
  const handleBlur = (e, key) => {
    const value = e?.target?.value;
    setSearchValue((searchValues) => ({
      ...searchValues,
      [key]: value && typeof value === "string" ? value.toUpperCase() : value,
    }));
  };
  const handleReset = () => {
    setHighlightError({});
    setSearchValue((prevState) => ({
      ...prevState,
      startOffset: 0,
      endOffset: 100,
      billerId: null,
      billerName: null,
      auditNumber: null,
      operationDate: getOneDayBeforeCurrentDate(),
      searchBy: "billerId",
      searchFaxesBy: "viewEntireBillerList",
      fiNumber: null,
    }));
    setValidationErrors([]);
    setErrorMessage(null);
    setLoading(false);
    if (initialLoading) {
      setInitialLoading(false);
    }
    dispatch({
      type: faxManagementActionTypes.GET_SEARCH_SENT_FAX_LISTS_FAILURE,
      error: null,
      data: null,
    });
    setFaxData(null);
  };

  const getMinDateValue = () => {
    const date = new Date();
    const minDateValue = new Date(
      date.getFullYear(),
      date.getMonth() - 13,
      date.getDate()
    );
    const dateOneDayBefore = new Date(date - 24 * 60 * 60 * 1000);
    return {
      minDateValue,
      maxDateValue: date,
      maxDateOneDayBeforeValue: dateOneDayBefore,
    };
  };

  const checkValidFaxIdValue = (faxId) => {
    const numericRegEx = /^1\d{10}$/;

    if (faxId !== undefined) {
      const data = faxData?.find(
        ({ faxNumber, id }) =>
          faxId === id && !numericRegEx.test(faxNumber.toString())
      );
      const cellErrorVal = {
        hasError: true,
        value: data?.faxNumber?.toString(),
        id: data?.id,
      };
      setCellError(cellErrorVal);
      return faxData?.some(
        (row) =>
          faxId === row?.id &&
          (row.faxNumber?.toString().length > 11 ||
            row.faxNumber < 0 ||
            !numericRegEx.test(row.faxNumber.toString()))
      );
    }
  };

  const handleGetCellClassName = (params) => {
    if (params.field === "faxNumber") {
      console.log("params", params);
      const checkError = hasCellError?.id == params.id ? hasCellError : {};
      if (checkError?.hasError) {
        return "billPaymentLimit_error_highlight";
      }
    }
  };

  const handleCellEditCommit = ({ id, field, value }) => {
    if (field === "faxNumber") {
      const updatedRows = faxData.map((row) => {
        if (row.id === id) {
          return { ...row, faxNumber: value };
        }
        return row;
      });
      setFaxData(updatedRows);
    }
  };
  const handleSelectionModelChange = (id, value) => {
    const updatedRows = faxData.map((r) => {
      if (id == r.id) {
        return { ...r, faxNumber: value };
      }
      return r;
    });

    setFaxData(updatedRows);
  };

  const handleResend = (resendType, params) => {
    if (validationErrors && validationErrors?.length) {
      setValidationErrors([]);
    }
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    const faxData = params?.row;
    const reqBody = {
      fiNumber: searchValues?.fiNumber,
      billerStatus: "C",
      faxIds: faxData?.faxId,
      faxNumber: faxData?.faxNumber,
    };
    if (checkValidFaxIdValue(params?.id)) {
      setValidationErrors([
        t("erps.faxManagement.page.errorMessage.faxNumber.10numericcharacters"),
      ]);
    } else {
      const arr = buttonLoading.slice();
      const idx = params?.id;
      const updatedLoadingValue = Array(idx).fill(false);
      updatedLoadingValue.splice(idx, 1, true);
      setButtonLoading(updatedLoadingValue);
      dispatch(
        actions.faxManagementActions.resendFax(reqBody, {
          setLoading: setButtonLoading,
          setErrorMessage,
          index: idx,
          loadingArr: arr,
          t,
          setShowSuccessMessage,
          setRefreshGrid,
        })
      );
    }
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        {validationErrors.length || errorMessage ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={validationErrors.length || errorMessage ? 2 : 0}
          >
            {validationErrors.length || errorMessage ? (
              <ErrorMessage
                error={
                  validationErrors?.length ? validationErrors : errorMessage
                }
                errorRef={errorRef}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.faxManagement.archivedSentFaxes.page.title")}
          </Typography>
        </Box>
        <Stack direction={"row"}>
          <Box mt={5} mr={4}>
            <Typography variant="h3">
              {t("erps.faxManagement.archivedSentFaxes.label.fiDropDownList")}
            </Typography>
          </Box>
          <ERPSBaseTextField
            id="financialInstitution"
            type="text"
            select={true}
            label={t("erps.newuser.label.fi", "Financial Institution")}
            className="three_column__textField"
            value={searchValues?.fiNumber || ""}
            onChange={(e) => handleChange(e, "fiNumber")}
            size="small"
            required="true"
            items={financialInstitutionList}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: searchValues?.fiNumber ? true : false }}
          />
        </Stack>
        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.faxManagement.archivedSentFaxes.label.searchFaxes")}
          </Typography>
        </Box>
        <Box mt={-4} display={"flex"}>
          <ERPSBaseRadioGroup
            id="searchFaxesBy"
            InputLabelProps={{ shrink: true }}
            value={searchValues?.searchFaxesBy}
            onChange={(e) => handleChange(e, "searchFaxesBy")}
            items={searchFaxesDetails}
            error={highlightError?.searchFaxesBy}
            row={false}
          />
          {searchValues?.searchFaxesBy === "viewEntireBillerList" && (
            <Box mt={1} ml={4}>
              <ERPSBaseDatePicker
                label={t(
                  "erps.faxManagement.archivedSentFaxes.date.label.searchFaxes"
                )}
                onChange={(e) => handleChange(e, "operationDate")}
                value={searchValues?.operationDate}
                className="app_datepicker_biller_transactionEnquiry"
                datePickerStyle={"quick-reg-datepicker-v1"}
                maxDate={getMinDateValue()?.maxDateOneDayBeforeValue}
                minDate={getMinDateValue()?.minDateValue}
                error={highlightError?.operationDate}
                id="operationDate"
                hasRequired={false}
              />
            </Box>
          )}
        </Box>

        {searchValues?.searchFaxesBy === "advancedSearch" ? (
          <>
            {searchValues?.searchBy === "billerName" && (
              <Box mt={4}>
                <Typography variant="body1">
                  {t(
                    "erps.faxManagement.archivedSentFaxes.page.wildcardDescription"
                  )}
                </Typography>
              </Box>
            )}
            <Stack direction="row" spacing={2} mt={1}>
              <Box mt={2}>
                <ERPSBaseRadioGroup
                  id="searchBy"
                  marginTop={"true"}
                  InputLabelProps={{ shrink: true }}
                  value={searchValues?.searchBy}
                  label={t("erps.transactionEnquiry.page.label.searchBy")}
                  onChange={(e) => handleChange(e, "searchBy")}
                  items={billerDetails}
                  error={highlightError?.searchBy}
                  labelPlacement="start"
                  className={"transactionEnquiry__radioButton_direction"}
                />
              </Box>
              <Box style={{ marginTop: "-16px" }}>
                {searchValues?.searchBy === "billerId" && (
                  <ERPSBaseTextField
                    id="billerId"
                    type={searchValues?.fiNumber == 2 ? "text" : "number"}
                    label={t("erps.transactionEnquiry.page.label.billerId")}
                    className="transactionEnquiry__textField__width"
                    size="small"
                    onChange={(e) => handleChange(e, "billerId")}
                    value={searchValues?.billerId}
                    error={highlightError?.billerId}
                  />
                )}
                {searchValues?.searchBy === "billerName" && (
                  <ERPSBaseTextField
                    id="billerName"
                    type="text"
                    label={t("erps.transactionEnquiry.page.label.billerName")}
                    className="transactionEnquiry__textField__width"
                    size="small"
                    onChange={(e) => handleChange(e, "billerName")}
                    value={searchValues?.billerName}
                    error={highlightError?.billerName}
                    onBlur={(e) => handleBlur(e, "billerName")}
                  />
                )}
              </Box>
            </Stack>
            <Stack direction="row" spacing={2}>
              <ERPSBaseTextField
                id="auditNumber"
                type="number"
                label={t(
                  "erps.faxManagement.archivedSentFaxes.label.auditNumber"
                )}
                className="transactionEnquiry__textField__width"
                size="small"
                onChange={(e) => handleChange(e, "auditNumber")}
                value={searchValues?.auditNumber}
                error={highlightError?.auditNumber}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 9);
                }}
              />
              <ERPSBaseDatePicker
                label={t(
                  "erps.faxManagement.archivedSentFaxes.date.label.searchFaxes"
                )}
                onChange={(e) => handleChange(e, "operationDate")}
                value={searchValues?.operationDate}
                className="app_datepicker_biller_transactionEnquiry"
                datePickerStyle={"quick-reg-datepicker-v1"}
                maxDate={getMinDateValue()?.maxDateValue}
                minDate={getMinDateValue()?.minDateValue}
                error={highlightError?.operationDate}
                id="operationDate"
                hasRequired={false}
              />
            </Stack>
          </>
        ) : (
          ""
        )}

        <Box display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.transactionEnquiry.page.button.reset")}
            onClick={handleReset}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.transactionEnquiry.page.button.search")}
              onClick={handleSearch}
              loading={loading}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Typography variant="h2">
            {" "}
            {t("erps.manageusers.searchuser.searchresults")}
          </Typography>
        </Box>
        {faxData !== null && faxData !== undefined && (
          <Box mt={1} mb={1.5}>
            <Typography variant="body1">
              {faxResponse?.faxSearchList?.count || faxData?.length}{" "}
              {t("erps.manageusers.searchuser.resultsfound")}
            </Typography>
          </Box>
        )}
        {faxData && faxData?.length ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: faxData ? faxData : [],
                  count: faxData ? faxResponse?.faxSearchList?.count : 0,
                  columns: getFailedFaxHeading(
                    {
                      hasArchivedSentPage: true,
                      history,
                      pathname,
                      searchValues,
                      hasViewOnlyPage: roleId == 10,
                    },
                    { buttonLoading, handleResend }
                  ),
                }}
                onAnyPaginationAction={paginateFaxResult}
                dataGridWidth="dataGrid__box__container__failedfax"
                initialLoading={initialLoading}
                pageNumber={pageNumber}
                rowPerPage={rowPerPage}
                tableLabelText={t(
                  "erps.faxManagement.archivedSentFaxes.page.title"
                )}
                getCellClassName={handleGetCellClassName}
                handleCellEditCommit={handleCellEditCommit}
                handleSelectionModelChange={handleSelectionModelChange}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: faxData,
                  count: faxData
                    ? faxResponse?.faxSearchList?.count || faxData?.length
                    : "0",
                  columns: getFailedFaxHeading(
                    {
                      hasArchivedSentPage: true,
                      history,
                      pathname,
                      searchValues,
                      hasViewOnlyPage: roleId == 10,
                    },
                    { buttonLoading, handleResend }
                  ),
                }}
                onAnyPaginationAction={paginateFaxResult}
                pageNumber={pageNumber}
                rowPerPage={rowPerPage}
                pageName={"fax-management"}
              />
            </Box>
          </>
        ) : (
          <Box ml={1} mt={1} sx={{ display: "flex" }}>
            {/* <CircularProgress /> */}
          </Box>
        )}
        {(faxData === null || faxData === undefined) && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {t(
                "erps.availabledocuments.searchuser.notstarted",
                "Search not started"
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default withSidebar(ArchivedSentFaxes);

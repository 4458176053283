import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import React from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import CommonContainerBox from "../custom/containers/CommonContainerBox";

import ChangePassword from "../User/ChangePassword";
import BillerProfile from "../User/BillerProfile";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import Footer from "../Footer/Footer";

const AccountRegistration = (props) => {
  const { t } = useTranslation();

  const hasUserUpdated = useSelector(
    (state) => state.loginUserReducer?.message
  );
  const hasUserPasswordUpdated = useSelector(
    (state) => state.userPasswordReducer?.user?.message
  );

  let user = useSelector((state) => state.loginUserReducer.user);
  const fiName = localStorage.getItem("fiName")?.toUpperCase();

  const {
    location: { state },
  } = props;

  return (
    <>
      <Header {...props} />
      <div role="main">
        <CommonContainerBox
          title={t("erps.accountRegistration.formtitle.message")}
          marginTop="100px"
          padding="0px 24px 0px 24px"
          minHeight="calc(100vh - 280px)"
        >
          {hasUserUpdated && <ConfirmationMessage message={hasUserUpdated} />}
          {hasUserPasswordUpdated && (
            <ConfirmationMessage message={hasUserPasswordUpdated} />
          )}
          <Box ml={3}>
            <Box mt={2}>
              <Typography variant="h1">
                {t("erps.welcome.page.title", "Welcome")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                {t("erps.welcome.page.accountsettings.description", {
                  fi_name: fiName,
                  service_name:
                    fiName === "BMO"
                      ? t(
                          "erps.app.title.message.bmo",
                          "Merchant Bill Pay Service"
                        )
                      : t(
                          "erps.app.title.message",
                          "Bill payment remittance reporting"
                        ),
                })}
              </Typography>
            </Box>
            <Box mt={6}>
              <Typography variant="h2">
                {t("erps.accountsettings.label", "Account settings")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                {t(
                  "erps.billerregistration.label.registration.required",
                  "All fields are required in order to complete registration."
                )}
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  marginTop: "1.5rem",
                }}
              >
                <BillerProfile
                  {...props}
                  profileId={user?.userId}
                  pageName="account-registration"
                />
              </Box>
            </Box>
          </Box>
        </CommonContainerBox>
      </div>
      <Footer />
    </>
  );
};

export default AccountRegistration;

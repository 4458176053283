import axios from "axios";
import loginActionTypes from "../actions/types/login.action.type";
import passwordActionTypes from "../actions/types/password.action.type";
import userActionTypes from "../actions/types/user.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  customErrorAddBillerUser,
  API_BASE_URL,
  roleConfigUrls,
} from "../utils/helper";

export const userService = {
  changePassword,
  getUserDetails,
  saveUser,
  getUserRoleTypeList,
  getSubscribingEntitiesList,
  updateUserDetails,
  checkUserExists,
};

function getChangePasswordIndex(props, values, currentUserId) {
  if (props.userId) {
    return props.userId != currentUserId ? "Y" : "N";
  } else {
    return values.userId != currentUserId ? "Y" : "N";
  }
}

function changePassword(values, currentUserId, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const selectedFi = store.getState()?.fiReducer.selectedFi;
    const roleId = user.role.role_id;

    var fiNumber = null;
    if (props.pageName === "update-password") {
      fiNumber = user.fiNumber;
    } else {
      fiNumber = selectedFi ? selectedFi : user.fiNumber;
    }
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/change-password",
        {
          currentPassword: values.currentpwd,
          newPassword: values.newpwd,
          confirmPassword: values.confirmnewpwd,
          fiNumber: fiNumber,
          userId: props.userId ? props.userId : currentUserId,
          loggedInUserId: user.userId,
          languageCode: user.languageCode,
          roleId: props.userData?.roleId,
          changePasswordIndex: getChangePasswordIndex(
            props,
            values,
            currentUserId
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        props.setLoginPending(false);
        if (props.setShowMessage) {
          props.setShowMessage(true);
        }
        if (props.setSaveAndContinue && props.setOpenCancelDialog) {
          props.setSaveAndContinue(false);
          props.setOpenCancelDialog(false);
        }
        if (res.data?.isSaved) {
          if (!user.emailAddress || user.emailAddress == "") {
            props.history.push("/account-settings");
          } else {
            if (props.pageName == "password-expired") {
              let path;
              if (roleId == 7) {
                path =
                  fiNumber == 10 || fiNumber == 16
                    ? "/manage-billers"
                    : "/review-transactions";
              } else {
                path = roleConfigUrls[roleId] || "/dashboard";
              }
              props.history.push(path);
            } else {
              dispatch(success(res, props.pageName));
            }
          }
          if (
            (props.pageName === "update-password" ||
              props.pageName === "password-expired") &&
            user.changePasswordIndex === "Y"
          ) {
            dispatch({
              type: loginActionTypes.UPDATE_PASSWORD_INDEX_SUCCESS,
              data: { changePasswordIndex: "N" },
            });
          }
        } else {
          dispatch(success(res, props.pageName));
        }
      })
      .catch((error) => {
        let errorMessage = getErrorMessageFromException(error, props.t);
        props.setLoginPending(false);
        const parsedError = JSON.parse(JSON.stringify(error));
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        if (!errorMessage) {
          if (parsedError.status) {
            errorMessage = "Access denied";
          } else {
            errorMessage = parsedError.message;
          }
        }

        dispatch(failure(errorMessage, props.pageName));
      });
  };

  function success(user, pageName) {
    if (pageName === "manage-users") {
      return { type: passwordActionTypes.CHILD_USER_PWD_UPDATE_SUCCESS, user };
    } else {
      return { type: passwordActionTypes.USER_PWD_UPDATE_SUCCESS, user };
    }
  }

  function failure(error, pageName) {
    if (pageName === "manage-users") {
      return { type: passwordActionTypes.CHILD_USER_PWD_UPDATE_ERROR, error };
    } else {
      return { type: passwordActionTypes.USER_PWD_UPDATE_ERROR, error };
    }
  }
}

function getUserDetails({
  count,
  userId = null,
  startOffset,
  endOffset,
  firstName = null,
  lastName = null,
  roleList,
  setLoading,
  t,
  billerId,
}) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/searchuser`,
        {
          count,
          userId,
          offset: startOffset,
          limit: endOffset,
          firstName: firstName ? `${firstName}` : null,
          lastName: lastName ? `${lastName}` : null,
          accessRoleList: roleList,
          fiNumber: user?.fiNumber,
          selectedFiNumber: fiDetail?.selectedFi,
          loggedInUserId: user.userId,
          fiCompanyNumber: user.fiCompanyNumber,
          parentRoleType: user?.role?.role_type,
          billerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        let response = {
          count: res.data.count,
          data:
            res?.data?.data?.map((value, index) => ({
              ...value,
              id: index + 1,
            })) || [],
          userDetails: {
            userId,
            startOffset,
            endOffset,
            firstName,
            lastName,
          },
        };
        dispatch(success(response));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        let errorMessage = getErrorMessageFromException(error, t);
        if (!errorMessage) {
          const parsedError = JSON.parse(JSON.stringify(error));
          if (parsedError.status !== 401) {
            dispatch(failure(parsedError.message));
          }
        } else {
          dispatch(failure(errorMessage));
        }
      });

    function success(data) {
      return { type: userActionTypes.GET_USER_DETAIL_SUCCESS, data };
    }

    function failure(error) {
      return { type: userActionTypes.GET_USER_DETAIL_FAIL, error };
    }
  };
}

function saveUser(
  finalObject,
  setValues,
  props,
  userRoleTypeList,
  subscribingEntitiesList
) {
  return (dispatch) => {
    const loginUser = store.getState()?.loginUserReducer;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUser.user?.fiNumber;
    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    // axios.get(
    //   API_BASE_URL() + `/existUserWithEmailAddressOrNot?emailAddress=${finalObject?.emailAddress}&fiNumber=${fiNumber}`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + loginUser.token,
    //     },
    //   }
    // )
    // .then((res) => {
    //   if(res?.data == false) {
    axios
      .post(
        API_BASE_URL() + "/manage-users/save-user",
        { ...finalObject, loggedInLanguageCode: languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + loginUser.token,
          },
        }
      )
      .then((res) => {
        props.setLoader(false);
        props.setShowSuccessMessage(true);
        dispatch({
          type: userActionTypes.NEW_USER_SUCCESS,
          data: res.data,
          userRoleTypeList: userRoleTypeList,
          subscribingEntitiesList: subscribingEntitiesList,
        });
        setValues({
          userId: "",
          fiNumber,
          roleId: "",
          languageCode: "E",
          fiCompanyNumber: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          phone: "",
          challengeNumber: 0,
          challengeResponse: "",
          changePasswordIndex: "Y",
          logonFailures: 0,
          emailNotification: "N",
          passwordHash: "",
          userIdErrorHighlight: false,
          roleIdErrorHighlight: false,
          subscribingEntityErrorHighlight: false,
          firstNameErrorHighlight: false,
          lastNameErrorHighlight: false,
          emailAddressErrorHighlight: false,
          phoneNumberErrorHighlight: false,
        });
        if (props?.setOpenCancelDialog) {
          props.setOpenCancelDialog(false);
        }
      })
      .catch((error) => {
        const errMessage = customErrorAddBillerUser(error, props.t);
        props.setLoader(false);
        props.setShowSuccessMessage(false);
        if (props?.setOpenCancelDialog) {
          props.setOpenCancelDialog(false);
        }
        dispatch({ type: userActionTypes.FAIL, error: errMessage });
      });
    //   } else {
    //     const errMessage = props.t("erps.newuser.emailExist");
    //     props.setLoader(false);
    //     props.setShowSuccessMessage(false);
    //     if (props?.setOpenCancelDialog) {
    //       props.setOpenCancelDialog(false);
    //     }
    //     dispatch({ type: userActionTypes.FAIL, error: errMessage });
    //   }
    // })
    // .catch((error) => {
    //   const errMessage = customErrorAddBillerUser(error, props.t);
    //   props.setLoader(false);
    //   props.setShowSuccessMessage(false);
    //   if (props?.setOpenCancelDialog) {
    //     props.setOpenCancelDialog(false);
    //   }
    //   dispatch({ type: userActionTypes.FAIL, error: errMessage });
    // });
  };
}

function getSubscribingEntitiesList(fiNumber, translation) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(`${API_BASE_URL()}/load-subscribing-Entities?fiNumber=${fiNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        dispatch({
          type: userActionTypes.GET_SUBSCRIBING_ENTITIES_LIST,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, translation) ||
          JSON.parse(JSON.stringify(error))?.message;
        dispatch({
          type: userActionTypes.GET_SUBSCRIBING_ENTITIES_LIST_ERROR,
          error: parsedError?.message,
        });
      });
  };
}

function updateUserDetails(userInfo, setterMethods) {
  return (dispatch) => {
    const {
      setDialogLoading,
      setOpen,
      setSaveLoading,
      setShowMessage,
      t,
      setErrorMessage,
    } = setterMethods;

    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/manage-users/update-user`,
        { ...userInfo, loggedInLanguageCode: languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setOpen) {
          setOpen(false);
        }
        if (setShowMessage) {
          setShowMessage(true);
        }
        res.data.token = token;

        dispatch({ type: userActionTypes.USER_UPDATE_SUCCESS, data: res.data });
      })
      .catch((error) => {
        if (setOpen) {
          setOpen(false);
        }
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        if (setShowMessage) {
          setShowMessage(false);
        }
        const errorMessage = getExceptionMessage(error, t);
        if (setErrorMessage) {
          setErrorMessage(errorMessage);
        }
        dispatch({
          type: userActionTypes.USER_UPDATE_ERROR,
          error: errorMessage,
        });
      });
  };
}

function getUserRoleTypeList(
  fiNumber,
  roleType,
  functionName,
  translation,
  setRole,
  personalUser
) {
  return (dispatch) => {
    const selectedFiNumber = store.getState()?.fiReducer.selectedFi;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/load-role-types`,
        {
          parentRoleType: roleType,
          functionType: functionName,
          fiNumber,
          selectedFiNumber: selectedFiNumber,
          personalUser,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setRole) {
          if (res.data && res.data.length) {
            const data = res.data.map(({ role_id }) => role_id);
            setRole(data);
          }
        }
        dispatch({
          type: userActionTypes.GET_USER_ROLE_TYPE_LIST,
          data: res.data,
        });
      })
      .catch((error) => {
        const errorResponse = getErrorMessageFromException(error, translation);
        const parsedError = JSON.parse(JSON.stringify(error));
        dispatch({
          type: userActionTypes.GET_USER_ROLE_TYPE_LIST_ERROR,
          error: errorResponse || parsedError.message,
        });
      });
  };
}

function checkUserExists(data) {
  const {
    userId,
    fiNumber,
    translation: t,
    setLoading,
    setErrorMessage,
    history,
    redirectPath,
    pathProps,
    args,
    callbackFunc,
    hasSuccessMessageAvailable,
    handleClose,
    roleId,
  } = data;
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        `${API_BASE_URL()}/checkUserExistOrNot?userId=${userId}&fiNumber=${fiNumber}&roleId=${roleId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (hasSuccessMessageAvailable) {
          handleClose();
        }
        const userExists = res.data;
        if (!userExists?.userStatus) {
          if (redirectPath) {
            history.push(redirectPath, {
              ...pathProps,
              keycloakUserStatus: userExists?.keycloakUserStatus,
            });
          } else if (callbackFunc && args) {
            callbackFunc(args, userExists?.keycloakUserStatus);
          }
        } else {
          setErrorMessage([t("erps.manageusers.errormessage.usernotexists")]);
        }
      })
      .catch((error) => {
        if (hasSuccessMessageAvailable) {
          handleClose();
        }
        setLoading(false);
        const errorMessage = getExceptionMessage(error, t);
        setErrorMessage(errorMessage);
      });
  };
}

import services from "../services";

export const fiActions = {
  getFinancialInstitutionList,
  getHelpDeskPhoneNumber,
  getMasterFiDescriptionList,
  getFinancialInstitutionListByOwningFiNumber,
};

function getFinancialInstitutionList(t) {
  return services.fiService.getFinancialInstitutionList(t);
}

function getFinancialInstitutionListByOwningFiNumber(t) {
  return services.fiService.getFinancialInstitutionListByOwningFiNumber(t);
}

function getMasterFiDescriptionList(t, setIsLoading) {
  return services.fiService.getMasterFiDescriptionList(t, setIsLoading);
}

function getHelpDeskPhoneNumber(fiNumber, roleId, t) {
  return services.fiService.getHelpDeskPhoneNumber(fiNumber, roleId, t);
}

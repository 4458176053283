import { useIdleTimer } from "react-idle-timer";

const useIdleTimeout = ({
  onIdle,
  idleTime,
  isLoggedIn,
  onActive,
  onAction,
}) => {
  const idleTimeout = 1000 * 60 * idleTime; // minutes to milliseconds
  const idleTimer = useIdleTimer({
    events: ['mousedown', 'MSPointerDown', 'keydown'], //'touchstart'
    timeout: idleTimeout,
    onIdle: onIdle,
    startOnMount: isLoggedIn,
    startManually: true,
    onActive: onActive,
    onAction: onAction,
    // debounce: 1000,
    throttle: 1000,
  });
  return idleTimer;
};
export default useIdleTimeout;

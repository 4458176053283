import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import actions from "../../actions";
import moment from "moment";
import domtoimage from "dom-to-image";
import {
  getFormatDetails,
  requiredFormatDate,
  formatAmount,
} from "../../utils/helper";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import DialogContent from "@mui/material/DialogContent";
import CommonContainerBox from "../custom/containers/CommonContainerBox";

const TransactionDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    location: { state },
  } = props;
  const webPortalRoleIds = [1, 2, 3, 4];

  const [openDialog, setDialogOpen] = useState({ isOpen: false });
  const [isRecallTrace, setRecallTrace] = useState(false);

  const transactionEnquiryData = useSelector(
    (state) => state.transactionEnquiryReducer?.transactionEnquiryData?.data
  );
  const successMessage = useSelector(
    (state) => state.transactionEnquiryReducer?.retraceRecallData
  );
  const errorMessage = useSelector(
    (state) => state.transactionEnquiryReducer?.retraceRecallError
  );
  const fiList = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const user = useSelector((state) => state.loginUserReducer.user);
  const roleId = user?.role?.role_id;
  let componentRef = useRef();
  const errorRef = useRef();
  const successRef = useRef();

  useEffect(() => {
    dispatch({ type: "UPDATE_RECALL_RETRACE_INFO_SUCCESS", data: null });
    dispatch(actions.fiActions.getFinancialInstitutionList(t));
  }, []);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage?.length]);

  let data = transactionEnquiryData
    ? transactionEnquiryData.find(({ payment_id }) => payment_id === state.id)
    : {};
  const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;
  const getBillerId = () => {
    return data?.display_biller_id;
  };
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [retraceLoading, setRetraceLoading] = useState(false);
  const [recallLoading, setRecallLoading] = useState(false);
  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  useEffect(() => {
    if (fiList && Array.isArray(fiList)) {
      const rc_flag = fiList.find(
        (report) => report.fi_number == fiNumberValue
      )?.recall_trace_flag;
      if (rc_flag) {
        setRecallTrace(true);
      }
    }
  }, [fiList]);

  const formatDateTime = (d) => {
    if (d) {
      return moment(d).format("YYYY-MM-DD hh:mm:ss:SS");
    } else {
      return "";
    }
  };

  const handleRecallAndRetrace = async (actionName) => {
    const setLoading =
      actionName === "trace" ? setRetraceLoading : setRecallLoading;
    setLoading(true);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    let reqBodyForAddRecallAndRetrace = {};
    if (data) {
      const {
        verification_number,
        payment_date,
        payment_time,
        biller_id,
        transaction_amount,
        fi_customer_name,
      } = data;
      const [date, time] = formatDateTime(new Date())?.split(" ");
      const initiatedDate = Number(date.replaceAll("-", ""));
      const initiatedTime = Number(time?.replaceAll(":", ""));
      reqBodyForAddRecallAndRetrace = {
        verification_number,
        payment_date,
        payment_time,
        biller_id,
        fi_number: selectedFiNumber || user?.fiNumber,
        transaction_amount,
        customer_name: fi_customer_name,
        request_status: "",
        requested_initiated_by: user?.userId,
        request_initiated_date: initiatedDate,
        request_initiated_time: initiatedTime,
        request_type: actionName === "trace" ? "T" : "R",
      };
    }
    const element = document.getElementById("screenshot-element");
    let blobData = await domtoimage.toBlob(element);
    // const canvas = await html2canvas(element);
    // const screenshotData = canvas.toDataURL("image/png");

    // const binaryString = atob(screenshotData.split(",")[1]);

    const reqBodyForSendEmail = {
      fiNumber: user?.fiNumber,
      clientName: data.fi_customer_name,
      userId: user?.userId,
      reqType: actionName === "trace" ? "T" : "R",
      screenshot: blobData,
      language: user?.languageCode,
    };
    dispatch(
      actions.transactionEnquiryActions.udpateRecallAndRetrace(
        { reqBodyForSendEmail, reqBodyForAddRecallAndRetrace },
        {
          setShowSuccessMessage,
          setLoading,
          t,
        }
      )
    );
  };

  const handleOpenCancelDialog = (actionName) => {
    setDialogOpen({ isOpen: true, actionName });
  };

  const displayPaymentTime = (paymentTime) => {
    if (paymentTime) {
      const parsedPaymentTime = paymentTime.toString();
      const updatedPaymentTime = parsedPaymentTime.padStart(8, "0");
      return updatedPaymentTime.replace(/(..)(?!$)/g, "$1:");
    }
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      {errorMessage ? (
        <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
          {errorMessage ? (
            <ErrorMessage error={errorMessage} errorRef={errorRef} />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      <Box ml={3}>
        <Breadcrumb
          {...props}
          componentAccess={state?.componentAccess}
          fromDetailPage={true}
        />
      </Box>

      <Box className="manageUsers__page__title" ml={3} mb={3}>
        {/* For Title update */}
        <CommonContainerBox
          title={t(
            "erps.transactionEnquiry.page.transactionDetails.page.heading"
          )}
        />
        <Typography variant="h1">
          {t("erps.transactionEnquiry.page.transactionDetails.page.heading")}
        </Typography>
      </Box>
      <Box id={"screenshot-element"} ml={3}>
        <Box mb={-2}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.page.transactionDetails.page.subheading"
            )}
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.label.effectiveDate")}
            <Typography variant="body2">
              {requiredFormatDate(data.effective_date_display_format)}
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t(
              "erps.transactionEnquiry.componentToPrint.label.paymentDate/Time"
            )}
            <Typography variant="body2">
              {requiredFormatDate(data.payment_date_display_format) +
                " " +
                displayPaymentTime(data?.payment_time)?.substring(0, 8)}
            </Typography>
          </Typography>
          <Typography
            variant="label"
            className="transaction__enquiry__value left_space"
          >
            {t("erps.transactionEnquiry.componentToPrint.label.deliveryDate")}
            <Typography variant="body2">
              {requiredFormatDate(data.delivery_date_display_format)}
            </Typography>
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.label.paymentAmount")}
            <Typography variant="body2">
              {formatAmount(data.transaction_amount)}
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t(
              "erps.transactionEnquiry.componentToPrint.label.confirmationNumber"
            )}
            <Typography variant="body2">{data.verification_number}</Typography>
          </Typography>
          <Typography
            variant="label"
            className="transaction__enquiry__value left_space"
          >
            {t("erps.transactionEnquiry.componentToPrint.label.auditNumber")}
            <Typography variant="body2">{data.biller_file_number}</Typography>
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t(
              "erps.transactionEnquiry.componentToPrint.label.transactionOrigination"
            )}
            <Typography variant="body2">{`${data?.cap_type || ""} ${
              data.caption_type_description || ""
            }`}</Typography>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails"
            )}
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t(
              "erps.transactionEnquiry.componentToPrint.billerDetails.label.corporateBiller"
            )}
            <Typography variant="body2">
              {data.biller_name} <br />
              {t("erps.common.label.attention")} {data.primary_contact_name}{" "}
              <br />
              {t("erps.common.label.telephone")}{" "}
              {getFormatDetails(data.primary_contact_phone)} <br />
              {t("erps.common.label.fax")}{" "}
              {getFormatDetails(data.primary_contact_fax)} <br />
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t(
              "erps.transactionEnquiry.componentToPrint.billerDetails.label.billerId"
            )}
            <Typography variant="body2">{getBillerId()}</Typography>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.componentToPrint.page.subheading.customerDetails"
            )}
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex" }}>
          {" "}
          <Typography className="transaction__enquiry__value">
            {t(
              "erps.transactionEnquiry.componentToPrint.customerDetails.label.customerAccountNumber"
            )}
            <Typography variant="body2">
              {data.biller_customer_number}
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t(
              "erps.transactionEnquiry.componentToPrint.customerDetails.label.customerName"
            )}
            <Typography variant="body2">{data.fi_customer_name}</Typography>
          </Typography>
          {!(fiNumberValue == 99 && webPortalRoleIds.includes(roleId)) && (
            <Typography
              variant="label"
              className="transaction__enquiry__value left_space"
            >
              {t(
                "erps.transactionEnquiry.componentToPrint.customerDetails.label.bankCardNumber"
              )}
              <Typography variant="body2">
                {data.fiCustomerNumberStr}
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>
      <Stack direction="row" spacing={2} ml={3}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.newuser.button.back", "Back")}
          onClick={() =>
            props.history.push("/review-transactions/", {
              componentAccess: state?.componentAccess,
              fromDetailPage: true,
            })
          }
        />
        <div>
          {/* button to trigger printing of target component */}
          <ReactToPrint
            trigger={() => (
              <ERPSBaseButton
                functionType="NU"
                variant="primary"
                onClick={() => {}}
                className="button_with_margin48"
                label={t(
                  "erps.transactionEnquiry.page.transactionDetails.button.print"
                )}
              />
            )}
            content={() => componentRef}
          />

          {/* component to be printed */}
          <div style={{ display: "none" }}>
            <ComponentToPrint
              translation={t}
              ref={(el) => (componentRef = el)}
              data={data}
              fiNumberValue={fiNumberValue}
              roleId={roleId}
            />
          </div>
        </div>
        {isRecallTrace && (
          <>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t(
                "erps.transactionEnquiry.page.transactionDetails.button.recall"
              )}
              onClick={() => {
                handleOpenCancelDialog("recall");
              }}
              loading={recallLoading}
              actionType={"hasCallAuditBtnAccess"}
              componentAccess={state?.componentAccess}
            />
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t(
                "erps.transactionEnquiry.page.transactionDetails.button.trace"
              )}
              onClick={() => {
                handleOpenCancelDialog("trace");
              }}
              loading={retraceLoading}
              actionType={"hasTraceAuditBtnAccess"}
              componentAccess={state?.componentAccess}
            />
          </>
        )}
      </Stack>

      <Dialog
        open={openDialog?.isOpen}
        onClick={() => {
          setDialogOpen({
            isOpen: false,
            actionName: openDialog?.actionName,
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogHeader
          id="alert-dialog-title"
          onClose={() => {
            setDialogOpen({
              isOpen: false,
              actionName: openDialog?.actionName,
            });
          }}
          className={"box-dialog-header"}
        >
          {openDialog?.actionName && openDialog.actionName === "recall"
            ? t("erps.transactionEnquiry.page.transactionDetails.button.recall")
            : t("erps.transactionEnquiry.page.transactionDetails.button.trace")}
        </DialogHeader>
        <DialogContent
          id="alert-dialog-description"
          className={"cancel-dialog-content-size"}
        >
          {t("erps.transactionEnquiry.page.transactionDetails.dialog.content", {
            actionName: openDialog?.actionName,
          })}
        </DialogContent>
        <DialogActions className={"box-dialog-buttons"}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_zeromargin"
            onClick={() => {
              setDialogOpen({
                isOpen: false,
                actionName: openDialog?.actionName,
              });
            }}
            label={t("erps.relatedbiller.label.cancel")}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_zeromargin"
            onClick={() => handleRecallAndRetrace(openDialog?.actionName)}
            label={"Confirm"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withSidebar(TransactionDetails);

import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatAmount, printPaginationFooter } from "./helper";
import { statisticsFormatDate } from "./../../utils/helper";

const CbansTable = (props) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.no")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.fileDate")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.completionDateTime")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.totalTransactions")}
            </TableCell>
            <TableCell align="right">
              {t("erps.statisticsEnquiry.page.label.totalPayments")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.fileMedium")}
            </TableCell>
            <TableCell align="left">
              {t("erps.statisticsEnquiry.page.label.auditNumber")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.pageData.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row?.id}</TableCell>
              <TableCell align="left">
                {statisticsFormatDate(row?.fileDate)}
              </TableCell>
              <TableCell align="left">
                {statisticsFormatDate(row?.complitionDateTime)}
              </TableCell>
              <TableCell align="left">{row?.totalTransactions}</TableCell>
              <TableCell align="right">
                {formatAmount(row?.totalPayments)}
              </TableCell>
              <TableCell align="left">{row?.fileMedium}</TableCell>
              <TableCell align="left">{row?.auditNumber}</TableCell>
            </TableRow>
          ))}
          {props.initialDataLength > 100 && (
            <TableRow key="paginate-footer">
              <TableCell colSpan={7} align="right">
                {printPaginationFooter(
                  props.rowPerPage,
                  props.pageNumber,
                  props.initialDataLength
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CbansTable;

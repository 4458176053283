const billerProfileTypes = {
  GET_BILLER_PROFILE_SUCCESS: "GET_BILLER_PROFILE_SUCCESS",
  GET_BILLER_PROFILE_ERROR: "GET_BILLER_PROFILE_ERROR",
  BILLER_PROFILE_UPDATE_ERROR: "BILLER_PROFILE_UPDATE_ERROR",
  BILLER_PROFILE_UPDATE_SUCCESS: "BILLER_PROFILE_UPDATE_SUCCESS",
  GET_BILLER_PROFILE_LIST_SUCCESS: "GET_BILLER_PROFILE_LIST_SUCCESS",
  GET_BILLER_PROFILE_LIST_ERROR: "GET_BILLER_PROFILE_LIST_ERROR",
  GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_SUCCESS:
    "GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_SUCCESS",
  GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_ERROR:
    "GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_ERROR",
  BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_ERROR:
    "BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_ERROR",
  BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_SUCCESS:
    "BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_SUCCESS",
  BILLER_PROFILE_CREATE_ERROR: "BILLER_PROFILE_CREATE_ERROR",
  BILLER_PROFILE_CREATE_SUCCESS: "BILLER_PROFILE_CREATE_SUCCESS",
  GET_CUSTOM_ROUTINE_LIST_SUCCESS: "GET_CUSTOM_ROUTINE_LIST_SUCCESS",
  GET_CUSTOM_ROUTINE_LIST_ERROR: "GET_CUSTOM_ROUTINE_LIST_ERROR",
  BILLER_PROFILE_IMAGE_UPLOAD_SUCCESS: "BILLER_PROFILE_IMAGE_UPLOAD_SUCCESS",
  BILLER_PROFILE_IMAGE_UPLOAD_FAILURE: "BILLER_PROFILE_IMAGE_UPLOAD_FAILURE",
};

export default billerProfileTypes;

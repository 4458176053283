import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ERPSBaseButton from "./ERPSBaseButton";
import DialogHeader from "./ERPSBaseModelDialog";

export default function CustomModelDialog(props) {
  const {
    open,
    handleRedirect,
    handleClose,
    tanslation: t
  } = props;

  const getDialogContent = () => {
    return (
      <Typography gutterBottom aria-describedby="dialog-message">
        {t(
          "erps.popup.body.contentV1",
          "Are you sure you want to Cancel and continue?"
        )}
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogHeader
        id="alert-dialog-title"
        onClose={handleClose}
      >
        {t("erps.popup.header.title", "Unsaved changes")}{" "}
      </DialogHeader>
      <DialogContent
        id="alert-dialog-description"
      >
        {getDialogContent()}
      </DialogContent>
      <DialogActions >
        <ERPSBaseButton
          variant="secondary"
          onClick={handleRedirect}
          label={"Yes"}
        />
        <ERPSBaseButton
          variant="primary"
          onClick={handleClose}
          label={"No"}
        />
      </DialogActions>
    </Dialog>
  );
}

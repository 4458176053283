import services from "../services";

export const userPendingApprovalsActions = {
  getPendingUserApprovals,
  updateUserApprovalData,
  createUserApprovalData,
  deleteUserApprovalData,
  declineUserApprovalData,
};

function getPendingUserApprovals(data, setLoading, translation, setUserPendingApprovalData) {
  return services.userPendingApprovalService.getPendingUserApprovals(
    data,
    setLoading,
    translation,
    setUserPendingApprovalData
  );
}

function updateUserApprovalData(updateUserData, props) {
  return services.userPendingApprovalService.updateUserApprovalData(
    updateUserData,
    props
  );
}

function deleteUserApprovalData(deleteUserData, props) {
  return services.userPendingApprovalService.deleteUserApprovalData(
    deleteUserData,
    props
  );
}

function declineUserApprovalData(declineUserData, props) {
  return services.userPendingApprovalService.declineUserApprovalData(
    declineUserData,
    props
  );
}

function createUserApprovalData(createUserApproval, props) {
  return services.userPendingApprovalService.createUserApprovalData(
    createUserApproval,
    props
  );
}

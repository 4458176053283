import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Header from "../../components/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import { passwordActions } from "../../actions/password.actions";
import Footer from "../Footer/Footer";

const CredentialMatch = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userDetails = useSelector((state) => state.forgetPasswordReducer.data);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    props.history.push("/");
  };

  const resendEmailButtonClickHandler = () => {
    const { history } = props;
    setLoading(true);
    const req = {
      userId: userDetails?.userId,
      emailId: userDetails?.emailId,
      language: i18n.language,
      fiNumber: localStorage.getItem("fiNumber"),
      loggedInUserId: userDetails?.userId,
      changePasswordIndex: "Y",
    };
    dispatch(
      passwordActions.generatedAutoPasswordAndSendEmailForForgetPassword({
        ...req,
        setLoading,
        history,
        t,
      })
    );
  };

  return (
    <>
      <Header {...props} />
      <CommonContainerBox
        title={t("erps.forgotpassword.formtitle.message", "Forgot password")}
        marginTop="150px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
      >
        <Grid item lg={6} md={6} sm={12} xs={12} role="main">
          <Box role="form">
            <Typography variant="h1">
              {t("erps.forgotpassword.formtitle.message", "Forgot password")}
            </Typography>

            <Box className="forgotpassword__paragraph">
              <p>
                {t(
                  "erps.login.forgotpassword.crendentialmatch.message.one",
                  "If your credentials match our records, you will receive an email with a new one-time password to be used on your next logon."
                )}
              </p>
              <p>
                {t("erps.login.forgotpassword.crendentialmatch.message.two", {
                  supportContactNumber: userDetails?.serviceContactNumberStr,
                })}
              </p>
            </Box>
          </Box>
          <Box pt={8} display="flex">
            <Box>
              <ERPSBaseButton
                variant="secondary"
                className="button_with_margin48"
                onClick={resendEmailButtonClickHandler}
                label={t(
                  "erps.forgotpassword.resendemailbutton.text",
                  "Resend email"
                )}
                loading={loading}
              />
            </Box>

            <Box pl={2}>
              <ERPSBaseButton
                variant="primary"
                className="button_with_margin48"
                label={t(
                  "erps.forgotpassword.returntologinbutton.text",
                  "Return to log in page"
                )}
                onClick={handleClick}
              />
            </Box>
          </Box>
        </Grid>
      </CommonContainerBox>
      <Footer />
    </>
  );
};

export default CredentialMatch;

import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import pricePlanActionType from "../../actions/types/pricePlan.action.type";
import { validateUser } from "./helper";
import ErrorMessage from "../custom/containers/ErrorMessage";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";

const NewPricePlan = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState([]);
  const [errorHighlights, setErrorHighlights] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state) => state.pricePlanReducer?.newPricePlanAdded
  );
  const apiErrorMessage = useSelector(
    (state) => state.pricePlanReducer?.newPricePlanError
  );
  let languageCode = useSelector(
    (state) => state.loginUserReducer.user.languageCode
  );

  const errorRef = useRef();

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, apiErrorMessage]);
  const {
    history,
    location: {
      state: { componentAccess },
    },
  } = props;

  const newPricePlanComponent = componentAccess.filter(
    ({ componentType, childComponents }) => componentType === "NPP"
  );

  const newPricePlanComponentAccess = newPricePlanComponent[0]?.childComponents;

  const [values, setValues] = useState({
    billingModelDescription: "",
    baseFee: "",
    csvFee: "",
    notificationFee: "",
    perDocumentRate: "",
    maximumDocumentRate: "",
    minimumDocumentRate: "",
  });

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleBlur = (prop) => (e) => {
    if (languageCode === "F" && e.target.value) {
      const parsedValue = Number(e.target.value);
      const value = !Number.isNaN(parsedValue)
        ? parsedValue.toLocaleString("fr")
        : e.target.value;
      setValues({ ...values, [prop]: value });
    }
  };

  const getDecimalSeparator = (inputValues) => {
    let obj = {};
    for (let key in inputValues) {
      const updatedVal = inputValues[key].replace(/,/, ".");
      obj = { ...obj, [key]: updatedVal };
    }
    return obj;
  };

  const addPricePlan = () => {
    dispatch({
      type: pricePlanActionType.NEW_PRICE_PLAN_REQUEST,
      newPricePlanError: null,
      newPricePlanAdded: null,
    });
    let formattedValues = {};
    if (languageCode === "F") {
      formattedValues = getDecimalSeparator(values);
    } else {
      formattedValues = { ...values };
    }
    const errormsg = validateUser(formattedValues);

    if (errormsg?.errorMessage) {
      setError(errormsg.errorMessage);
    }

    if (errormsg?.errorHighlights) {
      setErrorHighlights(errormsg.errorHighlights);
    }

    if (!errormsg?.errorMessage.length) {
      setLoader(true);
      dispatch(
        actions.pricePlanActions.submitPricePlanDetails(
          formattedValues,
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            t,
          }
        )
      );
    }
  };
  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleRedirect = () => {
    history.push("/manage-price-plan", {
      componentAccess,
    });
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={componentAccess} />
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.newpriceplan.page.title", "New price plan")}
          </Typography>
        </Box>
        {error?.length || apiErrorMessage ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={error.length || apiErrorMessage ? 2 : 0}
          >
            {error?.length || apiErrorMessage > 0 ? (
              <ErrorMessage
                error={apiErrorMessage || error}
                errorRef={errorRef}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        <Box component="form" autoComplete="off">
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="priceplanname"
              type="text"
              label={t(
                "erps.newpriceplan.label.priceplanname",
                "Price plan name"
              )}
              className="three_column__textField"
              onChange={handleChange("billingModelDescription")}
              value={values.billingModelDescription}
              size="small"
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasPricePlanNameTextField"
              error={errorHighlights?.billingModelDescription}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="basefee"
              type="text"
              label={t("erps.newpriceplan.label.basefee")}
              className="three_column__textField"
              size="small"
              value={values.baseFee}
              onChange={handleChange("baseFee")}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasBaseFeeTextField"
              onBlur={handleBlur("baseFee")}
              error={errorHighlights?.baseFee}
            />
            <ERPSBaseTextField
              id="csvFee"
              type="text"
              label={t("erps.newpriceplan.label.csvfee")}
              className="three_column__textField"
              size="small"
              value={values.csvFee}
              onChange={handleChange("csvFee")}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasCsvFeeTextField"
              onBlur={handleBlur("csvFee")}
              error={errorHighlights?.csvFee}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="notificationFee"
              type="text"
              label={t("erps.newpriceplan.label.notificationfee")}
              className="three_column__textField"
              size="small"
              value={values.notificationFee}
              onChange={handleChange("notificationFee")}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasNotificationFeeTextField"
              onBlur={handleBlur("notificationFee")}
              error={errorHighlights?.notificationFee}
            />
            <ERPSBaseTextField
              id="perdocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.perdocumentrate")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("perDocumentRate")}
              value={values.perDocumentRate}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasDocumentRateTextField"
              onBlur={handleBlur("perDocumentRate")}
              error={errorHighlights?.perDocumentRate}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="minimumDocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.mindocumentrate")}
              className="three_column__textField"
              size="small"
              value={values.minimumDocumentRate}
              onChange={handleChange("minimumDocumentRate")}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasMinDocumentRateTextField"
              onBlur={handleBlur("minimumDocumentRate")}
              error={errorHighlights?.minimumDocumentRate}
            />
            <ERPSBaseTextField
              id="maximumDocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.maxdocumentrate")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("maximumDocumentRate")}
              value={values.maximumDocumentRate}
              required="true"
              componentAccess={newPricePlanComponentAccess}
              actionType="hasMaxDocumentRateTextField"
              onBlur={handleBlur("maximumDocumentRate")}
              error={errorHighlights?.maximumDocumentRate}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={handleOpenCancelDialog}
              componentAccess={newPricePlanComponentAccess}
              actionType="hasCancelButton"
            />
            <ERPSBaseButton
              functionType="PPPA"
              variant="primary"
              onClick={addPricePlan}
              className="button_with_margin48"
              label={t("erps.newuser.button.save", "Save")}
              loading={loader}
              componentAccess={newPricePlanComponentAccess}
              actionType="hasApprovePricePlanQueue"
            />
          </Stack>
        </Box>
      </Box>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </div>
  );
};

export default withSidebar(NewPricePlan);

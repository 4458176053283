import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@material-ui/core";
import { requiredFormatDate } from "../utils/helper";
import { t } from "i18next";
export const getManageUsersGridHeadings = (userId, componentAccess) => {
  const USER_PENDING_APPROVALS_HEADERS = [
    {
      field: "transactionId",
      headerName: t("erps.manageusers.label.transactionid"),
      width: 130,
      renderCell: (params) => (
        <ERPSBaseLink
          to={{
            pathname: `/user-pending-approvals/approve-user`,
            state: { transactionId: params.value, componentAccess },
          }}
          className="link__blue"
          label={params.value.toString()}
          actionType={"hasUserPendingQueueListAccess"}
          componentAccess={componentAccess}
        />
      ),
      sortable: false,
    },
    {
      field: "userId",
      headerName: t("erps.manageusers.label.userid"),
      width: 150,
      sortable: false,
    },
    {
      field: "updatedBy",
      headerName: t("erps.manageusers.label.updatedby"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.displayUpdatedBy;
      },
    },
    {
      field: "updatedFormattedDate",
      headerName: t("erps.manageusers.label.updatedon"),
      width: 140,
      renderCell: (params) => {
        const t1 = new Date(params.row.updatedDate).getTime();
        const t2 = new Date().getTime();
        const diffDays = Math.floor((t2 - t1) / (24 * 3600 * 1000));

        if (diffDays >= 5) {
          return (
            <Box
              role="tooltip"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box mr={1}>{requiredFormatDate(params.value)}</Box>
              <AccessTimeIcon
                tabIndex={0}
                titleAccess={t("erps.manageusers.label.approvalpendingdays", {
                  value: diffDays,
                })}
              />
            </Box>
          );
        } else {
          return requiredFormatDate(params.value);
        }
      },
      sortable: false,
    },
    {
      field: "actionName",
      headerName: t("erps.manageusers.label.actions"),
      width: 128,
      sortable: false,
    },
  ];

  return USER_PENDING_APPROVALS_HEADERS;
};
export const USER_ACCOUNT_SETTINGS_HEADINGS = [
  {
    field: "attribute",
    headerName: t("erps.manageusers.label.attribute"),
    width: 200,
    sortable: false,
  },
  {
    field: "currentValue",
    headerName: t("erps.manageusers.label.currentvalue"),
    width: 300,
    sortable: false,
    headerClassName: "column_separator_border-right",
  },
  {
    field: "updatedValue",
    headerName: t("erps.manageusers.label.updatedvalue"),
    width: 300,
    sortable: false,
  },
];

export const EMPTY_SEARCH_CRITERIA = {
  userId: "",
  firstName: "",
  lastName: "",
  startOffset: 0,
  endOffset: 25,
};

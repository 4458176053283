import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withERPSBaseDiv = (Component) => (props) => {
  const { actionType, componentAccess, roleType, currentRoleType } = props;
  const user = useSelector((state) => state.loginUserReducer.user);

  if (
    actionType &&
    componentAccess &&
    hasShowComponent({ componentAccess, actionType, user })
  ) {
    return <Component {...props} />;
  } else if (roleType && currentRoleType && roleType === currentRoleType) {
    return <Component {...props} />;
  } else {
    return null;
  }
};

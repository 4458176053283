import { t } from "i18next";

export const ERROR_BUDGET_CATEGORY = {
  BudgetCodeError: t("erps.budgetCategory.BudgetCodeError"),
  BudgetDescriptionError: t("erps.budgetCategory.BudgetDescriptionError"),
  BudgetCodeLengthError: t("erps.budgetCategory.BudgetCodeLengthError"),
  DescriptionLengthError: t("erps.budgetCategory.DescriptionLengthError"),
  BudgetCodeAlphaNumericError: t(
    "erps.budgetCategory.BudgetCodeAlphaNumericError"
  ),
  DescriptionAlphaNumericError: t(
    "erps.budgetCategory.DescriptionAlphaNumericError"
  ),
  DescriptionEmptyError: t("erps.budgetCategory.DescriptionEmptyError"),
};

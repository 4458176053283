import services from "../services";

export const availableDocumentActions = {
  getAvailableDocument,
  downloadDocuments,
  getAvailableReports,
  getReportNames,
  downloadReports,
  getReportsList,
};

function getAvailableDocument(
  searchBillerFilesDto,
  t,
  setLoading,
  setBtnLoading
) {
  return services.availableDocumentService.getAvailableDocument(
    searchBillerFilesDto,
    t,
    setLoading,
    setBtnLoading
  );
}

function getReportsList(searchBillerFilesDto, t) {
  return services.availableDocumentService.getReportsList(
    searchBillerFilesDto,
    t
  );
}

function downloadDocuments(billerFilesDto, fileFormat, props) {
  return services.availableDocumentService.downloadDocuments(
    billerFilesDto,
    fileFormat,
    props
  );
}

function getAvailableReports(searchBillerFilesDto, t, setLoading) {
  return services.availableDocumentService.getAvailableReports(
    searchBillerFilesDto,
    t,
    setLoading
  );
}

function getReportNames(searchBillerFilesDto, t) {
  return services.availableDocumentService.getReportNames(
    searchBillerFilesDto,
    t
  );
}

function downloadReports(reportDocData, props) {
  return services.availableDocumentService.downloadReports(
    reportDocData,
    props
  );
}

import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withERPSTextFieldActionValidator = (Component) => (props) => {
  const { actionType, componentAccess } = props;
  const user = useSelector((state) => state.loginUserReducer.user);

  const getElementWithActionType = (hasShow) => {
    if (actionType === "hasRead") {
      return <Component disabled={true} {...props} />;
    }
    return hasShow ? <Component {...props} /> : null;
  };

  if (hasShowComponent({ componentAccess, actionType, user })) {
    return getElementWithActionType(true);
  } else {
    return getElementWithActionType(false);
  }
};

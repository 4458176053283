import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { PureComponent } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { createStyles } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { IconButton, withStyles } from "@material-ui/core";

class ERPSWeekPicker extends PureComponent {
  state = {
    selectedDate: new Date(),
  };

  handleWeekChange = (date) => {
    if (date === null) {
      this.setState({ selectedDate: "" });
      this.props.onChange("");
    } else {
      this.setState({ selectedDate: startOfWeek(date._d) });
      this.props.onChange(date?.format());
    }
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = date._d;

    return dateClone && isValid(dateClone)
      ? `Week of ${format(startOfWeek(dateClone), "MMM do")}`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    let dateClone = date._d;
    let selectedDateClone = selectedDate._d;

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { selectedDate } = this.state;
    const {
      label,
      onChange,
      value,
      className,
      required,
      maxDate,
      minDate,
      hasRequired = true,
      hasMinMaxNotCheck = false,
      isDisabled,
      translation,
    } = this.props;
    const todayDate = new Date();
    const minDateVal = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() - 365
    );
    const maxDateVal = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() + 365
    );
    return (
      <div className={className}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className={"quick-reg-datepicker"}
            disableToolbar
            role={"form"}
            allowKeyboardControl={true}
            autoOk={true}
            required={required}
            invalidDateMessage={
              hasRequired
                ? !selectedDate
                  ? translation("erps.common.datepicker.periodNotBlank", { period: "Week" })
                  : translation("erps.common.datepicker.periodInvalidformat", {period: "week",})
                : ""
            }
            leftArrowButtonProps={{
              "aria-label": "erps.common.datepicker.selectpreviousmonth",
            }}
            maxDate={maxDate || maxDateVal}
            minDate={minDate || minDateVal}
            maxDateMessage={
              hasMinMaxNotCheck
                ? ""
                : translation("erps.common.datepicker.notaftermaximaldate")
            }
            minDateMessage={
              hasMinMaxNotCheck
                ? ""
                : translation("erps.common.datepicker.notbeforemaximaldate")
            }
            rightArrowButtonProps={{
              "aria-label": "erps.common.datepicker.selectnextmonth",
            }}
            inputVariant="outlined"
            disabled={isDisabled}
            variant="inline"
            margin="normal"
            id={"week-picker"}
            label={label}
            value={value}
            onChange={this.handleWeekChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            renderDay={this.renderWrappedWeekDay}
            labelFunc={this.formatWeekSelectLabel}
            InputProps={{ readOnly: true }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const styles = createStyles((theme) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(ERPSWeekPicker);

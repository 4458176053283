import { useTranslation } from "react-i18next";
import ERPSGrid from "../custom/containers/ERPSGrid";
import {
  BSR_HEADER,
  CBANS_HEADER,
  DSR_HEADER,
  CBAS_HEADER,
  CBAS_TOTAL_HEADER,
  BSR,
  CBANS,
  DSR,
  CBAS,
} from "./data";
import React from "react";
import { findReportLabel, cbasTotalRows } from "./helper";

const ReportGrid = (props) => {
  const { t } = useTranslation();
  const reportType = props.reportType;
  const reportHeader = () => {
    if (reportType == BSR) {
      return BSR_HEADER;
    } else if (reportType == CBANS) {
      return CBANS_HEADER;
    } else if (reportType == DSR) {
      return DSR_HEADER;
    }
  };

  const dataGridWidth = () => {
    if (reportType == BSR) {
      return "dataGrid__box__container__statsEnquiry_bsr";
    } else if (reportType == CBANS) {
      return "dataGrid__box__container__statsEnquiry_cbans";
    } else if (reportType == DSR) {
      return "dataGrid__box__container__statsEnquiry_dsr";
    }
  };
  return (
    <>
      {/* Report equal to CBAS */}
      {reportType == CBAS && (
        <>
          <ERPSGrid
            data={{
              rows: props.data ? props.data : [],
              count: props?.viewStatisticsFirstData
                ? props?.viewStatisticsFirstData?.count
                : "0",
              columns: CBAS_HEADER,
            }}
            dataGridWidth="dataGrid__box__container__statsEnquiry_cbas"
            pageNumber={props.pageNumber}
            rowPerPage={props.rowPerPage}
            showPaginationBottom={true}
            onAnyPaginationAction={props.paginateData}
            tableLabelText={t("erps.statisticsEnquiry.page.label.cbas")}
          />
          <div style={{ marginTop: "20px" }}>
            <ERPSGrid
              data={{
                rows: cbasTotalRows(props.viewStatisticsFirstData, props.data)
                  ? cbasTotalRows(props.viewStatisticsFirstData, props.data)
                  : [],
                count: cbasTotalRows(props.viewStatisticsFirstData, props.data)
                  ? cbasTotalRows(props.viewStatisticsFirstData, props.data)
                      .length
                  : "0",
                columns: CBAS_TOTAL_HEADER,
              }}
              dataGridWidth="dataGrid__box__container__statsEnquiry_cbas"
              hideFooterPagination={true}
            />
          </div>
        </>
      )}
      {/* Report not equal to CBAS */}
      {reportType != CBAS &&
        (props.initialData?.length <= 100 ? (
          <ERPSGrid
            data={{
              rows: props.initialData ? props.initialData : [],
              count: props.initialData ? props.initialData.length : "0",
              columns: reportHeader(),
            }}
            dataGridWidth={dataGridWidth()}
            hideFooterPagination={true}
            tableLabelText={t(findReportLabel(reportType)?.label)}
          />
        ) : (
          <ERPSGrid
            data={{
              rows: props.data ? props.data : [],
              count: props.initialData ? props.initialData.length : "0",
              columns: reportHeader(),
            }}
            dataGridWidth={dataGridWidth()}
            pageNumber={props.pageNumber}
            rowPerPage={props.rowPerPage}
            showPaginationBottom={true}
            onAnyPaginationAction={props.paginateData}
            tableLabelText={t(findReportLabel(reportType)?.label)}
          />
        ))}
    </>
  );
};

export default ReportGrid;

import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Document, Page, pdfjs } from "react-pdf";

import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";

import actions from "../../actions";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Header from "../Header/Header";
import { getServiceAgreement } from "../../utils/helper";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import Footer from "../Footer/Footer";
import UserService from "../../utils/keycloak.service.js";

const Welcome = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [values, setValues] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

  const [formControlVisible, setFormControlVisible] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialoagButtonloading, setDialoagButtonloading] = React.useState(false);
  const user = useSelector((state) => state.loginUserReducer?.user);

  useEffect(() => {
    if (user?.languageCode === "F") {
      i18next.changeLanguage("fr");
    } else {
      i18next.changeLanguage("en");
    }
  }, []);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const agreementItems = [
    {
      name: t("erps.welcome.page.radiobutton.accept", "I accept"),
      value: "accept",
      disabled: !formControlVisible,
    },
    {
      name: t("erps.welcome.page.radiobutton.donotaccept", "I do not accept"),
      value: "notAccept",
    },
  ];
  function onScroll(e) {
    const { scrollHeight, scrollTop, clientHeight } = e.target;

    const hasRead = Math.round(scrollHeight - scrollTop) - clientHeight < 345;
    if (
      hasRead ||
      Math.round(scrollHeight - scrollTop) === clientHeight ||
      Math.round(scrollHeight - scrollTop) === clientHeight + 1 ||
      Math.round(scrollHeight - scrollTop) === clientHeight - 1
    ) {
      setFormControlVisible(true);
    }
  }

  const serviceAgreement = getServiceAgreement(user);

  const pdfFile =
    serviceAgreement &&
    (user?.languageCode === "F"
      ? serviceAgreement.french
      : serviceAgreement.english);
  const handleChange = (event, key) => {
    if (event.target.value === "notAccept") {
      setOpen(true);
    }
    setValues(event.target.value);
  };

  const logout = () => {
    localStorage.setItem("logoutClicked", "true");
    localStorage.removeItem("persist:erps");
    UserService.doLogout();
  };

  const handleClose = (e, key) => {
    if (key !== "ok") {
      setOpen(false);
      setValues("");
    } else {
      setDialoagButtonloading(true);
      logout();
    }
  };

  const handleRedirect = () => {
    setDialoagButtonloading(true);
    logout();
    // dispatch(actions.loginActions.logout({ ...props, setLoading, setOpen, t }));
  };

  const navigateToAccountSettings = () => {
    props.history.push("/account-registration");
    // setLoadingOnSubmit(true);
    // if (user) {
    //   const { userId, fiNumber } = user;
    //   let currentDate = new Date();
    //   const modifiedCurrentDate = parseInt(
    //     `${currentDate.getFullYear()}${getUpdatedValue(
    //       currentDate.getMonth() + 1
    //     )}${getUpdatedValue(currentDate.getDate())}`
    //   );

    //   var bodyFormData = new FormData();
    //   bodyFormData.append("userId", userId);
    //   bodyFormData.append("fiNumber", fiNumber);
    //   bodyFormData.append("agreementAccepted", "A");
    //   bodyFormData.append("agreementAcceptedDate", modifiedCurrentDate);
    //   dispatch(
    //     actions.loginActions.updateServiceAggrement(bodyFormData, {
    //       ...props,
    //       setLoadingOnSubmit,
    //     })
    //   );
    // }
  };

  const getDialogContent = () => {
    return (
      <>
        <Typography gutterBottom aria-describedby="dialog-message">
          {t(
            "erps.welcome.page.termsandconditions.model.description1",
            'By choosing "I do not accept" you cannot complete your registration for the online remittance reporting service. ' +
              "Therefore, you cannot access this service for your reports."
          )}
        </Typography>
        <br />
        <Typography gutterBottom aria-describedby="confirmation-message">
          {t(
            "erps.welcome.page.termsandconditions.model.description2",
            "For further details, refer to the service information available at www.bmo.com."
          )}
        </Typography>
        <br />
        <Typography gutterBottom aria-describedby="confirmation-message">
          {t(
            "erps.welcome.page.termsandconditions.model.description3",
            "If you select OK, you will be logged out."
          )}
        </Typography>
        <br />
      </>
    );
  };

  const getDialogContentForCancel = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <Typography gutterBottom aria-describedby="dialog-message">
          {t(
            "erps.welcome.page.cancel.model.description1",
            "In order to complete registration:"
          )}
        </Typography>

        <ul>
          <li>
            {t(
              "erps.welcome.page.cancel.model.description2",
              "The term and conditions must be accepted and"
            )}
          </li>
          <li>
            {t(
              "erps.welcome.page.cancel.model.description3",
              "All fields must be completed"
            )}
          </li>
        </ul>
        <Typography gutterBottom aria-describedby="confirmation-message">
          {t(
            "erps.welcome.page.cancel.model.description4",
            "If you continue, you will be logged out"
          )}
        </Typography>

        <br />
      </div>
    );
  };

  return (
    <>
      <Header {...props} />
      <CommonContainerBox
        marginTop="90px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
        title={t("erps.welcome.page.title", "Welcome")}
      >
        <div role="main">
          <Grid item lg={10} sm={12}>
            <Box mt={4}>
              <Typography variant="h1">
                {t("erps.welcome.page.title", "Welcome")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                {t(
                  "erps.welcome.page.description",
                  {
                    fi_name: serviceAgreement?.fileName,
                    service_name:
                      serviceAgreement?.fileName === "BMO"
                        ? t(
                            "erps.app.title.message.bmo",
                            "Merchant Bill Pay Service"
                          )
                        : t(
                            "erps.app.title.message",
                            "Bill payment remittance reporting"
                          ),
                  },

                  `Welcome to the ${serviceAgreement?.fileName} Merchant Bill Pay Service. In order to complete the registration process and access the application, please read and accept the terms & conditions below. If you require more information before completing registration, refer to ${serviceAgreement?.fileName} Merchange Bill Pay Service for additional information. If you leave this page, your registration will not be complete and you will be required to start again on your next sign in.`
                )}
              </Typography>
            </Box>
            <Box mt={7}>
              <Typography variant="h2">
                {t("erps.welcome.subheading", "Terms and conditions")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                {t(
                  "erps.welcome.page.subheading.description",
                  'Carefully read the Terms and Conditions and select either "I accept" or "I do not accept" below. If you select "I do no accept", you will be re-directed to the log in page and will be unable to access the system until you accept the terms and conditions.'
                )}
              </Typography>
            </Box>
            <span class="visually-hidden">
              To access the agreement press the Tab key & then press the down
              arrow key.
            </span>
            <Box mt={2}>
              {pdfFile && (
                <div
                  className="pdf_file_viewer_page"
                  onScrollCapture={onScroll}
                  tabIndex={0}
                >
                  <Document
                    file={pdfFile}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => (
                        <Page pageNumber={page} width={900} />
                      ))}
                  </Document>
                </div>
              )}
            </Box>
            <ERPSBaseRadioGroup
              name="agreement"
              value={values}
              required={true}
              label={t(
                "erps.welcome.radiogroup.termsandcondition",
                "Terms and conditions acceptance"
              )}
              onChange={(e) => handleChange(e, "agreement")}
              items={agreementItems}
            />

            <Stack direction="row" spacing={2} mt={6}>
              <ERPSBaseButton
                variant="secondary"
                label={t("erps.welcome.button.cancel", "Cancel")}
                onClick={() => {
                  setOpenCancelDialog(true);
                }}
                loading={loading}
              />
              <ERPSBaseButton
                variant={
                  values === "" || values === "notAccept"
                    ? "tertiary"
                    : "primary"
                }
                label={t("erps.welcome.button.next", "Next")}
                onClick={navigateToAccountSettings}
                isDisabled={Boolean(values === "" || values === "notAccept")}
              />
            </Stack>
          </Grid>
        </div>
        <div>
          <CustomModelDialog
            handleClose={() => {
              setOpenCancelDialog(false);
            }}
            open={openCancelDialog}
            dialogHeaderClassName={"dialogHeaderClassName"}
            dialogActionsClassName={"leftAlignDialogActions"}
            handleRedirect={handleRedirect}
            tanslation={t}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
          >
            <DialogHeader
              id="alert-dialog-title"
              onClose={handleClose}
              className={"box-dialog-header"}
            >
              {t(
                "erps.welcome.page.termsandconditions.model.title",
                "Terms and conditions not accepted"
              )}{" "}
            </DialogHeader>
            <DialogContent
              id="alert-dialog-description"
              className={"dialog-content-size"}
            >
              {getDialogContent()}
            </DialogContent>
            <DialogActions className={"box-dialog-buttons"}>
              <ERPSBaseButton
                variant="secondary"
                className="button_with_zeromargin"
                onClick={handleClose}
                label={t("erps.welcome.button.cancel", "Cancel")}
              />
              <ERPSBaseButton
                variant="primary"
                className="button_with_zeromargin"
                onClick={(e) => handleClose(e, "ok")}
                label={t("erps.welcome.page.button.Ok", "Ok")}
              />
            </DialogActions>
          </Dialog>
        </div>
      </CommonContainerBox>
      <Footer />
    </>
  );
};
export default Welcome;

import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { withERPSLinkActionValidator } from "../../hoc/withERPSLinkActionValidator";

function ERPSBaseLink(props) {
  const { label, className, to, onClick, ariaLabel, target } = props;
  return (
    <Link
      variant="contained"
      to={to}
      className={className}
      onClick={onClick}
      aria-label={ariaLabel || label}
      target={target}
    >
      {label}{" "}
    </Link>
  );
}

ERPSBaseLink.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withERPSLinkActionValidator(ERPSBaseLink);

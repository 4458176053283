import * as React from "react";
import Button from "./style/ERPSBaseButtonStyles";
import { PropTypes } from "prop-types";
import { withERPSButtonValidator } from "../../hoc/withERPSButtonValidator";
import LoadingIcon from "./ERPSLoadingIcon";

const ERPSBaseButton = (props) => {
  const {
    label,
    onClick,
    variant,
    component,
    to,
    className,
    loading = false,
    isDisabled,
    children,
  } = props;
  const getClassName = () => {
    const fi = localStorage.getItem("fiName");
    if (fi === "D&D" && (loading || isDisabled)) {
      return `${className} button_disable`;
    }
    return className;
  };
  return (
    <>
      <Button
        variant={variant}
        onClick={onClick}
        aria-label={label}
        component={component}
        to={to}
        className={getClassName()}
        disabled={loading || isDisabled}
      >
        {children}
        {loading ? <LoadingIcon className={"loadingIcon"} /> : label}
      </Button>
    </>
  );
};
ERPSBaseButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

export default withERPSButtonValidator(ERPSBaseButton);

import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { t } from "i18next";
import Checkbox from "@mui/material/Checkbox";
import ERPSBaseCheckBoxGrp from "../components/custom/inputs/ERPSBaseCheckBoxGrp";

export const getFinancialInstitutionHeadings = (
  isEditable = true,
  from = "",
  handleChange
) => {
  const FINANCIAL_INSTITUTION_HEADINGS = [
    {
      field: "fiNumber",
      headerName: t("erps.viewfimanagement.page.subheading.finumber"),
      width: 90,
      sortable: false,
      ...(from === "updateApprovalPage" && {
        headerClassName: "column_separator_border-right-only",
      }),
    },
    {
      field: "fiName",
      headerName: t("erps.viewfimanagement.page.subheading.finame"),
      width: 250,
      sortable: false,
      ...(from === "updateApprovalPage" && {
        headerClassName: "column_separator_border-right-only",
      }),
    },
  ];

  return from === "updateApprovalPage"
    ? [
        ...FINANCIAL_INSTITUTION_HEADINGS,
        {
          field: "updatedStatusCurrent",
          headerName: t(
            "erps.viewfimanagement.page.subheading.updatedStatusCurrent"
          ),
          renderCell: (params) => {
            return params?.row?.updatedStatus ? (
              <>
                <Checkbox
                  defaultChecked
                  disabled
                  checked={params?.row?.billerStatus === "A"}
                  inputProps={{
                    "aria-label": t(
                      "erps.billermanagement.label.previousstatus"
                    ),
                  }}
                />
                <span>{t("erps.viewfimanagement.page.subheading.active")}</span>
              </>
            ) : (
              ""
            );
          },

          width: 163,
          sortable: false,
          headerClassName: "column_separator_border-right-only",
        },
        {
          field: "updatedStatusNew",
          headerName: t(
            "erps.viewfimanagement.page.subheading.updatedStatusNew"
          ),
          renderCell: (params) => {
            return params?.row?.updatedStatus ? (
              <>
                <Checkbox
                  defaultChecked
                  disabled
                  checked={params?.row?.updatedStatus === "A"}
                  inputProps={{
                    "aria-label": t(
                      "erps.billermanagement.label.updatedstatus"
                    ),
                  }}
                />
                <span>{t("erps.viewfimanagement.page.subheading.active")}</span>
              </>
            ) : (
              ""
            );
          },
          width: 163,
          sortable: false,
        },
      ]
    : [
        ...FINANCIAL_INSTITUTION_HEADINGS,
        {
          field: "billPaymentLimit",
          headerName: t("erps.viewfimanagement.page.subheading.paymentlimit"),
          width: 110,
          sortable: false,
          editable: isEditable && true,
          type: "number",
          renderCell: (params) => {
            if (params.value) {
              return params.value;
            }
            return 0;
          },
        },
        {
          field: "billerStatus",
          renderCell: (params) => {
            return (
              <ERPSBaseCheckBoxGrp
                label={t("erps.viewfimanagement.page.subheading.active")}
                id="status"
                value={params.value === "A"}
                onChange={(e) => {
                  if (handleChange) {
                    handleChange(params?.id, e?.target?.checked);
                  }
                }}
                className={!handleChange ? "cursor-none" : ""}
              />
            );
          },
          headerName: t("erps.billermanagement.label.status"),
          width: 110,
          sortable: false,
        },
      ];
};

export const getUpdateBillerFinancialInstitutionHeadings = (
  isEditable = true,
  handleChange
) => {
  const FINANCIAL_INSTITUTION_HEADINGS = [
    {
      field: "fiNumber",
      headerName: t("erps.viewfimanagement.page.subheading.finumber"),
      width: 130,
      sortable: false,
    },
    {
      field: "fiName",
      headerName: t("erps.viewfimanagement.page.subheading.finame"),
      width: 250,
      sortable: false,
    },
    {
      field: "billerFiNumber",
      headerName: t("erps.viewfimanagement.page.subheading.fibillerhash"),
      width: 158,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return parseInt(params.value, 10);
        }
        return 0;
      },
    },
    {
      field: "billPaymentLimit",
      headerName: t("erps.viewfimanagement.page.subheading.paymentlimit"),
      width: 110,
      sortable: false,
      editable: isEditable && true,
      type: "number",
      renderCell: (params) => {
        if (params.value) {
          return params.value.toString().length > 10
            ? Math.max(0, parseInt(params.value)).toString().slice(0, 10)
            : params.value;
        }
        return 0;
      },
    },
    {
      field: "billerStatus",
      renderCell: (params) => {
        return (
          <ERPSBaseCheckBoxGrp
            label={t("erps.viewfimanagement.page.subheading.active")}
            id="status"
            value={params.value === "A"}
            onChange={(e) => {
              handleChange(params?.id, e?.target?.checked);
            }}
          />
        );
      },
      headerName: t("erps.billermanagement.label.status"),
      width: 110,
      sortable: false,
    },
    {
      field: "nextFileNumber",
      headerName: t("erps.viewfimanagement.page.subheading.availableaudithash"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value;
        }
        return 0;
      },
    },
  ];

  return FINANCIAL_INSTITUTION_HEADINGS;
};

export const fiMockData = [
  {
    id: 1,
    fi_number: 26,
    fi_name: "PC BANK",
    payment_limit: 0,
    active: "yes",
  },
  {
    id: 2,
    fi_number: 36,
    fi_name: "Motus BANK",
    payment_limit: 0,
    active: "yes",
  },
  {
    id: 3,
    fi_number: 37,
    fi_name: "Meridian Credit BANK",
    payment_limit: 0,
    active: "Yes",
  },
  {
    id: 4,
    fi_number: 40,
    fi_name: "MANULIFE BANK",
    payment_limit: 0,
    active: "No",
  },
];

import React from "react";
import { TablePagination } from "@mui/material";
import CustomPaginationActions from "../Pagination/CustomPaginationActions";
import { useTranslation } from "react-i18next";

const CustomPagination = (props) => {
  const {
    page,
    rowsPerPage,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  const value =
    (page + 1) * rowsPerPage < count ? (page + 1) * rowsPerPage : count;
  const rowsPerPageOptions = [25, 50, 75, 100];

  const { t } = useTranslation();

  return (
    <TablePagination
      rowsPerPage={rowsPerPage}
      count={count}
      page={page}
      ActionsComponent={CustomPaginationActions}
      labelDisplayedRows={({ page }) => {
        return `${page * rowsPerPage + 1} - ${value} ${t(
          "erps.pagination.to",
          "to"
        )} ${count}`;
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      aria-label="Pagination for search results"
    />
  );
};

export default CustomPagination;

import pricePlanActionType from "../actions/types/pricePlanPendingApproval.action.type";

export const pricePlanQueueInitialState = {
  pricePlanPendingApprovalData: null,
  pricePlanPendingApprovalError: null,

  pricePlanActionMessage: null,
  pricePlanActionError: null,

  nextPricePlanId: null,
};

export default function pricePlanQueueReducer(
  state = pricePlanQueueInitialState,
  action
) {
  switch (action.type) {
    case pricePlanActionType.GET_PENDING_PRICE_PLAN_APPROVAL_SUCCESS:
      return {
        ...state,
        pricePlanPendingApprovalData: action.data,
        pricePlanPendingApprovalError: null,
        nextPricePlanId: null,
        pricePlanActionError: null,
      };
    case pricePlanActionType.GET_PENDING_PRICE_PLAN_APPROVAL_FAILURE:
      return {
        ...state,
        pricePlanActionError: null,
        pricePlanPendingApprovalData: null,
        pricePlanPendingApprovalError: action.error,
        nextPricePlanId: null,
      };
    case pricePlanActionType.UPDATE_PRICE_PLAN_APPROVAL_SUCCESS:
      return {
        ...state,
        pricePlanActionMessage: action.data,
        pricePlanActionError: null,
        nextPricePlanId: action.nextPricePlanId,
        pricePlanPendingApprovalData: {
          ...state.pricePlanPendingApprovalData,
          queueDataList: action.queueDataList,
        },
      };
    case pricePlanActionType.UPDATE_PRICE_PLAN_APPROVAL_FAILURE:
      return {
        ...state,
        pricePlanActionMessage: null,
        pricePlanActionError: action.error,
      };
    case pricePlanActionType.CREATE_PRICE_PLAN_APPROVAL_SUCCESS:
      return {
        ...state,
        pricePlanActionMessage: action.data,
        pricePlanActionError: null,
        pricePlanPendingApprovalData: {
          ...state.pricePlanPendingApprovalData,
          queueDataList: action.queueDataList,
        },
        nextPricePlanId: action.nextPricePlanId,
      };
    case pricePlanActionType.CREATE_PRICE_PLAN_APPROVAL_FAILURE:
      return {
        ...state,
        pricePlanActionMessage: null,
        pricePlanActionError: action.error,
      };
    default:
      return state;
  }
}

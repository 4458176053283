import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import NewHscbcBiller from "./NewBillerHsbcUser";
import NewBillerProfileUser from "./NewBillerProfileUser";

function NewBillerUser(props) {
  const fiNumber = useSelector(
    (state) => state?.loginUserReducer?.user?.fiNumber
  );
  const selectedFiNumber = useSelector((state) => state?.fiReducer.selectedFi);

  return fiNumber != 16 ? (
    <NewBillerProfileUser {...props} />
  ) : (
    <NewHscbcBiller {...props} />
  );
}
export default withSidebar(NewBillerUser);

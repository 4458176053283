import { useState } from "react";
import actions from "../../actions";
import passwordActionTypes from "../../actions/types/password.action.type";
import { useDispatch, useSelector } from "react-redux";

const useForm = (callback, props) => {
  const {
    setSuccessMessage,
    errors,
    setOpenCancelDialog,
    handleTabChange,
    parentComponentAccess,
    t,
    setCustomError,
  } = props;
  const userId = useSelector((state) => state.loginUserReducer?.user?.userId);

  const [values, setValues] = useState({});
  const [showPwdIconPWD, setShowPwdIconPWD] = useState(false);
  const [showPwdIconNEWPWD, setShowPwdIconNEWPWD] = useState(false);
  const [showPwdIconConfirmPWD, setShowPwdIconConfirmPWD] = useState(false);
  const [loginPending, setLoginPending] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);

  const dispatch = useDispatch();

  const checkEmptyTextFields = () => {
    let error = {
      isConfirmPasswordError: false,
      isNewPasswordError: false,
      isCurrentPasswordError: false,
    };
    if (!values?.confirmnewpwd  ) {
      error = { ...error, isConfirmPasswordError: true };
    }
    if (!values?.newpwd) {
      error = { ...error, isNewPasswordError: true };
    }
    if ((props.pageName =="password-expired" || props.pageName =="update-password")  && !values?.currentpwd) {
      error = { ...error, isCurrentPasswordError: true };
    }
    if (setCustomError) {
      setCustomError(error);
    }

    return Object.values(error).every((value) => value === false)
      ? false
      : true;
  };

  const handleSubmit = (event, openCancelDialog) => {
    if (event) event.preventDefault();

    if (openCancelDialog) {
      setSaveAndContinue(true);
    } else {
      setLoginPending(true);
    }

    if (checkEmptyTextFields(values)) {
      if (setLoginPending) {
        setLoginPending(false);
      }
      if (setSaveAndContinue) {
        setSaveAndContinue(false);
      }
      if (setOpenCancelDialog) {
        setOpenCancelDialog(false);
      }
    } else {
      dispatch(
        actions.userActions.changePassword(values, userId, {
          ...props,
          setLoginPending,
          loginPending,
          setSuccessMessage,
          setSaveAndContinue,
          setOpenCancelDialog,
          t,
        })
      );
      setValues({});
    }
  };

  const handleClickShowPassword = () => {
    var status = !showPwdIconPWD;
    setShowPwdIconPWD(status);
  };

  const handleClickShowNewPassword = () => {
    var status = !showPwdIconNEWPWD;
    setShowPwdIconNEWPWD(status);
  };

  const handleClickShowConfirmPassword = () => {
    var status = !showPwdIconConfirmPWD;
    setShowPwdIconConfirmPWD(status);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = (event) => {
    event.preventDefault();
    if (setOpenCancelDialog) {
      setOpenCancelDialog(true);
    } else {
      setValues({});
      props.history.push("/");
    }
  };

  const navigationToMangerUserPage = (event) => {
    event.preventDefault();
    const hasEmptyTextValues = Object.values(values).every((value) => !value);
    if (!hasEmptyTextValues) {
      setValues({});
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));
  };

  const handleDiscardAndContinue = () => {
    setValues({});
    if (props.pageName == "password-expired") {
      dispatch(actions.loginActions.logout({ ...props, t }));
    }
    if (setCustomError(error)) {
      setCustomError({});
    }

    if (setOpenCancelDialog) {
      setOpenCancelDialog(false);
    }
    if (handleTabChange) {
      handleTabChange("", 0);
    }
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    navigationToMangerUserPage,
    handleClickShowPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmPassword,
    handleMouseDownPassword,
    values,
    showPwdIconPWD,
    showPwdIconNEWPWD,
    showPwdIconConfirmPWD,
    loginPending,
    handleDiscardAndContinue,
    saveAndContinue,
    setValues,
  };
};

export default useForm;

import services from "../services";

export const userPendingActions = {
  updateUserStatusPendingQueue,
  deleteUserStatusPendingQueue

};

function updateUserStatusPendingQueue(userInfo, setterMethods) {
   return services.userQueueService.userUpdatePendingQueue(userInfo, setterMethods);
}

function deleteUserStatusPendingQueue(userInfo, setterMethods) {
  return services.userQueueService.userDeletePendingQueue(userInfo, setterMethods);
}


import React from "react";
import { withERPSBaseDiv } from "../../hoc/withERPSBaseDiv";
import { Box } from "@material-ui/core";

function ERPSBaseBox(props) {
  const { children, ...rest } = props;
  return <Box {...rest}>{children}</Box>;
}

export default withERPSBaseDiv(ERPSBaseBox);

import React from "react";
import clsx from "clsx";

const Button = (props) => {
  const { children, format, active, toolTipText, ...rest } = props;
  return (
    <button
      className={clsx({
        instruction_textEditor_button: true,
        instruction_textEditor_btnActive: active,
      })}
      title={toolTipText}
      {...rest}
      style={{ width: "30px", height: "20px", margin: "0 2px" }}
    >
      {children}
    </button>
  );
};

export default Button;

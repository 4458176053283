import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { withSidebar } from "../hoc/withSidebar";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";

const Synchronization = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const successMessage = useSelector(
    (state) => state.synchronizationReducer?.success
  );

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const handleOpenDialog = () => {
    setDisplayDialog(true);
  };

  const handleCloseDialog = () => {
    setDisplayDialog(false);
  };

  const handleSynchronization = () => {
    dispatch(
      actions.synchronizationActions.runSynchronization({
        setDisplayDialog,
        setShowSuccessMessage,
        t,
      })
    );
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.synchronization.page.title")}
          </Typography>
        </Box>
        <Box className="manageUsers__wrap__box" mt={3}>
          <Typography variant="body1">
            {t("erps.synchronization.page.description")}
          </Typography>
        </Box>
        <Box mt={-3} display="flex">
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            label={t("erps.synchronization.page.button.synchronize")}
            onClick={handleOpenDialog}
          />
        </Box>
      </Box>
      <ERPSBaseDialog
        role="dialog"
        onClose={handleCloseDialog}
        aria-labelledby="synchronization-dialog"
        open={displayDialog}
      >
        <DialogHeader
          id="synchronization-dialog-title"
          onClose={handleCloseDialog}
          style={{
            fontFamily: "Roboto,Regular",
            fontSize: "18px",
            color: "#2a2c2e",
          }}
        >
          {t("erps.synchronization.page.dialog.title")}
        </DialogHeader>
        <DialogContent>
          <Typography aria-describedby="dialog-message">
            {t("erps.synchronization.page.dialog.description1")}
          </Typography>
          <ul>
            <li>
              <Typography aria-describedby="dialog-message">
                {t("erps.synchronization.page.dialog.description2")}
              </Typography>
            </li>
            <li>
              <Typography aria-describedby="dialog-message">
                {t("erps.synchronization.page.dialog.description3")}
              </Typography>
            </li>
          </ul>
          <Typography gutterBottom aria-describedby="confirmation-message">
            <strong>
              {t("erps.synchronization.page.dialog.confirmationMessage")}
            </strong>
          </Typography>
        </DialogContent>
        <DialogActions className={"rightAlignDialogActions"}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_zeromargin"
            onClick={handleCloseDialog}
            label={t("erps.synchronization.page.button.cancel")}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_zeromargin"
            label={t("erps.synchronization.page.button.synchronize")}
            onClick={handleSynchronization}
          />
        </DialogActions>
      </ERPSBaseDialog>
    </div>
  );
};

export default withSidebar(Synchronization);

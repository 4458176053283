import axios from "axios";
import pricePlanActionType from "../actions/types/pricePlan.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL,
} from "../utils/helper";

export const pricePlaneService = {
  submitPricePlanDetails,
  updatePricePlanDetails,
  getPricePlanList,
  getFilteredPricePlan,
};

function submitPricePlanDetails(values, setValues, props) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const token = loginUserDetail.token;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUserDetail.user?.fiNumber;
    const languageCode = loginUserDetail.user.languageCode;
    axios
      .post(
        API_BASE_URL() + "/manage-price-plan/queue/save",
        {
          ...values,
          fiNumber,
          languageCode,
          loggedInUserId: loginUserDetail?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        props.setLoader(false);
        props.setShowSuccessMessage(true);
        dispatch({
          type: pricePlanActionType.NEW_PRICE_PLAN_SUCCESS,
          data: res.data,
        });
        setValues({
          billingModelDescription: "",
          baseFee: "",
          csvFee: "",
          notificationFee: "",
          perDocumentRate: "",
          maximumDocumentRate: "",
          minimumDocumentRate: "",
        });
      })
      .catch((error) => {
        const errMessage = getExceptionMessage(error, props.t);
        props.setLoader(false);
        props.setShowSuccessMessage(false);
        // dispatch(showSnackbar(errMessage));
        dispatch({
          type: pricePlanActionType.NEW_PRICE_PLAN_FAILURE,
          error: errMessage,
        });
      });
  };
}

function getPricePlanList(t) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const token = loginUserDetail.token;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUserDetail.user?.fiNumber;

    axios
      .get(API_BASE_URL() + `/manage-price-plan/list?fiNumber=${fiNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        //budgetCategoryData = res.data;
        dispatch(success(res));
      })
      .catch((error) => {
        const errorResponse = getExceptionMessage(error, t);
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return { type: pricePlanActionType.SUCCESS, data };
    }

    function failure(error) {
      return { type: pricePlanActionType.FAIL, error };
    }
  };
}

function getFilteredPricePlan({
  pricePlanId,
  pricePlanName,
  setLoading,
  t,
  setPricePlaneData,
  setPricePlanCriteria,
}) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const token = loginUserDetail.token;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUserDetail.user?.fiNumber;
    axios
      .post(
        API_BASE_URL() + `/manage-price-plan/search`,
        {
          pricePlanId: pricePlanId,
          pricePlanName: pricePlanName,
          fiNumber: fiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const pricePlanSearchData = {
          pricePlanId: pricePlanId,
          pricePlanName: pricePlanName,
        };

        const updatedData = res.data.map(({ billingModelNumber, ...rest }) => ({
          ...rest,
          billingModelNumber,
          id: billingModelNumber,
        }));

        if (setPricePlaneData) {
          setPricePlaneData(updatedData);
        }
        if (setPricePlanCriteria) {
          setPricePlanCriteria((prevState) => ({
            ...prevState,
            pricePlanId,
            pricePlanName,
          }));
        }
        dispatch(success({ ...pricePlanSearchData }, updatedData));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const errorResponse = getExceptionMessage(error, t);

        dispatch(failure(errorResponse));
      });

    function success(data, updatedData) {
      return {
        type: pricePlanActionType.SEARCH_CRITERIA,
        data,
        priceList: { data: updatedData },
      };
    }

    function failure(error) {
      return { type: pricePlanActionType.FAIL, error };
    }
  };
}

function updatePricePlanDetails(values, props, pricePlanList) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const token = loginUserDetail.token;
    const fiDetail = store.getState()?.fiReducer;
    const fiNumber = fiDetail?.selectedFi || loginUserDetail.user?.fiNumber;
    const languageCode = loginUserDetail.user.languageCode;

    axios
      .post(
        API_BASE_URL() + "/manage-price-plan/update",
        {
          ...values,
          fiNumber,
          languageCode,
          loggedInUserId: loginUserDetail?.user?.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        props?.setLoader(false);
        dispatch({
          type: pricePlanActionType.UPDATE_PRICE_PLAN_SUCCESS,
          data: res.data,
          pricePlan: pricePlanList,
        });
        if (res.data !== "PP_DUP_ERR_MSG") {
          props.history.push("/manage-price-plan", {
            componentAccess: props?.componentAccess,
            fromEditDetailPage: true,
          });
        }
      })
      .catch((error) => {
        const errMessage = getExceptionMessage(error, props?.t);

        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(false);
        }

        if (props?.setLoader) {
          props?.setLoader(false);
        }

        dispatch({
          type: pricePlanActionType.UPDATE_PRICE_PLAN_FAIL,
          error: errMessage,
          pricePlan: pricePlanList,
        });
      });
  };
}

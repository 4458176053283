import { useState } from "react";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const useFormBillerProfile = (callback, props) => {
  const { setSuccessMessage } = props;
  const userId = useSelector((state) => state.loginUserReducer?.user?.userId);

  const [valuesPass, setValues] = useState({});
  const [showPwdIconPWD, setShowPwdIconPWD] = useState(false);
  const [showPwdIconNEWPWD, setShowPwdIconNEWPWD] = useState(false);
  const [showPwdIconConfirmPWD, setShowPwdIconConfirmPWD] = useState(false);
  const [loginPending, setLoginPending] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    setLoginPending(true);
    if (event) event.preventDefault();
    dispatch(
      actions.userActions.changePassword(valuesPass, userId, {
        ...props,
        setLoginPending,
        loginPending,
        setSuccessMessage,
      })
    );
    setValues({});
  };

  const handleClickShowPassword = () => {
    var status = !showPwdIconPWD;
    setShowPwdIconPWD(status);
  };

  const handleClickShowNewPassword = () => {
    var status = !showPwdIconNEWPWD;
    setShowPwdIconNEWPWD(status);
  };

  const handleClickShowConfirmPassword = () => {
    var status = !showPwdIconConfirmPWD;
    setShowPwdIconConfirmPWD(status);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setValues({});
    props.history.push("/");
  };

  const navigationToMangerUserPage = (event) => {
    event.preventDefault();
    setValues({});
    props.history.push("/manage-users");
  };

  const handleChange = (event) => {
    event.persist();
    setValues((valuesPass) => ({
      ...valuesPass,
      [event.target.id]: event.target.value,
    }));
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    navigationToMangerUserPage,
    handleClickShowPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmPassword,
    handleMouseDownPassword,
    valuesPass,
    showPwdIconPWD,
    showPwdIconNEWPWD,
    showPwdIconConfirmPWD,
    loginPending,
  };
};

export default useFormBillerProfile;

import React from 'react';
import { withSidebar } from '../hoc/withSidebar';

const Todo = () => {
    return (
        <div role="main">
            <h1>TODO</h1>
        </div>
    )
}

export default withSidebar(Todo);

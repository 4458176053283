import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withContainerBox = (Component) => (props) => {
  const { actionType, componentAccess, marginTop, ...rest } = props;
  const user = useSelector((state) => state.loginUserReducer.user);
  if (
    actionType &&
    componentAccess &&
    hasShowComponent({ componentAccess, actionType, user })
  ) {
    return <Component {...rest} />;
  } else {
    return <Component {...rest} marginTop={marginTop} />;
  }
};

import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";

export const getBudgetManagementGridHeading = (roleType, componentAccess) => {
  return [
    {
      field: "budgetCode",
      headerName: t("erps.budgetmanagement.label.budgetcode"),
      width: 144,
      sortable: false,
      renderCell: (params) =>
        roleType === "CE" || roleType === "BA" ? (
          <ERPSBaseLink
            to={{
              pathname: `/manage-budget-category/view-budget-category-details`,
              state: {
                id: params.value,
                componentAccess,
              },
            }}
            className="link__blue"
            label={params.value.toString()}
          />
        ) : (
          <ERPSBaseLink
            to={{
              pathname: `/manage-budget-category/budget-category-details`,
              state: {
                id: params.value,
                componentAccess,
              },
            }}
            className="link__blue"
            componentAccess={componentAccess}
            label={params.value.toString()}
            actionType={"hasManageBudgetCateoryListAccess"}
          />
        ),
    },
    {
      field: "description",
      headerName: t("erps.budgetmanagement.label.description"),
      width: 200,
      sortable: false,
    },
    {
      field: "createdDate",
      headerName: t("erps.budgetcategory.page.createdDate"),
      width: 150,
      sortable: false,
    },
    {
      field: "createdBy",
      headerName: t("erps.budgetcategory.label.createdBy"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.displayCreatedBy;
      },
    },
    {
      field: "lastModifiedDate",
      headerName: t("erps.budgetcategory.page.lastModifiedDate"),
      width: 150,
      sortable: false,
    },
    {
      field: "lastModifiedBy",
      headerName: t("erps.budgetcategory.page.lastModifiedBy"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.displayUpdatedBy;
      },
    },
  ];
};

export const EMPTY_BUDGET_CRITERIA = {
  budgetCode: "",
  description: "",
  startOffset: 0,
  endOffset: 25,
};

import userActionTypes from "../actions/types/user.action.type";

export const userRoleInitialState = {
  error: null,
  userRoleList: null,
};

export default function userRoleReducer(state = userRoleInitialState, action) {
  switch (action.type) {
    case userActionTypes.GET_USER_ROLE_LIST_SUCCESS:
      return { userRoleList: action.data, error: null };
    case userActionTypes.GET_USER_ROLE_LIST_ERROR:
      return { userRoleList: null, error: action.error };
    default:
      return state;
  }
}

import React, { useCallback, useRef } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import Toolbar from "./Toolbar/Toolbar";
import { getMarked, getBlock } from "./utils/SlateUtilityFunctions.js";
import { Box } from "@material-ui/core";

const Element = (props) => {
  return getBlock(props);
};
const Leaf = ({ attributes, children, leaf }) => {
  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
};
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const SlateEditor = (props) => {
  const editorRef = useRef();
  if (!editorRef.current) editorRef.current = withReact(createEditor());
  const editor = editorRef.current;
  const { value, handleEditorChange, readOnly, langType } = props;
  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  if (value && value?.length) {
    return (
      <Slate
        editor={editor}
        initialValue={value}
        onChange={handleEditorChange(langType)}
      >
        <Toolbar id={props?.id} />
        <Box mt={-6}>
          <Editable
            role={"textbox"}
            renderElement={renderElement}
            readOnly={readOnly}
            renderLeaf={renderLeaf}
            className="instruction_textEditor"
            spellCheck
            aria-labelledby={props?.id}
          />
        </Box>
      </Slate>
    );
  }
  return "";
};

export default SlateEditor;

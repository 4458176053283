import React from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import {
  getFormatDetails,
  requiredFormatDate,
  statisticsFormatDate,
  formatAmount,
} from "../../utils/helper";
import dndEn from "../../assets/images/dndEn.png";

class ComponentToPrint extends React.Component {
  render() {
    const { translation: t } = this.props;
    const webPortalRoleIds = [1, 2, 3, 4];

    const currentDate = new Date();
    const getFormattedDate = `${statisticsFormatDate(
      currentDate
    )}, ${currentDate.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    })}`;

    return (
      <div role="main">
        <Box ml={3}>
          <Box className="manageUsers__page__title">
            <Typography variant="label">{getFormattedDate}</Typography>
            <br />
            <Typography variant="h2">
              {t("erps.transactionEnquiry.componentToPrint.page.heading")}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h3">
              {t("erps.transactionEnquiry.componentToPrint.page.subheading")}
            </Typography>
          </Box>

          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="transaction__enquiry__value">
              {t(
                "erps.transactionEnquiry.componentToPrint.label.effectiveDate"
              )}
              <Typography variant="body2">
                {requiredFormatDate(
                  this.props.data.effective_date_display_format
                )}
              </Typography>
            </Typography>
            <Typography className="transaction__enquiry__value left_space">
              {t(
                "erps.transactionEnquiry.componentToPrint.label.paymentDate/Time"
              )}
              <Typography variant="body2">
                {requiredFormatDate(
                  this.props.data.payment_date_display_format
                ) +
                  " " +
                  this.props.data.payment_time_display_format?.substring(0, 8)}
              </Typography>
            </Typography>
            <Typography
              variant="label"
              className="transaction__enquiry__value left_space"
            >
              {t("erps.transactionEnquiry.componentToPrint.label.deliveryDate")}
              <Typography variant="body2">
                {requiredFormatDate(
                  this.props.data.delivery_date_display_format
                )}
              </Typography>
            </Typography>
          </Box>

          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="transaction__enquiry__value">
              {t(
                "erps.transactionEnquiry.componentToPrint.label.paymentAmount"
              )}
              <Typography variant="body2">
                {formatAmount(this.props.data.transaction_amount)}
              </Typography>
            </Typography>
            <Typography className="transaction__enquiry__value left_space">
              {t(
                "erps.transactionEnquiry.componentToPrint.label.confirmationNumber"
              )}
              <Typography variant="body2">
                {this.props.data.verification_number}
              </Typography>
            </Typography>
            <Typography
              variant="label"
              className="transaction__enquiry__value left_space"
            >
              {t("erps.transactionEnquiry.componentToPrint.label.auditNumber")}
              <Typography variant="body2">
                {this.props.data.biller_file_number}
              </Typography>
            </Typography>
          </Box>

          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="transaction__enquiry__value">
              {t(
                "erps.transactionEnquiry.componentToPrint.label.transactionOrigination"
              )}
              <Typography variant="body2">
                {`${this.props.data?.cap_type || ""} ${
                  this.props.data.caption_type_description || ""
                }`}
              </Typography>
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="h3">
              {t(
                "erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails"
              )}
            </Typography>
          </Box>
          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="transaction__enquiry__value">
              {t(
                "erps.transactionEnquiry.componentToPrint.billerDetails.label.corporateBiller"
              )}
              <Typography className="transaction__enquiry__value">
                {this.props.data.biller_name} <br />
                {t("erps.common.label.attention")}{" "}
                {this.props.data.primary_contact_name} <br />
                {t("erps.common.label.telephone")}{" "}
                {getFormatDetails(this.props.data.primary_contact_phone)} <br />
                {t("erps.common.label.fax")}{" "}
                {getFormatDetails(this.props.data.primary_contact_fax)} <br />
              </Typography>
            </Typography>
            <Typography className="transaction__enquiry__value left_space">
              {t(
                "erps.transactionEnquiry.componentToPrint.billerDetails.label.billerId"
              )}
              <Typography variant="body2">
                {this.props.data.display_biller_id}
              </Typography>
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="h3">
              {t(
                "erps.transactionEnquiry.componentToPrint.page.subheading.customerDetails"
              )}
            </Typography>
          </Box>

          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="transaction__enquiry__value">
              {t(
                "erps.transactionEnquiry.componentToPrint.customerDetails.label.customerAccountNumber"
              )}
              <Typography variant="body2">
                {this.props.data.biller_customer_number}
              </Typography>
            </Typography>
            <Typography className="transaction__enquiry__value left_space">
              {t(
                "erps.transactionEnquiry.componentToPrint.customerDetails.label.customerName"
              )}
              <Typography variant="body2">
                {this.props.data.fi_customer_name}
              </Typography>
            </Typography>
            {!(
              this.props.fiNumberValue == 99 &&
              webPortalRoleIds.includes(this.props.roleId)
            ) && (
              <Typography
                variant="label"
                className="transaction__enquiry__value left_space"
              >
                {t(
                  "erps.transactionEnquiry.componentToPrint.customerDetails.label.bankCardNumber"
                )}
                <Typography variant="body2">
                  {this.props.data.fiCustomerNumberStr}
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>
        {/* Footer Logo */}
        <Box
          ml={3}
          sx={{
            marginRight: "20px",
            position: "fixed",
            marginBottom: "10px",
            bottom: "0",
            width: "95%",
          }}
        >
          <hr
            style={{
              height: "1px",
              border: "none",
              color: "rgba(224, 224, 224, 1)",
              backgroundColor: "rgba(224, 224, 224, 1)",
              paddingRight: "20px",
            }}
          />

          <Typography sx={{ width: "180px" }} variant="p">
            <Box display={"flex"}>
              <Box>
                <p
                  style={{
                    fontSize: "8px",
                    marginBottom: "-12px",
                    color: "#6D1652",
                    paddingLeft: "50px",
                  }}
                >
                  powered by
                </p>
                <img
                  src={dndEn}
                  alt={"Dye and Durham"}
                  height="60"
                  width="180"
                />
              </Box>
              <Box sx={{ marginLeft: "auto" }} mt={3}>
                <Typography variant="label">{window.location.href}</Typography>
              </Box>
            </Box>
          </Typography>
        </Box>
      </div>
    );
  }
}

export default ComponentToPrint;

import { relatedBillerActionTypes } from "../actions/types/relatedBiller.action.type";
import store from "../store/index";
import axios from "axios";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL,
} from "../utils/helper";
export const relatedBillerService = {
  getAllChildBiller,
  getParentBiller,
  updateMonthlyInvoiceForChildBiller,
  removeBillerRelationShip,
  getRelatedBillerSearch,
  addBillerRelationship,
};

function getAllChildBiller(reqData, props, t) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/child-biller-list",
        { ...reqData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        if (props?.setChildRelatedBillerLoader) {
          props?.setChildRelatedBillerLoader(false);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        if (props?.setChildRelatedBillerLoader) {
          props?.setChildRelatedBillerLoader(false);
        }
        const errMessage = getExceptionMessage(error, t);
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.GET_ALL_CHILD_BILLER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.GET_ALL_CHILD_BILLER_FAILURE,
      error,
    };
  }
}

function addBillerRelationship(reqData, props, t) {
  return (dispatch) => {
    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/add-biller-link",
        { ...reqData, languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(true);
        }
        if (props?.setChildRelatedBillerLoader) {
          props?.setChildRelatedBillerLoader(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        const errMessage = getExceptionMessage(error, t);

        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.ADD_BILLER_RELATIONSHIP_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.ADD_BILLER_RELATIONSHIP_FAILURE,
      error,
    };
  }
}

function getRelatedBillerSearch(reqData, props, t) {
  return (dispatch) => {
    const selectedFiNumber = store.getState()?.fiReducer.selectedFi;
    const fiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const loggedInUserRoleType =
      store.getState()?.loginUserReducer?.user?.role?.role_type;
      const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/search-related-biller",
        {
          fi_number: fiNumber,
          selected_fi_number: selectedFiNumber,
          biller_id: reqData?.billerId,
          biller_name: reqData?.billerName,
          start_off_set: reqData?.startOffset,
          end_off_set: reqData?.endOffset,
          count: reqData?.count,
          role_type: loggedInUserRoleType,
          biller_fi_number_parent: reqData.billerFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        if (props?.setChildRelatedBillerLoader) {
          props?.setChildRelatedBillerLoader(false);
        }
        if (props?.setSearchLoader) {
          props?.setSearchLoader(false);
        }

        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        if (props?.setSearchLoader) {
          props?.setSearchLoader(false);
        }
        if (props?.setChildRelatedBillerLoader) {
          props?.setChildRelatedBillerLoader(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        const errMessage = getExceptionMessage(error, t);

        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.GET_RELATED_BILLER_SEARCH_RESPONSE_FAILURE,
      error,
    };
  }
}

function getParentBiller(reqData, props, t) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/get-parent-biller",
        { ...reqData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowMessage) {
          props?.setShowMessage(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        const errMessage = getExceptionMessage(error, t);
        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.GET_PARENT_PARENT_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.GET_PARENT_PARENT_FAILURE,
      error,
    };
  }
}

function updateMonthlyInvoiceForChildBiller(reqData, props, t) {
  return (dispatch) => {
    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/update-monthly-invoice-for-biller",
        { ...reqData, languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(true);
        }

        if (props?.setHasCheckboxDisable) {
          props?.setHasCheckboxDisable(true);
          props?.setCheckboxValue("Y");
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        const errMessage = getExceptionMessage(error, t);

        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE,
      error,
    };
  }
}

function removeBillerRelationShip(reqData, props, t) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const languageCode = loginUserDetail.user.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/remove-child-biller",
        { ...reqData, languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //setLoading(false);
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(true);
        }
        if (props?.setOpenCancelDialog) {
          props?.setOpenCancelDialog({
            isOpen: false,
            removeRelatedBiller: true,
          });
        }
        if (props?.setChildRelatedBillerLoader && props?.searchResults.length) {
          props?.setChildRelatedBillerLoader(true);
        }
        dispatch(success(res.data));
      })
      .catch((error) => {
        //setLoading(false);
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(false);
        }
        if (props?.setShowApiExceptionMessage) {
          props?.setShowApiExceptionMessage(true);
        }
        const errMessage = getExceptionMessage(error, t);

        dispatch(failure(errMessage));
      });
  };
  function success(data) {
    return {
      type: relatedBillerActionTypes.REMOVE_BILLER_RELATIONSHIP_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: relatedBillerActionTypes.REMOVE_BILLER_RELATIONSHIP_FAILURE,
      error,
    };
  }
}

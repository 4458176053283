import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Tab, Tabs, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import TabPanel from "../custom/containers/TabPanel";

import Breadcrumbs from "../Breadcrumb/Breadcrumb";

import ERPSBaseTab from "../custom/inputs/ERPSBaseTab";
import BillerProfileUser from "./BillerProfileUser";
import DownloadDocuments from "../AvailableDocuments/DownloadDocuments";
import ManageUsers from "../ManageUsers/ManageUsers";
import ManageBillerNewUser from "./ManageBillerNewUser";
import RelatedBiller from "./RelatedBiller";
import ManageBillerUserProfile from "./ManageBillerUserProfile";
import EditBillerProfileDetail from "./EditBillerProfileDetail";
import BillerProfileUserReadVew from "./BillerProfileUserReadView";
import ViewUserProfile from "../User/ViewUserProfile";
import AvailableDocuments from "../AvailableDocuments/AvailableDocuments";
import EditBillerProfileHsbcUser from "./EditBillerProfileHsbcUser";

const UpdateBillerProfileTab = (props) => {
  const { t } = useTranslation();
  const [childBillerInfo, setChildBillerId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [hasNewUser, setHasNewUser] = useState(false);
  const [hasUserProfile, setHasUserProfile] = useState(false);
  const [hasViewDocuments, setHasViewDocuments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [keycloakUserStatus, setKeycloakUserStatus] = useState(true);

  const [fromUserProfile, setUserProfile] = useState(false);
  const role = useSelector((state) => state.loginUserReducer.user?.role);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const fiNumber = selectedFi || loginUser.fiNumber;
  const roleId = role?.role_id;
  const roleType = role?.role_type;
  const [billerProfileData, setBillerProfileData] = useState({});
  const dispatch = useDispatch();
  const handleTabLoading = (value) => {
    setIsLoading(value);
  };
  const handleTabChange = (e, newValue) => {
    if (tabIndex === 3 || tabIndex === 2) {
      if (hasNewUser) {
        setHasNewUser(false);
      }
      if (hasUserProfile) {
        setHasUserProfile(false);
      }
      if (fromUserProfile) {
        setUserProfile(false);
      }
    }
    if (tabIndex === 1) {
      if (hasViewDocuments) {
        setHasViewDocuments(false);
      }
    }
    if (tabIndex === 4 || tabIndex === 3) {
      dispatch({
        type: "GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS",
        data: null,
      });
    }
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `biller-profile-tab-${index}`,
      // "aria-controls": `biller-profile-tabpanel-${index}`,
    };
  }
  const {
    location: { state },
  } = props;

  const billerComponentAccess = state?.componentAccess?.find(
    ({ componentType }) => componentType === "BPV"
  );

  const billerProfileComponentChildAccess =
    billerComponentAccess?.childComponents;
  const handleClickLink = () => {
    setHasNewUser(false);
  };
  const handleClickUserProfileLink = () => {
    setHasUserProfile(false);
    setUserProfile(true);
  };

  const handleCloseViewDocument = () => {
    setHasViewDocuments(false);
  };

  const handleOpenViewDocument = (data) => {
    const childBillerId =
      data?.billerId && typeof data.billerId === "string"
        ? data.billerId.split("-")[1]
        : "";
    setChildBillerId({
      ...data,
      billerId: childBillerId,
      searchedBillerId: data?.billerId,
    });
    setHasViewDocuments(true);
  };

  const handleOpenUserProfile = (data, keycloakStatus) => {
    setBillerProfileData(data);
    setUserProfile(true);
    setHasUserProfile(true);
    setKeycloakUserStatus(keycloakStatus);
  };

  const getActionTypeByRole = () => {
    if (roleId == "2" || roleId == "1") {
      return "hasEditWPBillerAccess";
    } else if (roleId == "10" || roleId == "8") {
      return "hasBillerProfileReadViewAccess";
    } else {
      return "hasBillerProfileTabAccess";
    }
  };

  const getTabPanelComponentByRole = () => {
    if (roleId == "2" || roleId == "1") {
      return (
        <EditBillerProfileDetail
          billerId={state.billerId}
          displayBillerId={state?.displayBillerId}
          {...props}
          componentAccess={billerProfileComponentChildAccess}
          parentComponentAccess={state.componentAccess}
          handleTabLoading={handleTabLoading}
          isLoading={isLoading}
        />
      );
    } else if (roleId == "10" || roleId == "8") {
      return (
        <BillerProfileUserReadVew
          billerId={state.billerId}
          displayBillerId={state?.displayBillerId}
          {...props}
          componentAccess={billerProfileComponentChildAccess}
          parentComponentAccess={state.componentAccess}
          handleTabLoading={handleTabLoading}
          isLoading={isLoading}
        />
      );
    } else if (fiNumber == 16) {
      return (
        <EditBillerProfileHsbcUser
          billerId={state?.billerId}
          displayBillerId={state?.displayBillerId}
          {...props}
          componentAccess={billerProfileComponentChildAccess}
          parentComponentAccess={state?.componentAccess}
          handleTabLoading={handleTabLoading}
          isLoading={isLoading}
        />
      );
    } else {
      return (
        <BillerProfileUser
          billerId={state?.billerId}
          displayBillerId={state?.displayBillerId}
          {...props}
          componentAccess={billerProfileComponentChildAccess}
          parentComponentAccess={state?.componentAccess}
          handleTabLoading={handleTabLoading}
          isLoading={isLoading}
        />
      );
    }
  };

  const renderViewDocument = () => {
    const updatedComponentAccess = billerProfileComponentChildAccess.find(
      ({ componentType }) => componentType === "BMUTAB"
    );

    return (
      <AvailableDocuments
        {...props}
        pageName="biller-profile"
        stateId={state.billerId}
        billerId={childBillerInfo?.billerId || updatedBillerId}
        billerInfo={childBillerInfo}
        componentAccess={updatedComponentAccess?.childComponents}
        parentComponentAccess={state?.componentAccess}
        pathValue={"/manage-billers/view-documents"}
        handleClickLink={handleCloseViewDocument}
        updateSetState={handleCloseViewDocument}
        handleCloseViewDocument={handleCloseViewDocument}
        handleOpenViewDocument={handleOpenViewDocument}
        checkRedirect={true}
      />
    );
  };

  const renderComponent = () => {
    const updatedComponentAccess = billerProfileComponentChildAccess.find(
      ({ componentType }) => componentType === "BMUTAB"
    );
    if (hasNewUser) {
      return (
        <ManageBillerNewUser
          {...props}
          updateSetState={setHasNewUser}
          stateId={state.billerId}
          routeTo={"/manage-billers/biller-profile"}
          handleClickLink={handleClickLink}
          pageName="biller-profile"
          componentAccess={updatedComponentAccess?.childComponents}
          parentComponentAccess={state?.componentAccess}
        />
      );
    } else if (hasUserProfile) {
      if (roleType === "CE") {
        return (
          <ViewUserProfile
            {...props}
            pageName="biller-profile"
            handleClickLink={handleClickUserProfileLink}
            componentAccess={updatedComponentAccess?.childComponents}
            userId={billerProfileData.userId}
            updateSetState={setHasUserProfile}
            stateId={billerProfileData.billerId}
          />
        );
      }
      return (
        <ManageBillerUserProfile
          {...props}
          updateSetState={setHasUserProfile}
          stateId={billerProfileData.billerId}
          userId={billerProfileData.userId}
          routeTo={"/manage-billers/biller-profile"}
          handleClickLink={handleClickUserProfileLink}
          pageName="manage-biller"
          componentAccess={updatedComponentAccess?.childComponents}
          parentComponentAccess={state?.componentAccess}
          keycloakUserStatus={keycloakUserStatus}
        />
      );
    }
  };

  const updatedBillerId = state?.billerId
    ? state.billerId.split("-")[1] || state.billerId
    : "";
  return (
    <Box role={tabIndex === 1 || tabIndex === 2 ? "" : "main"}>
      <Box ml={3}>
        <Breadcrumbs
          {...props}
          componentAccess={state?.componentAccess}
          fromDetailPage={true}
        />

        <Box mt={2}>
          <Typography variant="h1">
            {t("erps.billerprofile.label.billerid")} :{" "}
            {state?.displayBillerId || state?.billerId}
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="biller-tabs"
            >
              <ERPSBaseTab
                label={t("erps.editbiller.tabs.label.profile")}
                {...a11yProps(0)}
                setTabIndex={setTabIndex}
                componentAccess={billerProfileComponentChildAccess}
                actionType={getActionTypeByRole()}
              />
              <ERPSBaseTab
                label={t("erps.editbiller.tabs.label.availabledocuments")}
                {...a11yProps(1)}
                setTabIndex={setTabIndex}
                componentAccess={billerProfileComponentChildAccess}
                actionType={"hasBillerAvlDocTabAccess"}
                disabled={isLoading}
              />
              <ERPSBaseTab
                label={t("erps.editbiller.tabs.label.users")}
                {...a11yProps(2)}
                setTabIndex={setTabIndex}
                componentAccess={billerProfileComponentChildAccess}
                actionType={"hasManageBillerUserTabAccess"}
                disabled={isLoading}
              />
              {(fiNumber == "50" || fiNumber == "99") && (
                <ERPSBaseTab
                  label={t("erps.editbiller.tabs.label.relatedbiller")}
                  {...a11yProps(3)}
                  setTabIndex={setTabIndex}
                  componentAccess={billerProfileComponentChildAccess}
                  actionType={"hasBillerLinkTabAccess"}
                  disabled={isLoading}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel
            value={tabIndex}
            index={0}
            componentAccess={billerProfileComponentChildAccess}
            actionType={getActionTypeByRole()}
          >
            {getTabPanelComponentByRole()}
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={1}
            componentAccess={billerProfileComponentChildAccess}
            actionType={"hasBillerAvlDocTabAccess"}
          >
            {!hasViewDocuments ? (
              <DownloadDocuments
                {...props}
                pageName="biller-profile"
                stateId={state?.billerId}
                billerId={updatedBillerId}
                parentComponentAccess={state?.componentAccess}
                routeTo={"/download-documents/view-documents"}
                handleClickLink={handleCloseViewDocument}
                handleCloseViewDocument={handleCloseViewDocument}
                handleOpenViewDocument={handleOpenViewDocument}
              />
            ) : (
              renderViewDocument()
            )}
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={2}
            mt={-4}
            ml={-3}
            componentAccess={billerProfileComponentChildAccess}
            actionType={"hasManageBillerUserTabAccess"}
          >
            {!hasNewUser && !hasUserProfile ? (
              <ManageUsers
                {...props}
                pageName="biller-profile"
                stateId={state?.billerId}
                billerId={updatedBillerId}
                setHasNewUser={setHasNewUser}
                handleOpenUserProfile={handleOpenUserProfile}
                componentAccess={billerProfileComponentChildAccess}
                parentComponentAccess={state?.componentAccess}
                fromUserProfile={fromUserProfile}
              />
            ) : (
              renderComponent()
            )}
          </TabPanel>
          {(fiNumber == "50" || fiNumber == "99") && (
            <TabPanel
              value={tabIndex}
              index={3}
              componentAccess={billerProfileComponentChildAccess}
              actionType={"hasBillerLinkTabAccess"}
            >
              <RelatedBiller
                {...props}
                pageName="biller-profile"
                stateId={state.billerId}
                billerId={updatedBillerId}
                componentAccess={billerProfileComponentChildAccess}
                parentComponentAccess={state.componentAccess}
              />
            </TabPanel>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default withSidebar(UpdateBillerProfileTab);

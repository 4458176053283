import styled from "@emotion/styled";
import MuiButton from "@mui/material/Button";

const fi = localStorage.getItem("fiName");

const getFontColorOnHover = (prop) => {
  if (prop === "primary") {
    return "var( --secondary-bg-color) !important";
  } else if (prop === "secondary") {
    if (fi === "bmo" || fi === "D&D") {
      return "var( --tertiary-bg-color) !important";
    }
    return "var( --secondary-bg-color) !important";
  }
};
const getBackgroundColorOnHover = (prop) => {
  if (prop === "primary") {
    return "var( --tertiary-bg-color) !important";
  } else if (prop === "secondary") {
    if (fi === "bmo" || fi === "D&D") {
      return "var( --secondary-bg-color) !important";
    } else if (fi === "bns") {
      return "var( --main-bg-color) !important";
    }
    return "var( --tertiary-bg-color) !important";
  }
};
const getBorderColorOnHover = (prop) => {
  if (prop === "primary") {
    return "var( --tertiary-bg-color) !important";
  } else if (prop === "secondary") {
    return fi === "bns"
      ? "var( --secondary-bg-color) !important"
      : "var( --tertiary-bg-color) !important";
  }
};

const getBorderColor = (prop) => {
  if (prop.disabled) {
    return "#c0c0c0";
  }
  if (fi === "D&D") {
    if (prop.variant === "primary") {
      return "var(--main-bg-color) !important";
    }
    return "var(--tertiary-bg-color) !important";
  }
  return "var(--main-bg-color) !important";
};

const getTextColor = (prop) => {
  if (prop.disabled) {
    return "#ffffff";
  }
  if (prop.variant === "primary") {
    return "var(--secondary-bg-color) !important";
  }
  if (prop.variant === "secondary") {
    return fi === "D&D"
      ? "var(--tertiary-bg-color) !important"
      : "var(--main-bg-color) !important";
  }
  return "";
};

const getBackgroundColor = (prop) => {
  if (prop.disabled) {
    return "#c0c0c0";
  }
  if (prop.variant === "primary") {
    return "var(--main-bg-color) !important";
  } else if (prop.variant === "secondary") {
    return "var(--secondary-bg-color) !important";
  }
  return "var( --tertiary-bg-color) !important";
};

const Button = styled(MuiButton)((props) => ({
  color: getTextColor(props),
  backgroundColor: getBackgroundColor(props),
  border: "1px solid",
  borderColor: getBorderColor(props),
  padding:
    fi === "bmo" || fi === "bns" ? "0px 34px 0px 34px" : "0px 24px 0px 24px ",
  width: "auto",
  borderRadius: fi === "bmo" || fi === "bns" ? "51px !important" : "",
  height: "40px",
  textTransform: "none ",
  fontSize: "16px ",
  fontWeight: "400 ",
  "&:hover": {
    color: getFontColorOnHover(props.variant),
    backgroundColor: getBackgroundColorOnHover(props.variant),
    border: "1px solid ",
    borderColor: getBorderColorOnHover(props.variant),
  },
}));
export default Button;

import viewStatisticsActionTypes from "../actions/types/viewStatistics.action.type";
import store from "../store/index";
import axios from "axios";
import { getExceptionMessage, formatDate, API_BASE_URL } from "../utils/helper";
import {
  findReportLabel,
  findSortByLabel,
  findSettlementLabel,
  findPeriodLabel,
} from "./../components/StatisticsEnquiry/helper";
import {
  BSR,
  CBAS,
  CBANS,
  DSR,
  FIS,
} from "./../components/StatisticsEnquiry/data";
import moment from "moment";

export const viewStatisticsService = {
  getViewStatistics,
  setStatisticsBiller,
};

function getEndpointMethod(reportType) {
  if (reportType === BSR) {
    return "generate-report-bsr";
  } else if (reportType === CBAS) {
    return "generate-report-cbas";
  } else if (reportType === FIS) {
    return "generate-report";
  } else if (reportType === CBANS) {
    return "generate-report-cbans";
  } else if (reportType === DSR) {
    return "generate-report-dsr";
  }
}

function getViewStatistics(data, setLoading, translation) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const selectedFi = store.getState()?.fiReducer?.selectedFi;
    const token = store.getState()?.loginUserReducer.token;
    const fiNumber = selectedFi || user?.fiNumber;
    const apiEnpoint = `${API_BASE_URL()}/${getEndpointMethod(
      data.reportType
    )}`;
    const reportName = findReportLabel(data.reportType)?.apiValue;
    const sortResultBy = findSortByLabel(data.sortResultsBy)?.apiValue;
    const settlementType = findSettlementLabel(
      data.settlementType,
      fiNumber
    )?.apiValue;
    let fromDate = "";
    let toDate = "";
    if (data.reportType === BSR) {
      fromDate = null;
      toDate = null;
    } else {
      fromDate = Number(formatDate(data.fromDate));
      toDate = Number(formatDate(data.toDate));
    }
    axios
      .post(
        apiEnpoint,
        {
          fiNumber: Number(data.fiNumber),
          billerId: data.billerId || null,
          reportName: reportName,
          settlementType: settlementType || null,
          sortResultBy: sortResultBy || null,
          fromDate: fromDate,
          toDate: toDate,
          ...((data.reportType === CBAS || data.reportType === FIS) && {
            periodType: findPeriodLabel(data.periodType)?.apiValue,
          }),
          roleType: user?.role?.role_type,
          ...(data?.reportType === CBAS && {
            offset: data?.offset || 0,
            limit: data?.limit || 25,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        if (Array.isArray(parsedData)) {
          parsedData.forEach((item, i) => {
            item.id = i + 1;
          });
        }
        dispatch(success(parsedData));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedError = getExceptionMessage(error, translation);
        dispatch(failure(parsedError));
      });
  };

  function success(data) {
    return {
      type: viewStatisticsActionTypes.GET_VIEW_STATISTICS_SUCCESS,
      data: data,
    };
  }

  function failure(error) {
    return {
      type: viewStatisticsActionTypes.GET_VIEW_STATISTICS_FAILURE,
      error,
    };
  }
}

function setStatisticsBiller(billerId, fiNumber, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          "/biller-profile-name?billerId=" +
          billerId +
          "&fiNumber=" +
          fiNumber +
          "&roleType=" +
          user?.role?.role_type,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          dispatch(failure(res.data.error));
        } else {
          dispatch(success(res.data));
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props?.t);
        dispatch(failure(parsedError));
      });

    function success(data) {
      return {
        type: viewStatisticsActionTypes.SET_VIEW_STATISTICS_BILLERS,
        data,
      };
    }

    function failure(error) {
      return {
        type: viewStatisticsActionTypes.SET_VIEW_STATISTICS_BILLERS,
        error,
      };
    }
  };
}

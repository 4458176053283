import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers";

const middleware = [thunkMiddleware];
const loggerMiddleware = createLogger();

if (process.env.REACT_APP_ENV === "development") {
  middleware.push(loggerMiddleware);
}
const persistConfig = {
  key: "erps",
  blacklist: ["viewStatisticsReducer"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [...middleware],
  devTools: true,
});

const persistor = persistStore(store);
export { persistor };
export default store;

import axios from "axios";
import appConfigType from "../actions/types/app.config.type";

export const appConfigService = {
  loadEnvPropertyService,
};

function loadEnvPropertyService() {
  return (dispatch) => {
    axios
      .get("./config.json")
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => dispatch(failure(err)));
  };

  function success(data) {
    return { type: appConfigType.LOAD_ENV_PROPERTY_SUCCESS, data };
  }

  function failure(error) {
    return { type: appConfigType.LOAD_ENV_PROPERTY_SUCCESS, error };
  }
}

const passwordActionTypes = {
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  GENRATE_PASSWORD_SUCCESS: "GENRATE_PASSWORD_SUCCESS",
  GENRATE_PASSWORD_FAILURE: "GENRATE_PASSWORD_FAILURE",
  USER_PWD_UPDATE_SUCCESS: "USER_PWD_UPDATE_SUCCESS",
  USER_PWD_UPDATE_ERROR: "USER_PWD_UPDATE_ERROR",
  CHILD_USER_PWD_UPDATE_SUCCESS: "CHILD_USER_PWD_UPDATE_SUCCESS",
  CHILD_USER_PWD_UPDATE_ERROR: "CHILD_USER_PWD_UPDATE_ERROR"
};

export default passwordActionTypes;

import pricePlanActionType from "../actions/types/pricePlan.action.type";

export const pricePlanInitialState = {
  pricePlanList: null,
  error: null,
  pricePlanSearchCriteria: null,

  newPricePlanAdded: null,
  newPricePlanError: null,

  pricePlanUpdated: null,
  pricePlanUpdationError: null,
};

export default function pricePlanReducer(
  state = pricePlanInitialState,
  action
) {
  switch (action.type) {
    case pricePlanActionType.SUCCESS:
      return {
        ...state,
        pricePlanList: action.data,
        pricePlanSearchCriteria: null,
        error: null,
      };
    case pricePlanActionType.SEARCH_CRITERIA:
      return {
        ...state,
        pricePlanSearchCriteria: action.data,
        pricePlanList: action.priceList,
      };
    case pricePlanActionType.FAIL:
      return { pricePlanList: null, error: action.error };
    case pricePlanActionType.NEW_PRICE_PLAN_REQUEST:
      return {
        ...state,
        newPricePlanAdded: action.newPricePlanAdded,
        newPricePlanError: action.error,
      };
    case pricePlanActionType.NEW_PRICE_PLAN_SUCCESS:
      return {
        ...state,
        newPricePlanAdded: action.data,
        newPricePlanError: null,
      };
    case pricePlanActionType.NEW_PRICE_PLAN_FAILURE:
      return {
        ...state,
        newPricePlanAdded: null,
        newPricePlanError: action.error,
      };
    case pricePlanActionType.UPDATE_PRICE_PLAN_REQUEST:
      return {
        ...state,
        pricePlanUpdated: action.pricePlanUpdated,
        pricePlanUpdationError: action.pricePlanUpdationError,
      };
    case pricePlanActionType.UPDATE_PRICE_PLAN_SUCCESS:
      return {
        ...state,
        pricePlanUpdated: action.data,
        pricePlanUpdationError: null,
      };
    case pricePlanActionType.UPDATE_PRICE_PLAN_FAIL:
      return {
        ...state,
        pricePlanUpdationError: action.error,
        pricePlanUpdated: null,
        pricePlanList: action.pricePlan,
      };

    default:
      return state;
  }
}

import { relatedBillerActionTypes } from "../actions/types/relatedBiller.action.type";
export const relatedBillerInitialState = {
  childBillerList: null,
  parentBiller: null,
  removeBillerStatus: null,
  updateMonthlyInvoiceChildBiller: null,
  releatedBillerSearchData: null,
  error: null,
  addBillerStatus: null,
};

export default function relatedBillerReducer(
  state = relatedBillerInitialState,
  action
) {
  switch (action.type) {
    case relatedBillerActionTypes.GET_ALL_CHILD_BILLER_SUCCESS:
      return { ...state, childBillerList: action.data, error: null };
    case relatedBillerActionTypes.GET_ALL_CHILD_BILLER_FAILURE:
      return { ...state, childBillerList: null, error: action.error };

    case relatedBillerActionTypes.GET_PARENT_PARENT_SUCCESS:
      return { ...state, parentBiller: action.data, error: null };
    case relatedBillerActionTypes.GET_PARENT_PARENT_FAILURE:
      return { ...state, parentBiller: null, error: action.error };

    case relatedBillerActionTypes.UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_SUCCESS:
      return {
        ...state,
        updateMonthlyInvoiceChildBiller: action.data,
        error: null,
        removeBillerStatus: null,
        addBillerStatus: null,
      };
    case relatedBillerActionTypes.UPDATE_MONTHLY_INVOICE_FOR_CHILD_BILLER_FAILURE:
      return {
        ...state,
        updateMonthlyInvoiceChildBiller: null,
        removeBillerStatus: null,
        addBillerStatus: null,
        error: action.error,
      };

    case relatedBillerActionTypes.REMOVE_BILLER_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        removeBillerStatus: action.data,
        addBillerStatus: null,
        error: null,
        updateMonthlyInvoiceChildBiller: null,
      };
    case relatedBillerActionTypes.REMOVE_BILLER_RELATIONSHIP_FAILURE:
      return {
        ...state,
        removeBillerStatus: null,
        addBillerStatus: null,
        updateMonthlyInvoiceChildBiller: null,
        error: action.error,
      };

    case relatedBillerActionTypes.ADD_BILLER_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        addBillerStatus: action.data,
        removeBillerStatus: null,
        updateMonthlyInvoiceChildBiller: null,
        error: null,
      };
    case relatedBillerActionTypes.ADD_BILLER_RELATIONSHIP_FAILURE:
      return {
        ...state,
        addBillerStatus: null,
        removeBillerStatus: null,
        updateMonthlyInvoiceChildBiller: null,
        error: action.error,
      };

    case relatedBillerActionTypes.GET_RELATED_BILLER_SEARCH_RESPONSE_SUCCESS:
      return { ...state, releatedBillerSearchData: action.data, error: null };
    case relatedBillerActionTypes.GET_RELATED_BILLER_SEARCH_RESPONSE_FAILURE:
      return { ...state, releatedBillerSearchData: null, error: action.error };
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import Dialog from "@mui/material/Dialog";

import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { loginMessageManagementAction } from "../../actions/loginMessage.action";
import parse from "html-react-parser";
import { validateURL } from "../../utils/helper";

const ManageMessageCentre = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);

  const fiNumber = selectedFi || loginUser.user?.fiNumber;

  const successRef = useRef(null);

  const successMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.createDashboardMessage
  );
  const errorMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.createDashboardError
  );
  const [showExceptionMessage, setShowExceptionMessage] = useState(false);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [messagesList, setMessagesList] = useState([
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.french_label"),
      },
      messageId: 1,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.french_label"),
      },
      messageId: 2,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.french_label"),
      },
      messageId: 3,
    },
  ]);
  const getDashboardErrorMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardError
  );
  const getDashboardMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardMessage
  );
  const descriptionElementRef = useRef(null);
  const errorRef = useRef();

  const getFormattedData = (dashboardData) => {
    let dataV1 = [];
    dashboardData.forEach((data, idx) => {
      dataV1.push({
        en: {
          header: data?.messageHeaderEnglish,
          message: data?.messageTextEnglish,
          urlText: data?.messageLinkTextEnglish,
          urlLink: data?.messageUrlEnglish,
          type: t("erps.header.language.english_label"),
          id: `${idx + 1}`,
        },
        fr: {
          header: data?.messageHeaderFrench,
          message: data?.messageTextFrench,
          urlText: data?.messageLinkTextFrench,
          urlLink: data?.messageUrlFrench,
          type: t("erps.header.language.french_label"),
          id: `${idx + 1}`,
        },
        messageId: data?.id,
      });
    });
    return dataV1;
  };

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [getDashboardErrorMessage, errorMessage]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    dispatch(
      loginMessageManagementAction.getLoginMessage({
        setLoading,
        setSuccessMessage,
        setShowExceptionMessage,
        t,
      })
    );
  }, []);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  useEffect(() => {
    if (getDashboardMessage && getDashboardMessage.length) {
      const dataV1 = getFormattedData(getDashboardMessage);
      setMessagesList(dataV1);
    }
  }, [getDashboardMessage]);
  const getUpdatedUrlValue = (value) => {
    const updatedValue = value.replace(/ +/g, "");
    const hasProtocol =
      /^(https\:\/\/|http\:\/\/|https\:\/|http\:\/|https\:|http\:|https|http)/.test(
        updatedValue
      );

    if (hasProtocol) {
      return updatedValue;
    }
    return `http://${updatedValue}`;
  };
  const handleChange = (type, key, indexVal) => (e) => {
    const slicedVal = messagesList.slice();
    const objVal = slicedVal[indexVal];
    const updatedObj = {
      ...objVal,
      [type]: {
        ...objVal[type],
        [key]: e.target.value,
      },
    };
    slicedVal.splice(indexVal, 1, updatedObj);
    setMessagesList(slicedVal);
  };
  const handleBlur = (type, key, indexVal) => (e) => {
    const trimmedValue = e?.target?.value?.trim();
    if (trimmedValue) {
      const slicedVal = messagesList.slice();
      const objVal = slicedVal[indexVal];
      const updatedUrlValue = getUpdatedUrlValue(trimmedValue);
      const updatedObj = {
        ...objVal,
        [type]: {
          ...objVal[type],
          [key]:
            updatedUrlValue?.length > 256
              ? updatedUrlValue?.slice(0, 256)
              : updatedUrlValue,
        },
      };
      slicedVal.splice(indexVal, 1, updatedObj);
      setMessagesList(slicedVal);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const formatReqBody = (reqData) => {
    const resData = [];
    reqData.forEach((reqBody) => {
      resData.push({
        id: reqBody["en"]?.id,
        messageTextEnglish: reqBody["en"]?.message,
        messageUrlEnglish: reqBody["en"]?.urlLink,
        messageLinkTextEnglish: reqBody["en"]?.urlText,
        messageTextFrench: reqBody["fr"]?.message,
        messageLinkTextFrench: reqBody["fr"]?.urlText,
        messageUrlFrench: reqBody["fr"]?.urlLink,
        messageHeaderEnglish: reqBody["en"]?.header,
        messageHeaderFrench: reqBody["fr"]?.header,
        fiNumber: fiNumber,
      });
    });
    return resData;
  };

  const handleSave = () => {
    setLoading(true);
    setShowExceptionMessage(false);
    const updatedReqBody = formatReqBody(messagesList);
    dispatch(
      loginMessageManagementAction.createdLoginMessage({
        data: updatedReqBody,
        setLoading,
        setSuccessMessage,
        t,
        setShowExceptionMessage,
      })
    );
  };

  const getCardComponent = (lan) => {
    const messagesContent = messagesList.map((data) => data[lan]);

    return (
      <>
        <Box mb={2}>
          <Typography variant="h3">
            {lan === "en"
              ? t("erps.header.language.english_label")
              : t("erps.header.language.french_label")}
          </Typography>
        </Box>

        <Card className="messageCenter__card__container">
          <CardContent>
            <Typography
              variant="h2"
              className="messageCenter__card__content__header"
            >
              {t("erps.login.message.card.title", "Messages")}
            </Typography>
            {messagesContent.map(
              ({ header, message, urlText, urlLink }, idx) => (
                <Box mt={2}>
                  {header && (
                    <Typography
                      variant="h3"
                      className="messageCenter__card__content__heading"
                    >
                      {header}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className="signIn__card__content__header"
                  >
                    {message ? parse(message) : ""}
                    {message && <br />}
                    <ERPSBaseLink
                      label={urlText || urlLink}
                      to={{
                        pathname: !validateURL(urlLink)
                          ? `http://${urlLink}`
                          : urlLink,
                      }}
                      target={"_blank"}
                      className="signIn__card__content__link"
                    />
                  </Typography>
                </Box>
              )
            )}
          </CardContent>
        </Card>
      </>
    );
  };
  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setSuccessMessage}
          inputRef={successRef}
        />
      )}
      {showExceptionMessage || getDashboardErrorMessage ? (
        <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
          <ErrorMessage
            error={errorMessage || getDashboardErrorMessage}
            errorRef={errorRef}
          />
        </Box>
      ) : (
        ""
      )}
      <Box ml={3}>
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.marketingManagement.manageLoginPage.heading")}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body1">
            {t("erps.marketingManagement.manageLoginPage.subheading")}
          </Typography>
        </Box>
        {messagesList &&
          messagesList.length &&
          messagesList.map(({ messageId, en, fr }, idx) => (
            <Box>
              <Box mt={5}>
                <Typography variant="h2">{`Message ${idx + 1}`}</Typography>
              </Box>
              <Box>
                <Box mt={2}>
                  <Typography variant="h3">{en.type}</Typography>
                </Box>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`en-header${en.id}`}
                    type="text"
                    value={en.header}
                    onChange={handleChange("en", "header", idx)}
                    label={t("erps.marketingManagement.textField.header")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`en-message${en.id}`}
                    type="text"
                    value={en.message}
                    onChange={handleChange("en", "message", idx)}
                    label={t("erps.marketingManagement.textField.message")}
                    className="textField_dimensions_manageMessageCentre_message"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`en-urlText${en.id}`}
                    type="text"
                    value={en.urlText}
                    onChange={handleChange("en", "urlText", idx)}
                    label={t("erps.marketingManagement.textField.urlText")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                  <ERPSBaseTextField
                    id={`en-urlLink${en.id}`}
                    type="text"
                    value={en.urlLink}
                    onChange={handleChange("en", "urlLink", idx)}
                    label={t("erps.marketingManagement.textField.url")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                    onBlur={handleBlur("en", "urlLink", idx)}
                  />
                </Stack>
              </Box>
              <Box>
                <Box mt={4}>
                  <Typography variant="h3">{fr.type}</Typography>
                </Box>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`fr-header${fr.id}`}
                    type="text"
                    value={fr.header}
                    onChange={handleChange("fr", "header", idx)}
                    label={t("erps.marketingManagement.textField.header")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`fr-message${fr.id}`}
                    type="text"
                    value={fr.message}
                    onChange={handleChange("fr", "message", idx)}
                    label={t("erps.marketingManagement.textField.message")}
                    className="textField_dimensions_manageMessageCentre_message"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <ERPSBaseTextField
                    id={`fr-urlText${fr.id}`}
                    type="text"
                    value={fr.urlText}
                    onChange={handleChange("fr", "urlText", idx)}
                    label={t("erps.marketingManagement.textField.urlText")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                  />
                  <ERPSBaseTextField
                    id={`fr-urlLink${fr.id}`}
                    type="text"
                    value={fr.urlLink}
                    onChange={handleChange("fr", "urlLink", idx)}
                    label={t("erps.marketingManagement.textField.url")}
                    className="textField_dimensions_manageMessageCentre_header"
                    size="small"
                    onInput={(e) => {
                      e.target.value = e.target?.value.slice(0, 256);
                    }}
                    onBlur={handleBlur("fr", "urlLink", idx)}
                  />
                </Stack>
              </Box>
            </Box>
          ))}

        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t(
              "erps.marketingManagement.secondary.button.discardChanges"
            )}
            onClick={() => {
              if (getDashboardMessage && getDashboardMessage.length) {
                const dataV1 = getFormattedData(getDashboardMessage);
                setMessagesList(dataV1);
              } else {
                setMessagesList([
                  {
                    en: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 1,
                      type: t("erps.header.language.english_label"),
                    },
                    fr: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 1,
                      type: t("erps.header.language.french_label"),
                    },
                    messageId: 1,
                  },
                  {
                    en: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 2,
                      type: t("erps.header.language.english_label"),
                    },
                    fr: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 2,
                      type: t("erps.header.language.french_label"),
                    },
                    messageId: 2,
                  },
                  {
                    en: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 3,
                      type: t("erps.header.language.english_label"),
                    },
                    fr: {
                      header: "",
                      message: "",
                      urlText: "",
                      urlLink: "",
                      id: 3,
                      type: t("erps.header.language.french_label"),
                    },
                    messageId: 3,
                  },
                ]);
              }
            }}
          />
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            onClick={handleOpen}
            label={t(
              "erps.marketingManagement.secondary.button.previewMessages"
            )}
          />
          <ERPSBaseButton
            variant="primary"
            onClick={handleSave}
            className="button_with_margin48"
            label={t("erps.marketingManagement.primary.button.save")}
            loading={loading}
          />
        </Stack>
        <ERPSBaseDialog
          role="dialog"
          onClose={handleClose}
          aria-labelledby="preview-messages-dialog"
          open={open}
          fullWidth
          maxWidth="sm"
        >
          <DialogHeader
            id="preview-messages-dialog"
            onClose={handleClose}
            style={{
              fontFamily: "Roboto,Regular",
              fontSize: "18px",
              color: "#2a2c2e",
            }}
          >
            {t("erps.marketingManagement.manageLoginPage.dialog.header")}
          </DialogHeader>
          <DialogContent>
            <Box>{getCardComponent("en")}</Box>
            <Box mt={3} mb={2}>
              {getCardComponent("fr")}
            </Box>
          </DialogContent>
          <DialogActions className={"leftAlignDialogActions"}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_zeromargin"
              onClick={handleClose}
              label={t("erps.marketingManagement.dialog.closeButton")}
            />
          </DialogActions>
        </ERPSBaseDialog>
      </Box>
    </div>
  );
};
export default withSidebar(ManageMessageCentre);

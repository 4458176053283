import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import cibc from "../../assets/images/cibc.png";
import bmo from "../../assets/images/bmo.png";
import scotiabankFR from "../../assets/images/scotiabankFR.png";
import scotiabankEN from "../../assets/images/scotiabankEN.png";
import dndFr from "../../assets/images/dndFr.png";
import dndEn from "../../assets/images/dndEn.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { Link } from "react-router-dom";
import { AppBar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import NavBar from "../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import { getHeaderAndFooterLinks } from "../../utils/external-urls";
import Tooltip from "@mui/material/Tooltip";
import useIdle from "./useIdleTimeout";
import UserService from "../../utils/keycloak.service.js";
import axios from "axios";

const Header = (props) => {
  // This is legacy styling solution of MUI.
  const isLoggedIn = useSelector(
    (state) => state.loginUserReducer?.isAuthorized
  );
  const languageCode = useSelector(
    (state) => state.loginUserReducer?.user?.languageCode
  );
  const sessionTimeOut = useSelector(
    (state) => state.loginUserReducer?.user?.sessionTimeOut
  );
  const fiSessionTimeOut =
    useSelector((state) => state.loginUserReducer?.user?.maxFiSessionTimeOut) ||
    20;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const changeLocaleLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };

  const currentLang =
    i18next.language === "en"
      ? "erps.header.language.french_label"
      : "erps.header.language.english_label";
  const pagesBeforeLoggedIn = ["erps.header.contactus.message", currentLang];

  const pagesAfterLoggedIn = [
    "erps.header.contactus.message",
    "erps.header.link.help",
    "erps.header.link.logout",
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const open = Boolean(anchorElNav);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const [interceptorLoaded, setInterceptorLoaded] = useState(false);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [state, setState] = useState({
    left: false,
  });

  const [userWasIdle, setUserWasIdle] = useState(false);

  useEffect(() => {
    async function initKc() {
      await UserService.initKeycloak({ props });
    }
    initKc();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (languageCode == "E" && i18next.language != "en") {
        i18next.changeLanguage("en");
      } else if (languageCode === "F" && i18next.language != "fr") {
        i18next.changeLanguage("fr");
      }
    }
  }, []);

  const logout = () => {
    localStorage.setItem("logoutClicked", "true");
    localStorage.removeItem("persist:erps");
    UserService.doLogout();
    idleTimer.reset();
    // dispatch(
    //   actions.loginActions.logout({
    //     ...props,
    //     t,
    //     ...(userWasIdle && {
    //       idleLogoutMsg: t("erps.session.idletimeout.message"),
    //     }),
    //   })
    // );
  };

  const handleIdle = () => {
    console.log("User is idle");
    setUserWasIdle(true);
  };
  const handleActive = () => {
    console.log("User is active");
  };

  const handleAction = (event, idleTimer) => {
    if (
      isLoggedIn &&
      userWasIdle &&
      (event.type == "mousedown" || event.type == "keydown")
    ) {
      logout();
    }
  };

  const idleTimer = useIdle({
    onIdle: handleIdle,
    isLoggedIn,
    idleTime: sessionTimeOut || fiSessionTimeOut, // time in minutes
    onActive: handleActive,
    onAction: handleAction,
  });

  useEffect(() => {
    if (isLoggedIn) {
      idleTimer.start();
      /** Intercept any unauthorized and token expired request.
       * dispatch logout action accordinglyionStorage **/
      if (!interceptorLoaded) {
        setInterceptorLoaded(true);
        const UNAUTHORIZED = 401;
        axios.interceptors.response.use(
          (response) => response,
          (error) => {
            const { status, data } = error.response;
            if (status === UNAUTHORIZED) {
              // && data?.error == "invalid_token"
              logout();
            }
            return Promise.reject(error);
          }
        );
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      idleTimer.reset();
      idleTimer.start();
    }
  }, [sessionTimeOut]);

  const toggleDrawer = (event, anchor, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getHeaderLogo = () => {
    const fiName = localStorage.getItem("fiName");
    switch (fiName) {
      case "cibc":
        return cibc;
      case "bmo":
        return bmo;
      case "bns":
        return i18next.language === "en" ? scotiabankEN : scotiabankFR;

      default:
        return i18next.language === "en" ? dndEn : dndFr;
    }
  };

  const mobileLinkHandler = (page) => {
    if (page === "erps.header.language.english_label")
      changeLocaleLanguage("en");
    if (page === "erps.header.language.french_label")
      changeLocaleLanguage("fr");
    if (page === "erps.header.contactus.message" && contactUsLink) {
      window.open(contactUsLink, "_blank");
    }
    if (page === "erps.header.link.help" && helpLink) {
      window.open(helpLink, "_blank");
    }
    if (page === "erps.header.link.logout") logout();
    handleCloseNavMenu();
  };

  const pages = isLoggedIn ? pagesAfterLoggedIn : pagesBeforeLoggedIn;
  const fiName = localStorage.getItem("fiName");
  const { contactUsLink, helpLink } = getHeaderAndFooterLinks(i18n.language);
  return (
    <AppBar position="absolute" className="app_header">
      <Grid container>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          {isLoggedIn && (
            <>
              <Tooltip title={t("erps.login.tooltip.humburgericon")}>
                <IconButton
                  onClick={(e) => toggleDrawer(e, "left", true)}
                  className="header__container__hamburger__icon"
                  sx={{ zIndex: 10000 }}
                  data-testid="humburger-icon"
                >
                  {" "}
                  <MenuIcon fontSize="large" />{" "}
                </IconButton>
              </Tooltip>
              <Drawer
                open={state["left"]}
                onClose={(e) => toggleDrawer(e, "left", false)}
              >
                <NavBar {...props} />
              </Drawer>
            </>
          )}

          <img
            src={getHeaderLogo()}
            alt={
              fiName === "D&D"
                ? "Dye and Durham"
                : fiName.charAt(0).toUpperCase() + fiName.slice(1)
            }
            className={
              isLoggedIn
                ? "header__container__logo header__container__logo__loggedIn"
                : "header__container__logo"
            }
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          sm={8}
          xs={8}
          className="header__container__title"
        >
          <Typography
            variant="alignText"
            className="text__color app__header__heading"
          >
            {fiName === "bmo" && fiName === "D&D"
              ? t("erps.app.title.message.bmo", "Merchant Bill Pay Service")
              : t(
                  "erps.app.title.message",
                  "Bill payment remittance reporting"
                )}
          </Typography>
        </Grid>
        <Grid item lg={2} md={1} sm={1} xs={1}>
          {/* Desktop */}
          <Box
            sx={{
              pt: 2,
              justifyContent: "space-evenly",
              display: {
                md: "none",
                xs: "none",
                sm: "none",
                lg: "flex",
              },
            }}
          >
            <p>
              {contactUsLink ? (
                <ERPSBaseLink
                  to={{ pathname: contactUsLink || "" }}
                  className="header__link__active"
                  label={t("erps.header.contactus.message", "Contact Us")}
                  variant="link"
                  target={contactUsLink ? "_blank" : ""}
                />
              ) : (
                ""
              )}
            </p>
            {!isLoggedIn ? (
              <Box
                alignItems={"center"}
                justifyContent={"space-between"}
                display={"flex"}
                p={2}
              >
                <Box>
                  <Link
                    onClick={() => changeLocaleLanguage("en")}
                    to="#"
                    className={`${
                      i18n.language === "en"
                        ? "header__link__disable"
                        : "header__link__active"
                    }`}
                    aria-label="EN, Switch to English"
                  >
                    {t("erps.header.language.english", "EN")}
                  </Link>
                </Box>
                <Box ml={1}>
                  <span className="link_underline_color"> | </span>
                </Box>
                <Box ml={1}>
                  <Link
                    onClick={() => changeLocaleLanguage("fr")}
                    to="#"
                    className={`${
                      i18n.language === "fr"
                        ? "header__link__disable"
                        : "header__link__active"
                    }`}
                    aria-label="FR, Switch to French"
                  >
                    {t("erps.header.language.french", "FR")}
                  </Link>
                </Box>
              </Box>
            ) : (
              <div style={{ display: "flex", marginRight: "50px" }}>
                {helpLink ? (
                  <p className="link__paragraph">
                    <ERPSBaseLink
                      className="header__link__active"
                      label={t("erps.header.link.help")}
                      to={{ pathname: helpLink || "" }}
                      target={helpLink ? "_blank" : ""}
                    />
                  </p>
                ) : (
                  ""
                )}
                <p className="link__paragraph link__last__paragraph">
                  <ERPSBaseLink
                    to="/"
                    className="header__link__active"
                    onClick={logout}
                    label={t("erps.header.link.logout")}
                  />
                </p>
              </div>
            )}
          </Box>
          {/* Mobile */}
          <Box>
            <Box
              pt={3}
              pl={3}
              sx={{
                display: { lg: "none", xs: "flex" },
              }}
            >
              <Tooltip title={"Application menu"}>
                <IconButton
                  size="small"
                  aria-label="application menu"
                  // aria-controls="application-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleOpenNavMenu}
                  sx={{
                    display: { lg: "none", xs: "flex" },
                  }}
                  className="text__color"
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                sx={{
                  display: { lg: "none", xs: "flex" },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClick={handleCloseNavMenu}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) =>
                  page === "erps.header.contactus.message" ||
                  page === "erps.header.link.help" ? (
                    contactUsLink || helpLink ? (
                      <MenuItem
                        key={page}
                        onClick={() => mobileLinkHandler(page)}
                      >
                        <Typography style={{ textAlign: "center" }}>
                          <Link className="link__blue">{t(page)}</Link>
                        </Typography>
                      </MenuItem>
                    ) : (
                      ""
                    )
                  ) : (
                    <MenuItem
                      key={page}
                      onClick={() => mobileLinkHandler(page)}
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Link to="#" className="link__blue">
                          {t(page)}
                        </Link>
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;

const availableDocumentTypes = {
  GET_AVAILABLE_DOCUMENT_SUCCESS: "GET_AVAILABLE_DOCUMENT_SUCCESS",
  GET_AVAILABLE_DOCUMENT_ERROR: "GET_AVAILABLE_DOCUMENT_ERROR",

  AVAILABLE_DOCUMENT_DOWNLOAD_ERROR: "AVAILABLE_DOCUMENT_DOWNLOAD_ERROR",
  AVAILABLE_DOCUMENT_DOWNLOAD_SUCCESS: "AVAILABLE_DOCUMENT_DOWNLOAD_SUCCESS",

  GET_AVAILABLE_REPORTS_SUCCESS: "GET_AVAILABLE_REPORTS_SUCCESS",
  GET_AVAILABLE_REPORTS_ERROR: "GET_AVAILABLE_REPORTS_ERROR",

  GET_REPORTS_NAME_SUCCESS: "GET_REPORTS_NAME_SUCCESS",
  GET_REPORTS_NAME_ERROR: "GET_REPORTS_NAME_ERROR",

  AVAILABLE_REPORTS_DOWNLOAD_SUCCESS: "AVAILABLE_REPORTS_DOWNLOAD_SUCCESS",
  AVAILABLE_REPORTS_DOWNLOAD_ERROR: "AVAILABLE_REPORTS_DOWNLOAD_ERROR",

  GET_AVAILABLE_REPORTS_LIST_SUCCESS: "GET_AVAILABLE_REPORTS_LIST_SUCCESS",
  GET_AVAILABLE_REPORTS_LIST_ERROR: "GET_AVAILABLE_REPORTS_LIST_ERROR",
  RESET_AVAILABLE_REPORTS_LIST: "RESET_AVAILABLE_REPORTS_LIST",
};

export default availableDocumentTypes;

import React, { useEffect } from "react";
import UserService from "../../utils/keycloak.service.js";
import LoadingIcon from "../custom/inputs/ERPSLoadingIcon.js";
import { Box } from "@material-ui/core";

export default function KcRedirect(props) {
  useEffect(() => {
    // const hasCodeRun = localStorage.getItem("hasCodeRun");
    async function initKc() {
      // debugger
      // if (!hasCodeRun) {
        await UserService.initKeycloak(props);
        if(!UserService.isLoggedIn()) {
          UserService.doLogin();
        }
      // } else {
      //   localStorage.clear();
      // }
    }
    initKc();
  }, []);
  return (
    <Box textAlign={"center"} mt={30}>
      <LoadingIcon />
    </Box>
  );
}

import userActionTypes from "../actions/types/user.action.type";

export const userQueueInitialState = {
  message: null,
  error: null,
};

export default function userQueueReducer(
  state = userQueueInitialState,
  action
) {
  switch (action.type) {
    case userActionTypes.USER_QUEUE_UPDATE_SUCCESS:
      return { message: action.data, error: null };
    case userActionTypes.USER_QUEUE_UPDATE_ERROR:
      return { message: null, error: action.error };
    case userActionTypes.USER_QUEUE_DELETE_SUCCESS:
      return { message: action.data, error: null };
    case userActionTypes.USER_QUEUE_DELETE_ERROR:
      return { message: null, error: action.error };
    default:
      return state;
  }
}

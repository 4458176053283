import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import ChangePassword from "../User/ChangePassword";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import { getComponentAccess } from "../../utils/helper";
import warningIcon from "../../assets/images/warning-icon.png";
import ErrorMessage from "../custom/containers/ErrorMessage";

const UpdatePassword = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.loginUserReducer?.user);
  const [showMessageForChangePassword, setShowMessageForChangePassword] =
    useState(false);
  const hasUserPasswordUpdated = useSelector(
    (state) => state.userPasswordReducer?.user?.message
  );
  const hasUserchangePasswordIndex = useSelector(
    (state) => state.loginUserReducer?.user?.changePasswordIndex
  );
  const errorRef = useRef();
  const hasUserUpdatedPasswordIndex = useSelector(
    (state) => state.userPasswordReducer?.user?.userDetails?.changePasswordIndex
  );
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [hasUserchangePasswordIndex, hasUserUpdatedPasswordIndex]);

  const successRef = useRef(null);
  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [hasUserPasswordUpdated && showMessageForChangePassword]);

  const showExpiredPassworMsg = () => {
    if (hasUserchangePasswordIndex == "Y") {
      if (hasUserUpdatedPasswordIndex) {
        return hasUserUpdatedPasswordIndex == "Y" ? true : false;
      } else {
        return true;
      }
    }
    return false;
  };

  const {
    location: { state },
  } = props;

  const componentAccessData =
    state?.componentAccess ||
    getComponentAccess(
      {
        function_type_code: "UP",
        label_type_code: "MP",
      },
      user
    );

  return (
    <div role="main">
      {hasUserPasswordUpdated && showMessageForChangePassword && (
        <ConfirmationMessage
          message={hasUserPasswordUpdated}
          setShowMessage={setShowMessageForChangePassword}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Box mt={5}>
          <Typography variant="h1">
            {t("erps.updatepassword.label", "Update Password")}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginTop: "1.5rem",
            }}
          />
          <ChangePassword
            {...props}
            userId={user.userId}
            subTitle={false}
            fiNumber={user.fiNumber}
            pageName="update-password"
            setShowMessage={setShowMessageForChangePassword}
            componentAccess={componentAccessData}
          />
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(UpdatePassword);

import React, { useEffect, useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../actions/user.actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { withSidebar } from "../hoc/withSidebar";
import Breadcrumbs from "../Breadcrumb/Breadcrumb";
import { getFormattedPhoneNumber } from "../../utils/helper";
const ViewUserProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userResult = useSelector((state) => state.userDetailReducer.userData);
  const user = useSelector((state) => state.loginUserReducer.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const subscribeEntities = useSelector(
    (state) => state.subscribingEntitiesReducer
  );
  const [errorMessage, setErrorMessage] = useState([]);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);
  const roleTypeOfLoggedInUser = user?.role?.role_type;
  const {
    location: { state },
  } = props;

  useEffect(() => {
    if (subscribeEntities) {
      if (subscribeEntities.subscribingEntitiesList) {
        const data = subscribeEntities.subscribingEntitiesList.map(
          ({ fiCompanyNumber, fiCompanyName }) => ({
            value: fiCompanyNumber,
            label: fiCompanyName,
          })
        );
        setSubscribeEntityList(data);
      }
    }
    if (subscribeEntities?.error) {
      setErrorMessage([subscribeEntities.error]);
    }
  }, [subscribeEntities]);

  useEffect(() => {
    dispatch(
      userActions.getUserRoleTypeList(
        user?.fiNumber,
        roleTypeOfLoggedInUser,
        "manageusercomponent",
        t,
        null,
        false
      )
    );
  }, []);

  useEffect(() => {
    const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;
    if (fiNumberValue == 99 || fiNumberValue == 88) {
      dispatch(userActions.getSubscribingEntitiesList(fiNumberValue, t));
    }
  }, []);

  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const getUserData = () => {
    let data = null;
    if (
      props.pageName === "account-registration" ||
      props.pageName === "account-settings"
    ) {
      data = {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        phone: user.phone,
        role: user.role.role_name,
        statusCode: user.statusCode,
        roleId: user.role.role_id,
        languageCode: user.languageCode,
        fiCompanyNumber: user.fiCompanyNumber,
        fiCompanyName: user.fiCompanyName,
        challengeNumber: user.challengeNumber,
        challengeResponse: user.challengeResponse,
        fiNumber: selectedFiNumber || user.fiNumber,
        roleType: user.role.role_type,
        changePasswordIndex: "N",
        logonFailures: 0,
        passwordHash: "",
        billerId: user.billerNonVolatileDto?.biller_id,
        billerAccountNumber: user.billerNonVolatileDto?.debit_account_number,
        emailNotificationOk: user.billerNonVolatileDto?.email_notification_ok,
        csvAdviceOk: user.billerNonVolatileDto?.csv_advice_ok,
      };
    } else if (props?.pageName === "biller-profile") {
      data = userResult
        ? userResult.find(
            ({ userId, internalBMOUserId }) =>
              internalBMOUserId === props.userId || userId === props.userId
          )
        : {};
    } else {
      data = userResult
        ? userResult.find(({ userId }) => userId === state.id)
        : {};
    }

    return data;
  };

  const {
    userId,
    firstName,
    lastName,
    emailAddress,
    phone,
    statusCode,
    roleName,
    languageCode,
    challengeNumber,
    challengeResponse,
    roleType,
    fiCompanyNumber,
    fiCompanyName,
    internalBMOUserId,
  } = getUserData();

  const challengeQuesList = [
    {
      value: 1,
      label: t(
        "erps.userprofile.challengequestion1.label",
        "What is your mother's maiden name?"
      ),
    },
    {
      value: 2,
      label: t(
        "erps.userprofile.challengequestion2.label",
        "What is your's pet name?"
      ),
    },
    {
      value: 3,
      label: t(
        "erps.userprofile.challengequestion3.label",
        "Where is your favourite hoilday destination?"
      ),
    },
  ];
  const handleBack = () => {
    if (props?.pageName === "biller-profile") {
      props.updateSetState(false);
    } else {
      props.history.push("/manage-users", {
        componentAccess: state.componentAccess,
        fromDetailPage: true,
      });
    }
  };
  return (
    <Box>
      {errorMessage.length ? (
        <Box className={"manageUsers__errorMsg__container"} role="alert" mt={2}>
          {errorMessage.length ? (
            <ErrorMessage error={errorMessage} errorRef={errorRef} />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      <Box ml={3}>
        {props?.pageName !== "biller-profile" && (
          <Breadcrumbs
            {...props}
            stateId={props?.stateId}
            componentAccess={state.componentAccess}
            updateSetState={props?.updateSetState}
            fromDetailPage={true}
            pathValue={"/manage-users/user-profile"}
          />
        )}
        {props?.pageName !== "biller-profile" && (
          <>
            <Box mt={2}>
              <Typography variant="h1">
                {t("erps.userprofile.label.userid", "Profile for user ID")} :
                {state.id}
              </Typography>
            </Box>
            <Box mt={5}>
              <Typography variant="h2">
                {t("erps.navbar.myprofile.subtitle.accountsettings")}
              </Typography>
            </Box>
          </>
        )}
        <Stack
          direction="row"
          mt={props?.pageName === "biller-profile" ? 4 : 0}
          spacing={3}
        >
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.manageusers.label.userid", "User ID")}
              <br />
            </Typography>
            <label className="test-label">{internalBMOUserId || userId}</label>
          </div>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.role", "Role")}
              <br />
            </Typography>

            <label className="test-label">{roleName}</label>
          </div>
        </Stack>
        <Stack direction="row" spacing={3}>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.firstname", "First name")}
              <br />
            </Typography>

            <label className="test-label">{firstName}</label>
          </div>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.lastname", "Last name")}
              <br />
            </Typography>

            <label className="test-label">{lastName}</label>
          </div>
        </Stack>
        <Stack direction="row" spacing={3}>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.emailaddress", "Email Address")}
              <br />
            </Typography>

            <label className="test-label">{emailAddress}</label>
          </div>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.phonenumber", "Phone number")}
              <br />
            </Typography>

            <label className="test-label">
              {phone ? getFormattedPhoneNumber(phone) : ""}
            </label>
          </div>
        </Stack>
        <Stack direction="row" spacing={3}>
          {roleType === "CA" && (
            <div className="view_user_profile">
              <Typography variant="body1">
                {t(
                  "erps.newuser.label.subscribingentityid",
                  "Subscribing entity id"
                )}
                <br />
              </Typography>
              <label className="test-label">{fiCompanyName}</label>
            </div>
          )}
        </Stack>
        <Stack direction="row" spacing={3}>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.userprofile.language.text", "Language")}
              <br />
            </Typography>

            <label className="test-label">
              {languageCode === "F"
                ? t("erps.userprofile.language.french.text")
                : t("erps.userprofile.language.english.text")}
            </label>
          </div>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.status", "Status")}
              <br />
            </Typography>

            <label className="test-label">
              {statusCode === "A"
                ? t("erps.manageusers.label.status.active")
                : t("erps.manageusers.label.status.inactive")}
            </label>
          </div>
        </Stack>
        <Stack direction="column" style={{ marginTop: "4rem" }}>
          <Typography variant="h2">
            {t("erps.newuser.label.challengeques", "Challenge question")}
          </Typography>

          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {t(
              "erps.newuser.label.challengetext",
              "The challenge question and response are used to help securely identify you if you call support."
            )}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={3}>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.challengeques", "Challenge question")}
              <br />
            </Typography>

            <label className="test-label">
              {challengeQuesList[challengeNumber]?.label}
            </label>
          </div>
        </Stack>
        <Stack direction="row" spacing={3}>
          <div className="view_user_profile">
            <Typography variant="body1">
              {t("erps.newuser.label.challengeresponse", "Challenge response")}
              <br />
            </Typography>

            <label className="test-label">{challengeResponse}</label>
          </div>
        </Stack>

        <ERPSBaseButton
          variant="primary"
          className="button_with_margin48"
          label={t("erps.newuser.button.back", "Back")}
          onClick={handleBack}
          //actionType="hasUpdateUserAccess"
          //componentAccess={userProfileAccess?.childComponents}
        />
      </Box>
    </Box>
  );
};

export default withSidebar(ViewUserProfile);

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { USER_ACCOUNT_SETTINGS_HEADINGS } from "../../constants/UserPendingApprovalsContant";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import {
  getFormattedPhoneNumber,
  getNextFilterId,
  requiredFormatDate,
  getLabelName,
} from "../../utils/helper";
const UpdateUserApproval = (props) => {
  const { t } = useTranslation();
  const {
    data,
    existingDataList,
    setShowSuccessMessage,
    componentAccess,
    userId: loggedInUser,
    userPendingData,
  } = props;
  const dispatch = useDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const userActionError = useSelector(
    (state) => state.userApprovalReducer?.userActionError
  );

  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [userActionError]);

  function camelToPascalCase(key) {
    const result = key.replace(/([A-Z])/g, (val) => ` ${val.toLowerCase()}`);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const getAccontSettingData = () => {
    let obj = [];
    const allowedKeys = [
      "userId",
      "emailAddress",
      "phone",
      "languageCode",
      "firstName",
      "lastName",
      "roleName",
      "statusCode",
      "fiCompanyName",
      "companyInformation",
    ];
    for (let key in data) {
      if (allowedKeys.includes(key)) {
        let updatedKeyValue;
        let dataValue;
        let existingDataValue;

        if (key === "companyInformation") {
          updatedKeyValue = t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.subscribingEntityId"
          );
          dataValue = data?.companyInformation?.fiCompanyName;
          existingDataValue =
            existingDataList?.companyInformation?.fiCompanyName;
        } else if (key === "languageCode") {
          updatedKeyValue = t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.language"
          );
          dataValue =
            data?.[key] === "E"
              ? t("erps.userprofile.language.english.text")
              : t("erps.userprofile.language.french.text");
          existingDataValue =
            existingDataList?.[key] === "E"
              ? t("erps.userprofile.language.english.text")
              : t("erps.userprofile.language.french.text");
        } else if (key === "statusCode") {
          updatedKeyValue = t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.status"
          );
          dataValue =
            data?.[key] === "A"
              ? t("erps.manageusers.label.status.active")
              : t("erps.manageusers.label.status.inactive");
          existingDataValue =
            existingDataList?.[key] === "A"
              ? t("erps.manageusers.label.status.active")
              : t("erps.manageusers.label.status.inactive");
        } else if (key === "phone") {
          updatedKeyValue = "Phone number";
          dataValue = getFormattedPhoneNumber(data?.[key]);
          existingDataValue = getFormattedPhoneNumber(existingDataList?.[key]);
        } else {
          updatedKeyValue = camelToPascalCase(key);
        }
        if (key === "companyInformation") {
          const existingCompanyName =
            existingDataList && existingDataList?.[key]
              ? existingDataList?.[key]?.fiCompanyName
              : "";
          const updatedCompanyName =
            data && data?.[key] ? data[key]?.fiCompanyName : "";
          const requiredUpdatedValue =
            existingCompanyName !== updatedCompanyName
              ? dataValue || (data && data[key] ? data[key]?.fiCompanyName : "")
              : "";
          if (data?.roleId == "9" || existingDataList?.roleId == "9") {
            obj.push({
              id: "subscribingEntityId",
              attribute: updatedKeyValue,
              currentValue:
                existingDataValue ||
                (existingDataList && existingDataList?.[key])
                  ? existingDataList[key]?.fiCompanyName
                  : "",
              updatedValue: requiredUpdatedValue,
            });
          }
        } else if (
          data &&
          existingDataList &&
          data[key] &&
          data[key] !== existingDataList[key]
        ) {
          obj.push({
            id: key,
            attribute:
              updatedKeyValue === "Role name"
                ? t(
                    "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.role"
                  )
                : updatedKeyValue,
            currentValue:
              existingDataValue ||
              (existingDataList && existingDataList[key]
                ? existingDataList[key]
                : ""),
            updatedValue: dataValue || (data && data[key] ? data[key] : ""),
          });
        } else {
          obj.push({
            id: key,
            attribute:
              updatedKeyValue === "Role name"
                ? t(
                    "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.role"
                  )
                : updatedKeyValue,
            currentValue:
              existingDataList && existingDataList[key]
                ? existingDataValue || existingDataList[key]
                : "",
            updatedValue: "",
          });
        }
      }
    }
    if (data?.fiDto) {
      const fiName = data?.fiDto["fi_name"];
      obj.push({
        id: "fiName",
        attribute: t(
          "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.financialInstitution"
        ),
        currentValue: fiName,
        updatedValue: "",
      });
    }
    return obj;
  };

  const navigateToUserPendingApporvalPage = () => {
    props.history.push("/user-pending-approvals", { componentAccess });
  };

  const hasLastActiveRecord = () => {
    if (userPendingData && userPendingData.length === 1) {
      return true;
    }
    return false;
  };

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();
    const { id, userId, fiNumber, transactionId } = data;
    let declineUserApproval = {
      transactionId,
      userId,
      fiNumber,
    };
    const filteredQueueDataList = userPendingData.filter(
      ({ transactionId: tid }) => transactionId != tid
    );

    const currentIndexVal = userPendingData.findIndex(
      ({ transactionId: tid }) => transactionId == tid
    );
    const slicedArr = userPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "transactionId"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.userPendingApprovalsActions.declineUserApprovalData(
        declineUserApproval,
        {
          ...props,
          setDeclineLoading,
          setShowSuccessMessage,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === "" ? userPendingData : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const handleSubmit = (event) => {
    setUpdateLoading(true);
    if (event) event.preventDefault();
    const { transactionId, userId, fiNumber, roleId } = data;
    let userUpdatedFields = getAccontSettingData();
    const updateApprovalReq = userUpdatedFields.reduce((prev, curr) => {
      if (curr.updatedValue) {
        return { ...prev, [curr.id]: curr.updatedValue };
      } else {
        return { ...prev, [curr.id]: curr.currentValue };
      }
    }, {});

    const updateApprovalRequest = {
      ...updateApprovalReq,
      transactionId,
      userId,
      fiNumber,
      roleId,
    };
    const filteredQueueDataList = userPendingData.filter(
      ({ transactionId: tid }) => transactionId != tid
    );
    const hasLastRecord = hasLastActiveRecord();
    const currentIndexVal = userPendingData.findIndex(
      ({ transactionId: tid }) => transactionId == tid
    );
    const slicedArr = userPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;

    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "transactionId"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.userPendingApprovalsActions.updateUserApprovalData(
        updateApprovalRequest,
        {
          ...props,
          setUpdateLoading,
          setShowSuccessMessage,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === "" ? userPendingData : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const [viewUpdatedAttribute, setViewUpdatedAttribute] = useState("");

  const userPendingApprovalChildAccess = componentAccess.find(
    ({ componentType }) => componentType === "UPQFV"
  );
  const userApproverComponentAccess =
    userPendingApprovalChildAccess?.childComponents;

  return (
    <>
      {userActionError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={userActionError ? 2 : 0}
        >
          {userActionError && (
            <ErrorMessage error={userActionError} errorRef={errorRef} />
          )}
        </Box>
      )}
      <Grid container>
        <Grid item lg={8} sm={12}>
          <Box className="manageUsers__page__title">
            <Typography variant="h1">
              {t("erps.manageusers.pendingUser.heading", {
                transactionId: data.transactionId,
                userId: data.userId,
              })}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t(
                "erps.manageusers.userpendingapproval.updateapproval.page.subheading"
              )}
            </Typography>
          </Box>
          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="available__documents__value">
              {t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.updateBy"
              )}
              <Typography variant="body2">{data?.displayUpdatedBy}</Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.updateOn"
              )}
              <Typography variant="body2">
                {requiredFormatDate(data.updatedFormattedDate)}
              </Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.action"
              )}
              <Typography variant="body2">{data.actionName}</Typography>
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.subheading.userprofile"
              )}
            </Typography>
            <Box>
              <p>
                {t(
                  "erps.manageusers.userpendingapproval.updateapproval.paragraph.userprofile.detail"
                )}
              </p>
            </Box>
            <ERPSBaseCheckBoxGrp
              label={t(
                "erps.manageusers.userpendingapproval.updateapproval.page.checkbox.viewUpdatedAttribute"
              )}
              value={viewUpdatedAttribute}
              onChange={(e) => setViewUpdatedAttribute(e.target.checked)}
            />
          </Box>
          <Box mt={4}>
            <Typography variant="h3">
              {t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.userprofile.subheading.accountSetting"
              )}
            </Typography>
          </Box>
          <Box mt={2}>
            <ERPSGrid
              data={{
                rows: viewUpdatedAttribute
                  ? getAccontSettingData() &&
                    getAccontSettingData().length &&
                    getAccontSettingData().filter(
                      ({ updatedValue }) => updatedValue
                    )
                  : getAccontSettingData(),
                count: getAccontSettingData().length,
                columns: USER_ACCOUNT_SETTINGS_HEADINGS,
              }}
              dataGridWidth="dataGrid__box__container__pendingApproval__updateUser"
              hideFooterPagination={true}
              getCellClassName={(params) => {
                if (params.field === "currentValue") {
                  return "column_separator_border-right";
                }
              }}
              tableLabelText={t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.userprofile.subheading.accountSetting"
              )}
            />
          </Box>
          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={() => navigateToUserPendingApporvalPage()}
              componentAccess={userApproverComponentAccess}
              actionType={"hasUserQueueCancelAccess"}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="secondary"
              onClick={(e) => handleDecline(e)}
              loading={declineLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "decline",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={userApproverComponentAccess}
              actionType={"hasUserQueueDeclineAccess"}
              isDisabled={lastRecordDisable}
            />
            <ERPSBaseButton
              functionType="NU"
              variant="primary"
              onClick={(e) => handleSubmit(e)}
              loading={updateLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "approve",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={userApproverComponentAccess}
              actionType={"hasUserQueueApprovalAccess"}
              isDisabled={loggedInUser == data?.updatedBy || lastRecordDisable}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateUserApproval;

import batchHolidayActionType from "../actions/types/batchHoliday.action.type";

export const batchHolidayInitialState = {
  holidayList: null,
  error: null,
  success: null,
};

export default function batchHolidayReducer(
  state = batchHolidayInitialState,
  action
) {
  switch (action.type) {
    case batchHolidayActionType.LIST_BATCH_HOLIDAY_SUCCESS:
      return { ...state, holidayList: action?.data, error: null };
    case batchHolidayActionType.LIST_BATCH_HOLIDAY_ERROR:
      return { ...state, holidayList: null, error: action.error };
    case batchHolidayActionType.CREATE_BATCH_HOLIDAY_SUCCESS:
      return { ...state, holidayList: null, success: action.message };
    case batchHolidayActionType.CREATE_BATCH_HOLIDAY_ERROR:
      return { ...state, error: action.error };
    case batchHolidayActionType.DELETE_BATCH_HOLIDAY_SUCCESS:
      return { ...state, holidayList: null, success: action.message };
    case batchHolidayActionType.DELETE_BATCH_HOLIDAY_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

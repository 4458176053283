import React from 'react';
import { withSidebar } from '../hoc/withSidebar';
import { useTranslation } from "react-i18next";

const AccessDenied = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h1>{t("erps.error.message.accessdenied")}</h1>
        </div>
    )
}

export default withSidebar(AccessDenied);
import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { useSelector } from "react-redux";
import { t } from "i18next";

export const getManagePricePlanGridHeadings = (roleType, componentAccess) => [
  {
    field: "billingModelNumber",
    headerName: t("erps.managePricePlan.label.pricePlanId"),
    width: 144,
    sortable: false,
    renderCell: (params) =>
      roleType === "CE" || roleType === "BA" ? (
        <ERPSBaseLink
          to={{
            pathname: `/manage-price-plan/view-price-plan-details`,
            state: {
              id: params.value,
              componentAccess,
            },
          }}
          className="link__blue"
          label={params.value.toString()}
        />
      ) : (
        <ERPSBaseLink
          to={{
            pathname: `/manage-price-plan/price-plan-details`,
            state: { id: params.value, componentAccess },
          }}
          className="link__blue"
          label={params.value.toString()}
          actionType="hasGridColumnFullView"
          componentAccess={componentAccess}
        />
      ),
  },
  {
    field: "billingModelDescription",
    headerName: t("erps.managePricePlan.label.pricePlanName"),
    width: 200,
    sortable: false,
  },
];

export const EMPTY_PRICE_PLAN_CRITERIA = {
  pricePlanId: "",
  pricePlanName: "",
  startOffset: 0,
  endOffset: 25,
};

export const ERROR_NEW_PRICE_PLAN = {
  pricePlanNameBlankError: t(
    "erps.managePricePlan.error.pricePlanNameBlankError"
  ),
  pricePlanNameLengthAndAlphaNumericError: t(
    "erps.managePricePlan.error.pricePlanNameLengthAndAlphaNumericError"
  ),
};

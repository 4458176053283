import services from "../services";

export const marketManagementAction = {
  getDashboardMessage,
  createdDashboardMessage,
  dashboardPendingApprovalMessage,
  dashboardPendingDeclineMessage,
  getDashboardQueueMessage,
};

function getDashboardMessage(data) {
  return services.marketManagementSevice.getDashboardMessage(data);
}

function getDashboardQueueMessage(data) {
  return services.marketManagementSevice.getDashboardQueueMessage(data);
}

function createdDashboardMessage(data) {
  return services.marketManagementSevice.createdDashboardMessage(data);
}

function dashboardPendingApprovalMessage(data) {
  return services.marketManagementSevice.dashboardPendingApprovalMessage(data);
}

function dashboardPendingDeclineMessage(data) {
  return services.marketManagementSevice.dashboardPendingDeclineMessage(data);
}

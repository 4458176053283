import userActionTypes from "../actions/types/user.action.type";

export const newUserInitialState = {
  error: null,
  userRoleTypeList: null,
  subscribingEntitiesList: null,
  newUserAdded: null,
};

export default function newUserReducer(state = newUserInitialState, action) {
  switch (action.type) {
    case userActionTypes.NEW_USER_SUCCESS:
      return {
        newUserAdded: action.data,
        error: null,
        subscribingEntitiesList: action.subscribingEntitiesList,
        userRoleTypeList: action.userRoleTypeList,
      };
    case userActionTypes.FAIL:
      return {
        error: action.error,
        newUserAdded: null,
        subscribingEntitiesList: action.subscribingEntitiesList,
        userRoleTypeList: action.userRoleTypeList,
      };
    case userActionTypes.NEW_USER_REQUEST:
      return {
        error: null,
        newUserAdded: null,
        subscribingEntitiesList: action.subscribingEntitiesList,
        userRoleTypeList: action.userRoleTypeList,
      };
    case userActionTypes.RESET_ERROR:
      return { ...state, error: null, newUserAdded: null };
    default:
      return state;
  }
}

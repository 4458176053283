export const data = [
  {
    name: "erps.navbar.title.home",
    label: "erps.navbar.title.home",
    items: [
      {
        name: "erps.navbar.home.subtitle.dashboard",
        label: "erps.navbar.home.subtitle.dashboard",
        url: "/dashboard",
        function_type_code: "DS",
      },
    ],
    label_type_code: "HO",
  },
  {
    name: "erps.navbar.title.billermanagement",
    label: "erps.navbar.title.billermanagement",
    items: [
      {
        name: "erps.navbar.billermanagement.subtitle.managebiller",
        label: "erps.navbar.billermanagement.subtitle.managebiller",
        url: "/manage-billers",
        function_type_code: "MB",
      },
      {
        name: "erps.navbar.billermanagement.subtitle.billerpendingapproval",
        label: "erps.navbar.billermanagement.subtitle.billerpendingapproval",
        url: "/biller-pending-approvals",
        function_type_code: "BPA",
      },
    ],
    label_type_code: "BM",
  },
  {
    name: "erps.navbar.title.billersettingutilities",
    label: "erps.navbar.title.billersettingutilities",
    items: [
      {
        name: "erps.navbar.billersettingutilities.subtitle.managebudgetcategory",
        label:
          "erps.navbar.billersettingutilities.subtitle.managebudgetcategory",
        url: "/manage-budget-category",
        function_type_code: "MBC",
      },
      {
        name: "erps.navbar.billersettingutilities.subtitle.synchronisation",
        label: "erps.navbar.billersettingutilities.subtitle.synchronisation",
        url: "/synchronization",
        function_type_code: "SZ",
      },
      {
        name: "erps.navbar.billersettingutilities.subtitle.managepriceplan",
        label: "erps.navbar.billersettingutilities.subtitle.managepriceplan",
        url: "/manage-price-plan",
        function_type_code: "MPP",
      },
      {
        name: "erps.navbar.billersettingutilities.subtitle.priceplanpendingapprovals",
        label:
          "erps.navbar.billersettingutilities.subtitle.priceplanpendingapprovals",
        url: "/price-plan-pending-approvals",
        function_type_code: "PPPA",
      },
    ],
    label_type_code: "BSAU",
  },
  {
    name: "erps.navbar.title.usermanagement",
    label: "erps.navbar.title.usermanagement",
    items: [
      {
        name: "erps.navbar.usermanagement.subtitle.manageusers",
        label: "erps.navbar.usermanagement.subtitle.manageusers",
        url: "/manage-users",
        function_type_code: "MU",
      },
      {
        name: "erps.navbar.usermanagement.subtitle.userpendingapprovals",
        label: "erps.navbar.usermanagement.subtitle.userpendingapprovals",
        url: "/user-pending-approvals",
        function_type_code: "UPA",
      },
    ],
    label_type_code: "UM",
  },
  {
    name: "erps.navbar.title.fimanagement",
    label: "erps.navbar.title.fimanagement",
    items: [
      {
        name: "erps.navbar.fimanagement.subtitle.viewfimanagement",
        label: "erps.navbar.fimanagement.subtitle.viewfimanagement",
        url: "/view-financial-institution",
        function_type_code: "VFI",
      },
    ],
    label_type_code: "FM",
  },
  {
    name: "erps.navbar.title.transactionenquiry",
    label: "erps.navbar.title.transactionenquiry",
    items: [
      {
        name: "erps.navbar.transactionenquiry.subtitle.reviewtransactions",
        label: "erps.navbar.transactionenquiry.subtitle.reviewtransactions",
        url: "/review-transactions",
        function_type_code: "RT",
      },
    ],
    label_type_code: "TE",
  },
  {
    name: "erps.navbar.title.statisticsenquiry",
    label: "erps.navbar.title.statisticsenquiry",
    items: [
      {
        name: "erps.navbar.statisticsenquiry.subtitle.generatereport",
        label: "erps.navbar.statisticsenquiry.subtitle.generatereport",
        url: "/statistics-enquiry",
        function_type_code: "GR",
      },
    ],
    label_type_code: "SE",
  },
  {
    name: "erps.navbar.title.reports",
    label: "erps.navbar.title.reports",
    items: [
      {
        name: "erps.navbar.reports.subtitle.downloadreports",
        label: "erps.navbar.reports.subtitle.downloadreports",
        url: "/download-reports",
        function_type_code: "DR",
      },
    ],
    label_type_code: "RE",
  },
  {
    name: "erps.navbar.title.availabledocuments",
    label: "erps.navbar.title.availabledocuments",
    items: [
      {
        name: "erps.navbar.availabledocuments.subtitle.downloaddocuments",
        label: "erps.navbar.availabledocuments.subtitle.downloaddocuments",
        url: "/downloads-documents",
        function_type_code: "DD",
      },
    ],
    label_type_code: "AD",
  },
  {
    name: "erps.navbar.title.marketingmanagement",
    label: "erps.navbar.title.marketingmanagement",
    items: [
      {
        name: "erps.navbar.marketingmanagement.subtitle.managemessagecentre",
        label: "erps.navbar.marketingmanagement.subtitle.managemessagecentre",
        url: "/manage-message-centre",
        function_type_code: "MMC",
      },
      {
        name: "erps.navbar.marketingmanagement.subtitle.messagecentrependingapprovals",
        label:
          "erps.navbar.marketingmanagement.subtitle.messagecentrependingapprovals",
        url: "/message-centre-pending-approvals",
        function_type_code: "MCPA",
      },
      {
        name: "erps.navbar.marketingmanagement.subtitle.manageloginpage",
        label: "erps.navbar.marketingmanagement.subtitle.manageloginpage",
        url: "/manage-login-page",
        function_type_code: "MLP",
      },
      {
        name: "erps.navbar.marketingmanagement.subtitle.loginpagependingapprovals",
        label:
          "erps.navbar.marketingmanagement.subtitle.loginpagependingapprovals",
        url: "/login-page-pending-approvals",
        function_type_code: "LPPA",
      },
    ],
    label_type_code: "MM",
  },
  {
    name: "erps.navbar.title.environmentsetup",
    label: "erps.navbar.title.environmentsetup",
    items: [
      {
        name: "erps.navbar.environmentsetup.subtitle.batchholidaydates",
        label: "erps.navbar.environmentsetup.subtitle.batchholidaydates",
        url: "/batch-holiday-dates",
        function_type_code: "BHD",
      },
    ],
    label_type_code: "ESU",
  },
  {
    name: "erps.navbar.title.fax",
    label: "erps.navbar.title.fax",
    items: [
      {
        name: "erps.navbar.home.subtitle.fax.failedFaxes",
        label: "erps.navbar.home.subtitle.fax.failedFaxes",
        url: "/failed-faxes",
        function_type_code: "FF",
      },
      {
        name: "erps.navbar.home.subtitle.fax.archived/sentFaxes",
        label: "erps.navbar.home.subtitle.fax.archived/sentFaxes",
        url: "/archived-sent-faxes",
        function_type_code: "ASF",
      },
    ],
    label_type_code: "FAXM",
  },
  {
    name: "erps.navbar.title.myprofile",
    label: "erps.navbar.title.myprofile",
    items: [
      {
        name: "erps.navbar.myprofile.subtitle.accountsettings",
        label: "erps.navbar.myprofile.subtitle.accountsettings",
        url: "/account-settings",
        function_type_code: "AS",
      },
      {
        name: "erps.navbar.myprofile.subtitle.updatepassword",
        label: "erps.navbar.myprofile.subtitle.updatepassword",
        url: "/update-password",
        function_type_code: "UP",
      },
    ],
    label_type_code: "MP",
  },
];

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import moment from "moment";
import ERPSGrid from "../custom/containers/ERPSGrid";
import actions from "../../actions";
import { withSidebar } from "../hoc/withSidebar";
import ErrorMessage from "../custom/containers/ErrorMessage";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import CardList from "../custom/containers/cardList";

import { getFailedFaxHeading } from "../../constants/FaxConstant";

const FailedFax = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    history,
    location: { pathname, state },
  } = props;
  const errorRef = useRef();

  const [errorMessage, setErrorMessage] = useState(null);
  const [failedFaxData, setFailedFaxData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [initialLoading, setInitialLoading] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [hasCellError, setCellError] = useState([]);

  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer.selectedFi);
  const fiNumber = selectedFiNumber || loginUser?.fiNumber;
  const successRef = useRef(null);

  const [searchCriteria, setSearchCriteria] = useState({
    startDate: new Date(),
    endDate: new Date(),
    endOffset: 100,
    startOffset: 0,
  });

  const [buttonLoading, setButtonLoading] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [resendAllLoading, setButtonResendAll] = useState(false);
  const [resendAllDisabled, setResendAllDisabled] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const [hasValidFilter, setHasValidFilter] = useState(false);

  const [highlightError, setHighlightError] = useState({});
  const [pageNumber, setPageSize] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [maxDate, setMaxDate] = useState();

  const faxResponse = useSelector((state) => state.faxManagementReducer);
  const successMessage = faxResponse?.resendFax;

  const roleId = loginUser?.role?.role_id;

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [
    faxResponse?.failedFaxError,
    faxResponse?.resendFaxError,
    errorMessage?.length,
  ]);
  const fetchFailedFax = (hasRefreshGrid) => {
    if (!initialLoading) {
      setInitialLoading(true);
    }
    let searchBillerFilesDto;
    if (hasRefreshGrid || state?.fromViewFax) {
      const updatedSearchedValues = state?.fromViewFax
        ? state?.searchedValues
        : searchCriteria;
      if (state?.fromViewFax) {
        setSearchCriteria(updatedSearchedValues);
      }
      const { startDate, endDate, startOffset, endOffset } =
        updatedSearchedValues;
      const formatStartDate = startDate
        ? moment(startDate)?.format("YYYYMMDD")
        : "";
      const formatEndDate = endDate ? moment(endDate)?.format("YYYYMMDD") : "";
      searchBillerFilesDto = {
        offset: startOffset,
        limit: endOffset,
        billerId: null,
        billerName: null,
        auditNumber: null,
        faxDatetime: null,
        fiNumber: null,
        status: "F",
        fileName: null,
        fileLocation: null,
        faxNumber: null,
        count: 0,
        startDate: formatStartDate,
        endDate: formatEndDate,
      };
    } else {
      searchBillerFilesDto = {
        offset: 0,
        limit: 100,
        billerId: null,
        billerName: null,
        auditNumber: null,
        faxDatetime: null,
        fiNumber: null,
        status: "F",
        fileName: null,
        fileLocation: null,
        faxNumber: null,
        count: 0,
      };
    }

    dispatch(
      actions.faxManagementActions.getFailedFax(
        { ...searchBillerFilesDto },
        {
          ...props,
          t,
          history,
          setErrorMessage,
          setLoader,
          setInitialLoading,
          setRefreshGrid,
          refreshGrid: hasRefreshGrid,
        }
      )
    );
  };

  useEffect(() => {
    fetchFailedFax(false);
  }, []);

  useEffect(() => {
    if (refreshGrid) {
      fetchFailedFax(true);
    }
  }, [refreshGrid]);

  useEffect(() => {
    if (faxResponse?.failedFaxList?.data?.length) {
      const updatedData = faxResponse?.failedFaxList?.data?.map(
        (faxData, idx) => ({
          ...faxData,
          id: idx,
        })
      );
      setFailedFaxData(updatedData);
      if (resendAllDisabled) {
        setResendAllDisabled(false);
      }
    } else {
      setResendAllDisabled(true);
      setFailedFaxData(null);
    }
  }, [faxResponse]);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  // const isWeekend = (date) => {
  //   const day = date.day();

  //   return day === 0 || day === 6;
  // };
  const getMinDateValue = () => {
    const date = new Date();
    const minDateValue = new Date(
      date.getFullYear(),
      date.getMonth() - 13,
      date.getDate()
    );
    return { minDateValue, maxDateValue: date };
  };
  const handleChange = (key) => (e) => {
    if (key === "startDate" && e && e !== "Invalid date") {
      const date = new Date(e);
      const maxDateVal = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 6
      );
      setMaxDate(maxDateVal);
      setSearchCriteria((prevState) => ({
        ...prevState,
        [key]: e,
        endDate: maxDateVal,
      }));
    } else {
      setSearchCriteria((prevState) => ({
        ...prevState,
        [key]: e,
      }));
    }
  };
  const getDatesValidated = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    const { minDateValue, maxDateValue } = getMinDateValue();
    const errors = [];
    let highlightErrorV1 = {};

    // const isWeekend = (date) => {
    //   const day = date.getDay();
    //   return day === 0 || day === 6; // Sunday or Saturday
    // };

    const calculateWorkingDaysDifference = (startDate, endDate) => {
      let workingDays = 0;
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        workingDays++;
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return workingDays;
    };

    if (startDate && date1.toString() === "Invalid Date") {
      errors.push(t("erps.failedfax.filter.errorMessage.invalid.startDate"));
      highlightErrorV1.startDate = true;
    }

    if (endDate && date2.toString() === "Invalid Date") {
      errors.push(t("erps.failedfax.filter.errorMessage.invalid.endDate"));
      highlightErrorV1.endDate = true;
    }

    if (startDate || endDate) {
      if (startDate && !endDate) {
        errors.push(t("erps.failedfax.filter.errorMessage.required.endDate"));
        highlightErrorV1.endDate = true;
      } else if (endDate && !startDate) {
        errors.push(t("erps.failedfax.filter.errorMessage.required.startDate"));
        highlightErrorV1.startDate = true;
      }

      if (startDate && endDate && date1 > date2) {
        errors.push(t("erps.failedfax.filter.errorMessage.fromDate"));
        highlightErrorV1.startDate = true;
        highlightErrorV1.endDate = true;
      } else if (startDate && endDate) {
        const workingDaysDifference = calculateWorkingDaysDifference(
          date1,
          date2
        );

        if (workingDaysDifference > 7) {
          errors.push(
            t(
              "erps.faxManagement.page.errorMessage.datesdifferenceNotMoreThan7Days"
            )
          );
          highlightErrorV1.startDate = true;
          highlightErrorV1.endDate = true;
        }
      }

      // if (isWeekend(date1)) {
      //   errors.push(t("erps.common.datepicker.weekendStartDate"));
      //   highlightErrorV1.startDate = true;
      // }

      // if (isWeekend(date2)) {
      //   errors.push(t("erps.common.datepicker.weekendEndDate"));
      //   highlightErrorV1.endDate = true;
      // }

      if (date1 < minDateValue || date1 > maxDateValue) {
        errors.push(t("erps.common.datepicker.startDatenotbeforemaximaldate"));
        highlightErrorV1.startDate = true;
      }

      if (date2 < minDateValue || date2 > maxDateValue) {
        errors.push(t("erps.common.datepicker.endDatenotbeforemaximaldate"));
        highlightErrorV1.endDate = true;
      }
    } else {
      errors.push(t("erps.failedfax.filter.errorMessage.invalid.toDate"));
    }

    return { errors, highlightErrorV1 };
  };

  const startSearchHandler = () => {
    const { startDate, endDate, startOffset, endOffset } = searchCriteria;
    setSearchLoading(true);
    const { errors, highlightErrorV1 } = getDatesValidated(startDate, endDate);
    if (errors?.length) {
      setErrorMessage(errors);
      setHighlightError(highlightErrorV1);
      setSearchLoading(false);
    } else {
      setErrorMessage(null);
      setHighlightError({});
      setCellError([]);
      const formatStartDate = startDate
        ? moment(startDate)?.format("YYYYMMDD")
        : "";
      const formatEndDate = endDate ? moment(endDate)?.format("YYYYMMDD") : "";

      if (setPageSize) {
        setPageSize(0);
      }
      if (setRowPerPage) {
        setRowPerPage(100);
      }
      const searchBillerFilesDto = {
        offset: startOffset,
        limit: endOffset,
        billerId: null,
        billerName: null,
        auditNumber: null,
        faxDatetime: null,
        fiNumber: null,
        status: "F",
        fileName: null,
        fileLocation: null,
        faxNumber: null,
        count: 0,
        startDate: formatStartDate,
        endDate: formatEndDate,
      };

      dispatch(
        actions.faxManagementActions.getFailedFax(searchBillerFilesDto, {
          ...props,
          t,
          history,
          setErrorMessage,
          setLoader: setSearchLoading,
          setHasValidFilter,
        })
      );
    }
  };

  const handlePagination = (actualCriteria) => {
    if (actualCriteria.startOffset < actualCriteria.count) {
      const { endOffset, startOffset } = actualCriteria;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      const rowPerPage = endOffset - startOffset;
      const { startDate, endDate } = searchCriteria;

      const formatStartDate = startDate
        ? moment(startDate)?.format("YYYYMMDD")
        : "";
      const formatEndDate = endDate ? moment(endDate)?.format("YYYYMMDD") : "";

      if (setPageSize) {
        setPageSize(pageNumber);
      }
      if (setRowPerPage) {
        setRowPerPage(rowPerPage);
      }
      if (!initialLoading) {
        setInitialLoading(true);
      }
      const searchBillerFilesDto = {
        offset: startOffset,
        limit: endOffset,
        billerId: null,
        billerName: null,
        auditNumber: null,
        faxDatetime: null,
        fiNumber: null,
        status: "F",
        fileName: null,
        fileLocation: null,
        faxNumber: null,
        count: 0,
        startDate: formatStartDate,
        endDate: formatEndDate,
      };
      dispatch(
        actions.faxManagementActions.getFailedFax(searchBillerFilesDto, {
          ...props,
          t,
          history,
          setErrorMessage,
          setLoader: setSearchLoading,
          setInitialLoading,
        })
      );
    }
  };

  const resetHandler = () => {
    setResetLoading(true);
    setErrorMessage(null);
    setHighlightError({});
    setHasValidFilter(false);
    setCellError([]);
    setSearchCriteria({
      startDate: new Date(),
      endDate: new Date(),
      endOffset: 100,
      startOffset: 0,
    });
    if (!initialLoading) {
      setInitialLoading(true);
    }
    setMaxDate();
    dispatch(
      actions.faxManagementActions.getFailedFax(
        {
          offset: 0,
          limit: 100,
          billerId: null,
          billerName: null,
          auditNumber: null,
          faxDatetime: null,
          fiNumber: null,
          status: "F",
          fileName: null,
          fileLocation: null,
          faxNumber: null,
          count: 0,
        },
        {
          ...props,
          t,
          history,
          setErrorMessage,
          setLoader: setResetLoading,
          setInitialLoading,
        }
      )
    );
  };

  const checkValidFaxIdValue = (faxId) => {
    const numericRegEx = /^1\d{10}$/;

    if (faxId !== undefined) {
      const data = failedFaxData?.find(
        ({ faxNumber, id }) =>
          faxId === id && !numericRegEx.test(faxNumber.toString())
      );
      const cellErrorVal = {
        hasError: true,
        value: data?.faxNumber?.toString(),
        id: data?.id,
      };
      setCellError(cellErrorVal);
      return failedFaxData?.some(
        (row) =>
          faxId === row?.id &&
          (row.faxNumber?.toString().length !== 11 ||
            row.faxNumber < 0 ||
            !numericRegEx.test(row.faxNumber.toString()))
      );
    } else {
      let cellErrorVal = [];
      failedFaxData?.forEach(({ faxNumber }) => {
        if (faxNumber <= 0 && !numericRegEx.test(faxNumber.toString())) {
          cellErrorVal.push({
            hasError: true,
            value: faxNumber?.toString(),
          });
        } else {
          cellErrorVal.push({
            hasError: false,
            value: faxNumber?.toString(),
          });
        }
        setCellError(cellErrorVal);
      });
      return failedFaxData?.some(
        (row) =>
          row.faxNumber?.toString().length !== 11 ||
          row.faxNumber < 0 ||
          !numericRegEx.test(row.faxNumber.toString())
      );
    }
  };

  const handleGetCellClassName = (params) => {
    if (params.field === "faxNumber") {
      console.log("params", params);
      const checkError = hasCellError?.id == params.id ? hasCellError : {};
      if (checkError?.hasError) {
        return "billPaymentLimit_error_highlight";
      }
    }
  };

  const handleCellEditCommit = ({ id, field, value }) => {
    if (field === "faxNumber") {
      const updatedRows = failedFaxData.map((row) => {
        if (row.id === id) {
          return { ...row, faxNumber: value };
        }
        return row;
      });
      setFailedFaxData(updatedRows);
    }
  };
  const handleSelectionModelChange = (id, value) => {
    const updatedRows = failedFaxData.map((r) => {
      if (id == r.id) {
        return { ...r, faxNumber: value };
      }
      return r;
    });

    setFailedFaxData(updatedRows);
  };

  const handleResend = (resendType, params) => {
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (errorMessage && errorMessage?.length) {
      setErrorMessage(null);
    }
    if (highlightError && Object.keys(highlightError)?.length) {
      setHighlightError({});
    }

    if (resendType === "all") {
      setButtonResendAll(true);
      const faxIdLists = failedFaxData
        ? failedFaxData.map(({ faxId }) => faxId)?.join(",")
        : null;
      const reqBody = {
        fiNumber,
        billerStatus: "F",
        faxIds: faxIdLists,
        faxNumber: null,
      };
      if (checkValidFaxIdValue()) {
        setButtonResendAll(false);
        setErrorMessage([
          t(
            "erps.faxManagement.page.errorMessage.faxNumber.10numericcharacters"
          ),
        ]);
      } else {
        dispatch(
          actions.faxManagementActions.resendFax(reqBody, {
            setLoading: setButtonResendAll,
            setErrorMessage,
            t,
            setShowSuccessMessage,

            setRefreshGrid,
          })
        );
      }
    } else {
      const faxData = params?.row;
      const reqBody = {
        fiNumber: faxData?.fiNumber,
        billerStatus: "F",
        faxIds: faxData?.faxId,
        faxNumber: faxData?.faxNumber,
      };
      if (checkValidFaxIdValue(params?.id)) {
        setErrorMessage([
          t(
            "erps.faxManagement.page.errorMessage.faxNumber.10numericcharacters"
          ),
        ]);
      } else {
        const arr = buttonLoading?.slice();
        const idx = params?.id;
        const updatedLoadingValue = Array(idx).fill(false);
        updatedLoadingValue.splice(idx, 1, true);
        setButtonLoading(updatedLoadingValue);

        dispatch(
          actions.faxManagementActions.resendFax(reqBody, {
            setLoading: setButtonLoading,
            setErrorMessage,
            index: idx,
            loadingArr: arr,
            t,
            setShowSuccessMessage,

            setRefreshGrid,
          })
        );
      }
    }
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        {errorMessage ? (
          <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
            {errorMessage ? (
              <ErrorMessage error={errorMessage} errorRef={errorRef} />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        <Box className="manageUsers__page__title">
          <Typography variant="h1">{t("erps.failedfax.page.title")}</Typography>
        </Box>
        <Box mt={-3} display="flex">
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            label={t("erps.failedfax.page.button.resendall")}
            onClick={() => handleResend("all")}
            loading={resendAllLoading}
            isDisabled={resendAllDisabled}
          />
        </Box>

        <Box mt={4} mb={2}>
          <Typography variant="h2">
            {t("erps.failedfax.page.label.filterfailedfaxes")}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseDatePicker
            label={t("erps.availabledocuments.page.startdate")}
            value={searchCriteria?.startDate}
            onChange={handleChange("startDate")}
            maxDate={getMinDateValue()?.maxDateValue}
            minDate={getMinDateValue()?.minDateValue}
            error={highlightError?.startDate}
            hasRequired={false}
            // shouldDisableDate={isWeekend}
            hasMinMaxNotCheck={true}
            id={"fax-start-date"}
          />

          <ERPSBaseDatePicker
            label={t("erps.availabledocuments.page.enddate")}
            value={searchCriteria?.endDate}
            onChange={handleChange("endDate")}
            maxDate={getMinDateValue()?.maxDateValue}
            minDate={getMinDateValue()?.minDateValue}
            error={highlightError?.endDate}
            hasRequired={false}
            // shouldDisableDate={isWeekend}
            hasMinMaxNotCheck={true}
            id={"fax-end-date"}
          />
        </Stack>
        <Box mt={-3} display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.availabledocuments.button.reset")}
            onClick={resetHandler}
            loading={resetLoading}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.downloadreports.button.filter")}
              onClick={startSearchHandler}
              loading={searchLoading}
            />
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h2">
            {hasValidFilter
              ? t("erps.failedfax.page.label.search.filteredfailedfaxes")
              : t("erps.failedfax.page.label.default.allfailedfaxes")}
          </Typography>
        </Box>
        {!loader && (
          <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
            <Typography variant="body1">
              {failedFaxData ? faxResponse?.failedFaxList?.count : 0}{" "}
              {t("erps.failedfax.page.records")}
            </Typography>
          </Box>
        )}
        {failedFaxData ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                onAnyPaginationAction={handlePagination}
                data={{
                  rows: failedFaxData ? failedFaxData : [],
                  count: failedFaxData ? faxResponse?.failedFaxList?.count : 0,

                  columns: getFailedFaxHeading(
                    {
                      hasArchivedSentPage: false,
                      history,
                      pathname,
                      searchValues: searchCriteria,
                      hasViewOnlyPage: roleId == 10,
                    },
                    { buttonLoading, handleResend }
                  ),
                }}
                dataGridWidth="dataGrid__box__container__failedfax"
                hideFooterPagination={false}
                getCellClassName={handleGetCellClassName}
                handleCellEditCommit={handleCellEditCommit}
                handleSelectionModelChange={handleSelectionModelChange}
                initialLoading={initialLoading}
                tableLabelText={t("erps.failedfax.page.title")}
                pageNumber={pageNumber}
                rowPerPage={rowPerPage}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: failedFaxData ? failedFaxData : [],
                  count: failedFaxData ? faxResponse?.failedFaxList?.count : 0,

                  columns: getFailedFaxHeading(
                    {
                      hasArchivedSentPage: false,
                      history,
                      pathname,
                      hasViewOnlyPage: roleId == 10,
                    },
                    { buttonLoading, handleResend }
                  ),
                }}
                onAnyPaginationAction={handlePagination}
                pageNumber={pageNumber}
                rowPerPage={rowPerPage}
                pageName={"fax-management"}
              />
            </Box>
          </>
        ) : (
          ""
        )}
        {!failedFaxData && loader ? (
          <Box ml={40} mt={10} sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default withSidebar(FailedFax);

import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { requiredFormatDate } from "../utils/helper";

export const CLASSIC_ROLES_ID = [6, 7, 8, 9];

export const getBillerManagementGridHeadings = (componentAccess, roleId) => {
  return [
    {
      field: "fiBillerWithBillerId",
      headerName: t("erps.billermanagement.label.billerId"),
      width: 157,
      sortable: false,
      renderCell: (params) => {
        let billerId = CLASSIC_ROLES_ID.includes(roleId)
          ? params?.row?.billerId
          : params.value.toString();
        billerId = billerId?.includes("null")
          ? billerId.replaceAll("null-", "")
          : billerId.replaceAll("undefined-", "");
        let displayBillerId = CLASSIC_ROLES_ID.includes(roleId)
          ? params?.row?.billerFiNumber
          : billerId;
        return (
          <ERPSBaseLink
            to={{
              pathname: `/manage-billers/biller-profile`,
              state: { billerId, componentAccess, displayBillerId },
            }}
            className="link__blue"
            label={displayBillerId}
            actionType="hasBillerProfileAccess"
            componentAccess={componentAccess}
          />
        );
      },
    },
    {
      field: "billerName",
      headerName: t("erps.billermanagement.label.billerName"),
      width: 318,
      sortable: false,
    },
    {
      field: "billerStatus",
      headerName: t("erps.billermanagement.label.billerstatus"),
      width: 160,
      sortable: false,
    },
    {
      field: "department",
      headerName: t("erps.billermanagement.label.department"),
      width: 210,
      sortable: false,
    },
  ];
};

export const EMPTY_BILLER_CRITERIA = {
  billerId: "",
  billerName: "",
  startOffset: 0,
  endOffset: 25,
};

export const getBillerPendingGridHeadings = (userId, componentAccess) => {
  const BILLER_PENDING_APPROVALS_HEADERS = [
    {
      field: "id",
      headerName: t("erps.billermanagement.label.transactionid"),
      width: 130,
      renderCell: (params) => (
        <ERPSBaseLink
          to={{
            pathname: `/biller-pending-approvals/approver-biller`,
            state: { transactionId: params.value, componentAccess },
          }}
          className="link__blue"
          label={params.value.toString()}
          actionType={"hasBillerPendingApprovalListAccess"}
          componentAccess={componentAccess}
        />
      ),
      sortable: false,
    },
    {
      field: "billerId",
      headerName: t("erps.billermanagement.label.billerId"),
      width: 140,
      sortable: false,
    },
    {
      field: "updatedBy",
      headerName: t("erps.updatebillerapproval.label.updatedby"),
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.displayUpdatedBy;
      },
    },
    {
      field: "updatedFormattedDate",
      headerName: t("erps.updatebillerapproval.label.updatedon"),
      width: 180,
      sortable: false,
      renderCell: (params) => {
        return requiredFormatDate(params.value);
      },
    },
    {
      field: "actionName",
      headerName: t("erps.billermanagement.label.actions"),
      width: 100,
      sortable: false,
    },
  ];

  return BILLER_PENDING_APPROVALS_HEADERS;
};

export const BILLER_PROFILE_DETAILS = [
  {
    field: "attribute",
    headerName: t("erps.billermanagement.label.attribute"),
    width: 220,
    sortable: false,
  },
  {
    field: "currentValue",
    headerName: t("erps.billermanagement.label.currentvalue"),
    width: 223,
    sortable: false,
    headerClassName: "column_separator_border-right",
  },
  {
    field: "updatedValue",
    headerName: t("erps.billermanagement.label.updatedvalue"),
    width: 223,
    sortable: false,
  },
];

export const BILLER_IMAGE_URL = [
  {
    field: "currentValue",
    headerName: t("erps.billermanagement.label.currentvalue"),
    width: 333,
    sortable: false,
    headerClassName: "column_separator_border-right-only",
  },
  {
    field: "updatedValue",
    headerName: t("erps.billermanagement.label.updatedvalue"),
    width: 333,
    sortable: false,
  },
];

export const NEW_BILLER_PROFILE_DETAILS = [
  {
    field: "attribute",
    headerName: t("erps.billermanagement.label.attribute"),
    width: 180,
    sortable: false,
  },
  {
    field: "value",
    headerName: t("erps.billermanagement.label.value"),
    width: 180,
    sortable: false,
  },
];

export const getRelatedBillerDetails = (
  handleRowClick,
  actionName,
  componentAccess,
  actionType,
  roleId
) => {
  return roleId != "10"
    ? [
        {
          field: "fiBillerWithBillerId",
          headerName: t("erps.billermanagement.label.billerId"),
          width: 180,
          sortable: false,
        },
        {
          field: "billerName",
          headerName: t("erps.billermanagement.label.billerName"),
          width: 480,
          sortable: false,
        },
        {
          field: "action",
          headerName: t("erps.updatebillerapproval.label.action"),
          sortable: false,
          width: 180,
          renderCell: (params) => (
            <ERPSBaseLink
              className="link__blue"
              label={actionName}
              onClick={(e) => handleRowClick(params, actionName)}
              target={""}
              actionType={actionType}
              componentAccess={componentAccess}
            />
          ),
        },
      ]
    : [
        {
          field: "fiBillerWithBillerId",
          headerName: t("erps.billermanagement.label.billerId"),
          width: 180,
          sortable: false,
        },
        {
          field: "billerName",
          headerName: t("erps.billermanagement.label.billerName"),
          width: 368,
          sortable: false,
        },
      ];
};

export const removeRelatedBillerDetails = (
  handleRowClick,
  actionName,
  componentAccess,
  actionType,
  roleId
) => {
  return roleId != "10"
    ? [
        {
          field: "billerIdStr",
          headerName: t("erps.billermanagement.label.billerId"),
          width: 180,
          sortable: false,
        },
        {
          field: "billerName",
          headerName: t("erps.billermanagement.label.billerName"),
          width: 480,
          sortable: false,
        },
        {
          field: "action",
          headerName: t("erps.updatebillerapproval.label.action"),
          sortable: false,
          width: 180,
          renderCell: (params) => (
            <ERPSBaseLink
              className="link__blue"
              label={actionName}
              onClick={(e) => handleRowClick(params, actionName)}
              target={""}
              actionType={actionType}
              componentAccess={componentAccess}
            />
          ),
        },
      ]
    : [
        {
          field: "billerIdStr",
          headerName: t("erps.billermanagement.label.billerId"),
          width: 180,
          sortable: false,
        },
        {
          field: "billerName",
          headerName: t("erps.billermanagement.label.billerName"),
          width: 368,
          sortable: false,
        },
      ];
};

export const mockChildRelatedBillerDetails = [
  {
    id: 0,
    billerId: "22135651-60940",
    billerName: "CARADOC DELAWARE MUTUAL FIRE INSURACE CO",
    action: "Remove relationship",
  },
  {
    id: 1,
    billerId: "22163752-64509",
    billerName: "CARADOC TOWNSENT MUTUAL INSURANCE COMPANY",
    action: "Remove relationship",
  },
];

export const mockActiveRelatedBillerDetails = [
  {
    id: 0,
    billerId: "2206407-56626",
    billerName: "CARACO STORAGE SOLUTIONS",
    action: "Add relationship",
  },
  {
    id: 1,
    billerId: "22135651-22222",
    billerName: "CARADOC TOWNSENT MUTUAL INSURANCE COMPANY",
    action: "Add relationship",
  },
  {
    id: 2,
    billerId: "22163752-61111",
    billerName: "CARADOC ABCD MUTUAL FIRE INSURACE CO",
    action: "Add relationship",
  },
];

const pricePlanPendingApprovalActionTypes = {
    GET_PENDING_PRICE_PLAN_APPROVAL_SUCCESS: "GET_PENDING_PRICE_PLAN_APPROVAL_SUCCESS",
    GET_PENDING_PRICE_PLAN_APPROVAL_FAILURE: "GET_PENDING_PRICE_PLAN_APPROVAL_FAILURE",
    UPDATE_PRICE_PLAN_APPROVAL_SUCCESS: "UPDATE_PRICE_PLAN_APPROVAL_SUCCESS",
    UPDATE_PRICE_PLAN_APPROVAL_FAILURE: "UPDATE_PRICE_PLAN_APPROVAL_FAILURE",
    CREATE_PRICE_PLAN_APPROVAL_SUCCESS: "CREATE_PRICE_PLAN_APPROVAL_SUCCESS",
    CREATE_PRICE_PLAN_APPROVAL_FAILURE: "CREATE_PRICE_PLAN_APPROVAL_FAILURE",
    DECLINE_PRICE_PLAN_APPROVAL_SUCCESS: "DECLINE_PRICE_PLAN_APPROVAL_SUCCESS",
    DECLINE_PRICE_PLAN_APPROVAL_FAILURE: "DECLINE_PRICE_PLAN_APPROVAL_FAILURE"

  };
  
  export default pricePlanPendingApprovalActionTypes;
  
import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import NewAndDeletePricePlanApproval from "./NewAndDeletePricePlanApproval";
import UpdatePricePlanApproval from "./UpdatePricePlanApproval";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";

const ApprovePricePlan = (props) => {
  const { t } = useTranslation();
  const errorRef = useRef();

  const {
    location: { state },
  } = props;
  const userId = useSelector((state) => state.loginUserReducer?.user?.userId);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [pricePlanIdValue, setPricePlanIdValue] = useState(state.id);
  const [queueData, setQueueData] = useState([]);
  const [currentExistingData, setExistingData] = useState([]);
  const message = useSelector(
    (state) => state.pricePlanQueueReducer?.pricePlanActionMessage
  );
  const nextPricePlanId = useSelector(
    (state) => state.pricePlanQueueReducer?.nextPricePlanId
  );
  const pricePlanPendingApprovalData = useSelector(
    (state) =>
      state.pricePlanQueueReducer?.pricePlanPendingApprovalData?.queueDataList
  );

  const pricePlanPendingApprovalCurrentData = useSelector(
    (state) =>
      state.pricePlanQueueReducer?.pricePlanPendingApprovalData?.currentDataList
  );
  const pricePlanPendingApprovalError = useSelector(
    (state) => state.pricePlanQueueReducer?.pricePlanPendingApprovalError
  );
  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [message && showSuccessMessage]);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [pricePlanPendingApprovalError]);

  useEffect(() => {
    let id;
    if (nextPricePlanId !== null && nextPricePlanId !== "") {
      id = nextPricePlanId;
    } else if (
      pricePlanPendingApprovalData &&
      pricePlanPendingApprovalData.length === 1
    ) {
      id = pricePlanPendingApprovalData[0]?.billingModelNumber;
    } else if (pricePlanIdValue !== undefined) {
      id = pricePlanIdValue;
    }

    if (pricePlanPendingApprovalData) {
      const data = pricePlanPendingApprovalData.find(
        ({ billingModelNumber }) => billingModelNumber == id
      );
      if (data?.actionName === "Update") {
        const { billingModelDescription, billingModelNumber, ...rest } = data;

        const queueData = {
          pricePlanId: billingModelNumber,
          pricePlanName: billingModelDescription,
          ...rest,
        };
        setQueueData(queueData);
      } else if (data) {
        setQueueData(data);
      }
    }

    if (pricePlanPendingApprovalCurrentData) {
      const existingData = pricePlanPendingApprovalCurrentData.find(
        ({ billingModelNumber }) => billingModelNumber == id
      );
      const currentExistingData = {
        ...existingData,
        pricePlanName: existingData?.billingModelDescription,
        pricePlanId: existingData?.billingModelNumber,
      };

      setExistingData(currentExistingData);
    }
  }, [
    pricePlanPendingApprovalData,
    pricePlanPendingApprovalCurrentData,
    nextPricePlanId,
  ]);

  return (
    <div role="main">
      {message && showSuccessMessage && (
        <ConfirmationMessage
          message={message}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      {pricePlanPendingApprovalError ? (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={pricePlanPendingApprovalError ? 2 : 0}
        >
          {" "}
          {pricePlanPendingApprovalError && (
            <ErrorMessage
              error={pricePlanPendingApprovalError}
              errorRef={errorRef}
            />
          )}
        </Box>
      ) : (
        ""
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={state.componentAccess} />
        {queueData?.actionName === "Update" ? (
          <UpdatePricePlanApproval
            {...props}
            userId={userId}
            data={queueData}
            existingDataList={currentExistingData}
            setShowSuccessMessage={setShowSuccessMessage}
            componentAccess={state.componentAccess}
            setPricePlanIdValue={setPricePlanIdValue}
            pricePlanPendingApprovalData={pricePlanPendingApprovalData}
          />
        ) : (
          <NewAndDeletePricePlanApproval
            {...props}
            data={queueData}
            userId={userId}
            setShowSuccessMessage={setShowSuccessMessage}
            componentAccess={state.componentAccess}
            setPricePlanIdValue={setPricePlanIdValue}
            pricePlanPendingApprovalData={pricePlanPendingApprovalData}
          />
        )}
      </Box>
    </div>
  );
};

export default withSidebar(ApprovePricePlan);

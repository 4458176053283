import services from "../services";

export const passwordActions = {
  generatedAutoPasswordAndSendEmailForForgetPassword,
  generatedAutoPasswordAndSendEmailForLoggedInUser
};

function generatedAutoPasswordAndSendEmailForForgetPassword(userData) {
  return services.passwordService.generatedAutoPasswordAndSendEmailForForgetPassword(userData);
}


function generatedAutoPasswordAndSendEmailForLoggedInUser(userData) {
  return services.passwordService.generatedAutoPasswordAndSendEmailForLoggedInUser(userData);
}
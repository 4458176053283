import { t } from "i18next";
import { formatAmount } from "./helper";
import { statisticsFormatDate } from "./../../utils/helper";

export const BSR = "bsr";
export const CBAS = "cbas";
export const CBANS = "cbans";
export const DSR = "dsr";
export const FIS = "fis";
export const ALL_BILLERS = "all";
export const SPECIFIC_BILLER = "biller";
export const EDI = "edi";
export const EFT = "eft";
export const SORT_BY_ID = "id";

export const reportsList = [
  {
    label: "",
    value: "",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.bsr"),
    value: BSR,
    apiValue: "Biller settlement register",
    pdfName: "Creditor Settlement Register Detail",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.cbas"),
    value: CBAS,
    apiValue: "Corporate biller activity statistics",
    pdfName: "Corporate Biller Activity Statistics",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.cbans"),
    value: CBANS,
    apiValue: "Corporate biller audit number status",
    pdfName: "Corporate Biller Audit Number Status",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.dsr"),
    value: DSR,
    apiValue: "Daily settlement register",
    pdfName: "Daily Settlement Register Detail",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.fis"),
    value: FIS,
    apiValue: "Financial institution statistics",
    pdfName: "Financial Institution Statistics",
  },
];

export const periodsList = [
  {
    label: t("erps.statisticsEnquiry.page.label.calendarYear"),
    value: "year",
    format: "YYYY",
    views: ["year"],
    apiValue: "Y",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.month"),
    value: "month",
    format: "YYYY-MM",
    views: ["year", "month"],
    apiValue: "M",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.week"),
    value: "week",
    apiValue: "W",
  },
  {
    label: t("erps.statisticsEnquiry.page.label.day"),
    value: "day",
    format: "YYYY-MM-DD",
    views: ["year", "month", "date"],
    apiValue: "D",
  },
];

export const showStatisticsForItems = [
  {
    name: t("erps.statisticsEnquiry.page.label.allBillers"),
    value: ALL_BILLERS,
  },
  {
    name: t("erps.statisticsEnquiry.page.label.specificBiller"),
    value: SPECIFIC_BILLER,
  },
];

export const settlementTypeItems = (fiNumber) => {
  if (fiNumber == 16) {
    return [
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.all"),
        value: "all",
        apiValue: "All",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.edi"),
        value: EDI,
        apiValue: "EDI",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.eft"),
        value: "eft",
        apiValue: "EFT",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.tt"),
        value: "tt",
        apiValue: "TT",
      },
    ];
  } else if (fiNumber == 2) {
    return [
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.all"),
        value: "all",
        apiValue: "All",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.eft"),
        value: "eft",
        apiValue: "EFT",
      },
    ];
  } else {
    return [
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.all"),
        value: "all",
        apiValue: "All",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.edi"),
        value: EDI,
        apiValue: "EDI",
      },
      {
        name: t("erps.statisticsEnquiry.page.label.settlementType.eft"),
        value: "eft",
        apiValue: "EFT",
      },
    ];
  }
};

export const sortResultsByItems = [
  {
    name: t("erps.statisticsEnquiry.page.label.billerId"),
    value: SORT_BY_ID,
    apiValue: "Biller ID",
  },
  {
    name: t("erps.statisticsEnquiry.page.label.billerName"),
    value: "name",
    apiValue: "Biller name",
  },
];

export const defaultFormValues = {
  reportType: "",
  periodType: "year",
  periodValue: new Date(),
  showStatisticsFor: "",
  billerId: "",
  settlementType: "",
  sortResultsBy: "",
};

export const CBAS_HEADER = [
  {
    field: "externalBillerId",
    headerName: t("erps.statisticsEnquiry.page.label.billerId"),
    width: 140,
    sortable: false,
  },
  {
    field: "billerName",
    headerName: t("erps.statisticsEnquiry.page.label.billerName"),
    width: 350,
    sortable: false,
  },
  {
    field: "totalTransactionCount",
    headerName: t("erps.statisticsEnquiry.page.label.volume"),
    width: 100,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "totalTransactionAmount",
    headerName: t("erps.statisticsEnquiry.page.label.amount"),
    width: 150,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "billerFirstActivityDate",
    headerName: t("erps.statisticsEnquiry.page.label.firstActivity"),
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const CBAS_TOTAL_HEADER = [
  {
    field: "externalBillerId",
    headerName: "",
    width: 140,
    sortable: false,
  },
  {
    field: "billerName",
    headerName: "",
    width: 350,
    sortable: false,
  },
  {
    field: "totalTransactionCount",
    headerName: t("erps.statisticsEnquiry.page.label.volume"),
    width: 100,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "totalTransactionAmount",
    headerName: t("erps.statisticsEnquiry.page.label.amount"),
    width: 150,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "billerFirstActivityDate",
    headerName: "",
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const CBANS_HEADER = [
  {
    field: "id",
    headerName: t("erps.statisticsEnquiry.page.label.no"),
    width: 50,
    sortable: false,
  },
  {
    field: "fileDate",
    headerName: t("erps.statisticsEnquiry.page.label.fileDate"),
    width: 120,
    sortable: false,
    renderCell: (params) => {
      return statisticsFormatDate(params.value);
    },
  },
  {
    field: "complitionDateTime",
    headerName: t("erps.statisticsEnquiry.page.label.completionDateTime"),
    width: 170,
    sortable: false,
    renderCell: (params) => {
      return statisticsFormatDate(params.value);
    },
  },
  {
    field: "totalTransactions",
    headerName: t("erps.statisticsEnquiry.page.label.totalTransactions"),
    width: 150,
    sortable: false,
  },
  {
    field: "totalPayments",
    headerName: t("erps.statisticsEnquiry.page.label.totalPayments"),
    width: 140,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "fileMedium",
    headerName: t("erps.statisticsEnquiry.page.label.fileMedium"),
    width: 110,
    sortable: false,
  },
  {
    field: "auditNumber",
    headerName: t("erps.statisticsEnquiry.page.label.auditNumber"),
    width: 110,
    sortable: false,
  },
];

export const DSR_HEADER = [
  {
    field: "id",
    headerName: t("erps.statisticsEnquiry.page.label.no"),
    width: 50,
    sortable: false,
    renderCell: (params) => {
      return params.value - 1;
    },
  },
  {
    field: "externalBillerId",
    headerName: t("erps.statisticsEnquiry.page.label.billerId"),
    width: 120,
    sortable: false,
  },
  {
    field: "billerName",
    headerName: t("erps.statisticsEnquiry.page.label.billerName"),
    width: 250,
    sortable: false,
  },
  {
    field: "settlementCount",
    headerName: t("erps.statisticsEnquiry.page.label.settlementCount"),
    width: 130,
    sortable: false,
  },
  {
    field: "settlementAmount",
    headerName: t("erps.statisticsEnquiry.page.label.settlementAmount"),
    width: 160,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "auditNumber",
    headerName: t("erps.statisticsEnquiry.page.label.auditNo"),
    width: 90,
    sortable: false,
  },
  {
    field: "settlementType",
    headerName: t("erps.statisticsEnquiry.page.label.settlementType"),
    width: 120,
    sortable: false,
  },
];

export const BSR_HEADER = [
  {
    field: "id",
    headerName: t("erps.statisticsEnquiry.page.label.no"),
    width: 50,
    sortable: false,
    renderCell: (params) => {
      return params.value - 1;
    },
  },
  {
    field: "fileDate",
    headerName: t("erps.statisticsEnquiry.page.label.fileDate"),
    width: 110,
    sortable: false,
    renderCell: (params) => {
      return statisticsFormatDate(params.value);
    },
  },
  {
    field: "creditCount",
    headerName: t("erps.statisticsEnquiry.page.label.creditCount"),
    width: 100,
    sortable: false,
  },
  {
    field: "creditAmount",
    headerName: t("erps.statisticsEnquiry.page.label.creditAmount"),
    width: 120,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "auditNumber2",
    headerName: t("erps.statisticsEnquiry.page.label.billerauditNo"),
    width: 110,
    sortable: false,
  },
  {
    field: "totalSettlementCount",
    headerName: t("erps.statisticsEnquiry.page.label.settlementCount"),
    width: 130,
    sortable: false,
  },
  {
    field: "settlementAmount",
    headerName: t("erps.statisticsEnquiry.page.label.settlementAmount"),
    width: 150,
    sortable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      return formatAmount(params.value);
    },
  },
  {
    field: "auditNumber",
    headerName: t("erps.statisticsEnquiry.page.label.settlementauditNo"),
    width: 150,
    sortable: false,
  },
];

import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ERPSGrid from "./ERPSGrid";
import CardList from "./cardList";
import ERPSBaseButton from "../inputs/ERPSBaseButton";
import ERPSBaseDiv from "../inputs/ERPSBaseDiv";
import ERPSBaseTextField from "../inputs/ERPSBaseTextField";
import ERPSBaseDatePicker from "../inputs/ERPSDatePicker";
import { useEffect, useRef } from "react";
import ErrorMessage from "./ErrorMessage";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import LoadingIcon from "../inputs/ERPSLoadingIcon";

export default function CommonLayout(props) {
  const {
    pageTitle,
    pageDescription,
    pageAltDescription,
    primaryButtonLabel,
    pageSubHeading,
    pageSubHeadingDescription,
    errorMssg,
    primaryButtonLabelV2,
    secondaryButtonLabel,
    searchResultLabel,
    searchResultFoundLabel,
    startSearchHandler,
    searchCriteriaChangeHandler,
    resetHandler,
    navigateToNewUserComponent,
    isSearchValid,
    warningIcon,
    searchCriteria,
    initiateSearch,
    searchResults,
    searchNotStarted,
    pageName,
    firstTextInputFieldId,
    firstTextInputFieldLabel,
    secondTextInputFieldId,
    secondTextInputFieldLabel,
    thirdTextInputFieldId,
    thirdTextInputFieldLabel,
    pathName,
    isRequired = "true",
    history,
    loading,
    dataGridWidth,
    hideFooterPagination = false,
    componentAccess,
    pageNumber,
    rowPerPage,
    searchCriteriaBlurHandler,
    actionType: {
      primaryButtonActionTypeV1,
      firstTextInputFieldActionType,
      secondTextInputFieldActionType,
      thirdTextInputFieldActionType,
      primaryButtonActionTypeV2,
      secondaryButtonActionType,
    },
    isDisabled = true,
    hasNewButtonVisible = true,
    isEnable,
    initialLoading,
    initialStates,
    reportNameList,
    highlightError,
    hasDropDownLoading,
    minDateVal,
    maxDateVal,
  } = props;

  const errorRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMssg, !isSearchValid]);
  const getCardData = () => {
    const tempSearchResults = { ...searchResults };
    const { rows, columns, count } = tempSearchResults;
    const columnValues = columns.map(({ field }) => field);
    const cardData = rows.map((row) => {
      let rowData = {};
      columnValues.forEach((colValue) => {
        let updatedKey = "";
        if (colValue === "billingModelDescription") {
          updatedKey = "pricePlaneName";
        } else if (colValue === "billingModelNumber") {
          updatedKey = "pricePlanId";
        } else {
          updatedKey = colValue;
        }
        rowData = { ...rowData, [updatedKey]: row[colValue] };
      });
      return rowData;
    });
    return { rows: cardData, count, columns };
  };

  const highlightErrorField = () => {
    // Highlight 'Biller name' field if invalid wild card error
    return (
      !isSearchValid &&
      pageName == "manage-billers" &&
      errorMssg ==
        t(
          "erps.billermanagement.filterbillercategory.error.invaliduseofwildcardcharacter"
        )
    );
  };

  return (
    <div role="main">
      <Box ml={3}>
        <Box className="manageUsers__page__title">
          <Typography variant="h1">{pageTitle}</Typography>
        </Box>
        <ERPSBaseDiv
          actionType={primaryButtonActionTypeV1}
          componentAccess={componentAccess}
        >
          <Box className="manageUsers__wrap__box">
            <Typography variant="body1">{pageDescription}</Typography>
          </Box>
          <Box mt={-4}>
            {hasNewButtonVisible && (isEnable || !isDisabled) && (
              <ERPSBaseButton
                variant="primary"
                actionType={primaryButtonActionTypeV1}
                className="button_with_margin48"
                label={primaryButtonLabel}
                componentAccess={componentAccess}
                onClick={navigateToNewUserComponent}
              />
            )}
          </Box>
        </ERPSBaseDiv>
        <Box mt={isEnable || !isDisabled ? 4 : 6}>
          <Typography variant="h2">{pageSubHeading}</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">{pageSubHeadingDescription}</Typography>
        </Box>
        {!isSearchValid || (Array.isArray(errorMssg) && errorMssg.length) ? (
          <Box
            className={"manageUsers__errorMsg__container"}
            role="alert"
            mt={
              !isSearchValid || (Array.isArray(errorMssg) && errorMssg.length)
                ? 2
                : 0
            }
          >
            {!isSearchValid && (
              <ErrorMessage error={errorMssg} errorRef={errorRef} />
            )}
            {Array.isArray(errorMssg) && errorMssg.length && (
              <ErrorMessage error={errorMssg} errorRef={errorRef} />
            )}
          </Box>
        ) : (
          ""
        )}

        <Box style={{ width: "650px" }}>
          <Grid container>
            {pageName === "download-reports" ? (
              // For Download report page
              <>
                <Grid item sm={4} lg={4}>
                  <ERPSBaseDatePicker
                    label={firstTextInputFieldLabel}
                    value={searchCriteria.startDate || null}
                    onChange={searchCriteriaChangeHandler("startDate")}
                    minDate={minDateVal}
                    error={highlightError?.startDate}
                    id={`${pageName}-${firstTextInputFieldLabel}`}
                    maxDate={maxDateVal}
                  />
                </Grid>
                <Grid item sm={4} lg={4}>
                  <Box pl={1}>
                    <ERPSBaseDatePicker
                      label={secondTextInputFieldLabel}
                      value={searchCriteria.endDate || null}
                      onChange={searchCriteriaChangeHandler("endDate")}
                      minDate={minDateVal}
                      error={highlightError?.endDate}
                      id={`${pageName}-${secondTextInputFieldLabel}`}
                      maxDate={maxDateVal}
                    />
                  </Box>
                </Grid>
                <Grid item sm={4} lg={4}>
                  <Box pl={1}>
                    <ERPSBaseTextField
                      id="reportname__list"
                      type="text"
                      select={true}
                      label={thirdTextInputFieldId}
                      className="three_column__textField downloadreports__reportname"
                      size="small"
                      value={searchCriteria.reportName || ""}
                      items={reportNameList}
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: searchCriteria.reportName ? true : false,
                      }}
                      onChange={searchCriteriaChangeHandler("reportName")}
                      loading={hasDropDownLoading}
                      inputProps={{
                        startAdornment: hasDropDownLoading && (
                          <InputAdornment style={{ margin: "0px 120px" }}>
                            <LoadingIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={4} lg={4}>
                  <ERPSBaseTextField
                    id={firstTextInputFieldId}
                    type="text"
                    label={firstTextInputFieldLabel}
                    className="common__textField__width"
                    required={isRequired}
                    size="small"
                    onChange={searchCriteriaChangeHandler(
                      firstTextInputFieldId
                    )}
                    value={searchCriteria[firstTextInputFieldId]}
                    actionType={firstTextInputFieldActionType}
                    componentAccess={componentAccess}
                  />
                </Grid>
                <Grid item sm={4} lg={4}>
                  <Box pl={1}>
                    {pageName === "manage-billers" ? (
                      <ERPSBaseTextField
                        id={secondTextInputFieldId}
                        type="text"
                        label={secondTextInputFieldLabel}
                        className="common__textField__width"
                        required={isRequired}
                        size="small"
                        onChange={searchCriteriaChangeHandler(
                          secondTextInputFieldId
                        )}
                        value={searchCriteria[secondTextInputFieldId]}
                        actionType={secondTextInputFieldActionType}
                        componentAccess={componentAccess}
                        error={highlightErrorField()}
                        onBlur={searchCriteriaBlurHandler(
                          secondTextInputFieldId
                        )}
                      />
                    ) : (
                      <ERPSBaseTextField
                        id={secondTextInputFieldId}
                        type="text"
                        label={secondTextInputFieldLabel}
                        className="common__textField__width"
                        required={isRequired}
                        size="small"
                        onChange={searchCriteriaChangeHandler(
                          secondTextInputFieldId
                        )}
                        value={searchCriteria[secondTextInputFieldId]}
                        actionType={secondTextInputFieldActionType}
                        componentAccess={componentAccess}
                        error={highlightErrorField()}
                      />
                    )}
                  </Box>
                </Grid>
                {(pageName === "manage-users" ||
                  pageName === "biller-profile") && (
                  <Grid item sm={4} lg={4}>
                    <Box pl={2}>
                      <ERPSBaseTextField
                        id={thirdTextInputFieldId}
                        type="text"
                        label={thirdTextInputFieldLabel}
                        className="common__textField__width"
                        required={isRequired}
                        size="small"
                        onChange={searchCriteriaChangeHandler(
                          thirdTextInputFieldId
                        )}
                        value={searchCriteria.lastName}
                        actionType={thirdTextInputFieldActionType}
                        componentAccess={componentAccess}
                      />
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

        <Box mt={-3} display="flex">
          <ERPSBaseButton
            variant="secondary"
            actionType={primaryButtonActionTypeV2}
            className="button_with_margin48"
            label={primaryButtonLabelV2}
            onClick={resetHandler}
            componentAccess={componentAccess}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              actionType={secondaryButtonActionType}
              className="button_with_margin48"
              label={secondaryButtonLabel}
              onClick={startSearchHandler}
              loading={loading}
              componentAccess={componentAccess}
            />
          </Box>
        </Box>

        <Box mt={4}>
          <Typography variant="h2">{searchResultLabel}</Typography>
        </Box>

        {searchResults !== null &&
          searchResults !== undefined &&
          searchResults.count !== null &&
          searchResults.count !== -1 && (
            <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
              <Typography variant="body1">
                {searchResults.count} {searchResultFoundLabel}
              </Typography>
            </Box>
          )}

        {searchResults !== null &&
          searchResults !== undefined &&
          searchResults.rows.length > 0 &&
          searchResults.count > 0 && (
            <>
              <Box className="manageUsers_card_visible">
                <CardList
                  data={{ ...searchResults }}
                  onAnyPaginationAction={initiateSearch}
                  pathName={pathName}
                  hideFooterPagination={hideFooterPagination}
                  componentAccess={componentAccess}
                  pageNumber={pageNumber}
                  rowPerPage={rowPerPage}
                  history={history}
                />
              </Box>
              <Box className="manageUsers_table_visible">
                <ERPSGrid
                  data={{ ...searchResults }}
                  onAnyPaginationAction={initiateSearch}
                  history={history}
                  pathName={pathName}
                  dataGridWidth={dataGridWidth || "dataGrid__box__container"}
                  hideFooterPagination={hideFooterPagination}
                  componentAccess={componentAccess}
                  initialLoading={initialLoading}
                  initialStates={initialStates}
                  pageNumber={pageNumber}
                  rowPerPage={rowPerPage}
                  tableLabelText={pageTitle}
                />
              </Box>
            </>
          )}

        {(searchResults === null ||
          searchResults.count === null ||
          searchResults.count === -1) && (
          <Box mt={1}>
            <Typography variant="body1">{searchNotStarted}</Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

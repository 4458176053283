import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../actions";
import { lpad } from "../../utils/helper";

export const useCustomDropDownHook = (props) => {
  const { t, billerId, setIsLoading } = props;
  const dispatch = useDispatch();

  const [billerFinancialInstitutionList, setBillerFinancialInstitutionList] =
    useState([]);
  const [financialInstitutionTableList, setFinancialInstitutionTableList] =
    useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [deliveryMethodLists, setDeliveryMethodLists] = useState([]);
  const [formatedMethodMapList, setFormatedMethodMapList] = useState([]);
  const [errorMessageList, setErrorMessage] = useState([]);
  const [budgetCategoryCodes, setBudgetCategoryCodes] = useState([]);

  const financialInstitutionList = useSelector(
    (state) => state.fiReducer?.fiListByOwningFi
  );
  const masterfinancialInstitutionList = useSelector(
    (state) => state.fiReducer?.masterFiDescriptions
  );

  const budgetCategory = useSelector((state) => state.budgetCategoryReducer);
  const formattedMethodReducerValue = useSelector(
    (state) => state.formatMethodReducer
  );

  const provinceEntities = useSelector((state) => state.provinceReducer);

  const deliveryMethodReducerValue = useSelector(
    (state) => state.deliveryMethodReducer
  );

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    dispatch(actions.billerProfileActions.getAllProvince(t));
    dispatch(actions.fiActions.getFinancialInstitutionListByOwningFiNumber(t));
    dispatch(actions.fiActions.getMasterFiDescriptionList(t, setIsLoading));
  }, []);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getBudgetCategory(t));
  }, []);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getAllDeliveryMethod(t, billerId));
  }, []);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getFormatMethod(t, billerId));
  }, []);

  useEffect(() => {
    if (financialInstitutionList) {
      //  const filteredFiData = financialInstitutionList.filter(({ owning_fi_number }) => owning_fi_number == 50)
      const updatedFiTable = financialInstitutionList.map(
        ({ fi_number, fi_name }, index) => ({
          fiNumber: fi_number,
          fiName: fi_name,
          billPaymentLimit: 0,
          billerStatus: "I",
          id: index,
        })
      );

      setFinancialInstitutionTableList(updatedFiTable);
    }

    if (masterfinancialInstitutionList) {
      const data = masterfinancialInstitutionList
        .map(({ fiNumber, fiName }) => ({
          value: fiNumber,
          label: lpad(fiNumber, 4) + "-" + fiName,
        }))
        ?.filter(({ value }) => value != 0);
      data.unshift({
        value: "",
        label: "",
      });
      setBillerFinancialInstitutionList(data);
    }
  }, [financialInstitutionList, masterfinancialInstitutionList]);

  useEffect(() => {
    if (provinceEntities) {
      if (provinceEntities.provinceEntitiesList) {
        const data = Object.keys(provinceEntities.provinceEntitiesList).map(
          (key) => ({
            value: key,
            label: provinceEntities.provinceEntitiesList[key],
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
        setProvinceList(data);
      }
    }
    if (provinceEntities?.error) {
      setErrorMessage((preState) => [...preState, provinceEntities.error]);
    }
  }, [provinceEntities]);

  useEffect(() => {
    if (budgetCategory) {
      if (budgetCategory?.budgetCategoryFiAssociated) {
        const data = budgetCategory.budgetCategoryFiAssociated.map(
          (budgetCode) => ({
            value: budgetCode.budgetCategory,
            label: `${budgetCode.budgetCategory}-${budgetCode.budgetDescription}`,
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
        setBudgetCategoryCodes(data);
      }
    }
    if (budgetCategory?.budgetCategoryFiAssociatedError) {
      setErrorMessage((preState) => [...preState, provinceEntities.error]);
    }
  }, [budgetCategory]);

  useEffect(() => {
    if (deliveryMethodReducerValue) {
      if (deliveryMethodReducerValue.deliveryMethodList) {
        const data = Object.keys(
          deliveryMethodReducerValue.deliveryMethodList
        ).map((key) => ({
          value: key,
          label: deliveryMethodReducerValue.deliveryMethodList[key],
        }));

        setDeliveryMethodLists(data);
      }
    }
    if (deliveryMethodReducerValue?.error) {
      setErrorMessage((preState) => [
        ...preState,
        deliveryMethodReducerValue.error,
      ]);
    }
  }, [deliveryMethodReducerValue]);

  useEffect(() => {
    if (formattedMethodReducerValue) {
      if (formattedMethodReducerValue.formatMethodEntitiesList) {
        setFormatedMethodMapList(
          formattedMethodReducerValue.formatMethodEntitiesList
        );
      }
    }
    if (formattedMethodReducerValue?.error) {
      setErrorMessage((preState) => [
        ...preState,
        formattedMethodReducerValue.error,
      ]);
    }
  }, [formattedMethodReducerValue]);

  return {
    billerFinancialInstitutionList,
    provinceList,
    deliveryMethodLists,
    formatedMethodMapList,
    errorMessageList,
    financialInstitutionList,
    financialInstitutionTableList,
    setFinancialInstitutionTableList,
    budgetCategoryCodes,
  };
};

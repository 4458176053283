"use client";

import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import NavBar from "../../components/NavBar/NavBar";
import { Box, Grid } from "@material-ui/core";
import CommonContainerBox from "../../components/custom/containers/CommonContainerBox";
import Header from "../../components/Header/Header";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseBox from "../custom/inputs/ERPSBaseBox";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../actions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../Error/ErrorFallback";
import Footer from "../Footer/Footer";

export const withSidebar = (Component) => (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [financialInstitutionList, setFinancialInstitutionList] = useState([]);

  const roleId = useSelector(
    (state) => state.loginUserReducer.user?.role?.role_id
  );

  const financialInstitutionResponse = useSelector(
    (state) => state.fiReducer?.financialInstitutionList
  );

  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  useEffect(() => {
    if (!selectedFi && loginUser.fiNumber == 50) {
      dispatch(actions.loginActions.updateUserRolesPermission(50, roleId));
      dispatch(actions.fiActions.getHelpDeskPhoneNumber(50, roleId, t));
    } else if (selectedFi) {
      dispatch(
        actions.loginActions.updateUserRolesPermission(selectedFi, roleId)
      );
      dispatch(actions.fiActions.getHelpDeskPhoneNumber(selectedFi, roleId, t));
    }
  }, []);

  useEffect(() => {
    if ((!selectedFi && loginUser.fiNumber == 50) || roleId == 6) {
      dispatch(actions.fiActions.getFinancialInstitutionList(t));
    }
  }, []);

  useEffect(() => {
    if (!selectedFi && loginUser.fiNumber == 50) {
      dispatch({ type: "GET_FINANCIAL_INSTITUTION", data: "50" });
    } else {
      dispatch({ type: "GET_FINANCIAL_INSTITUTION", data: selectedFi });
    }
  }, []);

  useEffect(() => {
    if (financialInstitutionResponse && financialInstitutionResponse.length) {
      const data = financialInstitutionResponse.map(
        ({ fi_name, fi_number }) => ({
          value: fi_number,
          label: `${fi_number} - ${fi_name}`,
        })
      );
      const selectedfiName = financialInstitutionResponse.find(
        ({ fi_number }) => fi_number == 50
      );

      const updatedData = data.filter(
        ({ value }) => value !== selectedfiName?.fi_number
      );

      if (selectedfiName) {
        const fiValue = `${selectedfiName.fi_number} - ${selectedfiName.fi_name}`;
        updatedData.unshift({
          value: selectedfiName.fi_number,
          label: fiValue,
        });
      }

      setFinancialInstitutionList(updatedData);
    }
  }, [financialInstitutionResponse]);

  const handleChange = (event) => {
    const fiNumber = event.target.value;
    dispatch({ type: "GET_FINANCIAL_INSTITUTION", data: fiNumber });
    dispatch(
      actions.loginActions.updateUserRolesPermission(fiNumber, roleId, props)
    );
    dispatch(actions.fiActions.getHelpDeskPhoneNumber(fiNumber, roleId, t));
  };
  const {
    location: { pathname },
  } = props;
  const splittedValue = pathname.split("/");
  const title = splittedValue[splittedValue.length - 1]
    .split("-")
    .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
    .join(" ");

  if (
    props.pageName === "biller-profile" ||
    props.pageName === "download-document"
  ) {
    return (
      <Box>
        {" "}
        <Component {...props} />{" "}
      </Box>
    );
  }

  const hasDropDownDisable = () => {
    const hasEmailAddress = loginUser?.emailAddress;
    // if (loginUser?.changePasswordIndex == "Y") {
    //   return false;
    // } else {
      return Boolean(hasEmailAddress);
    // }
  };

  const logout = () => {
    // dispatch(actions.loginActions.logout({ ...props, t }));
    // let path = '/';
    // history.push(path);
  };

  return (
    <>
      <Header {...props} />
      <ERPSBaseBox
        mt={8}
        className="sidebar_drop_down_list"
        componentAccess={loginUser?.role?.modules}
        actionType="hasFiManagement"
      >
        <ERPSBaseTextField
          id={`financialInstitution-${selectedFi}`}
          type="text"
          select={true}
          label={t("erps.newuser.label.fi", "Financial Institution")}
          className="three_column__textField"
          value={selectedFi}
          onChange={handleChange}
          size="small"
          required="true"
          items={financialInstitutionList}
          SelectProps={{ native: true }}
          InputLabelProps={{ shrink: true }}
          isDisabled={!hasDropDownDisable()}
        />
      </ERPSBaseBox>

      <CommonContainerBox
        title={t("erps.user.pagetitle.message", title)}
        padding="0px 0px 0px 0px"
        minHeight="calc(100vh - 280px)"
        marginTop={"85px"}
        componentAccess={loginUser?.role?.modules}
        actionType="hasFiManagement"
      >
        <Grid item lg={3} sm={3} className="sidebar__disabale">
          <NavBar {...props} />
        </Grid>
        <Grid item lg={9} sm={12}>
          <Box>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Component {...props} />
            </ErrorBoundary>
          </Box>
        </Grid>
      </CommonContainerBox>
      <Footer />
    </>
  );
};

import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { requiredFormatDate } from "../utils/helper";
import { t } from "i18next";
export const getPricePlanPendingApprovals = (userId, componentAccess) => {
  return [
    {
      field: "billingModelNumber",
      headerName: t("erps.managePricePlan.label.pricePlanId"),
      width: 130,
      renderCell: (params) => (
        <ERPSBaseLink
          to={{
            pathname: `/price-plan-pending-approvals/approve-price-plan`,
            state: { id: params.value, componentAccess },
          }}
          className="link__blue"
          //label={params.value.toString()}
          label={
            params.row.actionName === "Update" ? params.value.toString() : "N/A"
          }
          componentAccess={componentAccess}
          actionType={"hasPricePlanListAccess"}
        />
      ),
      sortable: false,
    },
    {
      field: "billingModelDescription",
      headerName: t("erps.newpriceplan.label.priceplanname"),
      width: 200,
      sortable: false,
    },
    {
      field: "updatedBy",
      headerName: t("erps.managePricePlan.approval.updatedby"),
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.displayUpdatedBy;
      },
    },
    {
      field: "updatedFormattedDate",
      headerName: t("erps.managePricePlan.approval.updatedon"),
      width: 170,
      sortable: false,
      renderCell: (params) => {
        return requiredFormatDate(params.value);
      },
    },
    {
      field: "actionName",
      headerName: t("erps.managePricePlan.approval.action"),
      width: 100,
      sortable: false,
    },
  ];
};

export const PRICE_PLAN_UPDATE_HEADINGS = [
  {
    field: "attribute",
    headerName: t("erps.managePricePlan.label.attribute"),
    width: 200,
    sortable: false,
  },
  {
    field: "currentValue",
    headerName: t("erps.managePricePlan.label.currentvalue"),
    width: 300,
    sortable: false,
    headerClassName: "column_separator_border-right",
  },
  {
    field: "updatedValue",
    headerName: t("erps.managePricePlan.label.updatedvalue"),
    width: 300,
    sortable: false,
  },
];

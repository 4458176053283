const getEL = (url) => {
  const siteType = localStorage.getItem("type");
  return siteType == "webportal" ? url : "";
};

const headerLinks = () => {
  return {
    bmo: {
      contactUs: {
        en: getEL(
          "https://www.bmo.com/main/business/cash-management/merchant-bill-payment/"
        ),
        fr: getEL(
          "https://www.bmo.com/principal/entreprise/gestion-de-tresorerie/reglement-de-factures-commercants/"
        ),
      },
      legal: {
        en: getEL("https://www.bmo.com/main/personal/legal/"),
        fr: getEL("https://www.bmo.com/principal/particuliers/legal/"),
      },
      privacyAndSecurity: {
        en: getEL(
          "https://www.bmo.com/main/personal/ways-to-bank/security-centre/#"
        ),
        fr: getEL(
          "https://www.bmo.com/principal/particuliers/vos-operations-bancaires/centre-de-securite/#"
        ),
      },
      help: {
        en: getEL(
          "https://www.bmo.com/main/business/cash-management/merchant-bill-payment/"
        ),
        fr: getEL(
          "https://www.bmo.com/main/business/cash-management/merchant-bill-payment/"
        ),
      },
    },
    cibc: {
      contactUs: {
        en: getEL("https://www.cibc.com/en/contact-us.html"),
        fr: getEL(" https://www.cibc.com/fr/contact-us.html"),
      },
      legal: {
        en: getEL("https://www.cibc.com/en/legal.html"),
        fr: getEL("https://www.cibc.com/fr/legal.html"),
      },
      privacyAndSecurity: {
        en: getEL("https://www.cibc.com/en/privacy-security.html"),
        fr: getEL("https://www.cibc.com/fr/privacy-security.html"),
      },
      help: {
        en: getEL("https://www.cibc.com/en/personal-banking.html"),
        fr: getEL("https://www.cibc.com/en/personal-banking.html"),
      },
    },
    bns: {
      contactUs: {
        en: getEL("mailto:electronic.billpayment@scotiabank.com"),
        fr: getEL("mailto:electronic.billpayment@scotiabank.com"),
      },
      legal: {
        en: getEL(
          "https://www.scotiabank.com/ca/en/about/contact-us/legal.html"
        ),
        fr: getEL(
          "https://www.scotiabank.com/ca/fr/qui-nous-sommes/contactez-nous/notes-juridiques-banque-scotia.html"
        ),
      },
      privacyAndSecurity: {
        en: getEL(
          "https://www.scotiabank.com/ca/en/about/contact-us/security.html"
        ),
        fr: getEL("https://www.scotiabank.com/ca/fr/qui-nous-sommes.html"),
      },
      help: {
        en: getEL("mailto:electronic.billpayment@scotiabank.com"),
        fr: getEL("mailto:electronic.billpayment@scotiabank.com"),
      },
    },
    "D&D": {
      contactUs: {
        en: "",
        fr: "",
      },
      legal: {
        en: "",
        fr: "",
      },
      privacyAndSecurity: {
        en: "",
        fr: "",
      },
      help: {
        en: "",
        fr: "",
      },
    },
  };
};

export const getHeaderAndFooterLinks = (lang) => {
  const fiName = localStorage.getItem("fiName");
  const { contactUs, legal, privacyAndSecurity, help } = headerLinks()[fiName];
  return {
    contactUsLink: contactUs[lang],
    legalLink: legal[lang],
    privacyAndSecurityLink: privacyAndSecurity[lang],
    helpLink: help[lang],
  };
};

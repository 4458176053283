import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Tab, Tabs } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import TabPanel from "../custom/containers/TabPanel";
import ChangePassword from "./ChangePassword";
import UserProfile from "./UserProfile";
import Breadcrumbs from "../Breadcrumb/Breadcrumb";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import GeneratePassword from "./GeneratePassword";
import passwordActionTypes from "../../actions/types/password.action.type";
import ERPSBaseTab from "../custom/inputs/ERPSBaseTab";

const UserProfileTab = (props) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const userResult = useSelector((state) => state.userDetailReducer.userData);
  const user = useSelector((state) => state.loginUserReducer.user);
  const profileUpdateMsg = useSelector(
    (state) => state.loginUserReducer.message
  );
  //const usrQueueMsg = useSelector((state) => state.userQueueReducer.message);
  const response = useSelector((state) => state.changePasswordReducer.user);
  const mailStatusText = useSelector(
    (state) => state.forgetPasswordReducer.data?.statusText
  );
  const errorMessage = useSelector(
    (state) => state.forgetPasswordReducer.data?.errorMessage
  );

  const exceptionErrorMessage = useSelector(
    (state) => state.forgetPasswordReducer.error
  );

  const dispatch = useDispatch();

  const [showMessageForChangePassword, setShowMessageForChangePassword] =
    useState(false);
  const [showMessageForForgetPassword, setShowMessageForForgetPassword] =
    useState(false);
  const [showMessageForUserProfile, setShowMessageForUserProfile] =
    useState(false);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      if (errorMessage || exceptionErrorMessage) {
        dispatch({
          type: passwordActionTypes.GENRATE_PASSWORD_SUCCESS,
          error: null,
          data: null,
        });
      }
      dispatch({
        type: passwordActionTypes.CHILD_USER_PWD_UPDATE_ERROR,
        errors: null,
      });
    }
  };

  const getDisplayMessage = () => {
    let message = "";
    if (props.pageName === "account-settings") {
      message = profileUpdateMsg;
    } else {
      message = "";
    }

    return message;
  };

  const getUserData = () => {
    let data = null;

    if (props.pageName === "account-settings") {
      data = user;
    } else {
      data = userResult
        ? userResult.find(
            ({ userId, internalBMOUserId }) =>
              userId === state.id || internalBMOUserId == state.id
          )
        : {};
    }
    return data;
  };

  function a11yProps(index) {
    return {
      id: `user-tab-${index}`,
      //"aria-controls": `user-tabpanel-${index}`,
    };
  }
  const {
    location: { state },
  } = props;
  const userData = getUserData();
  const userComponentAccess = state.componentAccess.find(
    ({ componentType }) => componentType === "MUGFV"
  );

  const userProfileComponentChildAccess = userComponentAccess?.childComponents;
  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [
    response?.isSaved && showMessageForChangePassword,
    mailStatusText && showMessageForForgetPassword,
  ]);
  return (
    <div role="main">
      <Box>
        {response?.isSaved && showMessageForChangePassword && (
          <Box>
            <ConfirmationMessage
              message={response?.message}
              setShowMessage={setShowMessageForChangePassword}
              inputRef={successRef}
            />
          </Box>
        )}
        {mailStatusText && showMessageForForgetPassword && (
          <Box>
            <ConfirmationMessage
              message={mailStatusText}
              setShowMessage={setShowMessageForForgetPassword}
              inputRef={successRef}
            />
          </Box>
        )}

        <Box ml={3}>
          <Breadcrumbs
            {...props}
            componentAccess={state.componentAccess}
            fromDetailPage={true}
          />
          <Box mt={2}>
            <Typography variant="h1">
              {t("erps.userprofile.label.userid", "Profile for user ID")} :
              {state.id}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginTop: "1.5rem",
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="user-tabs"
              >
                <ERPSBaseTab
                  label={t("erps.userdetails.tab1.name", "Profile")}
                  {...a11yProps(0)}
                  componentAccess={userProfileComponentChildAccess}
                  actionType="hasUserProfileTabAccess"
                  setTabIndex={setTabIndex}
                />
                <ERPSBaseTab
                  label={t("erps.userdetails.tab2.name", "Change password")}
                  {...a11yProps(1)}
                  componentAccess={userProfileComponentChildAccess}
                  actionType="hasChangePasswordTabAccess"
                  setTabIndex={setTabIndex}
                  disabled={!userData?.emailAddress}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={tabIndex}
              index={0}
              componentAccess={userProfileComponentChildAccess}
              actionType="hasUserProfileTabAccess"
            >
              <UserProfile
                profileId={state.id}
                pageName="manage-users"
                setShowMessage={setShowMessageForUserProfile}
                componentAccess={userProfileComponentChildAccess}
                parentComponentAccess={state.componentAccess}
                personalUser={false}
                keycloakUserStatus={state?.keycloakUserStatus}
                {...props}
              />
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              componentAccess={userProfileComponentChildAccess}
              actionType="hasChangePasswordTabAccess"
            >
              {userData?.emailAddress ? (
                <GeneratePassword
                  userData={userData}
                  pageName="manage-users"
                  setShowMessage={setShowMessageForForgetPassword}
                  componentAccess={userProfileComponentChildAccess}
                  parentComponentAccess={state.componentAccess}
                  {...props}
                />
              ) : (
                <ChangePassword
                  {...props}
                  userId={state.id}
                  userData={userData}
                  subTitle={true}
                  setShowMessage={setShowMessageForChangePassword}
                  pageName="manage-users"
                  handleTabChange={handleTabChange}
                  componentAccess={userProfileComponentChildAccess}
                  parentComponentAccess={state.componentAccess}
                />
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(UserProfileTab);

import React from "react";
import Stack from "@mui/material/Stack";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { getNumericValue } from "./../../helpers/biller";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";

export default function ({
  deliveryMethods,
  handleChangeDeliveryMethods,
  deliveryMethodLists,
  getFormatListItem,
  handleClickDeliveryMethod,
  handleRemoveDeliveryMethod,
  error,
  appliesToItems,
  fiNumber,
}) {
  return deliveryMethods.map(
    (
      {
        deliveryMethod,
        distributionFormat,
        distributionListCode,
        destinationAttention,
        distributionPhoneNumber,
        distributionExtensionNumber,
        distributionEDIMailbox,
        distributionGroupRcpIndex,
      },
      id
    ) => (
      <React.Fragment key={id}>
        <Box mt={4} mb={-2}>
          <Typography variant="h3">
            {t(
              "erps.billermanagement.label.deilveryMethod",
              "Delivery method "
            ) +
              (id + 2)}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id={`destinationDeliveryType_${id}`}
            type="text"
            label={t(
              "erps.billermanagement.label.deilveryMethod",
              "Delivery method"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleChangeDeliveryMethods("deliveryMethod", id)}
            required={"true"}
            select={true}
            items={deliveryMethodLists}
            value={deliveryMethod}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: deliveryMethod ? true : false,
            }}
            error={!deliveryMethod && error?.[id]?.deliveryMethod}
          />
          <ERPSBaseTextField
            id={`distributionFormat_${id}`}
            type="text"
            label={t("erps.billermanagement.label.format", "Format")}
            className="common__textField__width"
            size="small"
            onChange={handleChangeDeliveryMethods("distributionFormat", id)}
            select={true}
            value={distributionFormat}
            items={getFormatListItem(deliveryMethod)}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: deliveryMethod ? true : false,
            }}
          />
          <ERPSBaseTextField
            id={`distributionListCode_${id}`}
            type="text"
            label={t("erps.billermanagement.label.destination", "Destination")}
            className="common__textField__width"
            size="small"
            onChange={handleChangeDeliveryMethods("distributionListCode", id)}
            value={distributionListCode}
            required={"true"}
            error={error?.[id]?.distributionListCode}
            inputProps={{ maxLength: 10 }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id={`destinationAttention_${id}`}
            type="text"
            label={t("erps.billermanagement.label.attention", "Attention")}
            className="common__textField__width"
            size="small"
            onChange={handleChangeDeliveryMethods("destinationAttention", id)}
            value={destinationAttention}
            InputLabelProps={{
              shrink: deliveryMethod ? true : false,
            }}
            inputProps={{ maxLength: 30 }}
          />
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id={`distributionPhoneNumber_${id}`}
              type="number"
              label={t(
                "erps.billermanagement.label.telNum",
                "Telephone number"
              )}
              className="common__textField__width_v1"
              size="small"
              onChange={handleChangeDeliveryMethods(
                "distributionPhoneNumber",
                id
              )}
              value={distributionPhoneNumber || ""}
              InputLabelProps={{
                shrink: deliveryMethod ? true : false,
              }}
              inputProps={{ maxLength: 10 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
              error={error?.[id]?.distributionPhoneNumber}
            />
            <ERPSBaseTextField
              id={`distributionExtensionNumber_${id}`}
              type="number"
              label={t("erps.billermanagement.label.ext", "Ext.")}
              className="common__textField__width_ext"
              size="small"
              onChange={handleChangeDeliveryMethods(
                "distributionExtensionNumber",
                id
              )}
              value={distributionExtensionNumber || ""}
              InputLabelProps={{
                shrink: deliveryMethod ? true : false,
              }}
              inputProps={{ maxLength: 5 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 5);
              }}
            />
          </Stack>
        </Stack>
        {deliveryMethod === "EDI" && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id={`destinationEDIMailbox_${id}`}
              type="text"
              label={t("erps.billermanagement.label.ediMailBox", "EDI mailbox")}
              className="common__textField__width"
              size="small"
              onChange={handleChangeDeliveryMethods(
                "distributionEDIMailbox",
                id
              )}
              value={distributionEDIMailbox}
              InputLabelProps={{
                shrink: deliveryMethod ? true : false,
              }}
              inputProps={{ maxLength: 45 }}
              isDisabled={true}
            />
          </Stack>
        )}
        {(fiNumber == 10 || fiNumber == 16) && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseRadioGroup
              value={distributionGroupRcpIndex || "C"}
              label={t("erps.billermanagement.appliesTo.text", "Applies to")}
              onChange={handleChangeDeliveryMethods(
                "distributionGroupRcpIndex",
                id
              )}
              items={appliesToItems}
              error={error?.[id]?.distributionGroupRcpIndex}
            />
          </Stack>
        )}

        <Stack direction="row" spacing={8} mt={3}>
          <ERPSBaseLink
            className="link__blue"
            label={t(
              "erps.billermanagement.label.removeDeilveryMethod",
              "Remove a delivery method"
            )}
            target={""}
            onClick={handleRemoveDeliveryMethod(id)}
          />

          {id < 2 && id === deliveryMethods.length - 1 && (
            <ERPSBaseLink
              className="link__blue"
              label={t(
                "erps.billermanagement.label.addDeilveryMethod",
                "Add a delivery method"
              )}
              target={""}
              onClick={() => handleClickDeliveryMethod()}
            />
          )}
        </Stack>
      </React.Fragment>
    )
  );
}

import services from "../services";

export const faxManagementActions = {
  getSentFaxLists,
  getFailedFax,
  getViewFaxFile,
  resendFax,
};

function getSentFaxLists(data, props) {
  return services.faxManagementService.getSentFaxLists(data, props);
}

function getFailedFax(data, props) {
  return services.faxManagementService.getFailedFax(data, props);
}

function getViewFaxFile(data, props) {
  return services.faxManagementService.getViewFaxFile(data, props);
}

function resendFax(data, props) {
  return services.faxManagementService.resendFax(data, props);
}

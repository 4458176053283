import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import NewAndDeleteUserApproval from "./NewAndDeleteUserApproval";
import UpdateUserApproval from "./UpdateUserApproval";
//import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useSelector } from "react-redux";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";

const ApprovalUser = (props) => {
  const { t } = useTranslation();
  const {
    location: { state },
  } = props;
  const message = useSelector(
    (state) => state.userApprovalReducer?.userActionMessage
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const nextTransactionId = useSelector(
    (state) => state.userApprovalReducer?.nextTransactionId
  );
  const userPendingData = useSelector(
    (state) => state.userApprovalReducer?.userPendingApprovalData?.queueDataList
  );
  const existingDataList = useSelector(
    (state) =>
      state.userApprovalReducer?.userPendingApprovalData?.existingDataList
  );
  const [queueData, setQueueData] = useState({});
  const [currentExistingData, setExistingData] = useState([]);

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [message && showSuccessMessage]);

  useEffect(() => {
    let id;
    if (nextTransactionId !== null && nextTransactionId !== "") {
      id = nextTransactionId;
    } else if (userPendingData && userPendingData.length === 1) {
      id = userPendingData[0]?.transactionId;
    } else if (state.transactionId !== undefined) {
      id = state.transactionId;
    }
    if (userPendingData) {
      const data = userPendingData.find(
        ({ transactionId }) => transactionId == id
      );
      if (data) {
        setQueueData(data);
      }
    }
    if (existingDataList) {
      const existingData = existingDataList.find(
        ({ transactionId }) => transactionId == id
      );
      setExistingData(existingData);
    }
  }, [userPendingData, existingDataList, nextTransactionId]);

  const roleType = loginUser?.role?.role_type;

  return (
    <div role="main">
      {message && showSuccessMessage && (
        <ConfirmationMessage
          message={message}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={state.componentAccess} />
        {queueData?.actionName === "Update" ? (
          <UpdateUserApproval
            {...props}
            data={queueData}
            userId={loginUser?.userId}
            existingDataList={currentExistingData}
            setShowSuccessMessage={setShowSuccessMessage}
            componentAccess={state.componentAccess}
            userPendingData={userPendingData}
          />
        ) : (
          <NewAndDeleteUserApproval
            {...props}
            userId={loginUser?.userId}
            data={queueData}
            setShowSuccessMessage={setShowSuccessMessage}
            roleType={roleType}
            componentAccess={state.componentAccess}
            userPendingData={userPendingData}
          />
        )}
      </Box>
    </div>
  );
};

export default withSidebar(ApprovalUser);

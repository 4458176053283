import { useSelector } from "react-redux";
import { hasShowComponent } from "./withBaseValidatorHelper";

export const withERPSLinkActionValidator = (Component) => (props) => {
  const { actionType, componentAccess } = props;
  const user = useSelector((state) => state.loginUserReducer.user);

  if (hasShowComponent({ componentAccess, actionType, user })) {
    return <Component {...props} />;
  } else if (actionType == "hasRead") {
    return <Component {...props} className="disabled-link" />;
  }

  return <Component {...props} className="disabled-link" />;
};

import services from "../services";

export const transactionEnquiryActions = {
  getTransactionEnquiry,
  udpateRecallAndRetrace,
};

function getTransactionEnquiry(values, props) {
  return services.transactionEnquiryService.getTransactionEnquiry(
    values,
    props
  );
}

function udpateRecallAndRetrace(values, props) {
  return services.transactionEnquiryService.udpateRecallAndRetrace(
    values,
    props
  );
}

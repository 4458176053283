import services from "../services";

export const batchHolidayActions = {
  createHoliday,
  getHoliday,
  deleteHoliday,
};

function createHoliday(values, setValues, props) {
  return services.batchHolidayService.createHoliday(
    values,
    setValues,
    props
  );
}

function getHoliday(
  count,
  budgetCode,
  description,
  pageSize,
  offset,
  translation,
  endOffset,
  setInitialLoading
) {
  return services.batchHolidayService.getHoliday(
    count,
    budgetCode,
    description,
    pageSize,
    offset,
    translation,
    endOffset,
    setInitialLoading,
  );
}

function deleteHoliday(values, setValues, props, holidayList) {
  return services.batchHolidayService.deleteHoliday(
    values,
    setValues,
    props,
    holidayList
  );
}

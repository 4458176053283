import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import Typography from "@mui/material/Typography";

import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
const Breadcrumbs = (props) => {
  const {
    location,
    componentAccess,
    pathValue,
    updateSetState,
    stateId,
    routeTo: routeVal,
    handleClickLink,
    fromDetailPage,
    checkRedirect,
  } = props;
  const { pathname } = location;
  const pathnames = pathValue
    ? pathValue.split("/").filter((x) => x)
    : pathname.split("/").filter((x) => x);
  return (
    <div style={{ marginTop: "2rem" }}>
      <MUIBreadcrumbs aria-label="Breadcrumb" separator=">">
        {pathnames.map((name, index) => {
          const last = index === pathnames.length - 1;
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const splitValue = name.split("-");
          const [firstIndexValue, ...rest] = splitValue;
          name = `${upperFirst(firstIndexValue)} ${rest.join(" ")}`;
          return last ? (
            <Typography color="textPrimary" key={routeTo}>
              {name}
            </Typography>
          ) : !checkRedirect ? (
            <ERPSBaseLink
              className="link__blue"
              to={
                updateSetState
                  ? {
                      pathname: routeVal,
                      state: {
                        componentAccess,
                        billerId: stateId,
                        fromDetailPage,
                      },
                    }
                  : {
                      pathname: routeTo,
                      state: { componentAccess, fromDetailPage },
                    }
              }
              label={name}
              onClick={() => {
                if (handleClickLink) {
                  handleClickLink();
                }
              }}
            />
          ) : (
            <ERPSBaseLink
              className="link__blue"
              label={name}
              onClick={() => {
                if (handleClickLink) {
                  handleClickLink();
                }
              }}
            />
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

function upperFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Breadcrumbs;

import services from "../services";

export const loginMessageManagementAction = {
  getLoginMessage,
  createdLoginMessage,
  loginPendingApprovalMessage,
  loginPendingDeclineMessage,
  getLoginQueueMessage,
};

function getLoginMessage(data) {
  return services.loginMessageSevice.getLoginMessage(data);
}
function getLoginQueueMessage(data) {
  return services.loginMessageSevice.getLoginQueueMessage(data);
}

function createdLoginMessage(data) {
  return services.loginMessageSevice.createdLoginMessage(data);
}

function loginPendingApprovalMessage(data) {
  return services.loginMessageSevice.loginPendingApprovalMessage(data);
}

function loginPendingDeclineMessage(data) {
  return services.loginMessageSevice.loginPendingDeclineMessage(data);
}

import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import userActionTypes from "../../actions/types/user.action.type";
import { validateUser } from "./helper";
import pricePlanActionType from "../../actions/types/pricePlan.action.type";
import ErrorMessage from "../custom/containers/ErrorMessage";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import { checkFieldsChange } from "./../../utils/helper";
import Alert from "@mui/material/Alert";

const UpdatePricePlanDetails = (props) => {
  const { t } = useTranslation();
  const {
    location: { state },
    history,
  } = props;
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState([]);
  const [errorHighlights, setErrorHighlights] = useState({});
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [hasShowWarningMessage, setShowWarningMessage] = useState(false);

  const warningRef = useRef();

  const pricePlanUpdatedError = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanUpdationError
  );
  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, pricePlanUpdatedError]);
  const pricePlanList = useSelector(
    (state) => state.pricePlanReducer?.pricePlanList
  );
  const pricePlanUpdatedMessage = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanUpdated
  );

  const data = pricePlanList?.data.find(
    ({ billingModelNumber }) => billingModelNumber === state.id
  );

  const pricePlanDetailsAccess = state?.componentAccess.find(
    ({ componentType }) => componentType === "MUGFV"
  );

  useEffect(() => {
    if (warningRef.current) {
      warningRef.current.focus();
    }
  }, [hasShowWarningMessage]);

  const pricePlaneDetailsChildComponent =
    pricePlanDetailsAccess?.childComponents;

  const {
    billingModelDescription,
    baseFee,
    csvFee,
    notificationFee,
    perDocumentRate,
    maximumDocumentRate,
    minimumDocumentRate,
  } = data;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    billingModelDescription: billingModelDescription,
    baseFee: baseFee.toFixed(2),
    csvFee: csvFee.toFixed(2),
    notificationFee: notificationFee.toFixed(2),
    perDocumentRate: perDocumentRate.toFixed(2),
    maximumDocumentRate: maximumDocumentRate.toFixed(2),
    minimumDocumentRate: minimumDocumentRate.toFixed(2),
    billingModelNumber: state.id,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const updatePricePlan = () => {
    dispatch({
      type: pricePlanActionType.UPDATE_PRICE_PLAN_REQUEST,
      pricePlanUpdationError: null,
      pricePlanUpdated: null,
    });
    const errormsg = validateUser(values);
    setError(errormsg.errorMessage);
    if (errormsg?.errorHighlights) {
      setErrorHighlights(errormsg.errorHighlights);
    }
    if (!errormsg?.errorMessage.length) {
      setLoader(true);
      const oldValues = {
        billingModelDescription,
        baseFee: baseFee?.toFixed(2).toString(),
        csvFee: csvFee?.toFixed(2).toString(),
        notificationFee: notificationFee?.toFixed(2).toString(),
        perDocumentRate: perDocumentRate?.toFixed(2).toString(),
        maximumDocumentRate: maximumDocumentRate?.toFixed(2).toString(),
        minimumDocumentRate: minimumDocumentRate?.toFixed(2).toString(),
        billingModelNumber: state.id,
      };
      const hasFieldsChanged = checkFieldsChange(oldValues, values);
      if (hasFieldsChanged) {
        if (hasShowWarningMessage) {
          setShowWarningMessage(false);
        }
        dispatch(
          actions.pricePlanActions.updatePricePlanDetails(
            values,
            {
              ...props,
              setLoader,
              loader,
              t,
              componentAccess: state?.componentAccess,
            },
            pricePlanList
          )
        );
      } else {
        setLoader(false);
        setShowWarningMessage(true);
      }
    }
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleRedirect = () => {
    history.push("/manage-price-plan", {
      componentAccess: state?.componentAccess,
    });
  };

  const checkExistingPricePlanUpdate = () => {
    if (pricePlanUpdatedMessage === "PP_DUP_ERR_MSG") {
      return t(
        "erps.managePricePlan.pricePlan.duplicate.error.messge",
        "A change for this Price Plan is already pending. Please try later."
      );
    }
  };

  return (
    <div role="main">
      <Box ml={3}>
        <Breadcrumb
          {...props}
          componentAccess={state?.componentAccess}
          fromDetailPage={true}
        />
        {hasShowWarningMessage && (
          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            tabIndex={0}
            ref={warningRef}
          >
            <Alert severity="warning">
              {t("erps.billermanagement.warning.nothinghaschanged")}
            </Alert>
          </Stack>
        )}
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.managePricePlan.label.pricePlan", "Price plan")}
            {`: ${state.id}`}
          </Typography>
        </Box>
        {error.length ||
        pricePlanUpdatedMessage === "PP_DUP_ERR_MSG" ||
        pricePlanUpdatedError ? (
          <Box
            className="manageUsers__errorMsg__container"
            mt={
              error.length ||
              pricePlanUpdatedMessage === "PP_DUP_ERR_MSG" ||
              pricePlanUpdatedError
                ? 2
                : 0
            }
            role="alert"
          >
            {(error.length > 0 ||
              pricePlanUpdatedMessage === "PP_DUP_ERR_MSG" ||
              pricePlanUpdatedError) && (
              <ErrorMessage
                error={
                  checkExistingPricePlanUpdate() ||
                  (error.length && error) ||
                  pricePlanUpdatedError
                }
                errorRef={errorRef}
              />
            )}
          </Box>
        ) : (
          ""
        )}
        <Box component="form" autoComplete="off">
          <Stack direction="row" spacing={20}>
            <Typography variant="label" className="budgetCategory__description">
              {t("erps.managePricePlan.label.pricePlanId")}
              <br /> {state.id}
            </Typography>
            <ERPSBaseTextField
              id="priceplanname"
              type="text"
              label={t(
                "erps.newpriceplan.label.priceplanname",
                "Price plan name"
              )}
              className="three_column__textField"
              onChange={handleChange("billingModelDescription")}
              value={values.billingModelDescription}
              size="small"
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasPricePlanNameTextField"
              error={errorHighlights?.billingModelDescription}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="basefee"
              type="text"
              label={t("erps.newpriceplan.label.basefee")}
              className="three_column__textField"
              size="small"
              value={values.baseFee}
              onChange={handleChange("baseFee")}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasBaseFeeTextField"
              error={errorHighlights?.baseFee}
            />
            <ERPSBaseTextField
              id="csvFee"
              type="text"
              label={t("erps.newpriceplan.label.csvfee")}
              className="three_column__textField"
              size="small"
              value={values.csvFee}
              onChange={handleChange("csvFee")}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasCsvFeeTextField"
              error={errorHighlights?.csvFee}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="notificationFee"
              type="text"
              label={t("erps.newpriceplan.label.notificationfee")}
              className="three_column__textField"
              size="small"
              value={values.notificationFee}
              onChange={handleChange("notificationFee")}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasNotificationFeeTextField"
              error={errorHighlights?.notificationFee}
            />
            <ERPSBaseTextField
              id="perdocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.perdocumentrate")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("perDocumentRate")}
              value={values.perDocumentRate}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasDocumentRateTextField"
              error={errorHighlights?.perDocumentRate}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="minimumDocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.mindocumentrate")}
              className="three_column__textField"
              size="small"
              value={values.minimumDocumentRate}
              onChange={handleChange("minimumDocumentRate")}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasMinDocumentRateTextField"
              error={errorHighlights?.minimumDocumentRate}
            />
            <ERPSBaseTextField
              id="maximumDocumentRate"
              type="text"
              label={t("erps.newpriceplan.label.maxdocumentrate")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("maximumDocumentRate")}
              value={values.maximumDocumentRate}
              required="true"
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasMaxDocumentRateTextField"
              error={errorHighlights?.maximumDocumentRate}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={handleOpenCancelDialog}
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasCancelButton"
            />
            <ERPSBaseButton
              variant="primary"
              onClick={updatePricePlan}
              className="button_with_margin48"
              label={t("erps.newuser.button.save", "Save")}
              loading={loader}
              componentAccess={pricePlaneDetailsChildComponent}
              actionType="hasSaveBudgetCategoryAccess"
            />
          </Stack>
        </Box>
      </Box>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </div>
  );
};

export default withSidebar(UpdatePricePlanDetails);

import axios from "axios";
import billerProfileActionType from "../actions/types/billerProfile.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  customErrorAddBiller,
  API_BASE_URL,
  getBillerGroupErrorMessage,
} from "../utils/helper";
import provinceActionType from "../actions/types/province.action.type";
import deliveryMethodActionType from "../actions/types/deliveryMethod.action.type";
import budgetCategoryActionType from "../actions/types/budgetCategory.action.type";
import loginActionTypes from "../actions/types/login.action.type";

export const billerProfileService = {
  getBillerProfile,
  getCustomRoutineList,
  updateBillerProfile,
  getBillerProfiles,
  getAllProvince,
  getBudgetCategory,
  getAllDeliveryMethod,
  getFormatMethod,
  getBillerProfileWithAssociatedObjects,
  addBillerProfile,
  updateBillerProfileWithAssociatesChildObjects,
  updateBillerProfileForWebPortalUser,
};

function updateBillerProfile(userInfo, setterMethods) {
  return (dispatch) => {
    const {
      setDialogLoading,
      setOpen,
      setSaveLoading,
      t,
      loginFieldsUpdation,
    } = setterMethods;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .put(`${API_BASE_URL()}/biller-profile/update`, userInfo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setOpen) {
          setOpen(false);
        }
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_UPDATE_SUCCESS,
          data: res.data,
        });
        if (loginFieldsUpdation) {
          dispatch({
            type: loginActionTypes.UPDATE_USER_AND_BILLER_NONVOLATILE_DETAILS_SUCCESS,
            data: { ...loginFieldsUpdation },
          });
        }
      })
      .catch((error) => {
        if (setOpen) {
          setOpen(false);
        }
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_UPDATE_ERROR,
          error:
            getErrorMessageFromException(error, t) ||
            JSON.parse(JSON.stringify(error)),
        });
      });
  };
}

function addBillerProfile(billerInfo, props) {
  return (dispatch) => {
    const {
      setSaveLoading,
      setShowSuccessMessage,
      setShowApiExceptionMessage,
      t,
      fiNumber,
      setLineOfbusinessData,
      setInitialDeliveryMethods,
      setDeliveryMethods,
      setHtmlContent,
      setEditorState,
      setCount,
      EditorState,
      setBillerProfile,
      setFinancialInstitutionTableList,
      financialInstitutionList,
      setPreview,
      setSelectedFile,
      setShowApiValidationErrorMessage,
    } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/biller-profile/add`,
        { ...billerInfo },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (setSaveLoading) {
          setSaveLoading(false);
          setShowSuccessMessage(true);
        }
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_CREATE_SUCCESS,
          data: res.data,
        });
        if (getBillerGroupErrorMessage(res?.data)?.length) {
          setShowApiValidationErrorMessage(true);
        } else {
          if (fiNumber == "10" && setLineOfbusinessData) {
            setLineOfbusinessData({
              isImgCIBCTelephoneBankingIVRChecked: "Y",
              isImgCIBCBranchBankingChecked: "Y",
              isImgCIBCTelePhoneBankingDeskChecked: "Y",
              isImgCIBCOnlineBankingChecked: "Y",
              isImgPCFinancialTelephoneBankingIVRChecked: "Y",
              isImgPCFinancialTelePhoneBankingDeskChecked: "Y",
              isImgPCFinancialOnlineBankingChecked: "Y",
              isInstCIBCTelephoneBankingIVRChecked: "Y",
              isInstCIBCBranchBankingChecked: "Y",
              isInstCIBCTelePhoneBankingDeskChecked: "Y",
              isInstCIBCOnlineBankingChecked: "Y",
              isInstPCFinancialTelephoneBankingIVRChecked: "Y",
              isInstPCFinancialTelePhoneBankingDeskChecked: "Y",
              isInstPCFinancialOnlineBankingChecked: "Y",
            });
          }
          if (fiNumber != "10" && setFinancialInstitutionTableList) {
            const updatedFiTable = financialInstitutionList.map(
              ({ fi_number, fi_name }, index) => ({
                fiNumber: fi_number,
                fiName: fi_name,
                billPaymentLimit: 0,
                billerStatus: "I",
                id: index,
              })
            );
            setFinancialInstitutionTableList(updatedFiTable);
          }
          if (fiNumber == 10 && setPreview) {
            setPreview({
              englishPreview: "",
              frenchPreview: "",
            });
          }
          if (fiNumber == 10 && setSelectedFile) {
            setSelectedFile(null);
          }
          if (setInitialDeliveryMethods) {
            setInitialDeliveryMethods({
              deliveryMethod: "",
              distributionListCode: "",
              distributionFormat: "",
              distributionEDIMailbox: "",
              destinationAttention: "",
              distributionPhoneNumber: "",
              distributionExtensionNumber: "",
              distributionGroupRcpIndex: "C",
            });
          }
          if (setDeliveryMethods) {
            setDeliveryMethods([]);
          }
          if (setBillerProfile) {
            setBillerProfile({
              billerLanguageCode: "E",
              billerLiveDate: new Date(),
              ...(fiNumber == "10" && { productType: "APA" }),
              ...(fiNumber == "16" && {
                distributionGroupRcpIndex: "C",
                billerStatus: "I",
              }),
            });
          }
          if (setHtmlContent) {
            setHtmlContent({});
          }
          if (setEditorState && EditorState) {
            setEditorState({
              en: EditorState.createEmpty(),
              fr: EditorState.createEmpty(),
            });
          }
          if (setCount) {
            setCount(1);
          }
        }
      })
      .catch((error) => {
        if (setSaveLoading) {
          setSaveLoading(false);
          setShowApiExceptionMessage(true);
        }
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_CREATE_ERROR,
          error: customErrorAddBiller(error, t),
        });
      });
  };
}

function getBillerProfile(billerId, fiNumber, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          "/biller-profile?billerId=" +
          billerId +
          "&fiNumber=" +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //budgetCategoryData = res.data;
        if (res.data.error) {
          dispatch(failure(res.data.error));
        } else {
          dispatch(success(res.data));
        }
      })
      .catch((error) => {
        const errorResponse =
          getErrorMessageFromException(error, props?.t) || error;
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return { type: billerProfileActionType.GET_BILLER_PROFILE_SUCCESS, data };
    }

    function failure(error) {
      return { type: billerProfileActionType.GET_BILLER_PROFILE_ERROR, error };
    }
  };
}

function getCustomRoutineList(props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(API_BASE_URL() + "/custom-routine/list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch(success(res.data));
        }
      })
      .catch((error) => {
        const errorResponse =
          getErrorMessageFromException(error, props?.t) || error;
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return {
        type: billerProfileActionType.GET_CUSTOM_ROUTINE_LIST_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: billerProfileActionType.GET_CUSTOM_ROUTINE_LIST_ERROR,
        error,
      };
    }
  };
}

function getBillerProfiles(
  count,
  billerId,
  billerName,
  pageSize,
  offset,
  translation,
  setLoading
) {
  return (dispatch) => {
    const selectedFiNumber = store.getState()?.fiReducer.selectedFi;
    const fiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const loggedInUserRoleType =
      store.getState()?.loginUserReducer?.user?.role?.role_type;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/biller-profile/search",
        {
          fi_number: fiNumber,
          selected_fi_number: selectedFiNumber,
          biller_id: billerId,
          biller_name: billerName,
          start_off_set: offset,
          end_off_set: pageSize,
          count: count,
          role_type: loggedInUserRoleType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        dispatch(success(res));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const errorResponse =
          getErrorMessageFromException(error, translation) || error;
        dispatch(failure(errorResponse));
      });

    function success(data) {
      return {
        type: billerProfileActionType.GET_BILLER_PROFILE_LIST_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: billerProfileActionType.GET_BILLER_PROFILE_LIST_ERROR,
        error,
      };
    }
  };
}

function getAllProvince(translation) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const loggedInFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber || loggedInFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(`${API_BASE_URL()}/load-province-Entities?fiNumber=${fiNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        dispatch({
          type: provinceActionType.GET_PROVINCE_LIST_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, translation) ||
          JSON.parse(JSON.stringify(error))?.message;
        dispatch({
          type: provinceActionType.GET_PROVINCE_LIST_ERROR,
          error: parsedError?.message,
        });
      });
  };
}

function getBudgetCategory(translation) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const loggedInFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber || loggedInFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          "/manage-budget-category/fi-budget-category?fiNumber=" +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: budgetCategoryActionType.BUDGET_CATEGORY_ASSOCIATED_FINUMBER_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, translation) ||
          JSON.parse(JSON.stringify(error))?.message;
        dispatch({
          type: budgetCategoryActionType.BUDGET_CATEGORY_ASSOCIATED_FINUMBER_FAILURE,
          error: parsedError?.message,
        });
      });
  };
}

function getAllDeliveryMethod(translation, billerId) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const loggedInFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber || loggedInFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          `/load-delivery-method?fiNumber=${fiNumber}&&billerId=${billerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: deliveryMethodActionType.GET_DELIVERY_METHOD_LIST_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, translation) ||
          JSON.parse(JSON.stringify(error))?.message;
        dispatch({
          type: deliveryMethodActionType.GET_DELIVERY_METHOD_LIST_ERROR,
          error: parsedError?.message,
        });
      });
  };
}

function getFormatMethod(translation, billerId) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const loggedInFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber || loggedInFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          `/load-format-method?fiNumber=${fiNumber}&&billerId=${billerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: deliveryMethodActionType.GET_FORMAT_METHOD_LIST_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError =
          getErrorMessageFromException(error, translation) ||
          JSON.parse(JSON.stringify(error))?.message;
        dispatch({
          type: deliveryMethodActionType.GET_FORMAT_METHOD_LIST_ERROR,
          error: parsedError?.message,
        });
      });
  };
}

function getBillerProfileWithAssociatedObjects(
  billerId,
  props,
  setLoading,
  hasRefreshData
) {
  return (dispatch) => {
    const currentFiNumber = store.getState()?.fiReducer.selectedFi;
    const loggedInFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const fiNumber = currentFiNumber || loggedInFiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        API_BASE_URL() +
          "/biller-profile/view?billerId=" +
          billerId +
          "&fiNumber=" +
          fiNumber,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        if (res.data.error) {
          dispatch(failure(res.data.error));
        } else {
          if (hasRefreshData) {
            dispatch(success(res.data, hasRefreshData));
          } else {
            dispatch(success(res.data));
          }
        }
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const errorResponse =
          getErrorMessageFromException(error, props.t) || error;
        dispatch(failure(errorResponse));
      });

    function success(data, hasRefreshData) {
      return {
        type: billerProfileActionType.GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_SUCCESS,
        data,
        ...(hasRefreshData && {
          billerProfileAssociatedUpdateData: hasRefreshData,
        }),
      };
    }

    function failure(error) {
      return {
        type: billerProfileActionType.GET_BILLER_PROFILE_ASSOCIATED_OBJECTS_ERROR,
        error,
      };
    }
  };
}

function updateBillerProfileWithAssociatesChildObjects(
  billerProfile,
  oldBillerProfile,
  props
) {
  return (dispatch) => {
    const { setSaveLoading, t, setShowApiValidationErrorMessage } = props;

    const user = store.getState()?.loginUserReducer?.user;
    const fiReducer = store.getState()?.fiReducer;
    const fiNumber = fiReducer?.selectedFi || user?.fi?.fi_number;
    const isUpdateAllowed = fiReducer?.financialInstitutionList?.find(
      ({ fi_number }) => fiNumber == fi_number
    )?.is_update_allowed;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .put(
        `${API_BASE_URL()}/biller-profile/update-with-associates-ojects`,
        {
          updatedBillerProfileDto: {
            ...billerProfile,
            createdBy: user.userId,
            updateAllowed: isUpdateAllowed || "Y",
          },
          oldBillerProfileDto: oldBillerProfile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        props.setShowSuccessMessage(true);
        if (getBillerGroupErrorMessage(res?.data)?.length) {
          setShowApiValidationErrorMessage(true);
        }
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_SUCCESS,
          data: res.data,
        });
        dispatch(
          getBillerProfileWithAssociatedObjects(
            billerProfile?.billerId,
            t,
            props?.handleTabLoading,
            res.data
          )
        );
        dispatch(getAllDeliveryMethod(t, billerProfile?.billerId));
      })
      .catch((error) => {
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        props.setShowSuccessMessage(false);
        props.setShowApiExceptionMessage(true);
        const errMessage = getExceptionMessage(error, props.t);
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_ERROR,
          error: errMessage,
        });
      });
  };
}

function updateBillerProfileForWebPortalUser(billerProfile, props) {
  return (dispatch) => {
    const { setSaveLoading, t } = props;

    const user = store.getState()?.loginUserReducer?.user;
    const fiReducer = store.getState()?.fiReducer;
    const fiNumber = fiReducer?.selectedFi || user?.fi?.fi_number;
    const isUpdateAllowed = fiReducer?.financialInstitutionList?.find(
      ({ fi_number }) => fiNumber == fi_number
    )?.is_update_allowed;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/biller-profile/webportal/update-with-associates-ojects`,
        {
          ...billerProfile,
          updateAllowed: isUpdateAllowed || "Y",
          role_type: user?.role?.role_type,
          updated_by: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        props.setShowSuccessMessage(true);
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_SUCCESS,
          data: res.data,
        });
        dispatch(
          getBillerProfileWithAssociatedObjects(
            billerProfile?.biller_id,
            t,
            props?.handleTabLoading,
            res.data
          )
        );
      })
      .catch((error) => {
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        props.setShowSuccessMessage(false);
        const errors = [];
        const errMessage =
          getErrorMessageFromException(error, props.t) || error.response.data;
        errors?.push(errMessage);
        props?.setValidationErrors(errors);
        dispatch({
          type: billerProfileActionType.BILLER_PROFILE_ASSOCIATES_OBJECTS_UPDATE_ERROR,
          error: errMessage,
        });
      });
  };
}

import { loginActions } from "./login.actions";
import { budgetCategoryActions } from "./budgetCategory.actions";
import { userActions } from "./user.actions";
import { passwordActions } from "./password.actions";
import { userPendingApprovalsActions } from "./userPendingApprovals.actions";
import { pricePlanPendingApprovalsActions } from "./pricePlanPendingApprovals.actions";
import { pricePlanActions } from "./pricePlan.actions";
import { availableDocumentActions } from "./availableDocument.action";
import { fiActions } from "./fi.actions";
import { appConfigActions } from "./app.config.action";
import { billerProfileActions } from "./billerProfile.action";
import { billerPendingApprovalsActions } from "./billerPendingApprovals.actions";
import { viewStatisticsActions } from "./viewStatistics.actions";
import { transactionEnquiryActions } from "./transactionEnquiry.actions";
import { batchHolidayActions } from "./batchHoliday.action";
import { synchronizationActions } from "./synchronization.action";
import { faxManagementActions } from "./faxManagement.actions";

const actions = {
  loginActions,
  budgetCategoryActions,
  batchHolidayActions,
  userActions,
  passwordActions,
  userPendingApprovalsActions,
  pricePlanActions,
  availableDocumentActions,
  pricePlanPendingApprovalsActions,
  fiActions,
  appConfigActions,
  billerProfileActions,
  billerPendingApprovalsActions,
  transactionEnquiryActions,
  viewStatisticsActions,
  synchronizationActions,
  faxManagementActions,
};

export default actions;

import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Card, CardContent, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Stack from "@mui/material/Stack";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import warningIcon from "../../assets/images/warning-icon.png";
import loginActionTypes from "../../actions/types/login.action.type";
import parse from "html-react-parser";
import Tooltip from "@mui/material/Tooltip";
import { loginMessageManagementAction } from "../../actions/loginMessage.action";
import { validateURL } from "../../utils/helper";
// import '../../utils/keycloak.service.js';
// import '../../utils/keycloak.js';
import UserService from '../../utils/keycloak.service.js';
import Footer from "../Footer/Footer";

export default function SignIn(props) {
  const dispatch = useDispatch();
  const errorRef = useRef();
  let currentUrl = window.location.href;
  const currentFI = currentUrl.split("/")[2].split(".")[0];
  const hasAdminSiteUrl = /admin/.test(currentFI);
  const getDashboardMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardMessage
  );
  const error = useSelector((state) => state.loginUserReducer?.error);
  const statusCode = useSelector(
    (state) => state.loginUserReducer?.user?.statusCode
  );
  const isLoggedIn = useSelector(
    (state) => state.loginUserReducer
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function initKc() {
      await UserService.initKeycloak(props);
    }
    initKc();
  }, [])

  // useEffect(() => {
  //   if(isLoggedIn.token || window.location.href.includes("session_state")) {
  //     console.log("======")
  //   } else {
  //     UserService.initKeycloak();
  //     setTimeout(() => {
  //       UserService.doLogin();
  //     }, 1000);
  //   }
  // }, [])

  useEffect(() => {
    if (error) {
      dispatch({ type: loginActionTypes.AUTHENTICATION_FAIL, error: null });
    }
  }, []);
  const [values, setValues] = useState({
    userID: "",
    password: "",
    showPassword: false,
    language: "",
    isConfirmed: false,
    passwordHighlight: false,
    userIDHighlight: false,
  });
  const [messagesList, setMessagesList] = useState([
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.french_label"),
      },
      messageId: 1,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.french_label"),
      },
      messageId: 2,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.french_label"),
      },
      messageId: 3,
    },
  ]);
  const [loginPending, setLoginPending] = React.useState(false);
  const [languageCode, setLanguageCode] = React.useState(i18n.language);

  useEffect(() => {
    if (!hasAdminSiteUrl) {
      dispatch(
        loginMessageManagementAction.getLoginMessage({
          t,
        })
      );
    }
  }, []);
  // if (isLoggedIn.token || window.location.href.includes("session_state")) {
  //   UserService.initKeycloak();
  // }

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     // Note - API causing 401 error so commented for now
  //     // dispatch(actions.loginActions.logout({ ...props, t }));
  //     dispatch({ type: loginActionTypes.LOGOUT_SUCCESS });
  //     localStorage.removeItem("persist:erps");
  //   }
  // }, []);

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (
      getDashboardMessage &&
      Array.isArray(getDashboardMessage) &&
      getDashboardMessage.length
    ) {
      let dataV1 = [];
      getDashboardMessage?.forEach((data) => {
        dataV1.push({
          en: {
            header: data?.messageHeaderEnglish,
            message: data?.messageTextEnglish,
            urlText: data?.messageLinkTextEnglish,
            urlLink: data?.messageUrlEnglish,
            type: t("erps.header.language.english_label"),
          },
          fr: {
            header: data?.messageHeaderFrench,
            message: data?.messageTextFrench,
            urlText: data?.messageLinkTextFrench,
            urlLink: data?.messageUrlFrench,
            type: t("erps.header.language.french_label"),
          },
          messageId: data?.id,
        });
      });
      setMessagesList(dataV1);
    }
  }, [getDashboardMessage]);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  const loginKeycloak = () => {
      UserService.doLogin();
  }
  
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const logon = () => {
    let obj = { userIDHighlight: false, passwordHighlight: false };
    if (!values.userID) {
      obj = { ...obj, userIDHighlight: true };
    }
    if (!values.password) {
      obj = { ...obj, passwordHighlight: true };
    }

    dispatch({ type: loginActionTypes.AUTHENTICATION_FAIL, error: null });

    setLoginPending(true);

    dispatch(
      actions.loginActions.login(values.userID, values.password, {
        ...props,
        setLoginPending,
        loginPending,
        t,
      })
    );
    setValues((pre) => ({ ...pre, ...obj }));
  };

  const getErrorMessage = (error) => {
    if (error) {
      if (typeof error === "string") {
        return error;
      } else if (typeof error === "object") {
        if (error?.error_description) {
          return error.error_description;
        }
      }
    }
  };
  const getCardComponent = (language) => {
    const messagesContent = messagesList.map((data) => data[language]);
    return (
      <>
        <Card className="messageCenter__card__container">
          <CardContent>
            <Typography
              variant="h2"
              className="messageCenter__card__content__header"
            >
              {t("erps.login.message.card.title", "Messages")}
            </Typography>
            {messagesContent.map(
              ({ header, message, urlText, urlLink }, idx) => (
                <Box mt={2}>
                  {header && (
                    <Typography
                      variant="h3"
                      className="messageCenter__card__content__heading"
                    >
                      {header}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className="signIn__card__content__header"
                  >
                    {message ? parse(message) : ""}
                    {message && <br />}
                    <ERPSBaseLink
                      label={urlText}
                      to={{
                        pathname: !validateURL(urlLink)
                          ? `https://${urlLink}`
                          : urlLink,
                      }}
                      target={urlLink ? "_blank" : ""}
                      variant="link"
                      className="signIn__card__content__link"
                    />
                  </Typography>
                </Box>
              )
            )}
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <Header {...props} />
      <CommonContainerBox
        title={t("erps.login.pagetitle.message", "Log in")}
        marginTop="150px"
        padding="0px 24px 0px 24px"
        minHeight="calc(100vh - 280px)"
      >
        <Grid item lg={4} sm={8} role="main">
          <Box className="signIn__container__header">
            {/* <Typography variant="h1">
              {t("erps.login.formtitle.message", "Log in")}
            </Typography> */}
          </Box>

          {/* <Stack direction="column">
            <>
              {error && (
                <Box
                  className="manageUsers__errorMsg__container"
                  role="alert"
                  mt={error ? 4 : 0}
                >
                  {error && (
                    <Box
                      tabIndex={0}
                      ref={errorRef}
                      style={{ display: "flex" }}
                    >
                      <Icon
                        className="manageUsers__error__icon"
                        aria-hidden={false}
                      >
                        <img src={warningIcon} alt="Error notification" />
                      </Icon>
                      <div style={{ marginTop: "5px", paddingLeft: "12px" }}>
                        {parse(parse(getErrorMessage(error)))}
                      </div>
                    </Box>
                  )}
                </Box>
              )}
              <ERPSBaseTextField
                id="userID"
                error={
                  values.userIDHighlight || Boolean(error) || statusCode == "I"
                }
                type="text"
                value={values.userID}
                label={t("erps.login.formcontrol.userid.label", "User ID")}
                onChange={handleChange("userID")}
                className="common__textField__width"
                required="true"
                size="small"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    logon();
                  }
                }}
              />

              <ERPSBaseTextField
                id="outlined-password-toggle-visibility"
                error={
                  values.passwordHighlight ||
                  Boolean(error) ||
                  statusCode == "I"
                }
                required="true"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                className="common__textField__width"
                label={t("erps.login.formcontrol.password.label", "Password")}
                onChange={handleChange("password")}
                size="small"
                inputProps={{
                  endAdornment: (
                    <Tooltip
                      title={
                        values.showPassword
                          ? t("erps.login.tooltip.showpassword")
                          : t("erps.login.tooltip.hidepassword")
                      }
                    >
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            values.showPassword
                              ? t("erps.login.tooltip.showpassword")
                              : t("erps.login.tooltip.hidepassword")
                          }
                          aria-pressed={values.showPassword ? "true" : "false"}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </Tooltip>
                  ),
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    logon();
                  }
                }}
              />
            </>
          </Stack> */}
          <Box>
            {UserService.isLoggedIn() && (
              <>
                {" "}
                <Typography variant="h3">
                  Successfully logged in
                </Typography>{" "}
                <ERPSBaseLink
                  to="/"
                  className="header__link__active"
                  onClick={() => UserService.doLogout()}
                  label={t("erps.header.link.logout")}
                />
              </>
            ) }
            {/* <Box mt={6}>
              <ERPSBaseLink
                to="/forgot-password"
                className="link__blue"
                label={t(
                  "erps.login.forgotpassword.link",
                  "I forgot my password"
                )}
              />
            </Box> */}
          </Box>
        </Grid>
        {/* <Grid item lg={10} sm={8}> */}
        {!hasAdminSiteUrl && (
          <Grid item lg={4} sm={12} role="complementary">
            {getDashboardMessage && getDashboardMessage.length
              ? getCardComponent(languageCode)
              : ""}
          </Grid>
        )}
      </CommonContainerBox>
      <Footer />
    </>
  );
}

import { t } from "i18next";

export const STATIC = "S";
export const DYNAMIC = "D";

export const instructTypeList = [
  {
    label: t("erps.billermanagement.label.staticText"),
    value: STATIC,
  },
  {
    label: t("erps.billermanagement.label.dynamicText"),
    value: DYNAMIC,
  },
];

export const findInstructType = (value) => {
  return instructTypeList.find((instructType) => instructType.value == value);
};

let iLangCD = 0;
let sWithAlpha = [" and/or letters", "  et/ou lettres"];
let sCanNotStart = [" cannot start", " ne peut débuter"];
let sStarts = [" starts", " doit débuter"];
let sCanNotEnd = [" cannot end", " ne peut se terminer"];
let sEnds = [" ends", " doit se terminer"];
let sL01billername = ["<B>|BILLER_NAME|</B>", "<B>|BILLER_NAME|</B>"];
let sL02fixlen = [
  "Your account number must be <B>|MIN_LENGTH|</B> numbers|AND_LETTERS| long and not contain any spaces.",
  "Votre numéro de compte doit être <B>|MIN_LENGTH|</B> chiffres|AND_LETTERS| long et ne peut contenir aucun espace.",
];
let sL02varlen = [
  "Your account number must be <B>|MIN_LENGTH| to |MAX_LENGTH|</B> numbers|AND_LETTERS| long and not contain any spaces.",
  "Votre numéro de compte doit être <B>|MIN_LENGTH| à |MAX_LENGTH|</B> chiffres|AND_LETTERS| long et ne peut contenir aucun espace.",
];
let sL03prefixonly = [
  "It|CAN_NOT_START| with |PREFIX_LIST|.",
  "Il|CAN_NOT_START|  par |PREFIX_LIST|.",
];
let sL03suffixonly = [
  "It|CAN_NOT_END| with |SUFFIX_LIST|.",
  "Il|CAN_NOT_END| par |SUFFIX_LIST|.",
];
let sL03prefixsuffix = [
  "It|CAN_NOT_START| with |PREFIX_LIST| and|CAN_NOT_END| with |SUFFIX_LIST|.",
  "Il|CAN_NOT_START| par |PREFIX_LIST| et|CAN_NOT_END| par |SUFFIX_LIST|.",
];
let sLastLine = [
  "If you are unable to validate your account, please contact <B>|BILLER_NAME|</B> customer service for assistance.",
  "Si vous ne parvenez pas à valider votre compte, veuillez contacter le service à la clientèle de <B>|BILLER_NAME|</B> pour recevoir une aide supplémentaire.",
];
let sOrWord = [" or ", " ou "];

export const getInstructionText = (data, languageCode) => {
  iLangCD = languageCode === 'fr' ? 1 : 0
  let sInsTxt = "";
  sInsTxt =
    "<P>" + sL01billername[iLangCD].replace("|BILLER_NAME|", data.billerName);
  if (data.billerCustomerNumberMinimum == data.billerCustomerNumberMaximum) {
    sInsTxt =
      sInsTxt +
      "<BR><BR>" +
      sL02fixlen[iLangCD].replace(
        "|MIN_LENGTH|",
        data.billerCustomerNumberMinimum || "0"
      );
  } else {
    sInsTxt =
      sInsTxt +
      "<BR><BR>" +
      sL02varlen[iLangCD]
        .replace("|MIN_LENGTH|", data.billerCustomerNumberMinimum || 0)
        .replace("|MAX_LENGTH|", data.billerCustomerNumberMaximum || 0);
  }

  if (data.alphaAllowed === "Y") {
    sInsTxt = sInsTxt.replace("|AND_LETTERS|", sWithAlpha[iLangCD]);
  } else {
    sInsTxt = sInsTxt.replace("|AND_LETTERS|", "");
  }

  let prefixList = data.prefixText;
  if (prefixList) {
    let iMoreThan1P = prefixList.indexOf(",");
    let iLastPosCommaP = prefixList.lastIndexOf(",");
    if (iMoreThan1P > 0)
      prefixList =
        prefixList.substring(0, iLastPosCommaP) +
        sOrWord[iLangCD] +
        prefixList.substring(iLastPosCommaP + 1);
  }

  let suffixList = data.suffixText;
  if (suffixList) {
    let iMoreThan1S = suffixList.indexOf(",");
    let iLastPosCommaS = suffixList.lastIndexOf(",");
    if (iMoreThan1S > 0)
      suffixList =
        suffixList.substring(0, iLastPosCommaS) +
        sOrWord[iLangCD] +
        suffixList.substring(iLastPosCommaS + 1);
  }

  if (data.prefixText && !data.suffixText) {
    if (data.prefixCheck == "Y") {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03prefixonly[iLangCD].replace(
          "|CAN_NOT_START|",
          sCanNotStart[iLangCD]
        );
    } else {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03prefixonly[iLangCD].replace("|CAN_NOT_START|", sStarts[iLangCD]);
    }
    sInsTxt = sInsTxt.replace("|PREFIX_LIST|", prefixList);
  } else if (!data.prefixText && data.suffixText) {
    if (data.suffixCheck == "Y") {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03suffixonly[iLangCD].replace("|CAN_NOT_END|", sCanNotEnd[iLangCD]);
    } else {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03suffixonly[iLangCD].replace("|CAN_NOT_END|", sEnds[iLangCD]);
    }
    sInsTxt = sInsTxt.replace("|SUFFIX_LIST|", suffixList);
  } else if (data.prefixText && data.suffixText) {
    if (data.prefixCheck == "Y") {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03prefixsuffix[iLangCD].replace(
          "|CAN_NOT_START|",
          sCanNotStart[iLangCD]
        );
    } else {
      sInsTxt =
        sInsTxt +
        "<BR><BR>" +
        sL03prefixsuffix[iLangCD].replace("|CAN_NOT_START|", sStarts[iLangCD]);
    }
    if (data.suffixCheck == "Y") {
      sInsTxt = sInsTxt.replace("|CAN_NOT_END|", sCanNotEnd[iLangCD]);
    } else {
      sInsTxt = sInsTxt.replace("|CAN_NOT_END|", sEnds[iLangCD]);
    }
    sInsTxt = sInsTxt
      .replace("|PREFIX_LIST|", prefixList)
      .replace("|SUFFIX_LIST|", suffixList);
  }

  sInsTxt =
    sInsTxt +
    "<BR><BR>" +
    sLastLine[iLangCD].replace("|BILLER_NAME|", data.billerName) +
    "</P>";
  return sInsTxt;
};

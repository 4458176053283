import pricePlanPendingApprovalActionType from "../actions/types/pricePlanPendingApproval.action.type";
import store from "../store/index";
import axios from "axios";
import { API_BASE_URL, getExceptionMessage } from "../utils/helper";

export const pricePlanPendingApprovalService = {
  getPendingPricePlanApprovals,
  updatePricePlanApprovalData,
  createPricePlanApprovalData,
  declinePricePlanApprovalData,
};
function getPendingPricePlanApprovals(
  data,
  setLoading,
  translation,
  setPricePendingApprovalData
) {
  return (dispatch) => {
    const userFiNumber = store.getState()?.loginUserReducer?.user?.fiNumber;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/price-plan-pending-approvals/list",
        {
          ...data,
          loggedInUserFiNumber: userFiNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        if (setPricePendingApprovalData && parsedData.queueDataList) {
          setPricePendingApprovalData(parsedData.queueDataList);
        }
        dispatch(success(parsedData));
      })
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        const parsedError = getExceptionMessage(error, translation);

        dispatch(failure(parsedError));
      });
  };

  function success(user) {
    return {
      type: pricePlanPendingApprovalActionType.GET_PENDING_PRICE_PLAN_APPROVAL_SUCCESS,
      data: user,
    };
  }

  function failure(error) {
    return {
      type: pricePlanPendingApprovalActionType.GET_PENDING_PRICE_PLAN_APPROVAL_FAILURE,
      error,
    };
  }
}

function updatePricePlanApprovalData(data, props) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const languageCode = loginUserDetail?.user?.languageCode;
    const loggedInUserId = loginUserDetail?.user?.userId;
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/price-plan-pending-approvals/approve-update-plan",
        {
          ...data,
          languageCode,
          hasLastRecord,
          loggedInUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setUpdateLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(success(parsedData, props));
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props.t);
        props.setUpdateLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: pricePlanPendingApprovalActionType.UPDATE_PRICE_PLAN_APPROVAL_SUCCESS,
      data: user,
      nextPricePlanId: props?.nextPricePlanId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: pricePlanPendingApprovalActionType.UPDATE_PRICE_PLAN_APPROVAL_FAILURE,
      error,
    };
  }
}

function createPricePlanApprovalData(data, props) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const languageCode = loginUserDetail.user.languageCode;
    const loggedInUserId = loginUserDetail?.user?.userId;
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/price-plan-pending-approvals/approve-new-plan",
        {
          ...data,
          languageCode,
          hasLastRecord,
          loggedInUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(success(parsedData, props));
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props.t);
        props.setSaveDeleteLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: pricePlanPendingApprovalActionType.CREATE_PRICE_PLAN_APPROVAL_SUCCESS,
      data: user,
      nextPricePlanId: props?.nextPricePlanId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: pricePlanPendingApprovalActionType.CREATE_PRICE_PLAN_APPROVAL_FAILURE,
      error,
    };
  }
}

function declinePricePlanApprovalData(data, props) {
  return (dispatch) => {
    const loginUserDetail = store.getState()?.loginUserReducer;
    const languageCode = loginUserDetail.user.languageCode;
    const { hasLastRecord, setLastRecordDisable } = props;
    const token = store.getState()?.loginUserReducer.token;
    const loggedInUserId = loginUserDetail?.user?.userId;
    axios
      .post(
        API_BASE_URL() + "/price-plan-pending-approvals/decline-pending-plan",
        {
          ...data,
          languageCode,
          hasLastRecord,
          loggedInUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const parsedData = JSON.parse(JSON.stringify(res)).data;
        props.setDeclineLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(success(parsedData, props));
        if (hasLastRecord && setLastRecordDisable) {
          setLastRecordDisable(hasLastRecord);
        }
      })
      .catch((error) => {
        const parsedError = getExceptionMessage(error, props.t);
        props.setDeclineLoading(false);
        props.setShowSuccessMessage(true);
        dispatch(failure(parsedError));
      });
  };

  function success(user, props) {
    return {
      type: pricePlanPendingApprovalActionType.UPDATE_PRICE_PLAN_APPROVAL_SUCCESS,
      data: user,
      nextPricePlanId: props?.nextPricePlanId,
      queueDataList: props?.queueDataList,
    };
  }

  function failure(error) {
    return {
      type: pricePlanPendingApprovalActionType.UPDATE_PRICE_PLAN_APPROVAL_FAILURE,
      error,
    };
  }
}

import loginActionTypes from "../actions/types/login.action.type";
import userActionTypes from "../actions/types/user.action.type";

export const loginUserInitialState = {
  isAuthorized: false,
  user: null,
  error: null,
  token: null,
  message: null,
};

export default function loginUserReducer(
  state = loginUserInitialState,
  action
) {
  switch (action.type) {
    case loginActionTypes.AUTHENTICATION_REQUEST:
      return { isAuthorized: false, user: null, token: null, error: null };
    case loginActionTypes.AUTHENTICATION_SUCCESS_KC:
      return { isAuthorized: false, user: null, token: action.data, error: null };
    case loginActionTypes.AUTHENTICATION_SUCCESS:
      return {
        isAuthorized: true,
        user: action.data.data,
        token: action?.data?.token,
        error: null,
      };
    case loginActionTypes.AUTHENTICATION_FAIL:
      return {
        isAuthorized: false,
        error: action.error,
        user: null,
        token: null,
      };
    case loginActionTypes.LOGOUT_REQUEST:
      return { isAuthorized: false, user: null, error: null };
    case loginActionTypes.LOGOUT_SUCCESS:
      const errorMessage = action?.message || null;
      return {
        isAuthorized: false,
        user: null,
        error: errorMessage,
        token: null,
      };
    case loginActionTypes.LOGOUT_FAIL:
      return { isAuthorized: false, user: null, error: action.error };
    case userActionTypes.USER_UPDATE_SUCCESS:
      return {
        isAuthorized: true,
        user: action.data.data,
        error: action.data.error,
        token: action?.data?.token,
        message: action.data.message,
      };
    case userActionTypes.USER_UPDATE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case userActionTypes.BILLER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        user: action.data,
      };
    case loginActionTypes.UPDATE_USER_ROLE_SUCCESS:
      return {
        isAuthorized: state.isAuthorized,
        user: {
          ...state.user,
          role: action.data,
        },
        token: state?.token,
        message: state.message,
        error: action.error,
      };
    case loginActionTypes.UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        error: null,
        user: action.data,
      };

    case loginActionTypes.UPDATE_PASSWORD_INDEX_SUCCESS:
      return {
        isAuthorized: state.isAuthorized,
        user: {
          ...state.user,
          changePasswordIndex: action.data.changePasswordIndex,
        },
        token: state?.token,
        message: state.message,
        error: action.error,
      };

    case loginActionTypes.UPDATE_USER_AND_BILLER_NONVOLATILE_DETAILS_SUCCESS:
      return {
        isAuthorized: state.isAuthorized,
        user: action.data,
        token: state?.token,
        message: state.message,
        error: action.error,
      };

    default:
      return state;
  }
}

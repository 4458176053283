import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

import ClearIcon from "@mui/icons-material/Clear";
import parse from "html-react-parser";

const ConfirmationMessage = (props) => {
  const { t } = useTranslation();
  const {
    message,
    setShowMessage,
    inputRef,
    handleClose: handleCloseV2,
    ariaText,
  } = props;
  const handleClose = () => {
    if (setShowMessage) {
      setShowMessage(false);
    }
    if (handleCloseV2) {
      handleCloseV2();
    }
  };
  const labelToShow = ariaText || t("erps.confirmation.message.close.icon");
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      alignContent={"center"}
      alignItems="center"
      className="confirmation__message"
    >
      <Box tabIndex={0} ref={inputRef}>
        <Typography
          variant="label"
          className="app_confirmation_success_message"
        >
          {parse(message)}
        </Typography>
      </Box>
      <Tooltip title={labelToShow}>
        <IconButton aria-label={labelToShow} onClick={handleClose}>
          <ClearIcon className="confirmation__message__close__icon" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ConfirmationMessage;

import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Tab, Tabs, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import TabPanel from "../custom/containers/TabPanel";
import ChangePassword from "../User/ChangePassword";
import UserProfile from "../User/UserProfile";
import Breadcrumbs from "../Breadcrumb/Breadcrumb";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import GeneratePassword from "../User/GeneratePassword";
import passwordActionTypes from "../../actions/types/password.action.type";
import ERPSBaseTab from "../custom/inputs/ERPSBaseTab";

export default function ManageBillerUserProfile(props) {
  const { t } = useTranslation();
  const {
    location: { state },
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const userResult = useSelector((state) => state.userDetailReducer.userData);
  const user = useSelector((state) => state.loginUserReducer.user);

  const response = useSelector((state) => state.changePasswordReducer.user);
  const userFromUpdatePassword = useSelector(
    (state) => state.userPasswordReducer?.user
  );
  const mailStatusText = useSelector(
    (state) => state.forgetPasswordReducer.data?.statusText
  );
  const errorMessage = useSelector(
    (state) => state.forgetPasswordReducer.data?.errorMessage
  );
  const exceptionErrorMessage = useSelector(
    (state) => state.forgetPasswordReducer.error
  );
  const [showMessageForChangePassword, setShowMessageForChangePassword] =
    useState(false);
  const [showMessageForForgetPassword, setShowMessageForForgetPassword] =
    useState(false);
  const dispatch = useDispatch();

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [
    response?.isSaved && showMessageForChangePassword,
    mailStatusText && showMessageForForgetPassword,
    userFromUpdatePassword?.isSave && showMessageForChangePassword,
  ]);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      if (errorMessage || exceptionErrorMessage) {
        dispatch({
          type: passwordActionTypes.GENRATE_PASSWORD_SUCCESS,
          error: null,
          data: null,
        });
      }
      dispatch({
        type: passwordActionTypes.CHILD_USER_PWD_UPDATE_ERROR,
        errors: null,
      });
    }
  };

  const getUserData = () => {
    let data = null;
    if (props.pageName === "account-settings") {
      data = user;
    } else {
      data = userResult
        ? userResult.find(
            ({ userId, internalBMOUserId }) =>
              internalBMOUserId === props.userId || userId === props.userId
          )
        : {};
    }
    return data;
  };

  const billerUserComponentAccess = props?.componentAccess.find(
    ({ componentType }) => componentType === "BMUPV"
  );

  const billerUserProfileComponentChildAccess =
    billerUserComponentAccess?.childComponents;

  function a11yProps(index) {
    return {
      id: `user-tab-${index}`,
      "aria-controls": `user-tabpanel-${index}`,
    };
  }

  const userData = getUserData();

  return (
    <div>
      {(response?.isSaved || userFromUpdatePassword?.isSave) &&
        showMessageForChangePassword && (
          <Box>
            <ConfirmationMessage
              message={response?.message || userFromUpdatePassword?.message}
              setShowMessage={setShowMessageForChangePassword}
              inputRef={successRef}
            />
          </Box>
        )}
      {mailStatusText && showMessageForForgetPassword && (
        <Box>
          <ConfirmationMessage
            message={mailStatusText}
            setShowMessage={setShowMessageForForgetPassword}
            inputRef={successRef}
          />
        </Box>
      )}
      <Box ml={3}>
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.userprofile.label.userid", "Profile for user ID")} :
            {props.userId}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginTop: "1.5rem",
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="user-tabs"
            >
              <ERPSBaseTab
                label={t("erps.userdetails.tab1.name", "Profile")}
                {...a11yProps(0)}
                setTabIndex={setTabIndex}
                componentAccess={billerUserProfileComponentChildAccess}
                actionType="hasBUserProfileTabAccess"
              />
              <ERPSBaseTab
                label={t("erps.userdetails.tab2.name", "Change password")}
                {...a11yProps(1)}
                setTabIndex={setTabIndex}
                componentAccess={billerUserProfileComponentChildAccess}
                actionType="hasBUserPwdTabAccess"
                disabled={!userData?.emailAddress}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={tabIndex}
            index={0}
            componentAccess={billerUserProfileComponentChildAccess}
            actionType="hasBUserProfileTabAccess"
            tabId={"biller_user_profile_tab_1"}
          >
            <UserProfile
              {...props}
              profileId={props.userId}
              pageName={props.pageName}
              handleClickLink={props.handleClickLink}
              personalUser={false}
              componentAccess={billerUserProfileComponentChildAccess}
              parentComponentAccess={props?.componentAccess}
              keycloakUserStatus={props?.keycloakUserStatus}
            />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={1}
            componentAccess={billerUserProfileComponentChildAccess}
            actionType="hasBUserPwdTabAccess"
            tabId={"biller_user_profile_tab_2"}
          >
            {userData?.emailAddress ? (
              <GeneratePassword
                userData={userData}
                handleClickLink={props.handleClickLink}
                {...props}
                pageName={props.pageName}
                setShowMessage={setShowMessageForForgetPassword}
              />
            ) : (
              <ChangePassword
                {...props}
                userId={props.userId}
                subTitle={true}
                pageName="manage-biller"
                handleTabChange={handleTabChange}
                setShowMessage={setShowMessageForChangePassword}
              />
            )}
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
}

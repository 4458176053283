const loginActionTypes = {
  AUTHENTICATION_SUCCESS: "AUTHENTICATION_SUCCESS",
  AUTHENTICATION_SUCCESS_KC: "AUTHENTICATION_SUCCESS_KC",
  AUTHENTICATION_FAIL: "AUTHENTICATION_FAIL",
  AUTHENTICATION_REQUEST: "AUTHENTICATION_REQUEST",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAIL: "LOGOUT_FAIL",
  UPDATE_SERVICE_AGREEMENT_SUCCESS: "UPDATE_SERVICE_AGREEMENT_SUCCESS",
  UPDATE_SERVICE_AGREEMENT_ERROR: "UPDATE_SERVICE_AGREEMENT_ERROR",
  UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_ERROR: "UPDATE_USER_ROLE_ERROR",
  UPDATE_PASSWORD_INDEX_SUCCESS: "UPDATE_PASSWORD_INDEX_SUCCESS",
  UPDATE_USER_AND_BILLER_NONVOLATILE_DETAILS_SUCCESS:
    "UPDATE_USER_AND_BILLER_NONVOLATILE_DETAILS_SUCCESS",
};

export default loginActionTypes;

import { ERROR_NEW_PRICE_PLAN } from "../../constants/ManagePricePlanConstant";
import { t } from "i18next";

const checkAlphNumericValue = (val) => {
  const alphaNumericRegEx = /^[0-9A-Za-z()-_\s]+$/;
  if (!val) {
    return ERROR_NEW_PRICE_PLAN.pricePlanNameBlankError;
  } else if (
    val.trim().length < 6 ||
    val.trim().length > 20 ||
    !alphaNumericRegEx.test(val)
  ) {
    return ERROR_NEW_PRICE_PLAN.pricePlanNameLengthAndAlphaNumericError;
  }
};
const checkNumericAndDecimalValue = (val, key, values) => {
  const decimalRegEx = /^\d{1,7}(?:\.\d{0,2})?$/;
  const trimmedValue = val;
  if (val === "" || val === undefined) {
    return `${key} - ${t("erps.managePricePlan.error.fieldisrequired")}`;
  } else if (!decimalRegEx.test(val)) {
    return `${key} - ${t("erps.managePricePlan.error.maxsize7plus2")}`;
  }

  if (
    key === "Maximum document rate" &&
    parseFloat(val) < parseFloat(values?.minimumDocumentRate)
  ) {
    return `"${key}" ${t(
      "erps.managePricePlan.error.equalorgreaterdocumentrate"
    )}`;
  }
};

const splitCamelCaseToString = (s) => {
  return s
    .split(/(?=[A-Z])/)
    .map(function (p, i) {
      if (i === 0) {
        return p.charAt(0).toUpperCase() + p.slice(1);
      }
      return p.charAt(0).toLowerCase() + p.slice(1);
    })
    .join(" ");
};

const getErrorMessage = (k, values) => {
  if (k === "billingModelDescription") {
    return checkAlphNumericValue(values.billingModelDescription);
  } else if (k === "csvFee") {
    return checkNumericAndDecimalValue(values.csvFee, "CSV fee");
  } else {
    return checkNumericAndDecimalValue(
      values[k],
      splitCamelCaseToString(k),
      values
    );
  }
};

export function validateUser(values) {
  const errormsg = [];
  const errorHighlights = Object.keys(values).reduce((pv, cv) => {
    return { ...pv, [cv]: false };
  }, {});
  for (let k in values) {
    const errorMessage = getErrorMessage(k, values);
    if (errorMessage) {
      errormsg.push(errorMessage);
      errorHighlights[k] = true;
    }
  }

  const filteredErrorMessage = errormsg.filter((msg) => msg);
  return { errorMessage: filteredErrorMessage, errorHighlights };
}

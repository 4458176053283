import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import DeliveryMethodComponent from "./DeliveryMethod";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import i18next from "i18next";

import ErrorMessage from "../custom/containers/ErrorMessage";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useCustomDropDownHook } from "./useCustomDropDownHook";
import actions from "../../actions";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import { useDispatch, useSelector } from "react-redux";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import CircularProgress from "@mui/material/CircularProgress";

import { getNumericValue } from "./../../helpers/biller";
import { formatDate, getBillerGroupErrorMessage } from "./../../utils/helper";

function NewBillerProfileUser(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    location: {
      state: { componentAccess },
    },
  } = props;
  const successMessage = useSelector(
    (state) => state.billerProfileReducer?.newBillerProfileData
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const fiNumber = loginUser.fiNumber;
  const [count, setCount] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  const errorRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showApiValidationErrorMessage, setShowApiValidationErrorMessage] =
    useState(false);

  const apiExceptionRef = useRef();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});
  const [billerProfile, setBillerProfile] = useState({
    billerLanguageCode: "E",
    billerLiveDate: new Date(),
    distributionGroupRcpIndex: "C",
    billerStatus: "I",
  });

  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [initialDeliveryMethods, setInitialDeliveryMethods] = useState({
    deliveryMethod: "",
    distributionListCode: "",
    distributionFormat: "",
    distributionEDIMailbox: "",
    destinationAttention: "",
    distributionPhoneNumber: "",
    distributionExtensionNumber: "",
    distributionGroupRcpIndex: "C",
  });

  const [reqValidationForDelivery, setValidationForDelivery] = useState([]);

  const {
    billerFinancialInstitutionList,
    provinceList,
    deliveryMethodLists,
    formatedMethodMapList,
    errorMessageList,
  } = useCustomDropDownHook({ t, billerId: 0, setIsLoading });
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [
    errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage,
  ]);

  const languageItems = [
    {
      name: t("erps.userprofile.language.english.text", "English"),
      value: "E",
    },
    { name: t("erps.userprofile.language.french.text", "French"), value: "F" },
  ];
  const productType = [
    { label: "APA", value: "APA" },
    { label: "MEBP", value: "MEBP" },
  ];
  const nilReports = [
    { name: t("erps.billermanagement.label.nilReports.yes"), value: "Y" },
    { name: t("erps.billermanagement.label.nilReports.no"), value: "N" },
  ];

  const statusList = [
    { label: "", value: "" },
    { label: t("erps.billermanagement.label.inactive"), value: "I" },
    { label: t("erps.billermanagement.label.active"), value: "A" },
  ];
  const appliesToItems = [
    {
      name: t("erps.billermanagement.label.appliesToItems.link"),
      value: "G",
    },
    {
      name: t("erps.billermanagement.label.appliesToItems.biller"),
      value: "C",
    },
  ];

  const apiExceptionMessage = useSelector(
    (state) => state.billerProfileReducer?.newBillerProfileError
  );

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);

  const newBillerUserComponent = componentAccess.find(
    ({ componentType }) => componentType === "NB"
  );

  const newBillerComponentAccess = newBillerUserComponent?.childComponents;

  const getFormatListItem = (deliveryMethod) => {
    if (formatedMethodMapList) {
      if (deliveryMethod) {
        let data = [];
        const formtedMap = formatedMethodMapList[deliveryMethod];
        if (
          deliveryMethod === "EDI" ||
          deliveryMethod === "FAX" ||
          deliveryMethod === "PC"
        ) {
          let filteredObj;
          if (deliveryMethod === "EDI") {
            filteredObj = { value: "H6 3030", label: formtedMap["H6 3030"] };
          } else if (deliveryMethod === "FAX") {
            filteredObj = {
              value: "DTL FAX EN",
              label: formtedMap["DTL FAX EN"],
            };
          } else if (deliveryMethod === "PC") {
            filteredObj = {
              value: "KITCHENER",
              label: formtedMap["KITCHENER"],
            };
          }

          data = Object.keys(formtedMap)
            .filter((formatKey) => formatKey && formatKey !== filteredObj.value)
            .map((key) => {
              return {
                value: key,
                label: formtedMap[key],
              };
            });
          data.unshift(filteredObj);
          data.unshift({
            value: "",
            label: "",
          });
        } else {
          data = Object.keys(formtedMap).map((key) => {
            return {
              value: key,
              label: formtedMap[key],
            };
          });
          data.unshift({
            value: "",
            label: "",
          });
        }
        data.shift();
        return data;
      }
    }
  };

  const handleClickDeliveryMethod = () => {
    setCount(count + 1);

    setDeliveryMethods([
      ...deliveryMethods,
      {
        deliveryMethod: "",
        distributionListCode: "",
        distributionFormat: "",
        distributionEDIMailbox: "",
        destinationAttention: "",
        distributionPhoneNumber: "",
        distributionExtensionNumber: "",
        distributionGroupRcpIndex: "C",
      },
    ]);
  };

  const handleRedirect = () => {
    props.history.push("/manage-billers", { componentAccess });
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const getEdiMailBoxValue = (val) => {
    if (fiNumber == "16") {
      if (val === "AMEX") {
        return "AMXCIE1";
      } else if (val === "CBSA 3030") {
        return "CBSA";
      } else if (val === "REV CAN 2") {
        return "CCRAADRCPR";
      } else if (val === "REVENUE CA") {
        return "8199562933";
      } else if (val) {
        return "BMO";
      }
    }
    return "";
  };

  const handleChangeInitialDelivery = (key, value) => {
    if (
      key === "distributionFormat" &&
      initialDeliveryMethods?.deliveryMethod === "EDI"
    ) {
      const ediMailBoxValue = getEdiMailBoxValue(value);
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else if (key === "deliveryMethod") {
      let ediMailBoxValue = "";
      const distributionFormatValue = value
        ? getFormatListItem(value)[0]?.value
        : "";
      if (value === "EDI") {
        ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
      }

      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionFormat: distributionFormatValue,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else {
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
      });
    }
  };

  const handleChangeDeliveryMethods = (key, i) => (e) => {
    const value = e.target.value;
    const updatedDeliveryMethods = deliveryMethods.map((item, id) => {
      if (id === i) {
        if (key === "distributionFormat" && item.deliveryMethod === "EDI") {
          const ediMailBoxValue = getEdiMailBoxValue(value);
          return {
            ...item,
            [key]: value,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else if (key === "deliveryMethod") {
          let ediMailBoxValue = "";
          const distributionFormatValue = value
            ? getFormatListItem(value)[0]?.value
            : "";
          if (value === "EDI") {
            ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
          }
          return {
            ...item,
            [key]: value,
            distributionFormat: distributionFormatValue,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else {
          return {
            ...item,
            [key]: value,
          };
        }
      }
      return item;
    });

    setDeliveryMethods(updatedDeliveryMethods);
  };

  const handleRemoveDeliveryMethod = (index) => (e) => {
    setCount(count - 1);
    const slicedArr = deliveryMethods.filter((item, i) => index !== i);
    const slicedValidationForDelivery = reqValidationForDelivery.filter(
      (item, i) => index !== i
    );
    setDeliveryMethods(slicedArr);
    setValidationForDelivery(slicedValidationForDelivery);
  };
  const handleBlur = (key) => (e) => {
    const value = e.target.value;

    setBillerProfile((preBillerData) => ({
      ...preBillerData,
      [key]: value && typeof value === "string" ? value?.toUpperCase() : value,
    }));
  };
  const handleBillerProfile = (key) => (e) => {
    setBillerProfile((prevBillerProfile) => ({
      ...prevBillerProfile,
      [key]: key === "billerLiveDate" ? e : e.target.value,
    }));
  };

  const checkRequiredRecipientCcin = () => {
    const hasCcinRequired =
      (initialDeliveryMethods?.deliveryMethod === "EDI" &&
        initialDeliveryMethods?.distributionFormat === "H6 3030") ||
      deliveryMethods.some(
        (method) =>
          method?.deliveryMethod === "EDI" &&
          method?.distributionFormat === "H6 3030"
      );
    return hasCcinRequired;
  };

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && successMessage !== "ERPS0055" && showSuccessMessage]);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [apiExceptionMessage && showApiExceptionMessage]);

  const getRequiredValidationForDeliveryMethod = () => {
    const deliveryValueValidation = [];
    const numericRegEx10 = /^[0-9]{10}$/;
    const invalidContactNumber = "0000000000";
    const validContactNumberRegex = /^([1-9]){1}[0-9]{9}$/;

    deliveryMethods.forEach(
      ({
        deliveryMethod,
        distributionListCode,
        distributionPhoneNumber,
        distributionGroupRcpIndex,
      }) => {
        let obj = {};
        if (!deliveryMethod) {
          obj = { ...obj, deliveryMethod: true };
        }
        if (!distributionListCode?.trim()) {
          obj = { ...obj, distributionListCode: true };
        } else {
          if (
            billerProfile?.billerLink &&
            distributionGroupRcpIndex === "G" &&
            distributionListCode?.trim()
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (deliveryMethod === "FAX") {
          if (
            distributionListCode?.trim() &&
            !numericRegEx10.test(distributionListCode?.trim())
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (
          distributionPhoneNumber &&
          (!validContactNumberRegex.test(distributionPhoneNumber?.trim()) ||
            distributionPhoneNumber?.trim() == invalidContactNumber)
        ) {
          obj = { ...obj, distributionPhoneNumber: true };
        }
        deliveryValueValidation.push(obj);
      }
    );
    return deliveryValueValidation;
  };

  const checkDistributionLengthValid = () => {
    const numericRegEx10 = /^([1-9]){1}[0-9]{9}$/;
    const invalidContactPhone = "0000000000";

    const initialDistributionNumber =
      initialDeliveryMethods?.distributionPhoneNumber?.toString().trim();
    const hasValidDistributionNumber =
      initialDistributionNumber &&
      (!numericRegEx10.test(initialDistributionNumber) ||
        initialDistributionNumber == invalidContactPhone);
    const hasValidOtherDistributionNumber =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber?.toString()?.trim() &&
          (!numericRegEx10.test(distributionPhoneNumber?.toString()?.trim()) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactPhone)
      );
    return { hasValidDistributionNumber, hasValidOtherDistributionNumber };
  };

  const hasAccess = (key) => {
    if (key === "recipientCcin") {
      return (
        initialDeliveryMethods?.deliveryMethod ||
        deliveryMethods.some((method) => method?.deliveryMethod)
      );
    }
  };

  const checkPhoneNumberValidationForDeliveryMethod = () => {
    const numericRegEx10 = /^[0-9]{10}$/;
    distributionPhoneNumber;
    const hasValidLengthForInitialMethod =
      initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim() &&
      !numericRegEx10.test(
        initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim()
      );

    const hasValidLengthForOtherMethods =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber?.trim() &&
          !numericRegEx10.test(distributionPhoneNumber?.toString()?.trim())
      );
    return { hasValidLengthForInitialMethod, hasValidLengthForOtherMethods };
  };

  const validateBillerProfileUser = (billerProfile) => {
    const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
    const alphaNumericWithSpecialCharRegEx =
      /^[éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\&\#\(\)_\-:;"',\.\/ ]+$/;
    const alphaNumericWithSpecialCharRegExWithFrenchChar =
      /^[0-9A-Za-z\&\#\(\)_\-:;"',\.\/À-ÿ ]+$/;
    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;

    const billerNameRegexWithFrenchChar = /^[0-9A-Za-z-&()_:";',.\/À-ÿ ]+$/;

    const numericRegEx = /^[0-9]+$/;
    const invalidContactPhone = "0000000000";
    const validContactNumberRegEx = /^([1-9]){1}[0-9]{9}$/;
    const numericRegEx10 = /^[0-9]{10}$/;

    const errors = [];
    let highlightErrorV1 = {};
    const regexForSpecialCharacter = /^[À-Ÿ!~@$%^*+=[]{}\|<>?]+$/;
    const regexForSpecialCharacterWithoutFrenchChar = /^[!~@$%^*+=[]{}\|<>?]+$/;

    const alphnumericWithHypen = /^[0-9A-Za-z\-]+$/;
    const billerCustomerNumberFormatRegEx = /^[B9A\-]+$/;

    if (!billerProfile?.billerId) {
      errors.push(t("erps.billermanagement.error.billerid.cannotblank"));
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }
    if (
      billerProfile?.billerId &&
      billerProfile?.billerId?.toString()?.length != 9
    ) {
      errors.push(t("erps.billermanagement.error.billeridShouldBe9"));
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }
    if (!numericRegEx.test(billerProfile?.billerId?.toString())) {
      errors.push(t("erps.billermanagement.error.billeridNumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }

    if (!billerProfile?.billerName) {
      errors.push(
        t("erps.billermanagement.error.cannotblank.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (billerProfile?.billerName && billerProfile?.billerName.length > 45) {
      errors.push(
        t("erps.billermanagement.error.nameNotGreaterThan45.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (!billerNameRegex.test(billerProfile?.billerName)) {
      errors.push(
        t("erps.billermanagement.error.nameAlphaNumeric.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (
      billerProfile?.billerShortName &&
      billerProfile?.billerShortName.length > 20
    ) {
      errors.push(
        "erps.billermanagement.error.nameNotGreaterThan20.billerShortNameEnglish"
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerNameRegex.test(billerProfile?.billerShortName)) {
      errors.push(
        t("erps.billermanagement.error.englishshortNameAlphaNumberic")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }

    if (!billerProfile?.billerShortName) {
      errors.push(
        t("erps.billermanagement.billershortnameenglish.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }

    if (!billerProfile?.billerCity) {
      errors.push(t("erps.billermanagement.error.cityNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (billerProfile?.billerCity && billerProfile?.billerCity.length > 25) {
      errors.push(t("erps.billermanagement.error.cityNotGreaterThan25"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (!billerProfile?.billerProvince) {
      errors.push(t("erps.billermanagement.error.provinceNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerProvince: true };
    }

    if (
      billerProfile?.billerPostalCode &&
      !alphaNumericRegEx.test(billerProfile?.billerPostalCode)
    ) {
      errors.push(t("erps.billermanagement.error.postalCodeAlphaNumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerPostalCode: true };
    }

    if (!billerProfile?.billerLanguageCode) {
      errors.push(t("erps.billermanagement.error.languageNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerLanguageCode: true };
    }
    if (
      billerProfile?.primaryContactName &&
      billerProfile?.primaryContactName.length > 35
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameNotGreaterThan35")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(billerProfile?.primaryContactName)
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactPhone !== undefined &&
      billerProfile?.primaryContactPhone?.toString().length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.telephoneNumberNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    } else if (
      billerProfile?.primaryContactPhone !== undefined &&
      (billerProfile?.primaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.primaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidprimaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }

    if (
      billerProfile?.secondaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(
        billerProfile?.secondaryContactName
      )
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactName: true };
    }

    if (
      billerProfile?.secondaryContactPhone &&
      billerProfile?.secondaryContactPhone?.toString().length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryTelephoneNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    } else if (
      billerProfile?.secondaryContactPhone &&
      (billerProfile?.secondaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.secondaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidsecondaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    }

    if (!billerProfile?.creditorFiNumber) {
      errors.push(t("erps.billermanagement.error.billerFiNotBlank"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        creditorFiNumber: true,
      };

      if (
        billerProfile?.debitTransitNumber &&
        !numericRegEx.test(billerProfile?.debitTransitNumber)
      ) {
        errors.push(
          t("erps.billermanagement.error.debitTransitNumberNumbericValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }

      if (
        numericRegEx.test(billerProfile?.debitTransitNumber) &&
        billerProfile?.debitTransitNumber &&
        billerProfile?.debitTransitNumber.length > 5
      ) {
        errors.push(
          t(
            "erps.billermanagement.error.debitTransitNumberNotGreaterThan5Digit"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }
      if (!alphnumericWithHypen.test(billerProfile?.debitAccountNumber)) {
        errors.push(
          t("erps.billermanagement.error.debitAccountNumberInvalidValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
      }
    }

    if (!billerProfile?.creditorTransitNumber) {
      errors.push(t("erps.billermanagement.error.creditTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      numericRegEx.test(billerProfile?.creditorTransitNumber) &&
      billerProfile?.creditorTransitNumber.length > 5
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNotGreaterThan5Digit")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      !numericRegEx.test(billerProfile?.creditorTransitNumber)
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNumbericValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }

    if (!billerProfile?.creditorAccountNumber) {
      errors.push(t("erps.billermanagement.error.creditAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (!alphnumericWithHypen.test(billerProfile?.creditorAccountNumber)) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNumberInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (
      !initialDeliveryMethods?.deliveryMethod ||
      (deliveryMethods.length &&
        deliveryMethods.some(({ deliveryMethod }) => !deliveryMethod))
    ) {
      errors.push(t("erps.billermanagement.error.deliveryMethodNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, deliveryMethod: true };
    }
    if (
      !initialDeliveryMethods?.distributionListCode.trim() ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode }) => !distributionListCode?.trim()
        ))
    ) {
      if (!initialDeliveryMethods?.distributionListCode.trim()) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.destinationNotBlank"));
    } else if (
      billerProfile?.billerLink &&
      billerProfile?.billerId !== billerProfile?.billerLink
    ) {
      if (
        (initialDeliveryMethods?.distributionListCode.trim() &&
          initialDeliveryMethods?.distributionGroupRcpIndex === "G") ||
        (deliveryMethods.length &&
          deliveryMethods.some(
            ({ distributionListCode, distributionGroupRcpIndex }) =>
              distributionListCode?.trim() && distributionGroupRcpIndex === "G"
          ))
      ) {
        if (
          initialDeliveryMethods?.distributionListCode.trim() &&
          initialDeliveryMethods?.distributionGroupRcpIndex === "G"
        ) {
          highlightErrorV1 = {
            ...highlightErrorV1,
            initialDistributionListCode: true,
          };
        } else {
          highlightErrorV1 = {
            ...highlightErrorV1,
            distributionListCode: true,
          };
        }
        errors.push(
          t("erps.billermanagement.error.memberAsLinkNotEnterdestination")
        );
      }
    }

    if (
      (initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)) ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode, deliveryMethod }) =>
            deliveryMethod === "FAX" &&
            distributionListCode &&
            !numericRegEx10.test(distributionListCode)
        ))
    ) {
      if (
        initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.invaliddestinationBlank"));
    }
    if (
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForInitialMethod ||
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForOtherMethods
    ) {
      if (
        checkPhoneNumberValidationForDeliveryMethod()
          ?.hasValidLengthForInitialMethod
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      errors.push(
        t(
          "erps.billermanagement.error.distributionTelephoneNumberShouldBe10Digit"
        )
      );
    }
    if (
      checkDistributionLengthValid()?.hasValidDistributionNumber ||
      checkDistributionLengthValid()?.hasValidOtherDistributionNumber
    ) {
      if (checkDistributionLengthValid()?.hasValidDistributionNumber) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      // if (
      //   checkDistributionLengthValid()?.hasValidDistributionNumber ||
      //   checkDistributionLengthValid()?.hasValidOtherDistributionNumber
      // ) {
      //   errors.push(
      //     t("erps.billermanagement.error.distributionTelephoneNumberInvalid")
      //   );
      // }
    }

    if (getRequiredValidationForDeliveryMethod().length) {
      const validationForDeliveryMethod =
        getRequiredValidationForDeliveryMethod();
      setValidationForDelivery(validationForDeliveryMethod);
    }
    if (checkRequiredRecipientCcin()) {
      if (
        billerProfile?.recipientCcin === undefined ||
        billerProfile?.recipientCcin === ""
      ) {
        errors.push(t("erps.billermanagement.error.cooperateCreditorNotBlank"));
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
    }
    if (
      billerProfile?.recipientCcin != undefined &&
      billerProfile?.recipientCcin <= 0
    ) {
      errors.push(
        t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
      );
      highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
    }
    if (
      billerProfile?.recipientCcin > 0 &&
      billerProfile?.recipientCcin.toString().length != 8 &&
      numericRegEx.test(billerProfile?.recipientCcin)
    ) {
      errors.push(
        t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
      );
      highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
    }

    if (
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMaximum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMaximum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMaxValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMaximum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMinimum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMinimum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMinValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      parseInt(billerProfile?.billerCustomerNumberMinimum) >
        parseInt(billerProfile?.billerCustomerNumberMaximum)
    ) {
      errors.push(
        t("erps.billermanagement.error.billingAccountMinLessThanMax")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
        billerCustomerNumberMaximum: true,
      };
    }
    if (fiNumber == 16) {
      const billerMinField = billerProfile?.billerCustomerNumberMinimum;
      const billerMaxField = billerProfile?.billerCustomerNumberMaximum;
      const min = parseInt(billerMinField);
      const max = parseInt(billerMaxField);
      if ((isNaN(min) && !isNaN(max)) || (!isNaN(min) && isNaN(max))) {
        const hasMinEmpty = isNaN(min) && !isNaN(max);
        const hasMaxEmpty = !isNaN(min) && isNaN(max);

        if (hasMinEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMinValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMinimum: true,
          };
        } else if (hasMaxEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMaxValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMaximum: true,
          };
        }
      }
    }

    if (
      billerProfile?.billerCustomerNumberFormat &&
      !billerCustomerNumberFormatRegEx.test(
        billerProfile?.billerCustomerNumberFormat
      )
    ) {
      errors.push(t("erps.billermanagement.error.accountFormatInvalidValue"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberFormat: true,
      };
    }
    if (
      billerProfile?.billerComment &&
      billerProfile?.billerComment.length > 160
    ) {
      errors.push(t("erps.billermanagement.error.commentLengthInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, billerComment: true };
    }
    return { errors, highlightErrorV1 };
  };

  const sanitizeBillerData = (jsonObj) => {
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      }
    });

    return jsonObj;
  };

  const handleSave = () => {
    setSaveLoading(true);
    setShowApiExceptionMessage(false);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (showApiValidationErrorMessage) {
      setShowApiValidationErrorMessage(false);
    }
    const billerProfileSanitizedData = sanitizeBillerData(billerProfile);

    const { errors, highlightErrorV1 } = validateBillerProfileUser(
      billerProfileSanitizedData
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      setSaveLoading(false);
      if (errorRef.current) {
        errorRef.current.focus();
      }
    } else {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      billerProfile.languageCode = i18next.language == "en" ? "E" : "F";
      billerProfile.fiNumber = fiNumber;
      billerProfile.userId = loginUser?.userId;
      billerProfile.createdBy = loginUser?.userId;

      let reqBody = {
        ...billerProfile,
        distributionList: [initialDeliveryMethods, ...deliveryMethods],
        billerLiveDate: formatDate(billerProfile.billerLiveDate),
        billerCountry: "CA",
        billerFiNumber: billerProfile?.billerId,
      };
      const nilReportValue = !billerProfile?.nilReportIndex
        ? "N"
        : billerProfile?.nilReportIndex;

      if (nilReportValue) {
        reqBody = { ...reqBody, nilReportIndex: nilReportValue };
      }
      dispatch(
        actions.billerProfileActions.addBillerProfile(
          { ...reqBody },
          {
            t,
            fiNumber,
            setSaveLoading,
            setShowSuccessMessage,
            setShowApiExceptionMessage,
            setInitialDeliveryMethods,
            setDeliveryMethods,
            setCount,
            setBillerProfile,
            setShowApiValidationErrorMessage,
          }
        )
      );
    }
  };

  console.log(
    "errorMessageList.length",
    errorMessageList,
    validationErrors,
    showApiValidationErrorMessage
  );

  return (
    <Box ml={3} role={"main"} aria-labelledby="title">
      {successMessage &&
        successMessage !== "ERPS0055" &&
        successMessage !== "HSBCDUP" &&
        showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}
      <Breadcrumb {...props} componentAccess={componentAccess} />
      <Box className="billerUsers__page__title">
        <Typography variant="h1" id="title">
          {t("erps.billermanagement.button.newbiller", "New Biller")}
        </Typography>
        {isLoading && (
          <Box ml={40}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      {errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={
            errorMessageList.length ||
            validationErrors.length ||
            showApiValidationErrorMessage
              ? 2
              : 0
          }
        >
          {errorMessageList.length ||
          validationErrors.length ||
          showApiValidationErrorMessage ? (
            <ErrorMessage
              error={
                showApiValidationErrorMessage
                  ? [
                      ...errorMessageList,
                      ...validationErrors,
                      ...getBillerGroupErrorMessage(successMessage),
                    ]
                  : [...errorMessageList, ...validationErrors]
              }
              errorRef={errorRef}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      {apiExceptionMessage && showApiExceptionMessage && (
        <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
          <ErrorMessage
            error={apiExceptionMessage}
            errorRef={apiExceptionRef}
          />
        </Box>
      )}
      <Box
        className="manageUsers__page__title"
        role="form"
        aria-labelledby="billerProfile"
      >
        <Typography variant="h2" id="billerProfile">
          {t("erps.billermanagement.page.formtitle", "Biller Profile")}
        </Typography>
        <ERPSBaseTextField
          id="billerId"
          type="number"
          label={t("erps.billerregistration.label.billerId", "Biller ID")}
          className="common__textField__width"
          size="small"
          onChange={handleBillerProfile("billerId")}
          value={billerProfile?.billerId || ""}
          required="true"
          error={highlightError?.billerId}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 9);
          }}
        />
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerName"
            type="text"
            label={t("erps.billermanagement.label.billerName")}
            className="common__textField__width45"
            size="small"
            onChange={handleBillerProfile("billerName")}
            value={billerProfile?.billerName || ""}
            required="true"
            error={highlightError?.billerName}
            inputProps={{ maxLength: 45 }}
            onBlur={handleBlur("billerName")}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerShortName"
            type="text"
            label={t("erps.billermanagement.label.billerShortName")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerShortName")}
            value={billerProfile?.billerShortName || ""}
            error={highlightError?.billerShortName}
            inputProps={{ maxLength: 20 }}
            required={"true"}
            onBlur={handleBlur("billerShortName")}
          />

          <ERPSBaseTextField
            id="billerLink"
            type="number"
            label={t("erps.billermanagement.label.billerLink")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerLink")}
            value={billerProfile?.billerLink || ""}
            error={highlightError?.billerLink}
            inputProps={{ maxLength: 9 }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerDepartment"
            type="text"
            label={t(
              "erps.viewfimanagement.page.subheading.department",
              "Department"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerDepartment")}
            value={billerProfile?.billerDepartment || ""}
            error={highlightError?.billerDepartment}
            inputProps={{ maxLength: 35 }}
          />

          <ERPSBaseTextField
            id="billerAddress"
            type="text"
            label={t("erps.billermanagement.label.address", "Address")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerAddress")}
            value={billerProfile?.billerAddress || ""}
            error={highlightError?.billerAddress}
            inputProps={{ maxLength: 40 }}
          />
          <ERPSBaseTextField
            id="billerCity"
            type="text"
            label={t("erps.billermanagement.label.city", "City")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCity")}
            value={billerProfile?.billerCity || ""}
            required={"true"}
            error={highlightError?.billerCity}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCountry"
            type="text"
            label={t("erps.billermanagement.label.country", "Country")}
            className="common__textField__width"
            size="small"
            value={"CA"}
            inputProps={{ maxLength: 2 }}
            isDisabled={true}
          />

          <ERPSBaseTextField
            id="billerProvince"
            type="text"
            label={t("erps.billermanagement.label.province", "Province")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerProvince")}
            value={billerProfile?.billerProvince || ""}
            select={true}
            items={provinceList}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: Boolean(billerProfile?.billerProvince) }}
            error={highlightError?.billerProvince}
            required="true"
          />
          <ERPSBaseTextField
            id="billerPostalCode"
            type="text"
            label={t("erps.billermanagement.label.postalcode", "Postal code")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerPostalCode")}
            value={billerProfile?.billerPostalCode || ""}
            inputProps={{ maxLength: 7 }}
            error={highlightError?.billerPostalCode}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseRadioGroup
            marginTop=".4rem"
            value={billerProfile?.billerLanguageCode || ""}
            label={t("erps.userprofile.language.text", "Language")}
            onChange={handleBillerProfile("billerLanguageCode")}
            items={languageItems}
            required={"true"}
            error={highlightError?.billerLanguageCode}
          />
          <ERPSBaseTextField
            id="groupPriority"
            type="number"
            label={t("erps.billermanagement.label.priority", "Group priority")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("groupPriority")}
            value={billerProfile?.groupPriority || ""}
            error={highlightError?.groupPriority}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
        </Stack>

        <Box
          mt={4}
          mb={-2}
          role={"form"}
          aria-labelledby={"contactInformation"}
        >
          <Typography variant="h3" id={"contactInformation"}>
            {t(
              "erps.billermanagement.button.contactInfo",
              "Contact information"
            )}
          </Typography>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="primaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.primarycontact",
                "Primary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactName")}
              value={billerProfile?.primaryContactName || ""}
              error={highlightError?.primaryContactName}
              inputProps={{ maxLength: 35 }}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="primaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1 addNewBiller_label_fontSize"
                size="small"
                onChange={handleBillerProfile("primaryContactPhone")}
                value={billerProfile?.primaryContactPhone || ""}
                error={highlightError?.primaryContactPhone}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
              />
              <ERPSBaseTextField
                id="primaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("primaryContactExtension")}
                value={billerProfile?.primaryContactExtension || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="primaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactFax")}
              value={billerProfile?.primaryContactFax || ""}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 18);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="secondaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.secondarycontact",
                "Secondary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactName")}
              value={billerProfile?.secondaryContactName || ""}
              inputProps={{ maxLength: 35 }}
              error={highlightError?.secondaryContactName}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="secondaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1"
                size="small"
                onChange={handleBillerProfile("secondaryContactPhone")}
                value={billerProfile?.secondaryContactPhone || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
              />
              <ERPSBaseTextField
                id="secondaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("secondaryContactExtension")}
                value={billerProfile?.secondaryContactExtension || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="secondaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactFax")}
              value={billerProfile?.secondaryContactFax || ""}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 18);
              }}
            />
          </Stack>
        </Box>

        {
          <>
            {" "}
            <Box mt={4} mb={-2}>
              {
                <>
                  {" "}
                  <Box
                    mt={4}
                    mb={-2}
                    role={"form"}
                    aria-labelledby={"bankAccount"}
                  >
                    <Typography variant="h3" id="bankAccount">
                      {t(
                        "erps.billermanagement.label.bankAccount",
                        "Bank account"
                      )}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <ERPSBaseTextField
                        id="creditorFiNumber"
                        items={billerFinancialInstitutionList}
                        SelectProps={{ native: true }}
                        type="text"
                        select={true}
                        label={t(
                          "erps.billermanagement.label.billerFinancialInstitution",
                          "Biller financial institution"
                        )}
                        className="common__textField__width addNewBiller_label_fontSize"
                        size="small"
                        onChange={handleBillerProfile("creditorFiNumber")}
                        value={billerProfile?.creditorFiNumber || ""}
                        InputLabelProps={{
                          shrink: Boolean(billerProfile?.creditorFiNumber),
                        }}
                        required={"true"}
                        error={highlightError?.creditorFiNumber}
                      />
                      <ERPSBaseTextField
                        id="creditorTransitNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.transitNumber",
                          "Transit number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorTransitNumber")}
                        value={billerProfile?.creditorTransitNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorTransitNumber}
                        inputProps={{ maxLength: 5 }}
                      />
                      <ERPSBaseTextField
                        id="creditorAccountNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.accountNumber",
                          "Account number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorAccountNumber")}
                        value={billerProfile?.creditorAccountNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorAccountNumber}
                        inputProps={{ maxLength: 12 }}
                      />
                    </Stack>
                    <Stack direction="row">
                      <ERPSBaseTextField
                        id="billerStatus"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.status",
                          "Status"
                        )}
                        className="common__textField__width"
                        size="small"
                        value={billerProfile?.billerStatus}
                        error={highlightError?.billerStatus}
                        select={true}
                        items={statusList}
                        SelectProps={{ native: true }}
                        isDisabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Box mt={2} ml={2}>
                        <ERPSBaseDatePicker
                          label={t(
                            "erps.billermanagement.label.liveDate",
                            "Live date"
                          )}
                          hasMinMaxNotCheck={true}
                          value={billerProfile?.billerLiveDate}
                          className="common__textField__width"
                          onChange={handleBillerProfile("billerLiveDate")}
                          required={true}
                          maxDate={new Date("2100-01-01")}
                          minDate={new Date("1900-01-01")}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </>
              }
            </Box>
          </>
        }
      </Box>
      <Box role={"form"} aria-Labelledby={"paymentAdviceFileDetails"} mt={8}>
        <div className="manageUsers__page__title">
          <Typography variant="h2" id="paymentAdviceFileDetails">
            {t(
              "erps.billermanagement.label.paymentAdviceFileDetails",
              "Payment advice file details"
            )}
          </Typography>
        </div>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="deliveryMethod"
            type="text"
            label={t(
              "erps.billermanagement.label.deilveryMethod",
              "Delivery method"
            )}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("deliveryMethod", e.target.value)
            }
            select={true}
            items={deliveryMethodLists}
            value={initialDeliveryMethods?.deliveryMethod}
            error={
              !initialDeliveryMethods.deliveryMethod &&
              highlightError?.deliveryMethod
            }
            required={"true"}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: initialDeliveryMethods?.deliveryMethod ? true : false,
            }}
          />
          <ERPSBaseTextField
            id="distributionFormat"
            type="text"
            label={t("erps.billermanagement.label.format", "Format")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("distributionFormat", e.target.value)
            }
            select={true}
            value={initialDeliveryMethods?.distributionFormat}
            items={getFormatListItem(initialDeliveryMethods?.deliveryMethod)}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink:
                initialDeliveryMethods?.distributionFormat ||
                initialDeliveryMethods?.deliveryMethod
                  ? true
                  : false,
            }}
          />
          <ERPSBaseTextField
            id="distributionListCode"
            type="text"
            label={t("erps.billermanagement.label.destination", "Destination")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionListCode",
                e.target.value
              )
            }
            value={initialDeliveryMethods.distributionListCode}
            required={"true"}
            error={highlightError?.initialDistributionListCode}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="destinationAttention"
            type="text"
            label={t("erps.billermanagement.label.attention", "Attention")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "destinationAttention",
                e.target.value
              )
            }
            value={initialDeliveryMethods.destinationAttention}
            inputProps={{ maxLength: 30 }}
          />
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionPhoneNumber"
              type="number"
              label={t(
                "erps.billermanagement.label.telNum",
                "Telephone number"
              )}
              className="common__textField__width_v1"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionPhoneNumber",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionPhoneNumber}
              inputProps={{ maxLength: 10 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
              error={highlightError?.initialDistributionPhoneNumber}
            />
            <ERPSBaseTextField
              id="distributionExtensionNumber"
              type="number"
              label={t("erps.billermanagement.label.ext", "Ext.")}
              className="common__textField__width_ext"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionExtensionNumber",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionExtensionNumber}
              inputProps={{ maxLength: 5 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 5);
              }}
            />
          </Stack>
        </Stack>
        {initialDeliveryMethods.deliveryMethod === "EDI" && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionEDIMailbox"
              type="text"
              label={t("erps.billermanagement.label.ediMailBox", "EDI mailbox")}
              className="common__textField__width"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionEDIMailbox",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionEDIMailbox}
              isDisabled={true}
            />
          </Stack>
        )}
        <Stack direction="row" spacing={0}>
          <ERPSBaseRadioGroup
            value={initialDeliveryMethods?.distributionGroupRcpIndex}
            label={t("erps.billermanagement.appliesTo.text", "Applies to")}
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionGroupRcpIndex",
                e.target.value
              )
            }
            items={appliesToItems}
            error={highlightError?.distributionGroupRcpIndex}
          />
        </Stack>
        {count > 1 && (
          <DeliveryMethodComponent
            deliveryMethods={deliveryMethods}
            handleChangeDeliveryMethods={handleChangeDeliveryMethods}
            getFormatListItem={getFormatListItem}
            deliveryMethodLists={deliveryMethodLists}
            handleRemoveDeliveryMethod={handleRemoveDeliveryMethod}
            handleClickDeliveryMethod={handleClickDeliveryMethod}
            count={count}
            error={reqValidationForDelivery}
            appliesToItems={appliesToItems}
            fiNumber={fiNumber}
          />
        )}

        <Box mt={count === 1 ? 3 : 0}>
          {count === 1 && (
            <Stack direction="row" spacing={0}>
              <ERPSBaseLink
                className="link__blue"
                label={t(
                  "erps.billermanagement.label.addDeilveryMethod",
                  "Add a delivery method"
                )}
                target={""}
                onClick={() => handleClickDeliveryMethod()}
              />
            </Stack>
          )}
          {hasAccess("recipientCcin") && (
            <Stack direction={"row"}>
              <ERPSBaseTextField
                id="recipientCcin"
                type="number"
                label={t(
                  "erps.billermanagement.label.ccin",
                  "Cooperate creditor id number"
                )}
                className="common__textField__width_v4"
                size="small"
                onChange={handleBillerProfile("recipientCcin")}
                value={billerProfile?.recipientCcin || ""}
                error={highlightError?.recipientCcin}
                required={checkRequiredRecipientCcin() ? "true" : ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 8);
                }}
              />
            </Stack>
          )}
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.billermanagement.label.deliveryDetails",
              "Delivery details"
            )}
          </Typography>
        </Box>

        <ERPSBaseRadioGroup
          marginTop=".4rem"
          value={billerProfile?.nilReportIndex || "N"}
          label={t("erps.billermanagement.label.nilReports", "Nil reports")}
          id="nilReportIndex"
          onChange={handleBillerProfile("nilReportIndex")}
          items={nilReports}
          required={"true"}
          error={highlightError?.nilReportIndex}
        />
      </Box>
      <Box
        className="manageUsers__page__title"
        role={"form"}
        aria-Labelledby={"billingAccountValidation"}
      >
        <Typography variant="h2" id="billingAccountValidation">
          {t(
            "erps.billermanagement.label.billingAccountValidation",
            "Billing account validations"
          )}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCustomerNumberFormat"
            type="text"
            label={t(
              "erps.billermanagement.label.accountFormat",
              "Account format"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberFormat")}
            value={billerProfile?.billerCustomerNumberFormat || ""}
            error={highlightError?.billerCustomerNumberFormat}
            inputProps={{ maxLength: 45 }}
          />

          <ERPSBaseTextField
            id="billerCustomerNumberMinimum"
            type="number"
            label={t(
              "erps.billermanagement.label.minimumLength",
              "Minimum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMinimum")}
            value={billerProfile?.billerCustomerNumberMinimum || ""}
            error={highlightError?.billerCustomerNumberMinimum}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
          <ERPSBaseTextField
            id="billerCustomerNumberMaximum"
            type="number"
            label={t(
              "erps.billermanagement.label.maximumLength",
              "Maximum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMaximum")}
            value={billerProfile?.billerCustomerNumberMaximum || ""}
            error={highlightError?.billerCustomerNumberMaximum}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
        </Stack>
      </Box>
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.comment", "Comments")}
        </Typography>
      </Box>
      <Box mt={1}>
        <TextareaAutosize
          aria-label="Comments"
          placeholder={t("erps.billermanagement.label.comment", "Comments")}
          className={"common__textField__prefix_text"}
          id="billerComment"
          onChange={handleBillerProfile("billerComment")}
          value={billerProfile?.billerComment || ""}
          error={highlightError?.billerComment}
          maxLength={160}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.newuser.button.cancel", "Cancel")}
          actionType={"hasCancelNewBillerAccess"}
          componentAccess={newBillerComponentAccess}
          onClick={handleOpenCancelDialog}
        />
        <ERPSBaseButton
          variant="primary"
          onClick={handleSave}
          className="button_with_margin48"
          label={t("erps.newuser.button.save", "Save")}
          loading={saveLoading}
          actionType={"hasSaveNewBillerAccess"}
          componentAccess={newBillerComponentAccess}
        />
      </Stack>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </Box>
  );
}

export default NewBillerProfileUser;
